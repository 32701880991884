/**
    * function that tests if an object is a dictionary
    * @param d the object to test
    */
export function isDictionary(d: any): boolean { return (d != null && d.name === "OMRPDictionary"); }

/**
    * dictionary can store objects for various key types, warning: do not use objects as keys
    */
export class Dictionary {
    name = "OMRPDictionary";
    protected dict: Object;
    protected len = -1; // constructor will initialize everything trough the clear method if len !== 0
    protected version = 0;

    /**
        * create a new empty dictionary
        */
    constructor(dict: Object = null){
        this.clear();
        if(dict) this.dict = dict;
    }

    /**
        * clear the dictionary of all items
        */
    clear() {
        if (this.len !== 0) {
                // create new object that has 0 inherited properties
            this.dict = Object.create(null);
            this.len = 0;
            this.version++;
        }
    }

    /**
        * get the object where the elements are stored
        */
    getStore(): Object {
        return this.dict;
    }

    /**
        * loop over all items in the dictionary
        * usage: dicTest.forEach((key, value) => { console.log(key, " = ", value); });
        * @param action the acion to take for each item
        */
    forEach(action: (key: any, value: any) => void) {
        for (var key in this.dict) {
            action(key, this.dict[key]);
        }
    }

    /**
        * try to get an item from the dictionary and call a function if it exists
        * usage: if (!dicTest.tryGetValue(key, (value) => { console.log(key, " = ", value); })) console.log(key, " not found");
        * @param key the key to find
        * @param action the action to take for the item if it exists
        */
    tryGetValue(key: any, action: (value: any) => void) {
        if (Object.prototype.hasOwnProperty.call(this.dict, key)) {
            action(this.dict[key]);
            return true;
        } else
            return false;
    }

    /**
        * returns the number of keys
        */
    get count(): number {
        if (this.len < 0) {
            this.len = 0;
            for (var key in this.dict)
                this.len++;
        }
        return this.len;
    }

    /**
        * returns if a key exists
        * @param key the key to search for
        */
    containsKey(key: any): boolean {
        return Object.prototype.hasOwnProperty.call(this.dict, key);
    }

    /**
        * returns undefined if key does not exist, note that undefined can also be a valid value for an existing key
        * @param key the key to get the value for
        */
    value(key: any): any {
        return this.dict[key];
    }

    /**
        * add a new key or overwrite an existing key
        * @param key the unique key
        * @param value the value
        */
    add(key: any, value: any) {
        this.dict[key] = value;
        this.len = -1;
        this.version++;
    }

    /**
        * remove a key if it exists, nothing happens if the key does not exist
        * @param key the key to remove
        */
    remove(key: any) {
        delete this.dict[key];
        this.len = -1;
        this.version++;
    }
}

/**
    * dictionary that stores items in an ordered list by key
    */
export class OrderedDictionary extends Dictionary {
    private order: any[]; // order of added keys

    /**
        * clear the dictionary of all items
        */
    clear() {
        if (this.len !== 0) {
            // create new object that has 0 inherited properties
            this.dict = Object.create(null);
            if (this.order == null || this.order.length !== 0) this.order = [];
            this.len = 0;
            this.version++;
        }
    }

    /**
        * loop over all items in the dictionary in the order in wich they were added
        * usage: dicTest.forEach((key, value) => { console.log(key, " = ", value); });
        * @param action the acion to take for each item
        */
    forEach(action: (key: any, value: any) => void) {
        var toIndex = this.order.length;
        for (var i = 0; i < toIndex; i++) {
            action(this.order[i], this.dict[this.order[i]]);
        }
    }

    /**
        * add a new key or overwrite an existing key
        * @param key the unique key
        * @param value the value
        */
    add(key: any, value: any) {
        if (!this.containsKey(key)) this.order.push(key);
        this.dict[key] = value;
        this.len = -1;
        this.version++;
    }

    /**
        * remove a key if it exists, nothing happens if the key does not exist
        * @param key the key to remove
        */
    remove(key: any) {
        if (this.order.length !== 0) {
            var index = this.order.indexOf(key);
            if (index >= 0) this.order.splice(index, 1);
        }
        delete this.dict[key];
        this.len = -1;
        this.version++;
    }
}