import { deInitializeAll } from './../components/planboard/entities/initialization';
import { IConfigurationService } from './configurationService';

export interface IUserService {

    isAuthenticated: boolean;

    /**
        * true if the user is a full web api user
        * false for only a resource user
        * Null if we do not know yet. only full users use web sockets.
        */
    isFullUser?: boolean;

    /**
        * set the time to wait before we can logoff for a specific view
        */
    setLogoffWaitTime(viewUniqueKey: string, waitTime: number): void;

    /**
        * get the maximum time to wait before we can logoff
        */
    getLogoffWaitTime(): number;

    /**
        * clear information about logged on user, does not send api messages
        */
    logoff(): void;

    /**
        * function to return the users displayname or emtpy string if not logged on
        */
    getUserDisplayName(): string;

    /**
        * function to return the users name or emtpy string if not logged on
        */
    getUserName(): string;

    /**
        * function to return the users id or 0 if not logged on
        */
    getUserId(): number;

    /**
        * refresh the current page
        */
    refreshPage(): void;

    /**
        * register an event that will be called after user settings are loaded
        */
    registerUserSettingsEvent(uniqueName: string, callbackFunction: any): void;

    /**
        * unregister an event for user settings
        */
    unregisterUserSettingsEvent(uniqueName: string): void;

    /**
        * get all user display settings for logged on user
        */
    retrieveDisplaySettings(): void;

    /**
        * returns if user settings have been loaded
        */
    userSettingsLoaded(): boolean;

    /**
        * get one user displaysetting or return the defaultValue if the setting does not exist
        */
    getDisplaySetting(settingName: string, defaultValue: string): string;

    /**
        * set one user displaysetting
        */
    setDisplaySetting(settingName: string, settingValue: string, cached?: boolean): void;

    /**
        * get if a displaysetting is enabled or disabled (true or false)
        */
    getDisplaySettingSwitch(settingName: string): boolean;

    /**
        * enable or disable a displaysetting (true or false)
        */
    setDisplaySettingSwitch(settingName: string, enabled: boolean, cached?: boolean): void;

    /**
        * get a displaySetting as a number
        */
    getDisplaySettingNumber(settingName: string, defaultValue: number): number;

    /**
        * set a displaySetting as a number
        */
    setDisplaySettingNumber(settingName: string, value: number, cached?: boolean): void;

    /**
        * get the variable store for the current user
        */
    getUserVariableStore(): Object;

    /**
        * set a variable for the current user
        */
    setUserVariable(name: string, value: any): void;

    /**
        * delete a variable for the current user
        */
    deleteUserVariable(name: string): void;

    /**
        * get a variable for the current user
        */
    getUserVariable(name: string): any;

    /**
        * deletes all user variables that start with a specific string
        */
    deleteUserVariables(startwith: string): void;

    /**
        * set multiple variables at once
        */
    setUserVariables(objectlist: Object): void;

    /**
        * returns if the name of a state is part of resourceManagement
        */
    isResourceManagementState(name: string): boolean;

    /**
        * get the path to the reports page for only a resource user
        */
    retrieveReportsPageForResourceUser(): void;
}

export var userService = [
    "$state", "$http", "$rootScope", "$q", "configurationService",
    function (
        $state,
        $http,
        $rootScope: angular.IRootScopeService,
        $q,
        configurationService: IConfigurationService,

    ) {
        var svc = this;
        svc.userInfo = null;
        svc.isAuthenticated = false;

        configurationService.getAppConfiguration(() => {
            const useNewOverviewScreens = configurationService.appConfiguration.featureManagement.featureOverviewPlanning;

            if (useNewOverviewScreens) {
                svc.reportsPageForResourceUserAngular = "angularomrp/overviews/schedule-overview";
            }
            else {
                svc.reportsPageForResourceUserAngular = "angularomrp/overviews/schedule-overview-precursor";
            }
        });

        svc.reportsPageForResourceUserSsrs = "#!/reports";
        svc.isFullUser = null; // true if the user is a full web api user or false for only a resource user. Null if we do not know yet. only full users use web sockets.
        svc.displaySettingsForUserId = 0; // for what userId displaySettings have been loaded
        svc.displaySettings = Object.create(null);
        svc.cachedDisplaySettings = Object.create(null);
        svc.waitTimerRunning = Object.create(null);
        svc.onUserSettingsLoaded = Object.create(null);
        svc.userVariables = Object.create(null);

        // set the time to wait before we can logoff for a specific view
        svc.setLogoffWaitTime = function (viewUniqueKey, waitTime) {
            if (waitTime === 0)
                delete svc.waitTimerRunning[viewUniqueKey];
            else
                svc.waitTimerRunning[viewUniqueKey] = waitTime;
        }

        // get the maximum time to wait before we can logoff
        svc.getLogoffWaitTime = function () {
            var maxWait = 0;
            for (var key in svc.waitTimerRunning) maxWait = Math.max(maxWait, svc.waitTimerRunning[key]);
            return maxWait;
        }

        // clear information about logged on user, does not send api messages 
        svc.logoff = function () {
            svc.userInfo = null;
            svc.isAuthenticated = false;
            svc.isFullUser = null;
            deInitializeAll(); // clear all planboard data
        }

        // function to return the users displayname or emtpy string if not logged on
        svc.getUserDisplayName = function () {
            return (svc.userInfo ? svc.userInfo.displayName : "");
        }

        // function to return the users name or emtpy string if not logged on
        svc.getUserName = function () {
            return (svc.userInfo ? svc.userInfo.userName : "");
        }

        // function to return the users id or 0 if not logged on
        svc.getUserId = function () {
            return (svc.userInfo ? svc.userInfo.id : 0);
        }

        // refresh the current page
        svc.refreshPage = function () {
            $state.transitionTo($state.current, $state.params, { reload: true, inherit: false, notify: true });
        }

        // register an event that will be called after user settings are loaded
        svc.registerUserSettingsEvent = function (uniqueName, callbackFunction) {
            svc.onUserSettingsLoaded[uniqueName] = callbackFunction;
        }

        // unregister an event for user settings
        svc.unregisterUserSettingsEvent = function (uniqueName) {
            delete svc.onUserSettingsLoaded[uniqueName];
        }

        // get all user display settings for logged on user
        svc.retrieveDisplaySettings = function (forceReload: boolean = false) {
            // already loaded for current user?
            if (forceReload === false && svc.displaySettingsForUserId === svc.getUserId()) {
                $rootScope.$broadcast("isUserLoggedIn", { isUserLoggedIn: true });

                return $q(function (resolve, reject) {
                    resolve("No loading of user display settings needed.");
                });
            }
            
            // reset displaySettings to empty object
            if (svc.displaySettingsForUserId !== 0) {
                svc.displaySettingsForUserId = 0;
                svc.displaySettings = Object.create(null);
            }
            // load for current user
            return $http.get("api/Users/DisplaySettings")
                .then(function (response) {
                    var data = response.data;
                    if (data != null && data.length != null)
                        for (var i = 0; i < data.length; i++)
                            svc.displaySettings[data[i].settingName] = data[i].settingValue;
                    svc.displaySettingsForUserId = svc.getUserId();

                    for (var key in svc.onUserSettingsLoaded) {
                        if (svc.onUserSettingsLoaded[key]) {
                            svc.onUserSettingsLoaded[key]();
                        }
                    }

                    $rootScope.$broadcast("isUserLoggedIn", { isUserLoggedIn: true });
                });
        }

        // returns if user settings have completed loading
        svc.userSettingsLoaded = function () {
            return svc.displaySettingsForUserId !== 0;
        }

        // get one user displaysetting or return the defaultValue if the setting does not exist
        svc.getDisplaySetting = function (settingName, defaultValue) {
            if (svc.displaySettings == null) return defaultValue;
            if (Object.prototype.hasOwnProperty.call(svc.displaySettings, settingName))
                return svc.displaySettings[settingName];
            return defaultValue;
        }

        // set one user displaysetting
        svc.setDisplaySetting = function (settingName, settingValue, cached) {
            if (svc.displaySettings == null ||
                (svc.displaySettings[settingName] === settingValue &&
                    svc.cachedDisplaySettings[settingName] == null)) return;
            svc.displaySettings[settingName] = settingValue;
            if (cached)
                svc.cachedDisplaySettings[settingName] = settingValue;
            else
                $http.post("api/Users/DisplaySettings", { settingName: settingName, settingValue: settingValue })
                    .then(function (success) {
                        svc.cachedDisplaySettings[settingName] = null;
                    }.bind(this));
        }

        // get if a displaysetting is enabled or disabled (true or false)
        svc.getDisplaySettingSwitch = function (settingName) { return svc.getDisplaySetting(settingName, "0") !== "0"; }

        // enable or disable a displaysetting (true or false)
        svc.setDisplaySettingSwitch = function (settingName, enabled, cached) { svc.setDisplaySetting(settingName, enabled ? "1" : "0", cached); }

        // get a displaySetting as a number
        svc.getDisplaySettingNumber = function (settingName, defaultValue) {
            return parseFloat(svc.getDisplaySetting(settingName,
                defaultValue != null ? defaultValue.toString() : null));
        }

        // set a displaySetting as a number
        svc.setDisplaySettingNumber = function (settingName, value, cached) {
            svc.setDisplaySetting(settingName, value != null ? value.toString() : null, cached);
        }

        // get the variable store for the current user
        svc.getUserVariableStore = function () {
            var userName = svc.getUserName();
            var userStore = svc.userVariables[userName];
            if (userStore == undefined) {
                userStore = Object.create(null);
                svc.userVariables[userName] = userStore;
            }
            return userStore;
        }

        // set a variable for the current user
        svc.setUserVariable = function (name, value) { svc.getUserVariableStore()[name] = value; }

        // delete a variable for the current user
        svc.deleteUserVariable = function (name) { delete svc.getUserVariableStore()[name]; }

        // get a variable for the current user
        svc.getUserVariable = function (name) { return svc.getUserVariableStore()[name]; }

        // deletes all user variables that start with a specific string
        svc.deleteUserVariables = function (startwith) {
            var store = svc.getUserVariableStore();
            var deletekeys = [];
            var len = startwith.length;
            for (var key in store)
                if (Object.prototype.hasOwnProperty.call(store, key))
                    if (key.substring(0, len) === startwith)
                        deletekeys.push(key);
            for (var i = 0; i < deletekeys.length; i++)
                delete store[deletekeys[i]];
        }

        // set multiple variables at once
        svc.setUserVariables = function (objectlist) {
            for (var key in objectlist)
                if (Object.prototype.hasOwnProperty.call(objectlist, key))
                    svc.setUserVariable(key, objectlist[key]);
        }

        // returns if the name of a state is part of resourceManagement
        svc.isResourceManagementState = function (name) {
            return (name === "resourceManagement" || name === "parttimeSchedule" || name === "periodBoundProperties");
        }

        // retrieve the path to the reports page for only a resource user
        svc.retrieveReportsPageForResourceUser = function () {
             // TO DO: fill the reportsPageForResourceUser with path from request to get it from app settings;
        }
    }
];