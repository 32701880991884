import './create-nonce.js';
import * as angular from 'angular';
import singleSpaAngularJS from 'single-spa-angularjs';

import 'jquery';
import '@uirouter/angularjs';
import 'angular-animate/angular-animate.min.js';
import 'angular-cookies/angular-cookies.min.js';
import 'angular-dynamic-locale';
import 'angular-ui-bootstrap';
import 'angular-ui-tree/dist/angular-ui-tree.js';
import 'angular-applicationinsights';
import 'bootstrap';
import 'bootstrap-material-design/dist/js/material.min.js';
import 'bootstrap-material-design/dist/js/ripples.min.js';
import './../assets/js/inputSelectAll.js';

import noUiSliderModule from 'angularjs-nouislider';

import { SSOLoginData, SaveSSOTokenController } from './components/login/saveSSOTokenController';

import { routeconfig, stateconfig, clickconfig, localeconfig, auth0config, authinterceptorconfig } from './app.routes';
import { authenticationService } from './components/login/authenticationService';
import { authInterceptorService } from './components/login/authInterceptorService';
import { tokenExpiredInterceptorService } from './components/login/tokenExpiredInterceptorService';
import { environmentProvider, IEnvironmentProvider } from './shared/environmentService';
import { notificationService } from './components/login/notificationService';
import { userService } from './shared/userService';
import { planningStatusService } from './shared/planningStatusService';
import { numberService } from './shared/numberService';
import { translationService } from './components/i18n/translationService';
import { modalConfirmationWindowService, modalConfirmationWindowCtrl } from './components/utils/modalConfirmationWindowService';
import { pageStartService } from './shared/pageStartService';
import { permissionService } from './components/permissions/permissionService';
import { featureService } from './components/features/featureService';
import { configurationService } from './shared/configurationService';
import { angularRedirectService } from './shared/angularRedirectService';

import { omrpCollapsible } from './../assets/js/collapsible';
import { omrpColorpicker } from './../assets/js/colorpicker';
import { omrpDatepicker } from './../assets/js/datepicker';
import { omrpDropdownFilter } from './../assets/js/dropdownFilter';
import { omrpDropdownTree } from './../assets/js/dropdownTree';
import { omrpDynamicInput } from './../assets/js/dynamicInput';
import { omrpSlider } from './../assets/js/slider';
import { omrpTaglist } from './../assets/js/taglist';

// Uncomment the line below to enable the performance test page on a release build(DANGEROUS).
// import { PerfTestCtrl } from './components/performanceTest/performanceTestController';
import { activityTypesCtrl } from './components/programManagement/activityTypes/activityTypesController';
import { LangSwitchCtrl } from './components/i18n/langswitchController';
import { MonitoringPageComponentCtrl } from './components/monitoring/monitoringPageComponentController';
import { MonitoringPageCtrl } from './components/monitoring/monitoringPageController';
import { MonitoringPageCtrlBase } from './components/monitoring/monitoringPageControllerBase';
import { MonitoringPageInstanceCtrl } from './components/monitoring/monitoringPageInstanceController';
import { PlanboardCtrl } from './components/planboard/PlanboardController';

import { ActivityController } from './components/activityManagement/activityController';
import { ActivityPasteController } from './components/activityPaste/activityPasteController';
import { ChangesInPlanningController } from './components/changesInPlanning/changesInPlanningController';
import { CustomCounterPeriodController } from './components/customCounterPeriod/customCounterPeriodController';
import { ExchangeUpdateConfigurationController } from './components/exchangeConfiguration/exchangeUpdateConfigurationController';
import { NarrowCastingConfigCtrl } from './components/narrowCastingConfiguration/narrowCastingConfigurationController';
import { AuthorizeController } from './components/login/authorizeController';
import { LoginController } from './components/login/loginController';
import { LoginLogoutLinkController } from './components/login/loginLogoutLinkController';
import { MergeConflictsController } from './components/mergeConflicts/mergeConflictsController';
import { MultiSelectController } from './components/multiSelect/multiSelectController';
import { PlanningStatusController } from './components/planningStatus/planningStatusController';
import { PortalPageController } from './components/portal/portalPageController';

import { HolidaysController } from './components/programManagement/holidays/holidaysController';
import { OrganizationUnitsController } from './components/programManagement/organizationUnits/organizationUnitsController';
import { OrganizationUnitSettingsController } from './components/programManagement/organizationUnitSettings/organizationUnitSettingsController';
import { ResourcePropertiesController } from './components/programManagement/resourceProperties/resourcePropertiesController';
import { ResourceTypesController } from './components/programManagement/resourceTypes/resourceTypesController';
import { SkillLevelsController } from './components/programManagement/skillLevels/skillLevelsController';
import { SkillsController } from './components/programManagement/skills/skillsController';
import { UserGroupsController } from './components/programManagement/userGroups/userGroupsController';
import { UsersController } from './components/programManagement/users/usersController';
import { ProgramManagementController } from './components/programManagement/programManagementController';

import { OverviewsController } from './components/overviews/overviewsController';
import { ReplaceResourceController } from './components/replaceResource/replaceResourceController';
import { ReportsController } from './components/reports/reportsController';
import { SingleReportController } from './components/reports/singleReportController';
import { ResourceCombinationsController } from './components/resourceCombinations/resourceCombinationsController';
import { ResourcesController } from './components/resourceManagement/resourcesController';
import { ResourceParttimeScheduleController } from './components/resourceParttimeSchedule/resourceParttimeScheduleController';
import { ResourcePeriodBoundPropertiesController } from './components/resourcePeriodBoundProperties/resourcePeriodBoundPropertiesController';
import { ScenarioManagementController } from './components/scenarioManagement/scenarioManagementController';
import { ActivityTypeFilterAndSortingController } from './components/activityTypeFilterAndSorting/activityTypeFilterAndSortingController';
import { SolverManagementController } from './components/solverManagement/solverManagementController';
import { SystemAdministrationController } from './components/systemAdministration/systemAdministrationController';
import { UpgradeDaymarkController } from './components/upgradeDaymark/upgradeDaymarkController';
import { UserPreferencesController } from './components/userPreferences/userPreferencesController';
import { ViolationsController } from './components/violations/violationsController';
import { YearPlanningController } from './components/yearPlanning/yearPlanningController';

import { indexPage } from './components/indexPage/indexPageController';
import { modalWithCheckbox } from './components/modalWithCheckbox/modalWithCheckbox';
import { userGroupPermissionsItem } from './components/utils/userGroupPermissionsItem';
import { userGroupPermissionsTable } from './components/utils/userGroupPermissionsTable';
import { userGroupPermissionsTree } from './components/utils/userGroupPermissionsTree';

// Stylesheets
// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Content/custom-bootstrap.css';
//Material icons + Roboto font
import "bootstrap-material-design-icons/css/material-icons.min.css";
import "roboto-font/css/fonts.css";
//Bootstrap Material Design
import "bootstrap-material-design/dist/css/bootstrap-material-design.min.css";
import "bootstrap-material-design/dist/css/ripples.min.css";
//nouislider
import "nouislider/distribute/nouislider.min.css";
//Spinner
import "../assets/css/spinners/loader.css";
//angular-ui-tree
import "angular-ui-tree/dist/angular-ui-tree.min.css";
//ui controls
import "../assets/css/collapsible.css";
//csp styles
import "angular/angular-csp.css";
import "angular-ui-bootstrap/dist/ui-bootstrap-csp.css";
//Our own styles
import "../assets/css/site.css";

let baseUrl = undefined;

// !!!!!!!!!!!!!!!!!!
// NOTE: please note that we have an on-prem-app.ts too that is not part of the solution but is visible in perforce
// (same folder as this file). If you make changes here, please also change them in the on-prem-app.ts file too!
// !!!!!!!!!!!!!!!!!!

angular.module('omrp', [
    'ui.router',
    'ui.bootstrap',
    'ngCookies',
    'tmh.dynamicLocale',
    'ui.tree',
    noUiSliderModule,
    'auth0.auth0',
    'ApplicationInsightsModule'
])
    .factory('SSOLoginData', SSOLoginData)

    .provider('environment', environmentProvider)

    .service('authenticationService', authenticationService)
    .service('authInterceptorService', authInterceptorService)
    .service('tokenExpiredInterceptorService', tokenExpiredInterceptorService)
    .service('notificationService', notificationService)
    .service('userService', userService)
    .service('planningStatusService', planningStatusService)
    .service('numberService', numberService)
    .service('translationService', translationService)
    .service('modalConfirmationWindowService', modalConfirmationWindowService)
    .service('pageStartService', pageStartService)
    .service('permissionService', permissionService)
    .service('featureService', featureService)
    .service('configurationService', configurationService)
    .service('angularRedirectService', angularRedirectService)

    .directive('omrpCollapsible', omrpCollapsible)
    .directive('omrpColorpicker', omrpColorpicker)
    .directive('omrpDatepicker', omrpDatepicker)
    .directive('omrpDropdownFilter', omrpDropdownFilter)
    .directive('omrpDropdownTree', omrpDropdownTree)
    .directive('omrpDynamicInput', omrpDynamicInput)
    .directive('omrpSlider', omrpSlider)
    .directive('omrpTaglist', omrpTaglist)

    // Uncomment the line below to enable the performance test page on a release build(DANGEROUS).
    // .controller('PerfTestCtrl', PerfTestCtrl)
    .controller('SaveSSOTokenController', SaveSSOTokenController)
    .controller('ModalConfirmationWindowCtrl', modalConfirmationWindowCtrl)
    .controller('ActivityTypesCtrl', activityTypesCtrl)
    .controller('LangSwitchCtrl', LangSwitchCtrl)
    .controller('MonitoringPageComponentCtrl', MonitoringPageComponentCtrl)
    .controller('MonitoringPageCtrl', MonitoringPageCtrl)
    .controller('MonitoringPageCtrlBase', MonitoringPageCtrlBase)
    .controller('MonitoringPageInstanceCtrl', MonitoringPageInstanceCtrl)
    .controller('PlanboardCtrl', PlanboardCtrl)

    .controller('activityController', ActivityController)
    .controller('activityPasteController', ActivityPasteController)
    .controller('changesInPlanningController', ChangesInPlanningController)
    .controller('customCounterPeriodController', CustomCounterPeriodController)
    .controller('exchangeUpdateConfigurationController', ExchangeUpdateConfigurationController)
    .controller('authorizeCtrl', AuthorizeController)
    .controller('loginController', LoginController)
    .controller('loginLogoutLinkController', LoginLogoutLinkController)
    .controller('mergeConflictsController', MergeConflictsController)
    .controller('multiSelectController', MultiSelectController)
    .controller('narrowCastingConfigCtrl', NarrowCastingConfigCtrl)
    .controller('planningStatusController', PlanningStatusController)
    .controller('portalPageController', PortalPageController)

    .controller('holidaysController', HolidaysController)
    .controller('organizationUnitsController', OrganizationUnitsController)
    .controller('organizationUnitSettingsController', OrganizationUnitSettingsController)
    .controller('overviewsController', OverviewsController)
    .controller('resourcePropertiesController', ResourcePropertiesController)
    .controller('resourceTypesController', ResourceTypesController)
    .controller('skillLevelsController', SkillLevelsController)
    .controller('skillsController', SkillsController)
    .controller('userGroupsController', UserGroupsController)
    .controller('usersController', UsersController)
    .controller('programManagementController', ProgramManagementController)

    .controller('replaceResourceController', ReplaceResourceController)
    .controller('reportsController', ReportsController)
    .controller('singleReportController', SingleReportController)
    .controller('resourceCombinationsController', ResourceCombinationsController)
    .controller('resourcesController', ResourcesController)
    .controller('resourceParttimeScheduleController', ResourceParttimeScheduleController)
    .controller('resourcePeriodBoundPropertiesController', ResourcePeriodBoundPropertiesController)
    .controller('scenarioManagementController', ScenarioManagementController)
    .controller('activityTypeFilterAndSortingController', ActivityTypeFilterAndSortingController)
    .controller('solverManagementController', SolverManagementController)
    .controller('systemAdminController', SystemAdministrationController)
    .controller('upgradeDaymarkController', UpgradeDaymarkController)
    .controller('userPreferencesController', UserPreferencesController)
    .controller('violationsController', ViolationsController)
    .controller('yearPlanningController', YearPlanningController)

    .component('indexPage', indexPage)
    .component('modalWithCheckbox', modalWithCheckbox)
    .component('userGroupPermissionsItem', userGroupPermissionsItem)
    .component('userGroupPermissionsTable', userGroupPermissionsTable)
    .component('userGroupPermissionsTree', userGroupPermissionsTree)

    .config(['applicationInsightsServiceProvider', (applicationInsightsServiceProvider) => {
        const options = {
            applicationName: window['OMRP_ApplicationInsightsApplicationName']
        };
        applicationInsightsServiceProvider.configure(window['OMRP_ApplicationInsightsInstrumentationKey'], options);
    }])
    .config(['$qProvider', function ($qProvider) {
        $qProvider.errorOnUnhandledRejections(false);
    }])
    .config(["environmentProvider", function (environment: IEnvironmentProvider) {
        environment.baseUrl = baseUrl;
        window['baseUrl'] = baseUrl;
    }])
    .config(routeconfig)
    .config(localeconfig)
    .config(auth0config)
    .config(authinterceptorconfig)

    .config(["$httpProvider", "$provide", "environmentProvider", function ($httpProvider: angular.IHttpProvider, $provide, environment: IEnvironmentProvider) {
        if (environment.baseUrl) {
            $provide.factory('BaseUrlInterceptor', ['$q', function ($q: angular.IQService) {
                return {
                    'request': function (config) {
                        const url = config.url.toLowerCase();
                        if (url.startsWith('omrp/') || url.startsWith('dist/omrp/') || url.startsWith('api/') || url === 'token' || url === 'auth/logout') {
                            config.url = `${environment.baseUrl}/${config.url}`;
                        }
                        return config || $q.when(config);
                    }
                }
            }]);
            $httpProvider.interceptors.push('BaseUrlInterceptor');
        }

    }])
    .run(stateconfig)
    .run(clickconfig);


const ngLifecycles = window['omrpLoadingFunction'] = singleSpaAngularJS({
    angular: window.angular,
    domElementGetter: function () {
        return document.getElementById('angularjs-container');
    },
    mainAngularModule: 'omrp',
    uiRouter: true,
    template: '<index-page />',
});

ngLifecycles.mount = [
    (props) => {
        baseUrl = props.baseUrl;

        return Promise.resolve()
    },
    ngLifecycles.mount,
];
