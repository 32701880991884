import { TreeEntity } from './../treeListController/TreeEntity';

import { Cooperation } from './cooperation';
import { DaypartsNormPeriod } from './daypartsNormPeriod';
import { MaxOccupationPeriod } from './maxOccupationPeriod';
import { OrganizationUnitMembershipPeriod } from './organizationUnitMembershipPeriod';
import { PercentagePeriod } from './percentagePeriod';
import { ResourcePropertyValue } from './resourcePropertyValue';
import { ResourceTypeMembershipPeriod } from './resourceTypeMembershipPeriod';
import { SkillMembershipPeriod } from './skillMembershipPeriod';

export class Resource extends TreeEntity {

    /**
        * Flag that indicates whether the full resource object has been loaded, including all relationships.
        */
    fullyLoaded = false;

    /**
        * The resource's email address.
        */
    emailAddress: string;

    /**
        * The resource's external id.
        */
    externalId: string;

    /**
        * The resource's property values.
        */
    properties: ResourcePropertyValue[];

    /**
        * The resource's organization unit memberships.
        */
    organizationUnits: OrganizationUnitMembershipPeriod[];

    /**
        * The resource's percentages.
        */
    percentages: PercentagePeriod[];

    /**
        * The resource's maximum occupations.
        */
    maxOccupations: MaxOccupationPeriod[];

    /**
        * The resource's resource types.
        */
    resourceTypes: ResourceTypeMembershipPeriod[];

    /**
        * The resource's skills.
        */
    skills: SkillMembershipPeriod[];

    /**
        * The resource's dayparts-norm.
        */
    daypartsNorm: DaypartsNormPeriod[];

    /**
        * The resource's cooperations.
        */
    cooperations: Cooperation[];

    /**
        * Ids of resources with preferred cooperation. Is inferred client side.
        */
    preferredResourceIds: number[];

    /**
        * Ids of resources with cooperations to avoid. Is inferred client side.
        */
    avoidResourceIds: number[];
}