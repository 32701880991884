import { INumberService } from './../../shared/numberService';
import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import { Dictionary } from './../utils/dictionary';
import { IConfigurationService } from '../../shared/configurationService';

export class UserPreferencesController {

    dayHoursStartList = new Dictionary();
    dayHoursEndList = new Dictionary();
    dayStartHour: number = Planboard.dayStartHour;
    dayEndHour: number = Planboard.dayEndHour;
    showPlanningStatusInPlanboard = false;

    private commonSvc: any;
    private saveChangesTimer: angular.IPromise<any> = null;
    private saveChangesTimerRunning = false;
    private anyChanges = false;
    private readonly dialogToken: string = "userResPref";
    private readonly automaticSaveDelay: number = 5000;

    static $inject = [
        "$scope",
        "$timeout",
        "pageStartService",
        "translationService",
        "userService",
        "configurationService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $timeout: ng.ITimeoutService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService,
        private configurationService: IConfigurationService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);
        this.commonSvc.start(() => { this.loadData(); });

        this.$scope.$on("documentClicked", (inner, target) => {
        });

        $scope.$on("$destroy", () => {
            this.userService.deleteUserVariables("preferences."); // delete all variables that start with "preferences."
            this.stopSaveChangesTimer();
            this.saveChanges();
        });

    }

    private loadData() {
        this.configurationService.getAppConfiguration(() => {
            if (!this.userService.isFullUser) {
                return;
            } 
        });

        // hours in a day
        this.dayHoursStartList.clear();
        this.dayHoursEndList.clear();
        for (var i = 0; i <= 24; i++) {
            this.dayHoursStartList.add(i, { id: i, order: i, text: i.toString(), visible: true });
            this.dayHoursEndList.add(i, { id: i, order: i, text: i.toString(), visible: true });
        }

        this.dayStartHour = this.userService.getDisplaySettingNumber("planboard.dayStartHour", this.dayStartHour);
        this.dayEndHour = this.userService.getDisplaySettingNumber("planboard.dayEndHour", this.dayEndHour);
        this.showPlanningStatusInPlanboard = this.userService.getDisplaySettingSwitch("planboard.showPlanningStatusInPlanboard");

        this.sanitizeValues();
    }

    onSettingsChanged(cachedSetting = null, cachedValue = null, cachedType = null) {
        if (cachedValue != null && cachedType != null)
            if (cachedType === 'switch')
                this.userService.setDisplaySettingSwitch(cachedSetting, !cachedValue, true);
            else
                this.userService.setDisplaySettingNumber(cachedSetting, cachedValue, true);

        this.anyChanges = true;
        this.startSaveChangesTimer();
        this.$timeout(() => { this.sanitizeValues(); }, 1);
    }

    getUserVariable(name, defaultValue) {
        var value = this.userService.getUserVariable(name);
        if (value == null && defaultValue != null) return defaultValue;
        return value;
    }

    /**
    * make sure that all entered values make sense
    */
    private sanitizeValues() {
        // adjust start and end hours
        if (this.dayStartHour >= this.dayEndHour) {
            this.dayEndHour = Math.min(this.dayStartHour + 1, 24);
            this.dayStartHour = Math.max(this.dayEndHour - 1, 0);
        }
        // set what start and end hours are visible in the dropdown lists
        for (var i = 0; i <= 24; i++) {
            this.dayHoursStartList.value(i).visible = i < this.dayEndHour;
            this.dayHoursEndList.value(i).visible = i > this.dayStartHour;
        }
    }

    private startSaveChangesTimer() {
        this.stopSaveChangesTimer();
        if (this.anyChanges) {
            this.userService.setLogoffWaitTime(this.dialogToken, this.automaticSaveDelay);
            this.saveChangesTimer = this.$timeout(() => { this.saveChanges(); }, this.automaticSaveDelay);
            this.saveChangesTimerRunning = true;
        }
    }

    private stopSaveChangesTimer() {
        if (this.saveChangesTimerRunning) this.$timeout.cancel(this.saveChangesTimer);
        this.saveChangesTimerRunning = false;
    }

    private saveChanges() {
        if (!this.anyChanges) return;
        this.anyChanges = false;
        Planboard.requestRefreshResourceList = true;

        if (this.dayStartHour < this.dayEndHour) {
            this.userService.setDisplaySettingNumber("planboard.dayStartHour", this.dayStartHour);
            this.userService.setDisplaySettingNumber("planboard.dayEndHour", this.dayEndHour);
        }
        this.userService.setDisplaySettingSwitch("planboard.showPlanningStatusInPlanboard", this.showPlanningStatusInPlanboard);
    }
    
}