import { BasicControl } from './basicControl';

/**
    * Picture control
    */
export class PictureControl extends BasicControl {
    /**
        * drawPicture callback event: override to do something interesting
        */
    drawPicture = (t: PictureControl, ctx: CanvasRenderingContext2D, contextX: number, contextY: number, width: number, height: number) => { }

    /**
        * create a new picture
        * @param owner the owner control
        * @param left x position from owner
        * @param top y position from owner
        * @param width width of the control
        * @param height height of the control
        */
    constructor(owner: BasicControl, left: number, top: number, width: number, height: number) {
        super(owner, left, top, width, height);
        this.userDraw = true;
        this.capturesMouse = false;
    }

    /**
        * draw callback
        * @param left left position on screen
        * @param top top position on screen
        */
    draw(left: number, top: number) {
        super.draw(left, top);
        this.drawPicture(this, this.controller.ctx, left, top, this.position.width, this.position.height);
    }
}