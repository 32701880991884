import { IPageStartService } from './../../../shared/pageStartService';

import { ITranslationService } from './../../i18n/translationService';

import { ITreeListScope } from './../../treeListController/ITreeListScope';

import { Dictionary } from './../../utils/dictionary';
import * as Timezone from './../../utils/timezone';

export class HolidaysController {
    private readonly currentYear: number = (new Date()).getFullYear();

    selectedYear: number = this.currentYear;
    yearNumbers: Dictionary = new Dictionary();
    holidaysArray: Array<any> = [];

    private commonSvc: any;
    private saveDataTimer: angular.IPromise<any> = null;
    private maxYear = this.currentYear + 5;
    private minYear = this.currentYear - 10;

    private readonly dialogToken: string = "holidaysInfo";
    private readonly permission: string = "ManageHolidays";
    private readonly apiUrl: string = "api/Holidays";
    private readonly automaticSaveDelay: number = 5000;

    static $inject = [
        "$scope",
        "$timeout",
        "pageStartService",
        "translationService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $timeout: ng.ITimeoutService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, this.permission, this.dialogToken);

        if (this.yearNumbers.count === 0) {
            for (let i = this.minYear; i <= this.maxYear; i++) {
                this.yearNumbers.add(i, { id: i, order: i, displayName: i.toString() });
            }
        }

        this.getMaxYear();
        this.getMinYear();

        this.$scope.$on("$destroy", () => {
            this.savePendingData();
        });

        this.commonSvc.start(() => { this.onYearChanged(this.selectedYear); });
    }

    onYearChanged(itemId: number): void {
        this.commonSvc.loadData(this.apiUrl + "/" + itemId, this.holidaysArray,
            (success) => {
                for (var i = 0; i < this.holidaysArray.length; i++) {
                    this.setHolidayDateForDatePicker(this.holidaysArray[i]);
                }
            }, null, true, true);
    }

    onHolidayChanged(holiday: any): void {
        holiday.changed = true;
        this.saveDataWithTimeOut(this.automaticSaveDelay);
    }

    onAddHolidayClick(): void {
        var newHoliday = {
            id: -1,
            holidayDate: Timezone.rollDateForWebApi(new Date(this.selectedYear, 0, 1)),
            text: this.$scope.textLabels.NEW_HOLIDAY
        }

        this.commonSvc.putData(this.apiUrl,
            newHoliday,
            (success) => {
                // Insert it client-side if we are still looking at the same year as when we sent it to the backend.
                var holiday = success.data;
                var holidayDate = Timezone.correctTimeZoneInfo(holiday.holidayDate);
                if (holidayDate.getFullYear() === this.selectedYear) {
                    this.holidaysArray.push(holiday);
                    this.setHolidayDateForDatePicker(holiday);
                }
            },
            null,
            true);
    }

    onRemoveHolidayClick(holiday: any): void {
        var id = holiday.id;
        this.commonSvc.deleteData(this.apiUrl + "/" + id,
            (success) => {
                for (var i = 0; i < this.holidaysArray.length; i++) {
                    if (this.holidaysArray[i].id === id) {
                        this.holidaysArray.splice(i, 1);
                        break;
                    }
                }
            },
            null,
            true);
    }

    getWeekNrText(): string {
        return this.$scope.textLabels.WEEK.toLowerCase();
    }


    private saveDataWithTimeOut(timeout: number): void {
        if (this.saveDataTimer) { this.$timeout.cancel(this.saveDataTimer); this.saveDataTimer = null; }
        if (timeout == null || timeout <= 0) {
            this.savePendingData();
            return;
        }
        this.saveDataTimer = this.$timeout(() => {
            this.savePendingData();
        }, timeout);
    }

    private savePendingData(): void {
        for (var i = 0; i < this.holidaysArray.length; i++) {
            if (this.holidaysArray[i].changed) {
                this.holidaysArray[i].holidayDate = Timezone.rollDateForWebApi(this.holidaysArray[i].holidayDateForDatePicker);
                this.commonSvc.putData(this.apiUrl, this.holidaysArray[i],
                    (success) => {
                        var holiday = success.data;
                        var holidayId = holiday.id;
                        var indexOfHoliday = -1;

                        var holidayYear = new Date(holiday.holidayDate).getFullYear();
                        if (!this.yearNumbers.containsKey(holidayYear))
                            this.yearNumbers.add(holidayYear, { id: holidayYear, order: holidayYear, displayName: holidayYear.toString() });

                        for (var j = 0; j < this.holidaysArray.length; j++) {
                            if (this.holidaysArray[j].id === holidayId) {
                                indexOfHoliday = j;
                                break;
                            }
                        }

                        if (indexOfHoliday > -1) {
                            this.holidaysArray[indexOfHoliday] = holiday;
                            this.setHolidayDateForDatePicker(holiday);
                        }

                    }, null, true);
            }
        }
    }

    private setHolidayDateForDatePicker(holiday: any): void {
        holiday.holidayDateForDatePicker = Timezone.correctTimeZoneInfo(holiday.holidayDate);
    }

    private getMaxYear(): void {
        this.commonSvc.loadData(this.apiUrl + "/maxYear", null, (success) => {
            this.maxYear = success.data;
            for (let i = this.minYear; i <= this.maxYear; i++) {
                if (i !== 0) {
                    this.yearNumbers.add(i, { id: i, order: i, displayName: i.toString() });
                }
            }
        }, null, true, true);
    }

    private getMinYear(): void {
        this.commonSvc.loadData(this.apiUrl + "/minYear", null, (success) => {
            this.minYear = success.data;
            for (let i = this.minYear; i <= this.maxYear; i++) {
                if (i !== 0) {
                    this.yearNumbers.add(i, { id: i, order: i, displayName: i.toString() });
                }
            }
        }, null, true, true);
    }
}