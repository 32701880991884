import { Rect } from './rect';
import * as Globals from './globals';

/**
    * offscreen canvas used for drawing cached images
    */
export class CanvasBuffer {
    private buffer: CanvasRenderingContext2D = null;
    private bufferX = 0;
    private bufferY = 0;
    private bufferMaxY = 0;
    private rev = 1;
    private name = "";
    public maxWidth = 0;
    public maxHeight = 0;

    /**
        * create a new offscreen canvas buffer
        * @param name unique name
        * @param width desired maximum width
        * @param height desired maximum height
        */
    constructor(name: string, width: number, height: number) {
        this.name = name;
        this.maxWidth = width;
        this.maxHeight = height;
        this.reset();
    }

    /**
        * recreate and clear the buffer and increase the revision number
        */
    reset() {
        const hiddenElements = document.getElementById("hiddenElements");
        const previousElement = document.getElementById(this.name);
        if (previousElement && previousElement.parentElement)
            previousElement.parentElement.removeChild(previousElement);
        const canvas = document.createElement("canvas");
        canvas.id = this.name;
        if (hiddenElements) hiddenElements.appendChild(canvas);
        this.buffer = canvas.getContext("2d");
        this.buffer.canvas.width = this.maxWidth;
        this.buffer.canvas.height = this.maxHeight;
        Globals.initContext(this.buffer);
        this.clear();
    }

    /**
        * get the context used for drawing on the buffer
        */
    get ctx(): CanvasRenderingContext2D { return this.buffer; }

    /**
        * get the current revision number of the buffer
        */
    get revision(): number { return this.rev; }

    /**
        * clear the buffer and increase the revision number
        */
    clear() {
        this.rev++;
        this.bufferX = 0;
        this.bufferY = 0;
        this.bufferMaxY = 0;
        this.ctx.clearRect(0, 0, this.buffer.canvas.width, this.buffer.canvas.height);
    }

    /**
        * get an unused rectangle on the buffer with a specific size
        * if no space is available then the buffer will be cleared and the revision will be increased
        * @param width width of the rectangle
        * @param height height of the rectangle
        */
    getRect(width: number, height: number): Rect {
        if (this.bufferX + width > this.buffer.canvas.width) {
            this.bufferX = 0;
            this.bufferY = this.bufferMaxY;
        }
        if (this.bufferY + height > this.buffer.canvas.height)
            this.clear();
        const r = new Rect(this.bufferX, this.bufferY, width, height);
        this.bufferMaxY = Math.max(this.bufferY + height + 1, this.bufferMaxY);
        this.bufferX += width + 1;
        return r;
    }
}