import { StateService } from '@uirouter/angularjs';
import { IUserService } from './../../shared/userService';
import { ITranslationService } from './../i18n/translationService';
import { INotificationService } from './../login/notificationService';
import { IPermissionService } from './../permissions/permissionService';
import { ITreeListScope } from './../treeListController/ITreeListScope';
import ConfigurationService = require("app/shared/configurationService");
import { IConfigurationService, FeatureManagement } from "./../../shared/configurationService";

export class SystemAdministrationController {
    $scope: ITreeListScope;
    featureManagement: FeatureManagement;

    private verificationStatus: any;
    private userPermissions: any;
    featureDisableMonitoringPage = false;

    private readonly sysAdminEvent = "sysAdminEvent";

    static $inject = [
        "$scope",
        "$state",
        "$window",
        "notificationService",
        "permissionService",
        "translationService",
        "userService",
        "configurationService"
    ];

    constructor(
        $scope: ITreeListScope,
        private $state: StateService,
        private $window: Window,
        private notificationService: INotificationService,
        private permissionService: IPermissionService,
        private translationService: ITranslationService,
        private userService: IUserService,
        private readonly configurationService: IConfigurationService,
    ) {
        this.$scope = $scope;
        this.translationService.getTextLabels(this.$scope);

        this.verificationStatus = {
            pending: true, // to prevent screen flickering
            failed: false,
            hasPermission: false,
            data: []
        };

        this.userPermissions = { granted: [] };

        this.permissionService.getAllPermissions(this.userPermissions, this.verificationStatus, this.$scope)
            .then(() => {

                // sysAdminEvent is used for notifications on changed access rights, for which the page needs to be refreshed
                // The latter is the reason for subscribing to these websocket events
                this.$scope.$on("$destroy",
                    () => {
                        this.notificationService.removeStringFromClientInfo(this.sysAdminEvent);
                    });

                this.notificationService
                    .subscribeToWebSocketEvent($scope,
                        this.sysAdminEvent,
                        (event, message) => {
                            this.userService.refreshPage();
                        });

                this.notificationService.addStringToClientInfo(this.sysAdminEvent);
            });

        this.configurationService.getAppConfiguration(() => {
            this.featureManagement = this.configurationService.appConfiguration.featureManagement;

            // Set the featureDisableMonitoringPage flag
            this.featureDisableMonitoringPage = this.featureManagement.featureDisableMonitoringPage;
        });
    }

    userHasPermission(permissionName) {
        return this.userPermissions.granted.indexOf(permissionName) > -1;
    }

    openMonitoring() {
        this.$state.transitionTo("monitoring", {});
    };

    openApplicationSettingsPage() {
        this.$window.location.href = "angularomrp/application-settings";
    }
}