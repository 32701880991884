import { StateService } from "@uirouter/angularjs";
import { IPageStartService } from "./../../../shared/pageStartService";
import { IUserService } from "./../../../shared/userService";

import { ITranslationService } from "./../../i18n/translationService";
import { IPermissionService } from "./../../permissions/permissionService";

import * as Globals from "./../../planboard/utils/globals";

import { TreeEntity } from "./../../treeListController/TreeEntity";
import { TreeListController } from "./../../treeListController/TreeListController";
import { VerificationStatus } from "./../../treeListController/TreeListScope";

import * as Constants from "./../../utils/constants";
import { Dictionary } from "./../../utils/dictionary";
import { IModalConfirmationWindowService } from "./../../utils/modalConfirmationWindowService";

import { User } from "./../users/user";

import { IUserGroupScope } from "./IUserGroupScope";
import { UserGroup } from "./userGroup";
import { IConfigurationService } from "./../../../shared/configurationService";

export class UserGroupsController extends TreeListController {

    scope: IUserGroupScope;
    commonSvc;

    // webApi urls
    urlGroupUsers = "api/UserGroups/{0}/Users";

    reportsEnabled = false;

    selectedUsersLoaded = false; // indicates if the selected users for the selected usergroup have been received
    previousSelectedGroupId = -1; // the id of the previous selected usergroup

    shouldShowModal = true; // stores the value of user's "remember my selection" checkbox, resets on usergroup selection change
    shouldPropagate = false; // stores user's propagation choice if they asked to remember their selection
    static $inject = [
        "$http",
        "$q",
        "$scope",
        "$state",
        "$timeout",
        "$filter",
        "$window",
        "configurationService",
        "permissionService",
        "modalConfirmationWindowService",
        "translationService",
        "pageStartService",
        "userService"
    ];

    constructor(
        $http: ng.IHttpService,
        $q: ng.IQService,
        $scope: IUserGroupScope,
        $state: StateService,
        $timeout: ng.ITimeoutService,
        $filter: ng.IFilterService,
        $window: ng.IWindowService,
        private readonly configurationService: IConfigurationService,
        permissionService: IPermissionService,
        modalConfirmationWindowService: IModalConfirmationWindowService,
        translationService: ITranslationService,
        protected pageStartService: IPageStartService,
        userService: IUserService) {

        super($http, $q, $scope, $state, $timeout, $filter, permissionService, modalConfirmationWindowService, translationService, pageStartService, userService);

        $window.location.href = "/angularomrp/program-management/user-groups";

        this.apiUrl = "api/UserGroups";
        this.scope.selectedUsers = [];
        this.scope.userGroups = [];
        this.scope.relatedUserDict = new Dictionary();
        this.scope.relatedReportUrisDict = new Dictionary();
        this.scope.userGroupDict = new Dictionary();
        this.commonSvc = pageStartService.initialize($scope, null, "UserGroups");

        //See whether the currently logged in user has the "UserGroups" permission and set flags accordingly.
        $scope.verificationStatus = new VerificationStatus();
        permissionService.userHasPermission("UserGroups", $scope.verificationStatus, $scope);

        // get data necessary for this model & controller
        this.getEntities();
        this.getEntityDictionary("api/Users", this.scope.relatedUserDict, () => this.onUsersLoaded(this.scope), null);

        this.getRelatedPermissions();

        this.configurationService.getAppConfiguration(() => {
            this.reportsEnabled = this.configurationService.appConfiguration.featureManagement.ssrsReports;

            if (this.reportsEnabled) {
                this.getRelatedReportUris();
            }
        });

        this.getUserGroups();

        this.scope.propagationModalOpen = false;
        this.scope.modalTitle = "";
        this.scope.modalQuestion = "";
        this.scope.modalCheckboxText = this.scope.textLabels.USER_GROUP_PROPAGATE_REMEMBER;
        this.scope.modalCallback = () => { };
    }

    /**
        * creates the tree with selectable parent user groups
        */
    private createSelectParentTree() {
        this.scope.selectParentTree = Object.create(null);
        if (!this.scope.selectedItem) return;
        // create default empty entity for the root
        const noEntity = new TreeEntity();
        noEntity.id = 0;
        noEntity.displayName = "";
        this.scope.selectParentTree[noEntity.id] = noEntity;
        // copy all entities from scope.entities
        const addIds: number[] = [];
        let index = 0;
        for (let i = 0; i < this.scope.entities.length; i++) addIds.push(this.scope.entities[i].id);
        while (index < addIds.length) {
            const entity: TreeEntity = this.scope.entityDict.value(addIds[index]);
            // do not add self, or all children under self
            if (entity && entity.id !== this.scope.selectedItem.id) {
                this.scope.selectParentTree[entity.id] = entity;
                for (let i = 0; i < entity.nodes.length; i++) addIds.push(entity.nodes[i].id);
            }
            index++;
        }
    }

    /**
        * callback when the user groups owner usergroup changes
        * @param entityId
        */
    protected onParentChanged(itemId: number) {
        if (!this.scope.selectedItem) return;
        if (this.scope.selectedItem.parentId === itemId) return; // no change

        // set new parent, then remove, and re-insert
        this.scope.selectedItem.parentId = itemId;
        this.removeEntityFromTree(this.scope.selectedItem);
        this.placeEntityInTree(this.scope.selectedItem);

        this.onEntityChanged(false, this.scope.selectedItem);
    }

    /**
        * callback when the selected permissions list changes
        * @param userGroup User group that was changed, leave it empty to default to the selected item.
        */
    protected onPermissionsChanged(userGroup: UserGroup) {
        //console.log("onPermissionsChanged()");
        if (!userGroup) userGroup = this.scope.selectedItem as UserGroup;
        if (!userGroup) return;
        userGroup.selectedPermissionsChanged = true;
        this.onEntityChanged(false, userGroup);
    }

    /**
        * Restore an entity to the state as known on the server. Used after failed update attempts to reset control values.
        * @param entityId Id of the item to restore the state for.
        */
    protected restoreEntity(entityId: number) {
        super.restoreEntity(entityId);
        this.previousSelectedGroupId = -1;

        const userGroup = this.scope.selectedItem as UserGroup;
        userGroup.selectedUserIds = null;
        userGroup.selectedPermissionIds = null;

        this.onEntitySelected();
    }

    /**
        * called when a usergroup is selected
        */
    protected onEntitySelected() {
        // selected same as previous selected?
        if (this.scope.selectedItem && this.scope.selectedItem.id === this.previousSelectedGroupId) return;

        // initialize variables for this selected group
        this.scope.selectedUsers = [];
        this.selectedUsersLoaded = false;
        this.scope.selectedItemWritable = false;
        this.scope.canAddUsers = false;
        this.createSelectParentTree();

        if (!this.scope.selectedItem) return;
        const userGroup = this.scope.selectedItem as UserGroup;
        this.scope.selectedItemWritable = (userGroup.id >= 0 && userGroup.maxPermissionForCurrentUser >= Constants.permOwner);
        //this.scope.canAddUsers = (userGroup.id >= 0 && userGroup.maxPermissionForCurrentUser >= Constants.permWrite);
        this.scope.canAddUsers = false; // not possible to edit the tag list of users on this page, this is now a readonly tag list
        this.previousSelectedGroupId = userGroup.id;

        // sort selected permissions by permissionName
        if (userGroup.selectedPermissionIds) {
            userGroup.selectedPermissionIds = this.filter("orderBy")(userGroup.selectedPermissionIds, (id: number) => {
                const item = this.scope.relatedPermissionsDict.value(id);
                return item == null ? null : item.permissionName;
            });
        }

        // sort selected reports by displayName
        if (userGroup.selectedReportUriIds) {
            userGroup.selectedReportUriIds = this.filter("orderBy")(userGroup.selectedReportUriIds, (id: number) => {
                const item = this.scope.relatedReportUrisDict.value(id);
                return item == null ? null : item.displayName;
            });
        }

        if (userGroup.selectedUserIds) {
            // re-use the selected users stored with the group
            this.scope.selectedUsers = userGroup.selectedUserIds;
            this.selectedUsersLoaded = true;
        } else {
            // retrieve all user ids in this selected group
            userGroup.selectedUserIds = this.scope.selectedUsers;
            this.getEntityIdArray(Globals.stringFormat(this.urlGroupUsers, [`${this.scope.selectedItem.id}`]),
                this.scope.selectedUsers,
                () => this.onSelectedUsersLoaded(this.scope));
        }

        if (!userGroup.selectedPermissionIds) {
            userGroup.selectedPermissionsChanged = false;
            userGroup.selectedPermissionIds = [];
            for (let permission of (userGroup as any).permissions) {
                userGroup.selectedPermissionIds.push(permission.id);
            }
        }
    }

    /**
    * Toggles the permission value for a user group on the scope.
    * Toggle means: set to the input value if the current value is smaller, else set it to the input value - 1.
    * @param userGroupId Id of the user group to toggle the permission value for.
    * @param newPermissionValue The new permission value.
    * @param item Item to set the permissions for.
    * @param setInsteadOfToggle Set to true to always set the new permission value, even if is the same as the actual value.
    * @param dontDiminishPermissions Set to true to only allow granting more rights in child organization units.
    */
    protected toggleUserGroupPermissionForItem(userGroupId: number,
        newPermissionValue: number,
        item: TreeEntity,
        shouldPropagate: boolean,
        userTriggered: boolean = false,
        setInsteadOfToggle: boolean = false, dontDiminishPermissions: boolean = false) {
        const oldValue = this.getUserGroupPermissionsForItem(userGroupId, item);
        const userGroup = item as UserGroup;
        userGroup.userGroupPermissionListChanged = true;

        // If diminishing permissions is not allowed only toggle when the new value is larger than the old one.
        if (!dontDiminishPermissions || newPermissionValue > oldValue) {
            super.toggleUserGroupPermissionForItem(userGroupId, newPermissionValue, item, shouldPropagate, userTriggered, setInsteadOfToggle);
        }
        const resultingValue = this.getUserGroupPermissionsForItem(userGroupId, item);

        // Did we just expand permissions? In that case, only allow expanding permissions in child organization units,
        // so no diminishing rights further down the tree.
        const permissionsExpanded = resultingValue >= oldValue;

        // Additionally, perform the same action for all children of this item.
        if (item.nodes && item.nodes.length > 0) {
            if (userTriggered) {
                this.commonSvc.showYesNoDialog(this.scope.textLabels.USER_GROUPS_AFFECT_CHILDREN,
                    this.scope.textLabels.USER_GROUPS_AFFECT_CHILDREN_QUESTION,
                    () => { // Yes
                        for (let child of item.nodes) {
                            this.toggleUserGroupPermissionForItem(userGroupId,
                                resultingValue,
                                child,
                                shouldPropagate,
                                false,
                                true,
                                permissionsExpanded);
                            this.onEntityChanged(false, child);
                        }
                    },
                    null);
            } else {
                for (let child of item.nodes) {
                    this.toggleUserGroupPermissionForItem(userGroupId,
                        resultingValue,
                        child,
                        shouldPropagate,
                        false,
                        true,
                        permissionsExpanded);
                    this.onEntityChanged(false, child);
                }
            }
        }
    }

    /**
        * returns if the current usergroup is writable
        */
    protected isSelectedItemWritable(): boolean {
        if (!this.scope.selectedItem) return false;
        const userGroup = this.scope.selectedItem as UserGroup;
        return (userGroup.id >= 0 && userGroup.maxPermissionForCurrentUser >= Constants.permOwner);
    }

    /**
        * returns if the current usergroup can be deleted
        */
    protected isSelectedItemDeletable(): boolean {
        if (!this.scope.selectedItem) return false; // no group is selected
        if (!this.selectedUsersLoaded) return false; // selected users have not been received yet
        if (this.scope.selectedItem.nodes.length > 0) return false; // group still has children
        if (this.scope.selectedItem.changeOfDeletabilityPending) return false; // change of deletability pending
        return this.scope.selectedUsers.length === 0; // only deletable if there are no users
    }

    /**
    * Returns the title of the modal delete confirmation window.
    * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowTitle(): string {
        return this.scope.textLabels.USER_GROUP_DELETION_MODAL_TITLE;
    }

    /**
        * Returns the text of the modal delete confirmation window.
        * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowText(): string {
        return this.scope.textLabels.USER_GROUP_DELETION_MODAL_TEXT;
    }

    /**
        * returns the name of a new usergroup
        */
    protected newEntityDisplayName() {
        return this.scope.textLabels.NEW_USERGROUP;
    }

    /**
        * callback when the selected users are loaded
        * @param myScope this scope
        */
    protected onSelectedUsersLoaded(myScope: IUserGroupScope) {
        this.selectedUsersLoaded = true;
        //console.log("Selected Users loaded", myScope.selectedUsers);
    }

    /**
        * callback when users are loaded
        * @param myScope this scope
        */
    protected onUsersLoaded(myScope: IUserGroupScope) {
        myScope.relatedUserDict.forEach((key, value) => {
            const entity: User = value;
            entity.longDisplayName = `${entity.displayName} (${entity.userName})`;
            if (entity.externalId && entity.externalId !== "")
                entity.longDisplayName += ` (${entity.externalId})`;
        });
        //console.log("Users loaded", myScope.relatedUserDict);
    }

    /**
        * Gets the related permissions.
        */
    protected getRelatedPermissions() {
        super.getRelatedPermissions(() => { this.onPermissionsLoaded(this.scope); });
    };

    /**
        * Replaces the display names of the permissions with the translated labels in the user's display language.
        * @param myScope
        */
    private onPermissionsLoaded(myScope: IUserGroupScope) {
        //console.log("Permissions loaded");
        let entityArray: any[] = [];
        myScope.relatedPermissionsDict.forEach((key, value) => {
            value.permissionName = myScope.textLabels[`PERM_${value.permissionName}`];
            entityArray.push(value);
        });
        // set order property based on changed permissionName
        const sortedArray: any[] = this.filter("orderBy")(entityArray, item => item.permissionName);
        for (let i = 0; i < sortedArray.length; i++) sortedArray[i].order = i + 1;
    }

    protected setSelected(entity: TreeEntity) {
        super.setSelected(entity);
        this.shouldShowModal = true;
        this.shouldPropagate = false;
    }

    /**
        * When a permission is added to the permissions for the selected user group,
        * Display a modal to ask if it should also bubble the addition down to the group's children
        * Bubble if user selects yes
        * @param permissionId
        */
    protected onSelectedPermissionAdded(permissionId: number): void {
        if (!this.scope.selectedItem.nodes || this.scope.selectedItem.nodes.length <= 0) return;

        if (this.shouldShowModal) {
            this.scope.propagationModalOpen = true;
            this.scope.modalTitle = this.scope.selectedItem.displayName;
            this.scope.modalQuestion = this.scope.textLabels.USER_GROUP_PROPAGATE_ADD;

            this.scope.modalCallback = (shouldPropagate: boolean, shouldRemember: boolean) => {
                this.scope.propagationModalOpen = false;
                if (shouldPropagate) {
                    for (let child of this.scope.selectedItem.nodes) {
                        this.addPermissionToGroupAndChildren((child as UserGroup), permissionId);
                    }
                }
                if (shouldRemember) {
                    this.shouldShowModal = false;
                    this.shouldPropagate = shouldPropagate;
                }
            }
        } else {
            if (this.shouldPropagate) {
                for (let child of this.scope.selectedItem.nodes) {
                    this.addPermissionToGroupAndChildren((child as UserGroup), permissionId);
                }
            }
        }
    }

    /**
        * Recursively adds a permission to a user groups and its children.
        * @param group User group to add the permission for.
        * @param permissionId Id of the permission to add.
        */
    private addPermissionToGroupAndChildren(group: UserGroup, permissionId: number): void {
        if (group.selectedPermissionIds.indexOf(permissionId) === -1) {
            group.selectedPermissionIds.push(permissionId);
            group.selectedPermissionsChanged = true;
            this.onPermissionsChanged(group);
        }

        if (group.nodes) {
            for (let child of group.nodes) {
                this.addPermissionToGroupAndChildren((child as UserGroup), permissionId);
            }
        }
    }

    /**
        * When a permission is removed from the permissions for the selected user group,
        * Display a modal to ask if it should also bubble the removal down to the group's children.
        * Bubble if user selects yes
        * @param permissionId
        */
    protected onSelectedPermissionRemoved(permissionId: number): void {
        if (!this.scope.selectedItem.nodes || this.scope.selectedItem.nodes.length <= 0) return;

        if (this.shouldShowModal) {
            this.scope.propagationModalOpen = true;
            this.scope.modalTitle = this.scope.selectedItem.displayName;
            this.scope.modalQuestion = this.scope.textLabels.USER_GROUP_PROPAGATE_REMOVE;

            this.scope.modalCallback = (shouldPropagate: boolean, shouldRemember: boolean) => {
                this.scope.propagationModalOpen = false;
                if (shouldPropagate) {
                    for (let child of this.scope.selectedItem.nodes) {
                        this.removePermissionFromGroupAndChildren((child as UserGroup),
                            permissionId);
                    }
                }
                if (shouldRemember) {
                    this.shouldShowModal = false;
                    this.shouldPropagate = shouldPropagate;
                }
            }
        } else {
            if (this.shouldPropagate) {
                for (let child of this.scope.selectedItem.nodes) {
                    this.removePermissionFromGroupAndChildren((child as UserGroup),
                        permissionId);
                }
            }
        }
    }

    /**
    * Recursively removes a permission from a user groups and its children.
    * @param group User group to remove the permission for.
    * @param permissionId Id of the permission to remove.
    */
    private removePermissionFromGroupAndChildren(group: UserGroup, permissionId: number): void {
        const indexOfPermission = group.selectedPermissionIds.indexOf(permissionId);
        if (indexOfPermission > -1) {
            group.selectedPermissionIds.splice(indexOfPermission, 1);
            group.selectedPermissionsChanged = true;
            this.onPermissionsChanged(group);
        }

        if (group.nodes) {
            for (let child of group.nodes) {
                this.removePermissionFromGroupAndChildren((child as UserGroup), permissionId);
            }
        }
    }

    /**
        * Loads report uris, can be used by controllers for entities that have relationship with reports
        */
    protected getRelatedReportUris() {
        super.getEntityDictionary("api/Reports/Uris/All", this.scope.relatedReportUrisDict, () => { this.onReportUrisLoaded(this.scope); }, rep => rep.displayName);
    }

    /**
        * TODO: update or remove this function
        * @param myScope
        */
    private onReportUrisLoaded(myScope: IUserGroupScope) {
    }

    /**
        * callback when the selected report uri list changes
        * @param userGroup User group that was changed, leave it empty to default to the selected item.
        */
    protected onReportUrisChanged(userGroup: UserGroup) {
        if (!userGroup) userGroup = this.scope.selectedItem as UserGroup;
        if (!userGroup) return;
        userGroup.selectedReportUriIdsChanged = true;
        this.onEntityChanged(false, userGroup);
    }

    /**
        * When a report uri is added to the reports for the selected user group, also bubble the addition down to the group's children.
        * @param reportUriId
        */
    protected onSelectedReportUriAdded(reportUriId: number): void {
        //console.log("added item", reportUriId);
        for (let child of this.scope.selectedItem.nodes) {
            this.addReportUriToGroupAndChildren((child as UserGroup), reportUriId);
        }
    }

    /**
        * Recursively adds a report uri to a user groups and its children.
        * @param group User group to add the report uri for.
        * @param reportUriId Id of the report uri to add.
        */
    private addReportUriToGroupAndChildren(group: UserGroup, reportUriId: number): void {
        if (group.selectedReportUriIds.indexOf(reportUriId) === -1) {
            group.selectedReportUriIds.push(reportUriId);
            group.selectedReportUriIdsChanged = true;
            this.onReportUrisChanged(group);
        }

        if (group.nodes) {
            for (let child of group.nodes) {
                this.addReportUriToGroupAndChildren((child as UserGroup), reportUriId);
            }
        }
    }

    /**
        * When a report uri is removed from the report for the selected user group, also bubble the removal down to the group's children.
        * @param reportUriId
        */
    protected onSelectedReportUriRemoved(reportUriId: number): void {
        //console.log("removed item", reportUriId);
        for (let child of this.scope.selectedItem.nodes) {
            this.removeReportUriFromGroupAndChildren((child as UserGroup), reportUriId);
        }
    }

    /**
    * Recursively removes a report uri from a user groups and its children.
    * @param group User group to remove the report uri for.
    * @param reportUriId Id of the report uri to remove.
    */
    private removeReportUriFromGroupAndChildren(group: UserGroup, reportUriId: number): void {
        const indexOfReportUri = group.selectedReportUriIds.indexOf(reportUriId);
        if (indexOfReportUri > -1) {
            group.selectedReportUriIds.splice(indexOfReportUri, 1);
            group.selectedReportUriIdsChanged = true;
            this.onReportUrisChanged(group);
        }

        if (group.nodes) {
            for (let child of group.nodes) {
                this.removeReportUriFromGroupAndChildren((child as UserGroup), reportUriId);
            }
        }
    }

}