export const userGroupPermissionsTree: angular.IComponentOptions = {
    template: require("./userGroupPermissionsTree.html"),
    controller: userGroupPermissionsTreeController,
    bindings: {
        userGroupList: "<",
        permissionList: "<",
        collapsed: "<?",
        canEdit: "<",
        hasOwnerColumn: "<",
        permissionChangeCallback: "&"
    }
}

function userGroupPermissionsTreeController() {
    this.getHighestPermission = (userGroupId: number): number => {
        let highestPermission = 0;
        if (this.permissionList)
            for (let i = 0; i < this.permissionList.length; i++)
                if (this.permissionList[i].userGroupId === userGroupId)
                    highestPermission = Math.max(highestPermission, this.permissionList[i].permission);
        return highestPermission;  
    }
    this.onCollapseExpand = (): void => {
        this.collapsed = !this.collapsed;
    }
    this.onCallback = (userGroupId: number, permission: number, newPermission: number, shouldShowDialog: boolean) => {
        this.permissionChangeCallback({ userGroupId: userGroupId, permission: permission, newPermission: newPermission, shouldShowDialog: shouldShowDialog});
    }
}