export var MonitoringPageCtrl = [
    "$scope", "$http", "$controller", "$state", "modalConfirmationWindowService", "translationService",
    function ($scope, $http, $controller, $state, modalConfirmationWindowService, translationService) {
        var dialogToken = "monitoring";
        var errorDialogToken = "monitoringErr";

        // Inherit from base.
        $controller("MonitoringPageCtrlBase", { $scope: $scope });

        $scope.getStatuses = function () {
            $http.get("api/Monitoring/StatusDigest")
                .then(function (response) {
                    $scope.data = response.data;
                    $scope.dataPresent = true;
                    modalConfirmationWindowService.closeModalWindow(dialogToken);
                }, function (response) {
                    modalConfirmationWindowService.closeModalWindow(dialogToken);

                    var errorMessage = translationService.translateErrorMessage(response);
                    modalConfirmationWindowService
                        .showModalInfoDialog($scope.textLabels.ERROR_OCCURRED,
                            errorMessage,
                            $scope.textLabels.OK,
                            null,
                            0,
                            errorDialogToken);
                });
        }

        // Opens the per instance statuses for a component.
        $scope.openComponentStatus = function (componentName) {
            $state.transitionTo("monitoring.component", { componentId: componentName });
        }
    }
];