export class VerificationStatus {
    pending: boolean; // to prevent screen flickering
    failed: boolean;
    hasPermission: boolean;

    constructor() {
        this.pending = true;
        this.failed = false;
        this.hasPermission = false;
    }
}