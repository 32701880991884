import * as Constants from './../utils/constants';

export interface IFeatureService {
    getEnabledFeatures(enabledFeatures, callerScope);
}

export var featureService = [
    "$http", "modalConfirmationWindowService",
    function ($http, modalConfirmationWindowService) {
        const svc = this;

        var dialogToken = "feature";

        svc.getEnabledFeatures = (enabledFeatures, callerScope) => {

            // Put text labels from the translation service onto a local object and start a delayed modal window
            // that will show the user that we're busy checking their permissions.
            modalConfirmationWindowService.showModalInfoDialog(callerScope.textLabels.CHECKING_PERMISSIONS_TITLE,
                callerScope.textLabels.CHECKING_PERMISSIONS_MESSAGE, "", null, Constants.modalWaitDelay, dialogToken);

            // Make sure the window is closed again when the calling controller is destroyed.
            callerScope.$on("$destroy", () => { modalConfirmationWindowService.closeModalWindow(dialogToken); });

            return $http.get("api/EnabledFeatures")
                .then(response => {
                    enabledFeatures.length = 0;
                    enabledFeatures.push(...response.data);
                    modalConfirmationWindowService.closeModalWindow(dialogToken);
                })
                .catch((data, status) => {
                    console.error("Error", status, data);
                    modalConfirmationWindowService.closeModalWindow(dialogToken);
                });
        }
    }
];