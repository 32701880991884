/**
    * contains elements with absolute position and size of each element, used for example as column or row position lists
    */
export class PositionList {
    private poscount = 0;
    private pos: number[];
    private size: number[];
    private minSize: number[];
    private lastnr = 0;
    private maximumPos = 0;

    /**
        * get or set the number of elements
        */
    get count(): number {
        return this.poscount;
    }
    set count(value: number) {
        if (this.poscount === 0) {
            this.pos = new Array(value);
            this.size = new Array(value);
            this.minSize = new Array(value);
        } else if (value > this.poscount) {
            this.setSize(value - 1, this.size[this.poscount - 1], this.minSize[this.poscount - 1]);
        } else if (value < this.poscount) {
            this.pos.length = Math.min(this.pos.length, value);
            this.size.length = Math.min(this.size.length, value);
            this.minSize.length = Math.min(this.minSize.length, value);
        }
        this.poscount = value;
        this.maximumPos = this.getPos(this.poscount);
    }

    /**
        * get the highest position
        */
    get maxPos(): number {
        return this.maximumPos;
    }

    /**
        * get the size of an element
        * @param n the element index
        */
    getSize(n: number): number {
        if (n < this.poscount && n >= 0) return this.size[n];
        if (n >= this.poscount && this.poscount > 0) return this.size[this.poscount - 1];
        return 0;
    }

    /**
        * get the absolute position of an element
        * @param n the element index
        */
    getPos(n: number): number {
        if (n < this.poscount && n >= 0) return this.pos[n];
        if (n >= this.poscount && this.poscount > 0) return this.pos[this.poscount - 1] + ((n + 1) - this.poscount) * this.size[this.poscount - 1];
        return 0;
    }

    /**
        * get the minimum size of an element
        * @param n the element index
        */
    getMinimum(n: number): number {
        if (n < this.poscount && n >= 0) return this.minSize[n];
        if (n >= this.poscount && this.poscount > 0) return this.minSize[this.poscount - 1];
        return 0;
    }

    /**
        * set the size and optionally the minimum size of an element
        * @param n the element index
        * @param value the size of the element
        * @param minimum the minimum size of the element
        */
    setSize(n: number, value: number, minimum?: number) {
        let p: number;
        if (n >= this.poscount) {
            p = 0;
            if (this.poscount > 0) p = this.pos[this.poscount - 1] + this.size[this.poscount - 1];
            if (minimum == undefined) minimum = this.getMinimum(n);
            while (n >= this.poscount) {
                this.size.push(value);
                this.minSize.push(minimum);
                this.pos.push(p);
                p += value;
                this.poscount++;
            }
        } else if (n >= 0) {
            if (minimum != undefined) this.minSize[n] = minimum;
            if (this.size[n] !== value) {
                this.size[n] = value;
                p = this.pos[n] + this.size[n];
                while (n < this.poscount - 1) {
                    n++;
                    this.pos[n] = p;
                    p += this.size[n];
                }
            }
        }
        this.maximumPos = this.getPos(this.poscount);
        return this;
    }

    /**
        * set the position, this will alter the size of the previous element
        * @param n the element index
        * @param value the absolute position of the element
        */
    setPos(n: number, value: number) {
        if (n > 0 && n < this.poscount) {
            const newSize = Math.max(this.size[n - 1] + (value - this.pos[n]), this.minSize[n - 1]);
            if (this.pos[n] !== value) this.setSize(n - 1, newSize, this.minSize[n - 1]);
        } else if (n === this.poscount) {
            if (this.pos[n - 1] + this.size[n - 1] !== value) this.size[n - 1] = Math.max(value - this.pos[n - 1], this.minSize[n - 1]);
        }
        this.maximumPos = this.getPos(this.poscount);
    }

    /**
        * find the element containing pos
        * @param pos the position
        */
    nrAtPos(pos: number): number {
        if (pos <= 0 || this.poscount <= 0) return 0;
        if (this.lastnr < 0 || this.lastnr >= this.poscount) this.lastnr = 0;
        let startNr = 0;
        let endNr = this.poscount - 1;
        while (startNr <= endNr) {
            if (pos < this.getPos(this.lastnr)) {
                if (this.lastnr > 0) this.lastnr--;
                if (pos >= this.getPos(this.lastnr)) return this.lastnr;
                endNr = this.lastnr - 1;
            } else if (pos >= this.getPos(this.lastnr + 1)) {
                if (pos >= this.getPos(this.poscount - 1)) return this.poscount - 1;
                if (this.lastnr < this.poscount - 1) this.lastnr++;
                if (pos < this.getPos(this.lastnr + 1)) return this.lastnr;
                startNr = this.lastnr + 1;
            } else return this.lastnr;
            this.lastnr = (endNr + startNr) >> 1;
        }
        return 0;
    }

    /**
        * create a new empty positionlist
        */
    constructor() {
        this.pos = [];
        this.size = [];
        this.minSize = [];
    }
}