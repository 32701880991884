import { PlanboardSplitters } from './../components/planboardSplitters';
import { PlannedActivities } from './../components/plannedActivities';
import { PlanboardResources } from './../components/planboardResources';
import { PlanboardCounters } from './../components/planboardCounters';
import { PlanboardDate } from './../components/planboardDate';
import { OpenActivities } from './../components/openActivities';

import { MainController } from './../controls/mainController';

import { DrawHelper } from './../drawing/drawing';

import * as Globals from './../utils/globals';
import { TimeSpan } from './../utils/timespan';

import { ActivityType } from './activitytype';
import { Planboard } from './planboard';

/**
    * initializes everything
    */
export function initializeAll(canvasElement: string) {
    if (!Planboard.controller) {
        initializeFirst();
        initializeControls(canvasElement);
        initializeLast();
    } else {
        Planboard.controller.attachToCanvas(canvasElement);

        // recreate the hidden canvas elements
        DrawHelper.reset();
        ActivityType.resetBuffer();
        Planboard.areaResources.resetBuffer();
        Planboard.areaResourceHeader.resetBuffer();
        Planboard.areaCounters.resetBuffer();
        Planboard.areaCounterHeader.resetBuffer();
        Planboard.areaDate.resetBuffer();
        Planboard.areaMain.resetBuffer();
        Planboard.areaToPlan.resetBuffer();

        initializeLast();
    }
}

/**
    * deinitialize everything
    */
export function deInitializeAll() {
    if (Planboard.controller) {
        Planboard.clearData();
        Planboard.controller = null;
    }
}

/**
    * resize and redraw the planboard
    */
export function resizeAndRedraw() {
    if (Planboard.controller) {
        Planboard.controller.resize(false);
        Planboard.controller.redraw(true);
        Planboard.dynamicResize();
    }
}

/**
    * initialization before controls are created
    */
export function initializeFirst() {
    // set first date in planbord to last weeks monday
    Planboard.leftDate = TimeSpan.today.addDays(-7).toDate();
    while (Planboard.leftDate.getDay() !== Planboard.firstDayOfWeek)
        Planboard.leftDate = TimeSpan.fromDate(Planboard.leftDate).addDays(-1).toDate();
}

/**
    * initialization after controls are created
    */
export function initializeLast() {
    // request data from webApi
    if (!Planboard.dataRequested) {
        Planboard.dataRequested = true;
        Planboard.readActivityTypes();
        Planboard.readResources();
    } else {
        if (Planboard.requestRefreshResourceList) Planboard.readResources();
        else Planboard.resourcesLoading = false;
        Planboard.requestRefreshResourceList = false;
    }

    // show everything
    Planboard.controller.resize(false);
    Planboard.controller.redraw();
}

/**
    * create all controls and events
    */
export function initializeControls(canvasElement: string) {
    // initialize main controller
    Planboard.controller = new MainController(canvasElement);

    PlanboardSplitters.initialize();
    PlannedActivities.initialize();
    PlanboardResources.initialize();
    PlanboardCounters.initialize();
    PlanboardDate.initialize();
    OpenActivities.initialize();

    // scroll to current week (left date is last week, so scroll to column 7)
    Planboard.areaMain.innerLeft = Planboard.areaDate.innerLeft = Planboard.areaToPlan.innerLeft = Planboard.areaMain.cols.getPos(7);

    // set default colors for currently unused areas
    let i: number;
    for (i = 0; i < Planboard.split1.splitAreas.length; i++)
        if (Planboard.split1.splitAreas[i].clients.length === 0)
            Planboard.split1.splitAreas[i].backcolor = Globals.windowColor;
    for (i = 0; i < Planboard.split2.splitAreas.length; i++)
        if (Planboard.split2.splitAreas[i].clients.length === 0)
            Planboard.split2.splitAreas[i].backcolor = Globals.windowColor;
}