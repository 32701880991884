import { Rect } from './../utils/rect';
import * as Globals from './../utils/globals';
import { MainController } from './../controls/mainController';

/**
    * the basic control, all other controls should extend this class
    */
export class BasicControl {
    controller: MainController = null; // controller
    owner: BasicControl; // parent control
    clients: Array<BasicControl>; // list of children
    position: Rect; // position relative to the parent
    screenPos: Rect; // absolute position on the screen
    clientArea: Rect; // the area where client controls can be drawn
    useClientArea = true; // use owners client area for positional x/y offset & width/height clipping
    clientAreaSet = false; // indicates if the clientArea has been initialized
    backcolor = ""; // default backcolor, "" = do not draw
    bordercolor = ""; // default bordercolor, "" = do not draw
    visible = true; // indicates if the control is visible
    enabled = true; // indicates if the control is enabled
    name = ""; // the name of the control
    dragAble = false; // set to true if the control can be dragged with the mouse
    useMouseWheel = false; // indicates if this control has interaction with the mousewheel
    align: Rect = null; // automatic alignment to parents left/top/right/bottom
    capturesMouse = true; // control can be found by mouse events
    protected userDraw = false; // do not draw the default backcolor and border when this is set to true
    private drag: [number, number, number]; // x, y, button

    /**
        * create a new control
        * @param owner the controls parent
        * @param left left position relative to parent
        * @param top top position relative to parent
        * @param width width of the control
        * @param height height of the control
        */
    constructor(owner: BasicControl, left: number, top: number, width: number, height: number) {
        this.owner = owner;
        this.clients = new Array<BasicControl>();
        this.position = new Rect(left, top, width, height);
        this.screenPos = new Rect(0, 0, 0, 0);
        this.clientArea = new Rect(0, 0, 0, 0);
        if (owner != null) {
            this.controller = owner.controller;
            owner.clients.push(this);
        }
        this.drag = [0, 0, -1];
    }

    /**
        * redraw this control
        */
    redraw() {
        this.controller.redrawControl(this);
    }

    /**
        * draw the control and update the absolute screen position
        * @param left left screen position
        * @param top top screen position
        */
    draw(left: number, top: number) {
        const context = this.controller.ctx;
        this.screenPos.change(left, top, this.position.width, this.position.height);
        // code below can be removed if no BasicControl is ever used without userDraw
        if (this.userDraw) return;
        if (this.backcolor !== "") {
            context.fillStyle = this.backcolor;
            context.fillRect(left, top, this.position.width, this.position.height);
        }
        if (this.bordercolor !== "") {
            context.fillStyle = this.bordercolor;
            context.fillRect(left, top, this.position.width, 1);
            context.fillRect(left, top + this.position.height - 1, this.position.width, 1);
            context.fillRect(left, top + 1, 1, this.position.height - 2);
            context.fillRect(left + this.position.width - 1, top + 1, 1, this.position.height - 2);
        }
    }

    /**
        * initialize the automatic alignment
        * @param left number of pixels on the left of the parent
        * @param top number of pixels on the top of the parent
        * @param right number of pixels on the right of the parent
        * @param bottom number of pixels on the bottom of the parent
        */
    setAlign(left?: number, top?: number, right?: number, bottom?: number) {
        if (left == undefined && top == undefined && right == undefined && bottom == undefined) {
            this.align = null;
        } else {
            if (this.align == undefined) this.align = new Rect(0, 0, 0, 0);
            this.align.left = (left == undefined ? Globals.maxInt : left);
            this.align.top = (top == undefined ? Globals.maxInt : top);
            this.align.right = (right == undefined ? Globals.maxInt : right);
            this.align.bottom = (bottom == undefined ? Globals.maxInt : bottom);
        }
    }

    /**
        * default mouseLeave callback
        */
    mouseLeave() {
        //console.log('name=' + this.name + ', MouseLeave');
    }

    /**
        * default mouseUp callback
        * @param x left screen position
        * @param y top screen position
        * @param button mousebutton number: 0 = left
        */
    mouseUp(x: number, y: number, button: number) {
        x -= this.screenPos.left;
        y -= this.screenPos.top;
        this.drag[2] = -1;
    }

    /**
        * default mouseDown callback
        * @param x left screen position
        * @param y top screen position
        * @param button mousebutton number: 0 = left
        */
    mouseDown(x: number, y: number, button: number) {
        if (this.dragAble) {
            this.drag[0] = x - this.position.left;
            this.drag[1] = y - this.position.top;
            this.drag[2] = button;
            this.controller.dragDrop = true;
        }
        x -= this.screenPos.left;
        y -= this.screenPos.top;
    }

    /**
        * default mouseDblClick callback
        * @param x left screen position
        * @param y top screen position
        * @param button mousebutton number: 0 = left
        */
    mouseDblClick(x: number, y: number, button: number) {
        x -= this.screenPos.left;
        y -= this.screenPos.top;
    }

    /**
        * default mouseMove callback
        * @param x left screen position
        * @param y top screen position
        * @param button mousebutton number: 0 = left
        */
    mouseMove(x: number, y: number, button: number) {
        if (this.dragAble && this.drag[2] >= 0) {
            this.controller.moveControl(this, new Rect(x - this.drag[0], y - this.drag[1], this.position.width, this.position.height));
        }
        x -= this.screenPos.left;
        y -= this.screenPos.top;
    }

    /**
        * default mouseWheel callback
        * @param x left screen position
        * @param y top screen position
        * @param delta mousewheel delta (-1,0,1)
        */
    mouseWheel(x: number, y: number, delta: number) {
        x -= this.screenPos.left;
        y -= this.screenPos.top;
    }

    /**
        * default dragDrop callback
        * @param x left screen position
        * @param y top screen position
        */
    dragDrop(x: number, y: number) {
        x -= this.screenPos.left;
        y -= this.screenPos.top;
    }

    /**
        * default dragDropCancel callback
        */
    dragDropCancel() {
            
    }

}