import { CanvasBuffer } from './../utils/canvasbuffer';
import { Rect } from './../utils/rect';
import * as Globals from './../utils/globals';

export class ActivityType {
    protected static buffer: CanvasBuffer = null; // static draw buffer for all activity types
    static absenceCategoryId = 3; // id for the absence activitytype category
    static daymarkCategoryId = 4; // id for the daymark activitytype category

    id: number;
    parentId: number;
    displayName: string;
    shortText: string;
    backColor: string;
    textColor: string;
    categoryId: number;
    sortOrder: number;
    maxPermissionForCurrentUser: number;
    resourceTypeIdList: number[];
    defaultTimeSlotList: TimeSlotItem[];
    private drawBuffer: [number, Rect, number, Rect]; // revision,rect,revision,rect (first is filled activity, second is open activity)
    isLeaf: boolean; // if this activityType is a leaf node (wich means it can have a resource planned)
    leafActivityTypeIdList: number[];
    notRequired: boolean;

    /**
        * create a new activity type
        * @param id unique id of the activitytype
        * @param parentId parent id
        * @param shortText the short text of the activitytype
        * @param backColor the background color
        * @param textColor the text color
        * @param categoryId the id of the category this activitytype belongs to
        */
    constructor(id: number, parentId: number, shortText: string, backColor: string, textColor: string, categoryId: number) {
        this.id = id;
        this.parentId = parentId;
        this.shortText = shortText;
        this.backColor = backColor;
        this.textColor = textColor;
        this.categoryId = categoryId;
        this.drawBuffer = [-1, null, -1, null];
        this.isLeaf = true; // will be set to false for all parents after activitytypes have been loaded
    }

    /**
        * test property to retrieve the static offscreen activity type canvas buffer
        */
    get ctx(): CanvasRenderingContext2D { return ActivityType.buffer == null ? null : ActivityType.buffer.ctx; }

    /**
        * reset the buffer
        */
    static resetBuffer() {
        if (ActivityType.buffer != null)
            ActivityType.buffer.reset();
    }

    /**
        * draw the activitytype on a specific context using a static cached drawing buffer
        * @param ctx the context to draw on
        * @param x position from the left
        * @param y position from the top
        * @param width the width
        * @param height the height
        * @param filled if the activitytype is completely filled
        */
    draw(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, filled: boolean) {
        if (width <= 0 || height <= 0) return;
        if (this.categoryId === ActivityType.absenceCategoryId) {
            ctx.fillStyle = this.backColor;
            ctx.fillRect(x, y, width, height);
            return;
        }
        if (ActivityType.buffer == null)
            ActivityType.buffer = new CanvasBuffer("ActivityTypeBuffer", 800, 600); // create static draw buffer for all activity types
        var drawBufferIndex = (filled ? 0 : 2); // filled starts at 0, unfilled at 2
        var r = this.drawBuffer[drawBufferIndex + 1] as Rect; // where was it last drawn on the buffer
        var bufferCtx = ActivityType.buffer.ctx; // shortcut to buffer context
        if (this.drawBuffer[drawBufferIndex] !== ActivityType.buffer.revision || (r != null && r.height !== height)) {
            // redraw on buffer if the buffer revision has changed or the height is different
            // console.log(`drawing activity: ${this.shortText} filled: ${filled}`);
            var textwOffset = Math.floor(bufferCtx.measureText(this.shortText).width + 4);
            var textw = Math.floor(Math.max(textwOffset, 40)); // text width
            textwOffset = Math.floor((textw - textwOffset) * 0.5); // calculate offset to center within the 40
            r = ActivityType.buffer.getRect(Math.floor(4 + textw + 40 + 4), height); // left corner(4) + text width + empty(40) + right corner(4)
            this.drawBuffer[drawBufferIndex + 1] = r; // remember rectangle
            this.drawBuffer[drawBufferIndex] = ActivityType.buffer.revision; // remember revision
            // background
            bufferCtx.save();
            bufferCtx.fillStyle = this.backColor;
            if (filled) {
                bufferCtx.fillRect(r.left, r.top, r.width, r.height);
            } else {
                bufferCtx.fillRect(r.left, r.top, r.width, 3);
                bufferCtx.fillRect(r.left, r.bottom - 2, r.width, 3);
                bufferCtx.fillRect(r.left, r.top, 4, r.height);
                bufferCtx.fillRect(r.right - 3, r.top, 4, r.height);
            }
            // dark & light edges
            bufferCtx.globalAlpha = 0.06;
            bufferCtx.fillStyle = "rgb(0,0,0)";
            /*
            if (!filled) {
                bufferCtx.fillRect(r.left + 3, r.top + 2, r.width - 6, 1);
                bufferCtx.fillRect(r.left + 3, r.top + 3, 1, r.height - 5);
            }
            bufferCtx.fillRect(r.left, r.bottom, r.width - 1, 1); // bottom side
            */
            bufferCtx.fillRect(r.right, r.top, 1, r.height); // right side
            bufferCtx.globalAlpha = 0.12;
            bufferCtx.fillStyle = "rgb(255,255,255)";
            /*
            if (!filled) bufferCtx.fillRect(r.left + 4, r.top + 3, r.width - 7, r.height - 5);
            bufferCtx.fillRect(r.left, r.top, r.width, 1); // top side
            */
            bufferCtx.fillRect(r.left, r.top, 1, r.height); // left side
            // corners
            /*
            bufferCtx.clearRect(r.left, r.top, 1, 1); bufferCtx.clearRect(r.right, r.top, 1, 1);
            bufferCtx.clearRect(r.left, r.bottom, 1, 1); bufferCtx.clearRect(r.right, r.bottom, 1, 1);
            bufferCtx.fillStyle = this.backColor;
            bufferCtx.fillRect(r.left, r.top, 1, 1); bufferCtx.fillRect(r.right, r.top, 1, 1);
            bufferCtx.fillRect(r.left, r.bottom, 1, 1); bufferCtx.fillRect(r.right, r.bottom, 1, 1);
            */
            // text
            bufferCtx.globalAlpha = 1.0;
            bufferCtx.beginPath();
            bufferCtx.rect(r.left, r.top + 2, r.width, r.height - 4);
            bufferCtx.closePath();
            bufferCtx.clip();
            bufferCtx.textAlign = Globals.alignLeft;
            bufferCtx.textBaseline = Globals.alignMiddle;
            bufferCtx.fillStyle = this.textColor;
            bufferCtx.fillText(this.shortText, Math.floor(r.left + 4 + textwOffset), Math.floor(r.top + r.height * 0.5));
            bufferCtx.restore();
        }
        // copy from buffer
        if (width < 8) { // too small, only copy left and right parts
            if (width > 0) ctx.drawImage(bufferCtx.canvas, r.left, r.top, Math.min(4, width), height, x, y, Math.min(4, width), height); // left part
            var wright = Math.min(4, width - 1);
            if (wright > 0) ctx.drawImage(bufferCtx.canvas, r.left + r.width - wright, r.top, wright, height, x + width - wright, y, wright, height); // right part
        } else {
            ctx.drawImage(bufferCtx.canvas, r.left, r.top, 4, height, x, y, 4, height); // left part
            ctx.drawImage(bufferCtx.canvas, r.left + r.width - 4, r.top, 4, height, x + width - 4, y, 4, height); // right part
            var w = Math.min(r.width - 48, width - 8);
            if (w > 0) {
                var x2 = Math.floor(width * 0.5 - w * 0.5);
                ctx.drawImage(bufferCtx.canvas, r.left + 4, r.top, w, height, x + x2, y, w, height); // centered text part
                if (w < width - 8) {
                    var srcx = r.left + r.width - 44;
                    var dstx = 4;
                    var dstw = x2 - 4;
                    if (dstw > 0) ctx.drawImage(bufferCtx.canvas, srcx, r.top, Math.min(dstw, 40), height, x + dstx, y, dstw, height); // between left and text
                    dstx = x2 + w;
                    dstw = width - dstx - 4;
                    if (dstw > 0) ctx.drawImage(bufferCtx.canvas, srcx, r.top, Math.min(dstw, 40), height, x + dstx, y, dstw, height); // between text and right
                }
            }
        }
    }

}

export class TimeSlotItem {
    id: number;
    startMinutes: number;
    endMinutes: number;
}