import { BasicControl } from './../controls/basicControl';

/**
    * timer object
    */
export class Timer {
    owner: BasicControl;
    interval: number;
    private isEnabled: boolean;
    private timer: any;

    /**
        * the callback function, override this function
        */
    tick = (t: Timer) => { }

    /**
        * get or set the enabled status
        */
    get enabled(): boolean { return this.isEnabled; }
    set enabled(value: boolean) {
        if (value !== this.isEnabled) {
            if (!value && this.timer != null)
                clearInterval(this.timer);
            this.isEnabled = value;
            if (value)
                this.timer = setInterval(() => this.tick(this), this.interval);
        }
    }

    /**
        * restart the timer with a different interval in miliseconds
        * @param interval the interval in miliseconds
        */
    restart(interval: number) {
        this.enabled = false;
        this.interval = interval;
        this.enabled = true;
    }

    /**
        * create a timer with a specific interval in miliseconds
        * @param owner the owner of the timer
        * @param interval the interval in miliseconds
        */
    constructor(owner: BasicControl, interval: number) {
        this.owner = owner;
        this.interval = interval;
        this.isEnabled = false;
        this.timer = null;
    }
}