import { StateService } from '@uirouter/angularjs';
import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';
import { CloneRequest } from './../../shared/cloneRequest';

import { ITranslationService } from './../i18n/translationService';
import { IPermissionService } from './../permissions/permissionService';

import { OrganizationUnit } from './../programManagement/organizationUnits/organizationUnit';
import { UserGroup } from './../programManagement/userGroups/UserGroup';

import * as Constants from './../utils/constants';
import { Dictionary } from './../utils/dictionary';
import { IModalConfirmationWindowService } from './../utils/modalConfirmationWindowService';

import { EntityTreeHelpers } from './EntityTreeHelpers';
import { ITreeListScope } from './ITreeListScope';
import { ITreeListScopeWithUserGroupPermissions } from './ITreeListScopeWithUserGroupPermissions';
import { TreeEntity } from './TreeEntity';
import { VerificationStatus } from './TreeListScope';

/**
    * Base class for controllers that use a lefthand pane tree/list to manage a certain type of entity.
    */
export abstract class TreeListController {
    // Needed for later TypeScript compilers, see https://stackoverflow.com/questions/44803945/after-upgrading-typescript-angular-controller-registration-now-fails-to-compile
    $onInit = () => { };

    protected http: ng.IHttpService;
    protected q: ng.IQService;
    protected timeout: ng.ITimeoutService;
    protected filter: ng.IFilterService;
    scope: ITreeListScope;
    state: StateService;

    /**
        * Base URL for the WebAPI that is used to manage entities for this controller. Should be overridden in actual controller implementations.
        */
    protected apiUrl = "api/Base";

    protected dialogToken = "treelistcontroller";

    // variables to track changes
    protected saveChangesTimerRunning = false;
    protected saveChangesTimer: any = null;
    protected changedItemIds: number[] = [];
    protected changedItemCount = 0;
    private automaticSaveDelay = 5000;

    // stuff for showing a modal wait window during one or more WebAPI requests
    private numberOfPendingOperations = 0;

    private userGroupHandled: Dictionary = new Dictionary();

    /**
        * Increment the number of pending operations and opens a delayed wait window if the resulting number > 0.
        * @param inc Amount to increase the number with.
        */
    protected incrementNumberOfPendingOperations(inc: number): void {
        this.numberOfPendingOperations += inc;
        //console.log("incrementNumberOfPendingGets()", this.numberOfPendingOperations);
        this.modalConfirmationWindowService.showModalInfoDialog(this.scope.textLabels.GETTING_DATA_TITLE,
            this.scope.textLabels.GETTING_DATA_TEXT, "", null, Constants.modalWaitDelay, this.dialogToken);
    }

    /**
        * Decrement the number of pending operations and close the modal wait window when this number reaches 0.
        * @param dec Amount to decrease the number with.
        */
    protected decrementNumberOfPendingOperations(dec: number): void {
        this.numberOfPendingOperations -= dec;
        //console.log("decrementNumberOfPendingGets()", this.numberOfPendingOperations);
        if (this.numberOfPendingOperations <= 0) this.modalConfirmationWindowService.closeModalWindow(this.dialogToken);
    }

    /**
        * Called when the scope is destroyed.
        * Should be overridden in controllers that make use of this functionality.
        */
    protected onScopeDestroy(): void { }

    /**
        * Makes our controller survive minification.
        */
    static $inject = ["$http", "$q", "$scope", "$state", "$timeout", "$filter", "permissionService", "modalConfirmationWindowService", "translationService", "pageStartService", "userService"];

    // constructor
    constructor(
        private $http: ng.IHttpService,
        private $q: angular.IQService,
        private $scope: ITreeListScope,
        private $state: StateService,
        protected $timeout: ng.ITimeoutService,
        protected $filter: ng.IFilterService,
        private permissionService: IPermissionService,
        protected modalConfirmationWindowService: IModalConfirmationWindowService,
        protected translationService: ITranslationService,
        protected pageStartService: IPageStartService,
        protected userService: IUserService) {

        this.http = $http;
        this.scope = $scope;
        this.state = $state;
        this.timeout = $timeout;
        this.filter = $filter;

        this.scope.ctrl = this;

        // create empty dictionaries, this is required for the getEntityDictionary method to work correctly (because it starts with a .clear)
        this.scope.entityDict = new Dictionary();
        this.scope.relatedOrganizationUnitDict = new Dictionary();
        this.scope.relatedResourceTypeDict = new Dictionary();
        this.scope.relatedSkillDict = new Dictionary();
        this.scope.relatedPermissionsDict = new Dictionary();
        this.scope.relatedSkillLevelDict = new Dictionary();
        this.scope.recentlyModifiedEntityIds = new Dictionary();

        this.scope.openModalConfirmationWindow = modalConfirmationWindowService.showModalDialog;

        this.scope.verificationStatus = new VerificationStatus();

        // Get text labels from translation service.
        this.translationService.getTextLabels(this.scope);

        // saveChanges on destroy
        $scope.$on("$destroy", () => {
            this.onScopeDestroy();
            this.saveChanges(false, () => {});
        });

        // Start user group permission list folded up if present.
        $scope.toggleUserGroupsMore = true;
    }

    /**
        * get the enabled state of a node in a tree checklist
        * @param node the node, in this case node is always an organization unit
        */
    protected getCheckListNodeEnabled(node: any): boolean {
        if (!this.scope.selectedItem) return false; // no entity selected
        if (!node) return false; // node is null
        let result = true;
        if (node.maxPermissionForCurrentUser != null) if (node.maxPermissionForCurrentUser < 2) result = false; // node says the user has no write permission
        if (node.selectable != null) if (node.selectable === false) result = false; // node is not selectable
        return result;
    }

    /**
        * Get the maximum number of visible entities per page, override this method in each controller.
        */
    protected getNumberOfEntitiesPerPage(): number {
        return 20;
    }

    /**
        * Initialize the current active page number.
        * @param pageNr the number of the page to show.
        * @param forceRebuild true to force recreate the arrays with visible entities and page numbers.
        */
    protected initActivePage(pageNr: number, forceRebuild: boolean = false) {
        if (pageNr != null && !isNaN(pageNr)) this.scope.activePageNr = Math.max(pageNr, 1);
        const entitiesPerPageCount = this.getNumberOfEntitiesPerPage();
        if (forceRebuild || this.scope.pageNrList == null || this.scope.allVisibleEntities == null) {
            // create array of all visible entities
            const visibleEntities: TreeEntity[] = [];
            if (this.scope.entities && this.scope.entities.length > 0) {
                for (let i = 0; i < this.scope.entities.length; i++) {
                    const entity = this.scope.entities[i];
                    // The entity either corresponds to the filter or has been recently created.
                    // Caution: the latter must be explicitly checked in isEntityVisible().
                    if (this.isEntityVisible(entity)) {
                        visibleEntities.push(this.scope.entities[i]);
                    }
                }
            }
            this.scope.allVisibleEntities = visibleEntities;
            // create array of page numbers
            const nrPages = Math.max(Math.ceil(this.scope.allVisibleEntities.length / entitiesPerPageCount), 1);
            let pageNrs: number[] = [];
            for (let i = 1; i <= nrPages; i++) pageNrs.push(i);
            this.scope.pageNrList = pageNrs;
        }
        // active page bounds check
        if (this.scope.activePageNr == null || isNaN(this.scope.activePageNr) || this.scope.activePageNr < 1)
            this.scope.activePageNr = 1;
        if (this.scope.activePageNr > this.scope.pageNrList.length)
            this.scope.activePageNr = this.scope.pageNrList.length;
        // create array of currently visible resources
        let index = (this.scope.activePageNr - 1) * entitiesPerPageCount;
        let entityList: TreeEntity[] = [];
        let indexOfSelectedEntity = this.scope.allVisibleEntities.indexOf(this.scope.selectedItem);
        // set activePageNr to page of selected entity if rebuilding pagination
        if (indexOfSelectedEntity > -1 && forceRebuild) {
            while (this.scope.activePageNr < this.scope.pageNrList.length) {
                if (indexOfSelectedEntity >= index && indexOfSelectedEntity < index + entitiesPerPageCount)
                    break;
                this.scope.activePageNr += 1;
                index = (this.scope.activePageNr - 1) * entitiesPerPageCount;
            }
        }
            
        while (index < this.scope.allVisibleEntities.length && entityList.length < entitiesPerPageCount) {
            entityList.push(this.scope.allVisibleEntities[index]);
            index++;
        }
        this.scope.visiblePageEntities = entityList;
    }

    /**
        * Is a specific entity visible, can be used to filter entities.
        * Should be overridden in controllers that make use of this functionality.
        */
    protected isEntityVisible(entity: TreeEntity): boolean {
        return true;
    }

    /**
        * Can the place of a specific entity in the tree change?
        * Should be overridden in controllers that make use of this functionality.
        */
    protected isEntitySortable(entity: TreeEntity): boolean {
        return false;
    }

    /**
        * Can the entity be placed higher?
        */
    protected isEntitySortableUp(entity: TreeEntity): boolean {
        if (!this.isEntitySortable(entity) ||
            entity.id === this.scope.entities[0].id) return false;
        let index = this.getEntityIndex(entity.id);
        while (index > 0) {
            index--;
            if (this.isEntityVisible(this.scope.entities[index])) return true;
        }
        return false;
    }

    /**
        * Can the entity be placed lower?
        */
    protected isEntitySortableDown(entity: TreeEntity): boolean {
        if (!this.isEntitySortable(entity) ||
            entity.id === this.scope.entities[this.scope.entities.length - 1].id) return false;
        let index = this.getEntityIndex(entity.id);
        while (index < this.scope.entities.length - 1) {
            index++;
            if (this.isEntityVisible(this.scope.entities[index])) return true;
        }
        return false;
    }

    /**
        * Get the index of an entity in this.scope.entities
        * @param id the id of the entity to find
        */
    private getEntityIndex(id: number): number {
        for (let i = 0; i < this.scope.entities.length; i++)
            if (this.scope.entities[i].id === id) return i;
        return -1;
    }

    /**
        * Change the order of an entity.
        */
    protected moveEntityUp(entity: TreeEntity) {
        const index1 = this.getEntityIndex(entity.id);
        if (index1 < 1) return;
        let index2 = index1;
        while (index2 > 0) {
            index2--;
            if (this.isEntityVisible(this.scope.entities[index2])) {
                this.swapEntityOrder(index1, index2);
                return;
            }
        }
    }

    /**
        * Change the order of an entity.
        */
    protected moveEntityDown(entity: TreeEntity) {
        const index1 = this.getEntityIndex(entity.id);
        if (index1 < 0 || index1 >= this.scope.entities.length - 1) return;
        let index2 = index1;
        while (index2 < this.scope.entities.length - 1) {
            index2++;
            if (this.isEntityVisible(this.scope.entities[index2])) {
                this.swapEntityOrder(index1, index2);
                return;
            }
        }
    }

    /**
        * Swap the place of two entities.
        * Should be overridden in controllers that make use of this functionality, so custom save functionality can be used.
        */
    protected swapEntityOrder(index1: number, index2: number) {
        const swap = this.scope.entities[index1];
        this.scope.entities[index1] = this.scope.entities[index2];
        this.scope.entities[index2] = swap;
    }

    /**
        * Can changes be made to the selected item?
        * Should be overridden in controllers that make use of this functionality.
        */
    protected isSelectedItemWritable(): boolean {
        if (!this.scope.selectedItem) return false;
        return true;
    }

    /**
        * Can the selected item be deleted?
        * Should be overridden in controllers that make use of this functionality.
        */
    protected isSelectedItemDeletable(): boolean {
        if (!this.scope.selectedItem) return false;
        if (this.scope.selectedItem.changeOfDeletabilityPending) return false;
        return true;
    }

    /**
    * Returns whether the user has the owner permission on the currently selected entity.
    */
    protected hasOwnerPermissionOnEntity(): boolean {
        // This requires owner permissions on the resource type.
        //console.log("hasOwnerPermissionOnEntity()", this.scope.selectedItem);
        return this.scope.selectedItem.maxPermissionForCurrentUser === 3;
    }

    /**
        * Returns the initial display name for a newly created entity.
        * Should be overridden in controllers that make use of this functionality.
        */
    protected newEntityDisplayName(): string {
        return null;
    }

    /**
        * Returns the title of the modal delete confirmation window.
        * Should be overridden in controllers that make use of this functionality.
        */
    protected getDeleteConfirmationWindowTitle(): string {
        return null;
    }

    /**
        * Returns the text of the modal delete confirmation window.
        * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowText(): string {
        return null;
    }

    /**
        * Called after an entity is selected.
        * Should be overridden in controllers that make use of this functionality.
    */
    protected onEntitySelected() { }

    /**
        * Called after an entity is restored.
        * Should be overridden in controllers that make use of this functionality.
    */
    protected onEntityRestored() { }

    /**
        * Shows a modal confirmation window, asking the user whether they really want to delete the selected entity.
        * Prodeeds with deletion upon positive response in de modal.
        * @param entity entity to be deleted after positive response to the modal.
        */
    protected confirmDeleteEntity(entity: TreeEntity): ng.ui.bootstrap.IModalServiceInstance {
        return this.scope.openModalConfirmationWindow(
            this.getDeleteConfirmationWindowTitle(),
            this.getDeleteConfirmationWindowText(),
            () => { return this.deleteEntity(entity); },
            () => { return this.$q.resolve(false); });
    }

    /**
        * Set the selected entity
        * @param entity the entity to select
        */
    protected setSelected(entity: TreeEntity) {
        //console.log("setSelected()", entity);
        this.scope.selectedItem = entity;
        this.onEntitySelected();
    }

    /**
        * Preprocess an entity to prepare it for saving. Override it in controllers where this is actually needed.
        * @param entity Entity to do preprocessing for.
        */
    protected preprocessEntity(entity: TreeEntity) {
            
    }

    /**
        * add an entity to the list of changed entities
        * @param entityId the id of the entity to add
        */
    protected addChange(entityId: number) {
        //console.log("addChange()", entityId, this.changedItemIds);

        const entity: TreeEntity = this.scope.entityDict.value(entityId);
        if (entity == null) return; // entity with this id does not exist

        entity.changed = true;

        // do nothing if this entityId is already in the changedItemIds
        let i = this.changedItemCount;
        let found = false;
        while (i > 0) {
            i--;
            if (this.changedItemIds[i] === entityId) {
                found = true;
                i = 0;
            };
        }

        if (!found) {
            this.changedItemIds[this.changedItemCount] = entityId;
            this.changedItemCount++;
        }

        // activate savechanges timer
        if (this.saveChangesTimerRunning) this.timeout.cancel(this.saveChangesTimer);
        this.saveChangesTimer = this.timeout(() => { this.saveChanges(true, () => {}); }, this.automaticSaveDelay);
        this.saveChangesTimerRunning = true;
    }

    /**
        * called when the scope is destroyed or when a timer calls it
        * @param calledFromTimer indication if this was called by a timer or by the scope destroy
        * @param uponSuccess Function called upon a successful promise resolve for the save.
        */
    protected saveChanges(calledFromTimer: boolean, uponSuccess: (entity: TreeEntity) => void) {
        if (this.saveChangesTimerRunning && !calledFromTimer) {
            this.timeout.cancel(this.saveChangesTimer);
            //console.log("save changes timer cancelled.");
        }
        this.saveChangesTimerRunning = false;
        if (this.changedItemCount === 0) return;

        //console.log(`save changes: ${this.changedItemCount} entities have changed.`, this.changedItemIds);

        for (let i = this.changedItemCount - 1; i >= 0; i--) {
            const entity: TreeEntity = this.scope.entityDict.value(this.changedItemIds[i]);
            if (entity != null && entity.changed) {

                this.preprocessEntity(entity); // perform any steps needed before saving the entity (e.g. convert t

                ((entity: TreeEntity) => {
                    this.http.put(this.apiUrl, entity)
                        .then(
                        response => {
                            entity.changeOfDeletabilityPending = false;
                            uponSuccess(entity);
                        },
                        response => {
                            entity.changeOfDeletabilityPending = false;
                            entity.updateFailed = true;
                            //console.log("Update failed", entity);
                            this.modalConfirmationWindowService
                                .showModalInfoDialog(this.scope.textLabels.ERROR_OCCURRED,
                                    this.getErrorMessage(response),
                                    this.scope.textLabels.OK,
                                    () => { this.restoreEntity(entity.id); },
                                    0,
                                    this.dialogToken);
                        },
                        () => {
                            this.userService.setLogoffWaitTime(this.dialogToken, 0);
                        });
                })(entity);
                entity.changed = false;
            }
        }

        this.changedItemCount = 0;
    }

    /**
        * Called from the view to delete an entity
        * @param entity the entity to delete
        */
    protected deleteEntity(entity: TreeEntity): ng.IPromise<boolean> {
        return ((entity: TreeEntity) => {
            this.numberOfPendingOperations += 1; // not strictly pending gets, but we should not get interference with other windows opened in parallel
            this.modalConfirmationWindowService.showModalInfoDialog(this.scope.textLabels.DELETING_DATA_TITLE,
                this.scope.textLabels.DELETING_DATA_TEXT, "", null, Constants.modalWaitDelay, this.dialogToken);
            return this.http.delete(`${this.apiUrl}/${entity.id}`)
                .then(
                    response => {
                        // clear selected entity
                        this.decrementNumberOfPendingOperations(1);
                        this.scope.selectedItem = null;
                        this.removeEntityFromTree(entity);

                        return true;
                    },
                    response => {
                        this.decrementNumberOfPendingOperations(1);
                        this.modalConfirmationWindowService
                            .showModalInfoDialog(this.scope.textLabels.ERROR_OCCURRED,
                                this.getErrorMessage(response),
                                this.scope.textLabels.OK,
                                () => {},
                                0,
                                this.dialogToken);

                        return false;
                    });
        })(entity);
    }

    /**
        * Removes an entity from the tree
        * @param entity the entity to remove
        */
    protected removeEntityFromTree(entity: TreeEntity) {
        // find the entities array in the entities tree
        let entityArray = this.scope.entities;
        if (entity.parentId != null) {
            const parentEntity: TreeEntity = this.scope.entityDict.value(entity.parentId);
            if (parentEntity != null && parentEntity.nodes != null) entityArray = parentEntity.nodes;
        }
        // remove the entity from the entities array
        let found = false;
        let i = entityArray.length;
        while (i > 0) {
            i--;
            if (entityArray[i].id === entity.id) {
                found = true;
                entityArray.splice(i, 1);
            }
        }
        // if not found in the entityArray, we need to loop over all entities
        if (!found) {
            this.scope.entityDict.forEach((key, value) => {
                const dictEntity: TreeEntity = value;
                if (dictEntity.nodes != null) {
                    entityArray = dictEntity.nodes;
                    i = entityArray.length;
                    while (i > 0) {
                        i--;
                        if (entityArray[i].id === entity.id) entityArray.splice(i, 1);
                    }
                }
            });
            entityArray = this.scope.entities;
            i = entityArray.length;
            while (i > 0) {
                i--;
                if (entityArray[i].id === entity.id) entityArray.splice(i, 1);
            }
        }

        if (this.scope.pageNrList != null) this.initActivePage(null, true); // recreate the paging

        // items are not removed from this.scope.entityDict, because this is not necessary
    }

    /**
        * Place an entity at the correct place in the entities tree and updates the dictionary
        * @param entity the entity to add or replace
        */
    protected placeEntityInTree(entity: TreeEntity, forceRebuild: boolean = true) {
        this.scope.entityDict.add(entity.id, entity); // add or replace in dictionary

        if (this.scope.entities == null) this.scope.entities = []; // make sure the entities array exists
        let entityArray = this.scope.entities;
        if (entity.parentId != null) {
            const parentEntity: TreeEntity = this.scope.entityDict.value(entity.parentId);
            if (parentEntity != null && parentEntity.nodes != null) entityArray = parentEntity.nodes;
        }
        let i = entityArray.length;
        while (i > 0) {
            i--;
            if (entityArray[i].id === entity.id) {
                entityArray[i] = entity; // replace existing
                if (this.scope.pageNrList != null) this.initActivePage(null, forceRebuild); // recreate the paging
                return;
            }
        }
        entityArray.push(entity); // add new
        EntityTreeHelpers.sortLastEntity(entityArray);

        if (this.scope.pageNrList != null) this.initActivePage(null, forceRebuild); // recreate the paging
    }

    /**
        * Creates a new entity object that can be inserted.
        */
    protected createNewEntity(): TreeEntity {
        const entity = new TreeEntity();
        entity.id = -1;
        entity.displayName = this.newEntityDisplayName();
        entity.receivedOrder = 0; // value of exactly 0 avoids sorting in the sortLastEntity function
        return entity;
    }

    /**
        * Clones the specified entity and inserts it into the collection of entities upon success.
        * @param entity Entity to be cloned.
        * @param actionUponSuccess Action to be performed after a succesful cloning operation.
        */
    protected cloneEntity(entity: TreeEntity, actionUponSuccess: () => void) {

        // First, save any pending changes, to prevent the old state of an entity from being cloned.
        this.saveChanges(false, () => {});

        let cloneRequest = new CloneRequest();
        cloneRequest.id = entity.id;
        cloneRequest.displayName = `${entity.displayName} (${this.scope.textLabels.COPY})`;

        this.incrementNumberOfPendingOperations(1);

        this.http.post(this.apiUrl + "/Clone", cloneRequest)
            .then(
                response => {
                    this.decrementNumberOfPendingOperations(1);
                    let newEntity = response.data as TreeEntity;
                    newEntity.nodes = [];
                    this.scope.recentlyModifiedEntityIds.add(newEntity.id, newEntity.id);
                    this.placeEntityInTree(newEntity);
                    this.setSelected(newEntity);
                    if (actionUponSuccess) actionUponSuccess();
                },
                response => {
                    this.decrementNumberOfPendingOperations(1);
                    this.modalConfirmationWindowService
                        .showModalInfoDialog(this.scope.textLabels.ERROR_OCCURRED,
                            this.getErrorMessage(response),
                            this.scope.textLabels.OK,
                            () => {},
                            0,
                            this.dialogToken);
                });
    }

    /**
        * Creates a new entity at the backend and puts it onto the scope after successful creation.
        * @param actionUponSuccess Action performed upon successful creation.
        * @param actionUponFailure Optional, Action performed upon failure.
        */
    protected newEntity(actionUponSuccess: () => void, actionUponFailure: () => void = null) {
        this.numberOfPendingOperations += 1; // not strictly pending gets, but we should not get interference with other windows opened in parallel
        this.modalConfirmationWindowService.showModalInfoDialog(this.scope.textLabels.ADDING_DATA_TITLE,
            this.scope.textLabels.ADDING_DATA_TEXT, "", null, Constants.modalWaitDelay, this.dialogToken);
        const entity = this.createNewEntity();
        ((entity: TreeEntity) => {
            this.http.put(this.apiUrl, entity)
                .then(
                    response => {
                        this.decrementNumberOfPendingOperations(1);
                        //console.log("newEntity()", response.data, entity);
                        const savedNewEntity: any = response.data;
                        if (savedNewEntity && savedNewEntity.id) {
                            this.setEntityDefaultValues(savedNewEntity, this.scope);
                            savedNewEntity.receivedOrder = entity.receivedOrder;
                            this.scope.recentlyModifiedEntityIds.add(savedNewEntity.id, savedNewEntity.id);
                            this.placeEntityInTree(savedNewEntity);
                            this.setSelected(savedNewEntity);
                            if (actionUponSuccess) actionUponSuccess();
                            if (this.scope.pageNrList != null) this.initActivePage(null, true); // recreate the paging
                        }
                    },
                    response => {
                        this.decrementNumberOfPendingOperations(1);
                        this.modalConfirmationWindowService
                            .showModalInfoDialog(this.scope.textLabels.ERROR_OCCURRED,
                            this.getErrorMessage(response),
                            this.scope.textLabels.OK,
                            () => { },
                            0,
                            "tlcNew");
                        if (actionUponFailure) actionUponFailure();
                    });
        })(entity);
    }

    /**
        * Restore an entity to the state as known on the server. Used after failed update attempts to reset control values.
        * @param entityId Id of the item to restore the state for.
        */
    protected restoreEntity(entityId: number) {
        this.incrementNumberOfPendingOperations(1);
        this.http.get(`${this.apiUrl}/${entityId}`)
            .then(response => {
                this.decrementNumberOfPendingOperations(1);
                const entity: any = response.data;
                if (entity && entity.id) {
                    this.setEntityDefaultValues(entity, this.scope);

                    // The fetched entity will not have children. Add the existing children instead now.
                    const currentNode = this.scope.entityDict.value(entity.id);
                    entity.nodes = currentNode.nodes;

                    // Now proceed replacing the old entity.
                    this.placeEntityInTree(entity);
                    this.setSelected(entity);
                    this.onEntityRestored();
                }
            },
            response => {
                //console.log(`http.get('${this.apiUrl}/${entityId}'): Failed`);
                this.decrementNumberOfPendingOperations(1);
                this.modalConfirmationWindowService
                    .showModalInfoDialog("Error",
                    this.getErrorMessage(response),
                    this.scope.textLabels.OK,
                    () => { },
                    0,
                    "tlcRestore");
            });
    }

    /**
        * Get an error message from an httpResponse, also show user feedback if the message contains a known statusText
        * @param httpResponse the response to get the error message from
        */
    protected getErrorMessage(httpResponse: any): string {
        return this.translationService.translateErrorMessage(httpResponse);
    }

    /**
        * Override to perform specific postprocessing on fetched entities
        * @param entityArray the fetched entities
        */
    protected postProcessEntities(entityArray: any): void { }

    /**
        * Retrieve a list of entities from the webAPI
        * @param apiUrl webAPI url to get the list of entities from
        * @param toDict dictionary where the entities should be stored in
        * @param onEntitiesLoaded callback function to call when the dictionary is filled, can be null if not used
        */
    protected getEntityDictionary(apiUrl: string,
        toDict: Dictionary,
        onEntitiesLoaded: () => void,
        getPropertyToCompare: (item: any) => any) {
        ((ctrl: TreeListController,
            apiUrl: string,
            toDict: Dictionary,
            onEntitiesLoaded: () => void,
            getPropertyToCompare: (item: any) => any) => {
            ctrl.incrementNumberOfPendingOperations(1);
            ctrl.http.get(apiUrl)
                .then(response => {
                        ctrl.decrementNumberOfPendingOperations(1);
                        const entityArray: any = response.data;
                        // Set the "order" property to the order when sorting by the specified property,
                        // given that a function to get this property has been supplied.
                        if (getPropertyToCompare) {
                            const sortedArray: any[] = this.filter("orderBy")(entityArray, item => getPropertyToCompare(item));
                            for (let i = 0; i < sortedArray.length; i++) sortedArray[i].order = i + 1;
                        }
                        ctrl.postProcessEntities(entityArray);
                        toDict.clear();
                        let receivedOrder = 1;
                        for (let entity of entityArray) {
                            entity.receivedOrder = receivedOrder++;
                            toDict.add(entity.id, entity);
                        }
                        if (onEntitiesLoaded != null) onEntitiesLoaded();
                    },
                    response => {
                        ctrl.decrementNumberOfPendingOperations(1);
                        this.modalConfirmationWindowService
                            .showModalInfoDialog(this.scope.textLabels.ERROR_OCCURRED,
                                this.getErrorMessage(response),
                                this.scope.textLabels.OK,
                                null,
                                0,
                                "tlcGetEntityDictionary");
                    });
        })(this, apiUrl, toDict, onEntitiesLoaded, getPropertyToCompare);
    }

    /**
        * Retrieve a list of entities Ids from the webAPI
        * @param apiUrl webAPI url to get the list of entities Ids from
        * @param toArray number array where the entities Ids should be stored in
        * @param onEntitiesLoaded callback function to call when the array is filled, can be null if not used
        */
    protected getEntityIdArray(apiUrl: string, toArray: number[], onEntitiesLoaded: () => void) {
        ((ctrl: TreeListController, apiUrl: string, toArray: number[], onEntitiesLoaded: () => void) => {
            ctrl.incrementNumberOfPendingOperations(1);
            this.http.get(apiUrl)
                .then(response => {
                        ctrl.decrementNumberOfPendingOperations(1);
                        const entityArray: any = response.data;
                        // clear the array and keep the same reference, another solution is: toArray.splice(0, toArray.length)
                        toArray.length = 0;
                        for (let entity of entityArray) {
                            if (toArray.indexOf(entity.id) < 0) toArray.push(entity.id);
                        }
                        if (onEntitiesLoaded != null) onEntitiesLoaded();
                    },
                    response => {
                        ctrl.decrementNumberOfPendingOperations(1);
                        this.modalConfirmationWindowService
                            .showModalInfoDialog(this.scope.textLabels.ERROR_OCCURRED,
                                this.getErrorMessage(response),
                                this.scope.textLabels.OK,
                                null,
                                0,
                                "tlcGetEntityIdArray");
                    });
        })(this, apiUrl, toArray, onEntitiesLoaded);
    }

    /**
        * Retrieve an array of numbers and store those in an Object
        * @param apiUrl webAPI url to get the list of entities Ids from
        * @param toObject the object where to store the numbers in
        * @param onLoaded callback function to call when the object is filled, can be null if not used
        */
    protected getNumberArray(apiUrl: string, toObject: Object, onLoaded: () => void) {
        ((ctrl: TreeListController, apiUrl: string, toObject: Object, onLoaded: () => void) => {
            ctrl.incrementNumberOfPendingOperations(1);
            this.http.get(apiUrl)
                .then(response => {
                    ctrl.decrementNumberOfPendingOperations(1);
                    const numberArray: any = response.data;
                    for (let value of numberArray) {
                        toObject[value] = value;
                    }
                    if (onLoaded != null) onLoaded();
                },
                response => {
                    ctrl.decrementNumberOfPendingOperations(1);
                    this.modalConfirmationWindowService
                        .showModalInfoDialog(this.scope.textLabels.ERROR_OCCURRED,
                        this.getErrorMessage(response),
                        this.scope.textLabels.OK,
                        null,
                        0,
                        "tlcGetNumberArray");
                });
        })(this, apiUrl, toObject, onLoaded);
    }

    /**
        * Event handler to be used by controls in the view.
        * @param affectsDeletability Boolean flag that indicates whether the change affects whether the entity still can be deleted.
        * @param item Item that was changed. Can be omitted, in that case scope.selectedItem will be considered the changed item.
        */
    protected onEntityChanged(affectsDeletability: boolean, item: TreeEntity) {
        //console.log("onEntityChanged()", this.scope.selectedItem);
        if (item == undefined) item = this.scope.selectedItem; // for controllers where only the selected item can change and hence the item argument is omitted
        if (!item) return;
        if (affectsDeletability) item.changeOfDeletabilityPending = true;
        this.scope.recentlyModifiedEntityIds.add(item.id, item.id);
        this.addChange(item.id);
        this.userService.setLogoffWaitTime(this.dialogToken, this.automaticSaveDelay);
    }

    /**
        * Initialize entity values that may not be set by the webAPI
        * @param entity the entity to initialize
        * @param myScope Scope that can be used in overrides of this function.
        */
    protected setEntityDefaultValues(entity: TreeEntity, myScope: ITreeListScope) {
        if (entity.nodes == null) entity.nodes = [];
        entity.changed = false;
        entity.updateFailed = false;
        entity.changeOfDeletabilityPending = false;
    }

    /**
        * Callback function called after the entityDict dictionary is filled, this callback function will fill the entities array/tree.
        * @param myScope current scope
        */
    protected onEntitiesLoaded(myScope: ITreeListScope) {
        myScope.entities = EntityTreeHelpers
            .entityDictionaryToTree(myScope.entityDict,
                myScope,
                this.setEntityDefaultValues,
                EntityTreeHelpers.sortLastEntity);
    }

    /**
        * Function that tells the controller how to get the property to compare for sorting its entities.
        * Override in actual controllers if necessary.
        * @param entity
        */
    protected getPropertyToCompare(entity: any): any {
        return entity.displayName;
    }

    /**
        * Loads entities and fills the entityDict dictionary, calls onEntitiesLoaded when the dictionary is filled
        */
    protected getEntities(initializePaging: boolean = false) {
        this.getEntityDictionary(this.apiUrl,
            this.scope.entityDict,
            () => {
                this.onEntitiesLoaded(this.scope);
                if (initializePaging) this.initActivePage(1, true);
            },
            entity => this.getPropertyToCompare(entity));
    }

    /**
        * Loads resource types, can be used by controllers for entities that have a relationship with resource types.
        * @param onResourceTypesLoaded Function executed once the resource types have been successfully loaded. Can be used in cases where certain actions can only be done after this.
        */
    protected getRelatedResourceTypes(onResourceTypesLoaded: () => void) {
        this.getEntityDictionary("api/ResourceTypes", this.scope.relatedResourceTypeDict, onResourceTypesLoaded, resourceType => resourceType.displayName);
    }

    /**
        * Loads skills, can be used by controllers for entities that have a relationship with skills.
        * @param onSkillsLoaded Function executed once the skills have been successfully loaded. Can be used in cases where certain actions can only be done after this.
        */
    protected getRelatedSkills(onSkillsLoaded: () => void) {
        this.getEntityDictionary("api/Skills", this.scope.relatedSkillDict, onSkillsLoaded, skill => skill.displayName);
    }

    /**
        * Loads skill levels, can be used by controllers for entities that have a relationship with skill levels.
        * @param onSkillLevelsLoaded Function executed once the skill levels have been successfully loaded. Can be used in cases where certain actions can only be done after this.
        */
    protected getRelatedSkillLevels(onSkillLevelsLoaded: () => void) {
        this.getEntityDictionary("api/SkillLevels", this.scope.relatedSkillLevelDict, onSkillLevelsLoaded, null);
    }

    /**
    * Loads organization units, can be used by controllers for entities that have a relationship with organization units.
    * @param onOrganizationUnitsLoaded Function executed once the organization units have been successfully loaded. Can be used in cases where certain actions can only be done after this.
    */
    protected getRelatedOrganizationUnits(onOrganizationUnitsLoaded: () => void) {
        this.getEntityDictionary("api/OrganizationUnits", this.scope.relatedOrganizationUnitDict, () => {
            // Set property that indicates whether an organization unit is selectable for tag lists. As this alters an organization unit's relationships,
            // this requires at least write permissions.
            this.scope.relatedOrganizationUnitDict.forEach((key, value) => {
                value.selectable = value.maxPermissionForCurrentUser >= 2;
            });
            if (onOrganizationUnitsLoaded != null) onOrganizationUnitsLoaded();
        }, unit => unit.displayName);
    }

    /**
    * Registers an organization unit as child to its parent.
    * @param organizationUnit Organization to register to its parent.
    * @param childId Id of the child to register/propagate.
    */
    protected registerChildToParent(organizationUnit: OrganizationUnit, childId: number) {
        if (!organizationUnit) return;
        if (!organizationUnit.childIds) organizationUnit.childIds = [];
        if (!organizationUnit.parentId) return;
        if (!childId) childId = organizationUnit.id;
        const parent = this.scope.relatedOrganizationUnitDict.value(organizationUnit.parentId);
        if (!parent) return;
        if (!parent.childIds) parent.childIds = [childId];
        else parent.childIds.push(childId);
        this.registerChildToParent(parent, childId);
    }

    /**
        * Loads permissions, can be used by controllers for entities that have relationship with permissions
        * @param onPermissionsLoaded Function executed once the permissions have been successfully loaded. Can be used in cases where certain actions can only be done after this.
        */
    protected getRelatedPermissions(onPermissionsLoaded: () => void) {
        this.getEntityDictionary("api/Permissions", this.scope.relatedPermissionsDict, onPermissionsLoaded, null);
    }

    /**
        * Checks for this user if the minimum specified permission level has been set for at least one of the organization units in the array.
        * @param unitArray The input array of organization units.
        * @param permissionLevel The minimum permission level to check for.
        */
    protected hasAtLeastPermissionOnOrganizationUnit(unitArray: OrganizationUnit[],
        permissionLevel: number): boolean {
        let result = false;
        if (unitArray != null && unitArray.length > 0)
            for (let i = 0; i < unitArray.length; i++) {
                if (unitArray[i] != null && unitArray[i].maxPermissionForCurrentUser >= permissionLevel) {
                    result = true;
                    break;
                }
            }
        return result;
    }

    /**
        * Checks if an entity is selected.
        * @param entity The input entity to select the selected status for.
        */
    protected isEntitySelected(entity: TreeEntity): boolean {
        if (!this.scope.selectedItem) return false;
        return entity.id === this.scope.selectedItem.id;
    }

    /**
        * Gets related user groups, needed for permission management.
        */
    protected getUserGroups() {
        const myScope = this.scope as ITreeListScopeWithUserGroupPermissions;
        this.getEntityDictionary("api/UserGroups/Names", myScope.userGroupDict, () => this.onUserGroupsLoaded(myScope), null);
    }

    /**
    * Get the highest permission the currently logged in user has on a user group, based on their group memberships.
    * @param userGroupId Id of the user group to get the highest permission for.
    */
    protected getUserGroupPermissions(userGroupId: number): number {
        return this.getUserGroupPermissionsForItem(userGroupId, this.scope.selectedItem);
    }

    /**
    * Get the highest permission the currently logged in user has on a user group, based on their group memberships.
    * @param userGroupId Id of the user group to get the highest permission for.
    * @param item Item to get the highest permission for.
    */
    protected getUserGroupPermissionsForItem(userGroupId: number, item: TreeEntity) {
        if (!item) return 0;
        const permissionList = item.userGroupPermissionList;
        let highestPermission = 0;
        if (permissionList)
            for (let i = 0; i < permissionList.length; i++)
                if (permissionList[i].userGroupId === userGroupId)
                    highestPermission = Math.max(highestPermission, permissionList[i].permission);
        return highestPermission;   
    }

    /**
    * Toggles the permission value for a user group for the selected item on the scope.
    * Toggle means: set to the input value if the current value is smaller, else set it to the input value - 1.
    * @param userGroupId Id of the user group to toggle the permission value for.
    * @param newPermissionValue The new permission value.
    * @param setInsteadOfToggle Set to true to always set the new permission value, even if is the same as the actual value.
    */
        public toggleUserGroupPermission(userGroupId: number, newPermissionValue: number, shouldPropagate: boolean, userTriggered: boolean = false, setInsteadOfToggle: boolean = false) {
            if (!this.isSelectedItemWritable() || !userGroupId) return;

            if (userTriggered) {
                this.userGroupHandled.clear();
            }

            if (this.userGroupHandled.containsKey(userGroupId)) {
                return;
            }

            this.userGroupHandled.add(userGroupId, userGroupId);
			
            this.toggleUserGroupPermissionForItem(userGroupId,
                newPermissionValue,
                this.scope.selectedItem,
                shouldPropagate,
                userTriggered,
                setInsteadOfToggle);
        }

    /**
        * Toggles the permission value for a user group for a given item.
        * @param userGroupId Id of the user group to toggle the permission value for.
        * @param newPermissionValue The new permission value.
        * @param item Item to set the permissions for.
        * @param setInsteadOfToggle Set to true to always set the new permission value, even if is the same as the actual value.
        */
    protected toggleUserGroupPermissionForItem(userGroupId: number,
        newPermissionValue: number,
        item: TreeEntity,
        shouldPropagate: boolean,
        userTriggered: boolean = false,
        setInsteadOfToggle: boolean = false) {

        let permissionList = item.userGroupPermissionList;
        if (!permissionList) { // TODO: is this really necessary?
            permissionList = [];
            this.scope.selectedItem.userGroupPermissionList = permissionList;
        }

        // find previous highest permission and remove all permissions for this userGroup
        let oldValue = 0;
        let index = permissionList.length;
        while (index > 0) {
            index--;
            if (permissionList[index].userGroupId === userGroupId) {
                oldValue = Math.max(oldValue, permissionList[index].permission);
                permissionList.splice(index, 1);
            }
        }

        let newPermValue = newPermissionValue;

        if (oldValue >= newPermissionValue && !setInsteadOfToggle) {
            newPermValue--;
        }
        permissionList.push({ permission: newPermValue, userGroupId: userGroupId });

        // Propagate down the newly set value.
        if (shouldPropagate) {
            const userGroupDict =
                (this.scope as ITreeListScopeWithUserGroupPermissions).userGroupDict;
            if (userGroupDict) {
                const group = userGroupDict.value(userGroupId) as UserGroup;
                for (let node of group.nodes) {
                    this.toggleUserGroupPermission(node.id, newPermValue, shouldPropagate, false, true);
                }
            }
        }

        // Only call onEntityChanged() if this was not a recursive call.
        if (!setInsteadOfToggle) {
            this.onEntityChanged(false, item);
        }
    }

    /**
        * Gets the indentation for a node in the permission tree.
        * The indentation consists of repeating a character multiple times, depending on the tree level op the node.
        * @param level
        */
    protected getPermissionTreeIndent(level: number): string {
        let result = "";
        for (let i = 0; i < level; i++) result = result + "\u00A0\u00A0"; // nicely indent two spaces here
        return result;
    }

    /**
    * Called after successfully loading user groups in order to get them into tree shape.
    * @param myScope Scope where the user group dictionary lives and where the user group tree array goes.
    */
    protected onUserGroupsLoaded(myScope: ITreeListScopeWithUserGroupPermissions): void {
        myScope.userGroups = EntityTreeHelpers
            .entityDictionaryToTree(myScope.userGroupDict, myScope,
                this.setEntityDefaultValues,
                EntityTreeHelpers.sortLastEntity) as UserGroup[];
    }

    /**
    * Returns the ids of all child organization units of the specified organization unit.
    * @param selectedUnit The organization unit to get the ids of child organization units for.
    * @param organizationUnits Object containing whole organization unit tree.
    */
    protected getAllChildOrganizationUnits(selectedUnit: any, organizationUnits: Object): number[] {
        let unitAndChildIds = selectedUnit != undefined ? [selectedUnit.id] : [];

        // find root unit
        let root = selectedUnit;
        while (root != undefined && root.parentId != undefined && organizationUnits[root.parentId] != undefined)
            root = organizationUnits[root.parentId];
        // (re)build the tree if the root does not have both the childId and nextId property
        if (root && root.childId == undefined && root.nextId == undefined) {
            for (let key in organizationUnits) {
                if (organizationUnits.hasOwnProperty == undefined || organizationUnits.hasOwnProperty(key)) {
                    let item = organizationUnits[key];
                    if (item.parentId && organizationUnits[item.parentId]) {
                        let parentItem = organizationUnits[item.parentId];
                        item.nextId = parentItem.childId;
                        parentItem.childId = key;
                    }
                }
            }
        }

        if (selectedUnit != undefined && selectedUnit.childId != undefined) {
            unitAndChildIds =
                unitAndChildIds.concat(
                    this.getChildAndSiblingOrganizationUnitIds(organizationUnits[selectedUnit.childId],
                        organizationUnits));
        }
        return unitAndChildIds;
    }

    /**
        * Recursively add child ids to an array. This is an helper function for getChildAndSiblingOrganizationUnitIds.
        * @param unit The first child unit.
        * @param organizationUnits Object containing whole organization unit tree.
        * @param idList The array to add the ids to.
        */
    private addChildOrganizationUnitIds(unit: any, organizationUnits: Object, idList: number[]) {
        // loop over self and siblings
        while (unit != null) {
            // add if it is not already in the array, to avoid infinity loops
            if (idList.indexOf(unit.id) < 0) {
                idList.push(unit.id);
                // add children
                if (unit.childId != null) this.addChildOrganizationUnitIds(organizationUnits[unit.childId], organizationUnits, idList);
                // next sibling
                unit = unit.nextId == null ? null : organizationUnits[unit.nextId];
            } else
                unit = null; // skip this loop because it would cause an infinite loop
        }
    }

    /**
    * Recursively gets the ids of an organization itself, along with the unit's siblings and children.
    * @param unit Unit to get siblings/children for.
    * @param organizationUnits Object containing whole organization unit tree.
    */
    private getChildAndSiblingOrganizationUnitIds(unit: any, organizationUnits: Object): number[] {
        let result = [];
        this.addChildOrganizationUnitIds(unit, organizationUnits, result);
        return result;
    }

    /**
        * Change a date string so that it has no timezone component (add a Z to the string if it does not end with Z)
        */
    protected getDateWithoutTimezone(dateString: any): any {
        // is a string and does not end with Z
        if (dateString != undefined && dateString.charAt != undefined && dateString !== "" && dateString.charAt(dateString.length - 1) !== "Z") 
            return dateString + "Z";
        return dateString; // return whatever it was that we got
    }
}