import { StateService } from "@uirouter/angularjs";

import { ITreeListScope } from "./../treeListController/ITreeListScope";

import { IPageStartService } from "./../../shared/pageStartService";
import { IUserService } from "./../../shared/userService";

import { IPermissionService } from "./../permissions/permissionService";
import { IFeatureService } from "./../features/featureService";

import { IConfigurationService, FeatureManagement } from "./../../shared/configurationService";
import { INotificationService } from "./../login/notificationService";
import { ITranslationService } from "./../i18n/translationService";

export class OverviewsController {
    reportsAvailable: boolean;
    isFullUser: boolean;
    reportsEnabled = false;
    featureManagement: FeatureManagement;
    angularOverviewsFeatureEnabled: boolean;
    useNewOverviewScreens: boolean;

    private commonSvc: any;
    private verificationStatus: any = {
        pending: true, // to prevent screen flickering
        failed: false,
        hasPermission: true
    };
    private userPermissions: any = { granted: [] };
    private enabledFeatures: any[] = [];

    private readonly dialogToken: string = "overviews";
    private readonly overviewsEvent: string = "overviewsEvent";

    static $inject = [
        "$scope",
        "$state",
        "$window",
        "$q",
        "configurationService",
        "notificationService",
        "pageStartService",
        "translationService",
        "userService",
        "permissionService",
        "featureService"
    ];

    constructor(
        public $scope: ITreeListScope,
        private readonly $state: StateService,
        private readonly $window: Window,
        private readonly $q: ng.IQService,
        private readonly configurationService: IConfigurationService,
        private readonly notificationService: INotificationService,
        private readonly pageStartService: IPageStartService,
        private readonly translationService: ITranslationService,
        private readonly userService: IUserService,
        private readonly permissionService: IPermissionService,
        private readonly featureService: IFeatureService,
    ) {
        const deferred = $q.defer();
        const getReportsUrisPromise = deferred.promise;
        

        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);

        this.configurationService.getAppConfiguration(() => {
            this.featureManagement = this.configurationService.appConfiguration.featureManagement;
            this.useNewOverviewScreens = this.configurationService.appConfiguration.featureManagement.featureOverviewPlanning;

            if (!this.featureManagement.ssrsReports) {
                deferred.resolve();
            } else {
                // See if a reports base uri is available, only show the reports button if that is indeed the case.
                this.commonSvc.loadData("api/Reports/Uris", null, (response) => {
                    this.reportsAvailable = response.data && response.data.length > 0;
                    deferred.resolve();
                }, null, true, false);
            }
        });

        const getPermissionsPromise = permissionService.getAllPermissions(this.userPermissions, this.verificationStatus, this.$scope);
        const getEnabledFeaturesPromise = featureService.getEnabledFeatures(this.enabledFeatures, this.$scope);
        $q.all([getPermissionsPromise, getEnabledFeaturesPromise, getReportsUrisPromise]).then(() => {

            this.angularOverviewsFeatureEnabled = this.enabledFeatures.filter(feature => feature.featureName === "Angular overviews").length > 0;

            this.$scope.$on("$destroy",
                () => {
                    this.notificationService.removeStringFromClientInfo(this.overviewsEvent);
                });

            this.notificationService
                .subscribeToWebSocketEvent(this.$scope,
                    this.overviewsEvent,
                    () => {
                        this.userService.refreshPage();
                    });

            this.notificationService.addStringToClientInfo(this.overviewsEvent);
        });
    }

    openCountersOverviewPage() {
        this.$window.location.href = "angularomrp/overviews/counters-overview";
    }

    openScheduleOverviewPage() {
        if (this.useNewOverviewScreens) {
            this.$window.location.href = "angularomrp/overviews/schedule-overview";
        }
        else {
            this.$window.location.href = "angularomrp/overviews/schedule-overview-precursor";
        }
    }

    openReports() {
        this.$state.transitionTo("reports", {});
    }
}