export interface INumberService {

    /**
        * convert a value to a float, returns null on invalid input
        */
    toFloat(value: string): number;

    /**
        * convert a value to an integer, returns null on invalid input
        */
    toInteger(value: string): number;

    /**
        * convert a float value to a string representation using the selected locale
        */
    toFloatStr(value: number): string;

    /**
        * returns the decimal symbol for the current locale
        */
    getDecimalSymbol(): string;

    /**
        * convert total minutes to time string
        * @param minutes total number of minutes since midnight
        * @returns time string in hours:minutes format, e.g. 12:35
        */
    minutesToStr(minutes: number): string;

    /**
        * convert time string to total minutes since midnight, e.g. 7:45 -> 7*60+45
        * @param timeStr time string in format hh:nn or hhnn or hnn or hh or h
        * @returns total minutes since midnight
        */
    timeStrToMinutes(timeStr: string): number;

    /**
        * returns if a keyCode is: left, up, right, down, escape, backspace, tab, enter
        */
    isNavigationKeyCode(keyCode: number): boolean;

    /**
        * filter input on text to only allow time value, e.g. 12:45
        */
    filterTextTime($event: any, oldValue: string): void;

    /**
        * filter input on text to only allow numeric values
        */
    filterTextValue($event: any, oldValue: string, allowDecimal: boolean, maxLength?: number): void;
}

export var numberService = [
    "$filter",
    function ($filter) {
        var svc = this;

        /**
         * convert a value to a float, returns null on invalid input
         */
        svc.toFloat = function (value) {
            if (value == null) return null;
            var result = parseFloat(value.toString().replace(",", "."));
            if (isNaN(result)) result = null;
            return result;
        }

        /**
         * convert a value to an integer, returns null on invalid input
         */
        svc.toInteger = function (value) {
            if (value == null) return null;
            var result = parseInt(value);
            if (isNaN(result)) result = null;
            return result;
        }

        /**
         * convert a float value to a string representation using the selected locale
         */
        svc.toFloatStr = function (value) {
            if (value == null) return null;
            var strValue = svc.toFloat(value).toString();
            if (strValue.indexOf(".") >= 0) {
                var decimalSymbol = svc.getDecimalSymbol();
                if (decimalSymbol !== ".") strValue = strValue.replace(".", decimalSymbol);
            }
            return strValue;
        }

        /**
         * convert total minutes to time string
         * @param minutes total number of minutes since midnight
         * @returns time string in hours:minutes format, e.g. 12:35
         */
        svc.minutesToStr = function (minutes) {
            var hours = Math.floor(minutes / 60);
            var str = "00" + Math.floor(minutes - (hours * 60)).toString();
            str = "00" + hours.toString() + ":" + str.substring(str.length - 2);
            return str.substring(str.length - 5);
        }

        /**
         * convert time string to total minutes since midnight, e.g. 7:45 -> 7*60+45
         * @param timeStr time string in format hh:nn or hhnn or hnn or hh or h
         * @returns total minutes since midnight
         */
        svc.timeStrToMinutes = function (timeStr) {
            var index = timeStr.indexOf(":");
            if (index >= 0)
                timeStr = ("00" + timeStr.substring(0, index)).substring(Math.min(index, 2)) + // up to two leading zeros before :
                    "00".substring(Math.min(timeStr.length - 1 - index, 2)) + timeStr.substring(index + 1); // up to two leading zeros after :
            else
                timeStr = "0000".substring(Math.min(timeStr.length, 4)) + timeStr + (timeStr.length < 3 ? "00" : ""); // h or hh or hnn -> 00hhnn
            return parseInt(timeStr.substring(timeStr.length - 2)) + 60 * parseInt(timeStr.substring(0, timeStr.length - 2));
        }

        /**
         * returns if a keyCode or key is: left, up, right, down, escape, backspace, tab, enter
         * @param keyCode the keycode to test
         * @param key the key name to test (in case keyCode and charCode are deprecated)
         * @returns true if either the keyCode or the key name is one of the navigation keys
         */
        svc.isNavigationKeyCode = function (keyCode, key) {
            if (keyCode != undefined) {
                if (keyCode >= 37 && keyCode <= 40) return true; // left, up, right, down
                if (keyCode === 27 || keyCode === 8 || keyCode === 9 || keyCode === 13) return true; // escape, backspace, tab, enter; delete equals ., so cannot be used here
            }
            if (key != undefined) {
                var lkey = key.toLowerCase != undefined ? key.toLowerCase() : key;
                if (lkey === "down" || lkey === "up" || lkey === "left" || lkey === "right") return true;
                if (lkey === "arrowdown" || lkey === "arrowup" || lkey === "arrowleft" || lkey === "arrowright") return true;
                if (lkey === "escape" || lkey === "backspace" || lkey === "tab" || lkey === "enter" ||
                    lkey === "delete" || lkey === "del") return true;
            }
            return false;
        }

        /**
         * returns the decimal symbol for the current locale
         */
        svc.getDecimalSymbol = function () {
            return $filter("number")(1.2).trim().substring(1, 2);
        }

        /**
         * filter input on text to only allow time value, e.g. 12:45
         */
        svc.filterTextTime = function ($event, oldValue) {
            var keyCode = $event.keyCode;
            if (keyCode == undefined || keyCode === 0) keyCode = $event.charCode; // firefox fix
            if (svc.isNavigationKeyCode(keyCode, $event.key)) return true;
            if (oldValue != null && oldValue.length >= 5) {
                if (oldValue.length >= 6 || oldValue.indexOf(":") < 0) {
                    $event.preventDefault();
                    return false;
                }
            }
            var keyValue = (keyCode == undefined || keyCode === 0) ? $event.key : String.fromCharCode(keyCode);
            if (keyValue === ":") {
                if (oldValue == null || oldValue.indexOf(":") >= 0) {
                    $event.preventDefault();
                    return false;
                }
                return true;
            }
            if (isNaN(parseInt(keyValue, 10))) {
                $event.preventDefault();
                return false;
            }
            return true;
        }

        /**
         * filter input on text to only allow nummeric values
         */
        svc.filterTextValue = function ($event, oldValue, allowDecimal, maxLength) {
            var keyCode = $event.keyCode;
            if (keyCode == undefined || keyCode === 0) keyCode = $event.charCode; // firefox fix
            if (svc.isNavigationKeyCode(keyCode, $event.key)) return true;
            if (maxLength != null && oldValue != null && oldValue.length + 1 > maxLength) {
                $event.preventDefault();
                return false;
            }
            var keyValue = ($event.key != undefined || keyCode == undefined || keyCode === 0)
                ? $event.key
                : String.fromCharCode(keyCode);
            if (allowDecimal) {
                var decimalSymbol = svc.getDecimalSymbol();
                if (keyValue === decimalSymbol) {
                    if (oldValue != null && oldValue.toString().indexOf(decimalSymbol) >= 0) {
                        $event.preventDefault();
                        return false;
                    }
                    return true;
                }
            }
            if (isNaN(keyValue)) {
                $event.preventDefault();
                return false;
            }
            return true;
        };

    }
];