import { StateService } from '@uirouter/angularjs';
import { INumberService } from './../../../shared/numberService';
import { IPageStartService } from './../../../shared/pageStartService';
import { IUserService } from './../../../shared/userService';

import { ITranslationService } from './../../i18n/translationService';
import { IPermissionService } from './../../permissions/permissionService';

import { TreeEntity } from './../../treeListController/TreeEntity';
import { TreeListController } from './../../treeListController/TreeListController';
import { VerificationStatus } from './../../treeListController/TreeListScope';

import { Dictionary } from './../../utils/dictionary';
import { IModalConfirmationWindowService } from './../../utils/modalConfirmationWindowService';

import { IUserGroupScope } from './../userGroups/IUserGroupScope';

import { IOrganizationUnitsScope } from './IOrganizationUnitsScope';
import { OrganizationUnit } from './organizationUnit';

export class OrganizationUnitsController extends TreeListController {
    scope: IOrganizationUnitsScope;
    commonSvc;

    static $inject = [
        "$http",
        "$q",
        "$scope",
        "$state",
        "$timeout",
        "$filter",
        "$window",
        "permissionService",
        "modalConfirmationWindowService",
        "translationService",
        "pageStartService",
        "numberService",
        "userService"
    ];

    constructor(
        $http: ng.IHttpService,
        $q: ng.IQService,
        $scope: IUserGroupScope,
        $state: StateService,
        $timeout: ng.ITimeoutService,
        $filter: ng.IFilterService,
        $window: ng.IWindowService,
        permissionService: IPermissionService,
        modalConfirmationWindowService: IModalConfirmationWindowService,
        translationService: ITranslationService,
        protected pageStartService: IPageStartService,
        private numberService: INumberService,
        userService: IUserService) {

        super($http, $q, $scope, $state, $timeout, $filter, permissionService, modalConfirmationWindowService, translationService, pageStartService, userService);

        $window.location.href = "/angularomrp/program-management/organization-units";

        this.scope.userGroupDict = new Dictionary();
        this.commonSvc = pageStartService.initialize($scope, null, "OrgUnits");

        this.apiUrl = "api/OrganizationUnits";

        //See whether the currenly logged in user has the "OrganizationUnits" permission and set flags accordingly.
        $scope.verificationStatus = new VerificationStatus();
        permissionService.userHasPermission("OrganizationUnits", $scope.verificationStatus, $scope);

        // get data necessary for this model & controller
        this.getEntities();

        // get user groups so that we can show permissions
        this.getUserGroups();
    }

    /**
        * Override the default createNewEntity so that we can specify the selected parent organization unit.
        */
    protected createNewEntity(): TreeEntity {
        const entity = new OrganizationUnit();
        entity.id = -1;
        entity.displayName = this.newEntityDisplayName();
        entity.receivedOrder = 0;
        if (this.scope.selectedItem) {
            entity.parentId = this.scope.selectedItem.id;
            entity.fte = this.numberService.toFloat((this.scope.selectedItem as any).fte);
        }
        return entity;
    }

    /**
        * Creates the tree with selectable parent organization units.
        */
    private createSelectParentTree() {
        this.scope.selectParentTree = Object.create(null);
        if (!this.scope.selectedItem) return;

        // create default empty entity for the root
        const noEntity = new TreeEntity();
        noEntity.id = 0;
        noEntity.displayName = "";
        this.scope.selectParentTree[noEntity.id] = noEntity;

        // copy all entities from scope.entities
        const addIds: number[] = [];
        for (let entity of this.scope.entities)
            addIds.push(entity.id);

        for (let index = 0; index < addIds.length; index++) {
            const entity: TreeEntity = this.scope.entityDict.value(addIds[index]);
            // do not add self, or all childs under self
            if (entity && entity.id !== this.scope.selectedItem.id) {
                this.scope.selectParentTree[entity.id] = entity;
                for (let node of entity.nodes)
                    addIds.push(node.id);
            }
        }

    }

    /**
        * Callback when an organization unit's parent changes.
        * @param entityId
        */
    protected onParentChanged(itemId: number) {
        if (!this.scope.selectedItem) return;
        if (this.scope.selectedItem.parentId === itemId) return; // no change

        // set new parent, then remove, and re-insert
        this.scope.selectedItem.parentId = itemId;
        this.removeEntityFromTree(this.scope.selectedItem);
        this.placeEntityInTree(this.scope.selectedItem);

        this.onEntityChanged(false, this.scope.selectedItem);
    }

    /**
        * Called when an organization unit is selected.
        */
    protected onEntitySelected() {

        this.scope.selectedItemWritable = false;
        const unit = this.scope.selectedItem as any;
        unit.fte = this.numberService.toFloatStr(unit.fte);

        // see what elegible parent entities for the selected entity are
        this.createSelectParentTree();

        this.scope.selectedItemWritable = this.isSelectedItemWritable();
    }

    /**
        * Returns if the current organization unit is writable.
        */
    protected isSelectedItemWritable(): boolean {

        if (!this.scope.selectedItem) return false;

        // This requires owner permissions on the resource type.
        let result = this.hasOwnerPermissionOnEntity();
        //console.log("isSelectedItemWritable()", this.scope.selectedItem, result);
        return result;
    }

    /**
        * Returns if the current organization unit can be deleted.
        */
    protected isSelectedItemDeletable(): boolean {
        if (!this.scope.selectedItem) return false; // no organization unit is selected
        if (this.scope.selectedItem.nodes.length > 0) return false; // organization unit still has children

        return !this.scope.selectedItem.changeOfDeletabilityPending; // no pending update that needs to be processed first
    }

    /**
        * Returns the name of a new organization unit.
        */
    protected newEntityDisplayName() {
        return this.scope.textLabels.NEW_ORGANIZATION_UNIT;
    }

    /**
    * Returns the title of the modal delete confirmation window.
    * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowTitle(): string {
        return this.scope.textLabels.ORGANIZATION_UNIT_DELETION_MODAL_TITLE;
    }

    /**
        * Returns the text of the modal delete confirmation window.
        * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowText(): string {
        return this.scope.textLabels.ORGANIZATION_UNIT_DELETION_MODAL_TEXT;
    }

    /**
    * Toggles the permission value for a user group on the scope.
    * Toggle means: set to the input value if the current value is smaller, else set it to the input value - 1.
    * @param userGroupId Id of the user group to toggle the permission value for.
    * @param newPermissionValue The new permission value.
    * @param item Item to set the permissions for.
    * @param setInsteadOfToggle Set to true to always set the new permission value, even if is the same as the actual value.
    * @param dontDiminishPermissions Set to true to only allow granting more rights in child organization units.
    */
    protected toggleUserGroupPermissionForItem(userGroupId: number,
            newPermissionValue: number,
            item: TreeEntity,
            shouldPropagate: boolean,
            userTriggered: boolean = false,
            setInsteadOfToggle: boolean = false,
            dontDiminishPermissions: boolean = false) {

        const oldValue = this.getUserGroupPermissionsForItem(userGroupId, item);

        // If diminishing permissions is not allowed only toggle when the new value is larger than the old one.
        if (!dontDiminishPermissions || newPermissionValue > oldValue) {
            super.toggleUserGroupPermissionForItem(userGroupId, newPermissionValue, item, shouldPropagate, userTriggered, setInsteadOfToggle);
        }
        const resultingValue = this.getUserGroupPermissionsForItem(userGroupId, item);

        // Did we just expand permissions? In that case, only allow expanding permissions in child organization units,
        // so no diminishing rights further down the tree.
        const permissionsExpanded = resultingValue >= oldValue;

        // Additionally, perform the same action for all children of this item.
            if (item.nodes && item.nodes.length > 0) {
                if (userTriggered) {
                    this.commonSvc.showYesNoDialog(this.scope.textLabels.ORTANIZATION_UNIT_AFFECT_CHILDREN,
                        this.scope.textLabels.ORGANIZATION_UNIT_AFFECT_CHILDREN_QUESTION,
                        () => { // Yes
                            for (let child of item.nodes) {
                                this.toggleUserGroupPermissionForItem(userGroupId,
                                    resultingValue,
                                    child,
                                    shouldPropagate,
                                    false,
                                    true,
                                    permissionsExpanded);
                                this.onEntityChanged(false, child);
                            }
                        },
                        null);
                } else {
                    for (let child of item.nodes) {
                        this.toggleUserGroupPermissionForItem(userGroupId,
                            resultingValue,
                            child,
                            shouldPropagate,
                            false,
                            true,
                            permissionsExpanded);
                        this.onEntityChanged(false, child);
                    }
                }
        }
    }

    /**
    * Event handler to be used when the fte value of an entity has changed.
    */
    protected onEntityFteChanged() {
        const unit = this.scope.selectedItem as OrganizationUnit;
        this.onEntityChanged(false, unit);

        const fte = this.numberService.toFloat((this.scope.selectedItem as any).fte);
        if (unit.nodes) {
            for (const node of unit.nodes) {
                this.bubbleDownFteValue(node as OrganizationUnit, fte);
            }
        }
    }

    /**
        * Sets and bubbles down the specified fte value to child organization units.
        * @param unit Unit to bubble down the fte value from.
        * @param value The fte value to set to this unit and its children.
        */
    protected bubbleDownFteValue(unit: OrganizationUnit, value: number) {
        unit.fte = value;
        this.onEntityChanged(false, unit);
        if (unit.nodes) {
            for (const subUnit of unit.nodes) {
                this.bubbleDownFteValue(subUnit as OrganizationUnit, value);
            }
        }
    }

    /**
    * Filter input on text to only accept numeric values.
    */
    filterTextValue($event, oldValue, allowDecimal, maxLength) {
        this.numberService.filterTextValue($event, oldValue, allowDecimal, maxLength);
    }

    /**
        * Preprocesses the organization unit prior to saving: set the correct decimal symbol in the fte value.
        * @param entity Organization unit to preprocess.
        */
    preprocessEntity(entity: TreeEntity): void {
        const unit = entity as any; // otherwise we would have to convert unit.fte to a string, even though it already is :(
        unit.fte = this.numberService.toFloat(unit.fte);
    }
}