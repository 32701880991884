import { Dictionary } from './../utils/dictionary';

import { ITreeListScope } from './ITreeListScope';
import { TreeEntity } from './TreeEntity';

/**
    * Reusable functions for handling entity trees.
    */
export class EntityTreeHelpers {

    /**
    * Flattens an entity tree as follows: each element is followed by the flattened trees of its children, then by its successors.
    * @param tree Entity tree to flatten.
    */
    static flattenEntityTree(tree: TreeEntity[]): TreeEntity[] {
        const result = [];

        for (let node of tree) {
            result.push(node);
            if (node.nodes) {
                const flattenedSubtree = this.flattenEntityTree(node.nodes);
                //console.log(flattenedSubtree);
                result.push.apply(result, flattenedSubtree);
            }
        }

        return result;
    }

    /**
    * Change the order of the last entity in the entityArray based on the receivedOrder property.
    * @param entityArray the array of entities to sort
    */
    static sortLastEntity(entityArray: TreeEntity[]) {
        let entity: TreeEntity;
        let index = entityArray.length - 1;
        // only sort if the receivedOrder has a value different (higher or lower) than 0
        while (index > 0 &&
            entityArray[index].receivedOrder !== 0 && entityArray[index - 1].receivedOrder !== 0 &&
            entityArray[index].receivedOrder < entityArray[index - 1].receivedOrder) {
            entity = entityArray[index - 1];
            entityArray[index - 1] = entityArray[index];
            entityArray[index] = entity;
            index--;
        }
    }

    /**
        * Function used to create a tree array from entities in a dictionary.
        * @param entitiesDict Dictionary to create the entity tree from.
        * @param myScope Scope that can be used, mainly for passing through to functions called here (i.e. setting entity default values).
        * @param setEntityDefaultValues Function that sets the default 
        */
    static entityDictionaryToTree(entitiesDict: Dictionary, myScope: ITreeListScope,
        setEntityDefaultValues: (entity: TreeEntity, myScope: ITreeListScope) => void,
        sortLastEntity: (entities: TreeEntity[]) => void): TreeEntity[] {
        const result: TreeEntity[] = [];
        entitiesDict.forEach((key, value) => {
            const entity: TreeEntity = value;
            let parentEntity: TreeEntity = null;
            let entityArray = result;
            // set default values for this entity
            if (setEntityDefaultValues != null) setEntityDefaultValues(entity, myScope);
            // test if entity is null
            if (entity == null) return; // this returns from the callback and thus will continue with the next item in the foreach loop.
            // if the entity has a parentId, try to find the parent entity object in the dictionary
            if (entity.parentId != null) {
                parentEntity = entitiesDict.value(entity.parentId);
                entity.treeLevel = parentEntity == null ? 0 : parentEntity.treeLevel + 1;
            } else {
                entity.treeLevel = 0;
            }
            // if the dictionary did not contain the parent, then the parent is undefined (null == undefined)
            if (parentEntity != null) {
                // add the entity to its parent nodes array
                if (parentEntity.nodes == null) parentEntity.nodes = [];
                entityArray = parentEntity.nodes;
            }
            // add to either the entities array or the nodes array of a parent
            entityArray.push(entity);
            // change the place of the entity in the array according to the receivedOrder property
            if (sortLastEntity != null) sortLastEntity(entityArray);
        });
        return result;
    }
}