import { StateService } from "@uirouter/angularjs";
import { IPageStartService } from "./../../shared/pageStartService";
import { IUserService } from "./../../shared/userService";

import { ITranslationService } from "./../i18n/translationService";

import { INotificationService } from "./../login/notificationService";

import { ITreeListScope } from "./../treeListController/ITreeListScope";
import { IConfigurationService } from "./../../shared/configurationService";

export class ReportsController {

    reports: Array<any> = [];
    isFullUser: boolean;
    reportsEnabled = false;

    private commonSvc: any;

    private readonly reportsEvent: string = "reportsEvent";
    private readonly dialogToken: string = "reports";

    private jwtToken = "";

    static $inject = [
        "$scope",
        "$state",
        "configurationService",
        "notificationService",
        "pageStartService",
        "translationService",
        "userService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private readonly $state: StateService,
        private readonly configurationService: IConfigurationService,
        private readonly notificationService: INotificationService,
        private readonly pageStartService: IPageStartService,
        private readonly translationService: ITranslationService,
        private readonly userService: IUserService
    ) {


        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);
        this.isFullUser = this.userService.isFullUser;

        this.configurationService.getAppConfiguration(() => {
            this.reportsEnabled = this.configurationService.appConfiguration.featureManagement.ssrsReports;

            if (!this.reportsEnabled) {
                return;
            }

            this.notificationService
                .subscribeToWebSocketEvent($scope,
                    this.reportsEvent, () => {
                        this.userService.refreshPage();
                    });

            this.notificationService.addStringToClientInfo(this.reportsEvent);

            this.commonSvc.loadData("api/Reports/Uris", this.reports, () => { }, null, true, false);

            this.$scope.$on("$destroy",
                () => {
                    this.notificationService.removeStringFromClientInfo(this.reportsEvent);
                });
        });
    }

    openReport(report: any): void {
        console.log("Redirecting to report:", report.uri);
        this.getJwtToken(() => { this.openReportInFrame(report); });
    }

    private getJwtToken(action?: () => void): void {
        this.commonSvc.loadData("api/Reports/Token", null,
            (success) => {
                this.jwtToken = success.data;
                if (action != null) action();
            }, null, true, false);
    }

    private openReportInFrame(report: any): void {
        let uri = report.uri;

        // replace, remove, or add the jwt token as a query component
        if (this.jwtToken != null && this.jwtToken !== "") {
            // replace the tag "token=?" with "token=value_of_jwtToken"
            if (uri.indexOf("token=?") >= 0) {
                uri = uri.replace("token=?", "token=" + this.jwtToken);
            } else {
                // add the additional or first query component for the jwtToken to the uri
                if (uri.indexOf("?") >= 0)
                    uri += "&token=" + this.jwtToken;
                else
                    uri += "?token=" + this.jwtToken;
            }
        }
        this.$state.transitionTo("report", { uri: uri });
    }
}