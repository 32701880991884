import * as Constants from './../utils/constants';

export var SSOLoginData = function () {
    return {
        ssoLoginSucces: false,
        ssoLoginAttempted: false
    }
};

export class SaveSSOTokenController {
    static $inject = [
        "$scope",
        "$stateParams",
        "$location", "$window",
        "$http",
        "authenticationService",
        "translationService",
        "SSOLoginData",
        "userService",
    ];

    constructor(
        $scope,
        $stateParams,
        $location,
        $window: ng.IWindowService,
        $http,
        authenticationService,
        translationService,
        SSOLoginData,
        userService
    ) {
        // Put text label object on scope.
        translationService.getTextLabels($scope);

        // use factory to share data with login scope
        $scope.SSOLoginData = SSOLoginData;
        $scope.SSOLoginData.ssoLoginAttempted = true;
        // Save token to storage 
        if ($stateParams.token === "UserNotFound") {
            $location.path("/login");
            $scope.SSOLoginData.ssoLoginSuccess = false;
        } else {
            $scope.SSOLoginData.ssoLoginSuccess = true;

            const currentUser = $window.localStorage.getItem(Constants.currentUserStorageKey) == undefined
                ? {}
                : JSON.parse($window.localStorage.getItem(Constants.currentUserStorageKey));

            currentUser.token = $stateParams.token;

            $window.localStorage.setItem(Constants.currentUserStorageKey, JSON.stringify(currentUser));

            $http.get("api/GetUserInfo")
                .then(function (userInfo) {
                    // set userInfo in browser & connect websocket
                    if (authenticationService.reconnect(userInfo.data)) {
                        if (userService.isFullUser) {
                            translationService.switchToUserLanguage();
                            $location.path("/portal");
                        } else {
                            $location.path("/reports");
                        }
                    } else {
                        $window.localStorage.removeItem(Constants.currentUserStorageKey);
                        $location.path("/login");
                        $scope.SSOLoginData.ssoLoginSuccess = false;
                    }
                }, function (reason) {
                    // obtaining user info failed, remove token & return to login page
                    console.log("Promise for GetUserInfo got rejected: " + JSON.stringify(reason));
                    $window.localStorage.removeItem(Constants.currentUserStorageKey);
                    $location.path("/login");
                    $scope.SSOLoginData.ssoLoginSuccess = false;
                });
        }
    }
}