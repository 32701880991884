export var LangSwitchCtrl = [
    "$scope", "translationService",
    function ($scope, translationService) {
        // Put text label object on scope.
        translationService.getTextLabels($scope);

        // Call to change the language to that with the specified language code.
        $scope.changeLanguage = function (language) {
            //console.log("Changed language to " + language + ".");
            translationService.retrieveTextLabels(language);
        }
    }
];