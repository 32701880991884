export var MonitoringPageComponentCtrl = [
    "$scope", "$http", "$controller", "$stateParams", "$state", "modalConfirmationWindowService", "translationService",
    function ($scope, $http, $controller, $stateParams, $state, modalConfirmationWindowService, translationService) {
        var dialogToken = "monitoring";
        var errorDialogToken = "monitoringErr";

        // Inherit from base.
        $controller("MonitoringPageCtrlBase", { $scope: $scope });

        $scope.componentName = $stateParams.componentId;

        $scope.getStatuses = function () {
            $http.get("api/Monitoring/StatusDigest/" + encodeURIComponent($scope.componentName))
                .then(function (response) {
                    $scope.data = response.data;
                    //console.log($scope.data);
                    $scope.dataPresent = true;
                    modalConfirmationWindowService.closeModalWindow(dialogToken);
                }, function (response) {
                    modalConfirmationWindowService.closeModalWindow(dialogToken);

                    var errorMessage = translationService.translateErrorMessage(response);
                    modalConfirmationWindowService
                        .showModalInfoDialog($scope.textLabels.ERROR_OCCURRED,
                            errorMessage,
                            $scope.textLabels.OK,
                            null,
                            0,
                            errorDialogToken);
                });
        }

        // Opens the messages for an instance.
        $scope.openInstanceMessages = function (instanceName) {
            $state.go("monitoring.component.instance", { instance: instanceName });
        }
    }
];