import { StateParams } from '@uirouter/angularjs';
import { INumberService } from './../../shared/numberService';
import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';
import { IConfigurationService } from './../../shared/configurationService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import { TimeSpan } from './../planboard/utils/timespan';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import { IModalConfirmationWindowService } from './../utils/modalConfirmationWindowService';
import * as Timezone from './../utils/timezone';

export class ResourceParttimeScheduleController {

    resourceName: string = "";
    allParttimeEntries: Array<any> = Object.create(null);
    allItemsVersion: number = 1;
    selectedEntryId: number = -1;
    selectedWeek: number = 1; // the selected week 1-4
    scheduleDays: Array<any> = []; // the current schedule for a week
    totalHours: string = "";
    isShowingEmptyWeek: boolean = true;
    isCreatingNewSchedule: boolean = false;
    newStartTime: string = "";
    newEndTime: string = "";
    newAbsenceInputOpen: boolean = false;
    newScheduleInputOpen: boolean = false;
    popupLeft: string = "0px";
    popupTop: string = "0px";
    popupWidth: string = "50%";
    schedule: any = null;
    isSaveChangePending: boolean = false;
    isRollOutPending: boolean = false;
    newCreateDate: Date = new Date();
    maxDayNr: number = 0;
    lastParttimeActivityDate: string = null;
    applyScheduleInputOpen: boolean = false;
    applyUntilDate: Date = new Date();
    applyStartDate: Date = new Date();
    untilDateIsValid: boolean = false;
    untilDateYearIsValid: boolean = true;
    beginDateYearIsValid: boolean = true;

    private commonSvc: any;
    private firstWeekDay: Date = new Date();
    private saveChangesTimer: angular.IPromise<any> = null;
    private saveChangesTimerRunning: boolean = false;
    private untilDateDifference: number;
    private todayDayNr: number = TimeSpan.getDayNr(TimeSpan.today.toDate());
    private newAbsenceDayNr: number = 0; // day nr in the week where to add the new absence (0-6)
    private scheduleChanged: boolean = false;
    private workingScheduleRollOutMaxYearsDifference = null;

    private readonly apiUrl: string = "api/Resources";
    private readonly dialogToken: string = "partSchedInfo";
    private readonly permission: string = "Resources";
    private readonly untilDateDiffUserSettingName: string = "parttimeSchedule.untilDateDiff";
    private readonly automaticSaveDelay: number = 5000;


    static $inject = [
        "$scope",
        "$filter",
        "$stateParams",
        "$timeout",
        "modalConfirmationWindowService",
        "numberService",
        "pageStartService",
        "translationService",
        "userService",
        "configurationService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $filter: ng.IFilterService,
        private $stateParams: StateParams,
        private $timeout: ng.ITimeoutService,
        private modalConfirmationWindowService: IModalConfirmationWindowService,
        public numberService: INumberService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService,
        private configurationService: IConfigurationService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.configurationService.getLimitSettings(() => {
            this.workingScheduleRollOutMaxYearsDifference = this.configurationService.limitSettings.workingScheduleRollOutMaxYearsDifference;

            this.untilDateYearIsValid = this.applyUntilDate ? (this.applyUntilDate.getFullYear() - new Date().getFullYear()) <= this.workingScheduleRollOutMaxYearsDifference : true;
            this.beginDateYearIsValid = this.newCreateDate ? (this.newCreateDate.getFullYear() - new Date().getFullYear()) <= this.workingScheduleRollOutMaxYearsDifference : true;
        });

        this.commonSvc = this.pageStartService.initialize(this.$scope, this.permission, this.dialogToken);

        this.untilDateDifference = userService.getDisplaySettingNumber(this.untilDateDiffUserSettingName, 365);
        this.applyUntilDate.setDate(this.applyUntilDate.getDate() + this.untilDateDifference);
        this.untilDateIsValid = TimeSpan.getDayNr(this.applyUntilDate) - this.todayDayNr >= 0;

        $scope.$on("$destroy", () => {
            this.stopSaveChangesTimer();
            this.saveChanges();
        });

        this.commonSvc.start(() => { this.loadData(); });
    }

    private loadData(): void {
        // schedule days initialization
        this.onSelectWeek(-1);

        // load Resource displayname
        var selectedItem = this.userService.getUserVariable("resources.selectedItem");
        if (selectedItem != undefined)
            this.resourceName = selectedItem.displayName;
        else
            this.commonSvc.post(this.apiUrl + "/WithId", { idList: [this.$stateParams.resourceId] },
                (success) => {
                    if (success.data && success.data.length > 0)
                        this.resourceName = success.data[0].displayName;
                },
                null, true);

        // load ParttimeEntries
        this.commonSvc.loadData(this.apiUrl + "/" + this.$stateParams.resourceId + "/ParttimeEntries", null,
            (success, loadInto) => {
                var entries = Object.create(null);
                for (var i = 0; i < loadInto.length; i++) {
                    loadInto[i].displayName = this.$filter("date")(loadInto[i].startDate, "longDate");
                    entries[loadInto[i].id] = loadInto[i];
                }
                this.allParttimeEntries = entries;
                this.selectedEntryId = loadInto.length > 0 ? loadInto[loadInto.length - 1].id : -1;
                this.onSelectedEntryIdChanged(this.selectedEntryId);
            }, null, true, true);

        this.getLastParttimeActivityDate();
    }

    private calculateTotalHours(): void {
        var total = 0;
        if (this.scheduleDays) {
            for (var i = 0; i < this.scheduleDays.length; i++) {
                if (this.scheduleDays[i].hours !== "") {
                    total += this.numberService.toFloat(this.scheduleDays[i].hours);
                }
            }
        }
        this.totalHours = this.numberService.toFloatStr(total);
    }

    onSelectWeek(weekNr: number): void {
        // create a copy of the selected week, used for displaying only one week of the parttimeschedule
        var emptyWeek = weekNr < 0 || this.selectedEntryId < 0 || this.schedule == null;
        if (emptyWeek && this.isShowingEmptyWeek && this.scheduleDays && this.scheduleDays.length > 0) return; // already showing empty week
        this.maxDayNr = 0;
        this.isShowingEmptyWeek = emptyWeek;
        this.getFirstWeekDay();
        var i = 0;
        var days = [];
        for (i = 0; i < 7; i++)
            days.push({ dayNr: i, displayName: this.$filter("date")(this.addDays(this.firstWeekDay, i), "EEEE"), hours: "", absences: [] });
        if (!emptyWeek) {
            var startDayNr = (weekNr - 1) * 7 + 1;
            var scheduleHours = this.schedule != null ? this.schedule.scheduleHours : null;
            var absenceBlocks = this.schedule != null ? this.schedule.absenceBlocks : null;
            // copy scheduleHours
            if (scheduleHours != null)
                for (i = 0; i < scheduleHours.length; i++) {
                    this.maxDayNr = Math.max(scheduleHours[i].dayNumber, this.maxDayNr);
                    if (scheduleHours[i].dayNumber >= startDayNr && scheduleHours[i].dayNumber < startDayNr + 7)
                        days[scheduleHours[i].dayNumber - startDayNr].hours = this.numberService.toFloatStr(scheduleHours[i].hours);
                }
            // copy absenceBlocks and assign a unique id (unique only on the day) to the absenceblock
            if (absenceBlocks != null)
                for (i = 0; i < absenceBlocks.length; i++) {
                    this.maxDayNr = Math.max(absenceBlocks[i].dayNumber, this.maxDayNr);
                    if (absenceBlocks[i].dayNumber >= startDayNr && absenceBlocks[i].dayNumber < startDayNr + 7)
                        absenceBlocks[i].absenceId = this.addAbsence(days, absenceBlocks[i].dayNumber - startDayNr, absenceBlocks[i].startMinutes, absenceBlocks[i].endMinutes);
                }
            this.selectedWeek = weekNr;
        }

        this.scheduleDays = days;
        this.calculateTotalHours();
    }

    onSelectedEntryIdChanged(itemId: number): void {
        // first save any changes to the current schedule
        this.stopSaveChangesTimer();
        this.saveChanges();
        // display empty week
        this.onSelectWeek(-1);
        // load one Parttime Entry
        if (itemId < 0) return;
        this.commonSvc.loadData(this.apiUrl + "/" + this.$stateParams.resourceId + "/ParttimeEntries/" + itemId, null,
            (success, loadInto) => {
                this.schedule = loadInto;
                this.onSelectWeek(this.selectedWeek);
                this.scheduleChanged = false;
            }, null, true, true);
    }

    onCreateScheduleClick(): void {
        var popupWidth = $("#popupWidth"); // TODO: find alternative to jquery
        this.popupTop = "" + popupWidth.offset().top + "px";
        this.popupLeft = "" + popupWidth.offset().left + "px";
        this.popupWidth = "" + popupWidth.outerWidth() + "px";
        this.newScheduleInputOpen = true;
    }

    onModalCreateScheduleClick(): void {
        // test if a schedule with this date already exists
        this.newCreateDate.setHours(0, 0, 0, 0);
        for (var key in this.allParttimeEntries) {
            var startDate = this.allParttimeEntries[key].startDate;
            if (startDate != null && startDate !== "" &&
                startDate.indexOf("T") >= -1 && startDate.substring(startDate.length - 1) !== "Z")
                startDate = startDate + "Z";
            var existingDate = new Date(startDate);
            existingDate.setHours(0, 0, 0, 0);
            if (existingDate.getTime() === this.newCreateDate.getTime()) {
                this.modalConfirmationWindowService
                    .showModalInfoDialog(this.$scope.textLabels.NEW_PARTTIME_SCHEDULE,
                        this.$scope.textLabels.PARTTIME_SCHEDULE_DATE_EXISTS,
                        this.$scope.textLabels.OK,
                        null, 0, this.dialogToken);
                return; // do nothing and keep the createNewSchedule div visible
            }
        }

        this.newScheduleInputOpen = false;
        // first save any changes to the current schedule
        this.stopSaveChangesTimer();
        this.saveChanges();

        this.isCreatingNewSchedule = true;
        this.selectedEntryId = -1;
        this.onSelectWeek(-1);

        var sched = {
            scheduleHours: [],
            absenceBlocks: [],
            resourceId: this.$stateParams.resourceId,
            startDate: Planboard.adjustToUtc(this.newCreateDate),
            id: -1
        }
        this.commonSvc.post(this.apiUrl + "/" + this.$stateParams.resourceId + "/ParttimeEntries", sched,
            (success) => {
                this.isCreatingNewSchedule = false;
                if (success.data) {
                    // add the new entry to drop drowdownTree and select it
                    success.data.displayName = this.$filter("date")(success.data.startDate, "longDate");
                    this.allParttimeEntries[success.data.id] = success.data;
                    this.allItemsVersion++; // to signal the dropdownTree that something has changed
                    this.selectedEntryId = success.data.id;
                    this.onSelectedEntryIdChanged(this.selectedEntryId);
                }
            },
            (error) => {
                this.isCreatingNewSchedule = false;
                this.commonSvc.httpErrorResponse(error, () => { });
            },
            true);
    }

    onDeleteScheduleClick(): void {
        this.modalConfirmationWindowService
            .showModalDialog(this.$scope.textLabels.PARTTIME_SCHEDULE_DELETION_MODAL_TITLE,
                this.$scope.textLabels.PARTTIME_SCHEDULE_DELETION_MODAL_TEXT,
                () => {
                    this.deleteSchedule(); 
                },
                null);
    }

    onNewAbsenceClick(dayNr: number): void {
        var popupWidth = $("#popupWidth"); // TODO: find alternative to jquery
        this.popupTop = "" + popupWidth.offset().top + "px";
        this.popupLeft = "" + popupWidth.offset().left + "px";
        this.popupWidth = "" + popupWidth.outerWidth() + "px";
        this.newAbsenceInputOpen = true;
        this.newAbsenceDayNr = dayNr;
    }

    onModalCreateNewAbsenceClick(): void {
        this.newAbsenceInputOpen = false;
        var start = this.numberService.timeStrToMinutes(this.newStartTime);
        var end = this.numberService.timeStrToMinutes(this.newEndTime);
        var warning = "";
        if (start > 1440 || end > 1440) warning = this.$scope.textLabels.TIME_AFTER_MIDNIGHT;
        else if (start >= end) warning = this.$scope.textLabels.BEGIN_AFTER_END_TIME;
        if (warning !== "") {
            this.modalConfirmationWindowService.showModalInfoDialog(this.$scope.textLabels.NEW_ABSENCE, warning, this.$scope.textLabels.OK, null, 0, this.dialogToken);
            return;
        }
        if (start >= end || this.newAbsenceDayNr < 0 || this.newAbsenceDayNr > 6) return; // invalid values
        var absenceId = this.addAbsence(this.scheduleDays, this.newAbsenceDayNr, start, end);

        // also add to the real schedule
        if (this.schedule == null || this.selectedWeek < 1) return;
        if (this.schedule.absenceBlocks == null) this.schedule.absenceBlocks = [];
        var dayNr = (this.selectedWeek - 1) * 7 + this.newAbsenceDayNr + 1;
        this.maxDayNr = Math.max(dayNr, this.maxDayNr);
        this.schedule.absenceBlocks.push({
            absenceId: absenceId,
            dayNumber: dayNr,
            startMinutes: start,
            endMinutes: end
        });

        // remember that there is a change and start the timer
        this.scheduleChanged = true;
        this.startSaveChangesTimer();
    }

    onRemoveAbsenceClick(dayNr: number, absenceId: number): void {
        // remember that there is a change and start the timer
        this.scheduleChanged = true;
        this.startSaveChangesTimer();
        // remove from the week copy
        var absences = this.scheduleDays[dayNr].absences;
        var i = absences.length;
        while (i > 0) {
            i--;
            if (absences[i].id === absenceId) absences.splice(i, 1);
        }

        // also remove from the real schedule
        if (this.schedule == null || this.schedule.absenceBlocks == null || this.selectedWeek < 1) return;
        var realDayNr = (this.selectedWeek - 1) * 7 + dayNr + 1;
        absences = this.schedule.absenceBlocks;
        i = absences.length;
        while (i > 0) {
            i--;
            if (absences[i].dayNumber === realDayNr && absences[i].absenceId === absenceId) absences.splice(i, 1);
        }
    }

    onApplyScheduleClick(): void {
        var popupWidth = $("#popupWidth"); // TODO: find alternative to jquery
        this.popupTop = "" + popupWidth.offset().top + "px";
        this.popupLeft = "" + popupWidth.offset().left + "px";
        this.popupWidth = "" + popupWidth.outerWidth() + "px";
        this.applyScheduleInputOpen = true;
    }

    onEndDateChanged(date: any): void {
        var newEndDate = new Date(date.getTime());
        newEndDate.setHours(0, 0, 0, 0);
        this.applyUntilDate = newEndDate;
        var dateDiff = TimeSpan.getDayNr(this.applyUntilDate) - this.todayDayNr;
        var yearDiff = newEndDate.getFullYear() - new Date().getFullYear();
        // Check if date is earlier than today
        //Check if end date year it's current date year or earlier than the limit setting
        this.untilDateIsValid = (dateDiff >= 0) && (yearDiff === 0 || yearDiff <= this.workingScheduleRollOutMaxYearsDifference);
        this.untilDateYearIsValid = yearDiff === 0 || yearDiff <= this.workingScheduleRollOutMaxYearsDifference;

        if (this.untilDateIsValid)
            this.untilDateDifference = dateDiff;
    }

    onStartDateChanged(date: any): void {
        var newStartDate = new Date(date.getTime());
        newStartDate.setHours(0, 0, 0, 0);
        var yearDiff = newStartDate.getFullYear() - new Date().getFullYear();

        this.beginDateYearIsValid = yearDiff === 0 || yearDiff <= this.workingScheduleRollOutMaxYearsDifference;
    }

    onHoursChanged(): void {
        if (this.schedule == null || this.scheduleDays == null || this.selectedWeek < 1) return;
        if (this.schedule.scheduleHours == null) this.schedule.scheduleHours = [];
        var scheduleHours = this.schedule.scheduleHours;

        for (var i = 0; i < this.scheduleDays.length; i++) {
            var dayNr = (this.selectedWeek - 1) * 7 + i + 1;
            var value = this.scheduleDays[i].hours === ""
                ? null
                : this.numberService.toFloat(this.scheduleDays[i].hours);

            var index = scheduleHours.length;
            while (index > 0) {
                index--;
                if (scheduleHours[index].dayNumber === dayNr) {
                    if (value == null) {
                        // remove the value
                        this.scheduleChanged = true;
                        scheduleHours.splice(index, 1);
                    } else if (value !== scheduleHours[index].hours) {
                        // change the value
                        this.scheduleChanged = true;
                        scheduleHours[index].hours = value;
                    }
                    value = null; // so it will not be added
                    continue;
                }
            }
            // add the value
            if (value != null) {
                this.scheduleChanged = true;
                this.maxDayNr = Math.max(dayNr, this.maxDayNr);
                scheduleHours.push({ dayNumber: dayNr, hours: value });
            }
        }

        if (this.scheduleChanged) this.startSaveChangesTimer();
        this.calculateTotalHours();
    }

    rollOutSchedule(): void {
        if (this.isSaveChangePending || this.isRollOutPending || !this.untilDateIsValid) return;

        // make sure the planboard will reload data
        Planboard.clearData();

        // post request to rollout part-time schedules
        this.isRollOutPending = true;
        this.userService.setDisplaySettingNumber(this.untilDateDiffUserSettingName, this.untilDateDifference);
        this.applyScheduleInputOpen = false;
        this.commonSvc.post(this.apiUrl + "/" + this.$stateParams.resourceId + "/ParttimeEntries/Rollout/" + Timezone.dateToStr(this.applyStartDate) + "/" + Timezone.dateToStr(this.applyUntilDate), null,
            (success) => {
                var text = this.$scope.textLabels.PARTTIME_SCHEDULE_ROLLOUT_OK;
                this.isRollOutPending = false;
                this.getLastParttimeActivityDate();
                this.commonSvc.showDialog(this.$scope.textLabels.PARTTIME_SCHEDULE_PAGE_TITLE, text, this.$scope.textLabels.OK, () => { });
            }, null, true);
    }

    private saveChanges(): void {
        if (!this.scheduleChanged || this.schedule == null) return;
        this.scheduleChanged = false;
        this.isSaveChangePending = true;
        this.commonSvc.post(this.apiUrl + "/" + this.schedule.resourceId + "/ParttimeEntries", this.schedule,
            (success) => {
                this.isSaveChangePending = false;
                this.userService.setLogoffWaitTime(this.dialogToken, 0);
            },
            null, false);
    }

    private deleteSchedule(): void {
        if (this.schedule == null) return;
        var scheduleId = this.schedule.id;

        // stop the saveChangesTimer
        this.scheduleChanged = false;
        this.stopSaveChangesTimer();
        this.userService.setLogoffWaitTime(this.dialogToken, 0);

        // display empty week
        this.selectedEntryId = -1;
        this.onSelectWeek(-1);

        // remove the schedule form the dropdown
        delete this.allParttimeEntries[scheduleId];
        this.allItemsVersion++; // to signal the dropdownTree that something has changed
        this.schedule = null;

        this.commonSvc.deleteData(this.apiUrl + "/" + this.$stateParams.resourceId + "/ParttimeEntries/" + scheduleId, null, null, false);

        // try to select the most recent other schedule
        var newSelectedId = -1;
        var lastDate = null;
        for (var key in this.allParttimeEntries)
            if (newSelectedId === -1 || lastDate < this.allParttimeEntries[key].startDate) {
                newSelectedId = parseInt(key);
                lastDate = this.allParttimeEntries[key].startDate;
            }
        if (newSelectedId !== -1) {
            this.selectedEntryId = newSelectedId;
            this.onSelectedEntryIdChanged(this.selectedEntryId);
        }
    }

    private addAbsence(days: Array<any>, dayNr: number, startMin: number, endMin: number): number {
        // get absences array for the current day
        var absences = days[dayNr].absences;
        // determine absenceId. An absence only has an id client side, so it can be removed easily from within the view (see removeAbsence).
        var absenceId = 1;
        for (var i = 0; i < absences.length; i++) absenceId = Math.max(absences[i].id + 1, absenceId);
        // add absence to the array
        absences.push({ id: absenceId, startMinutes: startMin, endMinutes: endMin });
        // sort the absence based on startMinutes
        var index = absences.length - 1;
        while (index > 0 && absences[index].startMinutes < absences[index - 1].startMinutes) {
            var swap = absences[index];
            absences[index] = absences[index - 1];
            absences[index - 1] = swap;
            index--;
        }
        return absenceId;
    }

    private getLastParttimeActivityDate(): void {
        this.commonSvc.loadData(this.apiUrl + "/" + this.$stateParams.resourceId + "/LastParttimeScheduleActivityDate", null,
            (success, loadInto) => {
                // success.data is a date in string format, it can also be null if there was never anything rolled out
                this.lastParttimeActivityDate = this.$filter("date")(success.data, "longDate");
            }, null, true, true);
    }

    private getFirstWeekDay(): Date {
        while (this.firstWeekDay.getDay() !== Planboard.firstDayOfWeek) this.firstWeekDay = this.addDays(this.firstWeekDay, -1);
        return this.firstWeekDay;
    }

    private addDays(date: any, days: number): Date {
        let result: Date = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    private startSaveChangesTimer(): void {
        this.stopSaveChangesTimer();
        if (this.scheduleChanged) {
            this.isSaveChangePending = true;
            this.userService.setLogoffWaitTime(this.dialogToken, this.automaticSaveDelay);
            this.saveChangesTimer = this.$timeout(() => { this.saveChanges(); }, this.automaticSaveDelay);
            this.saveChangesTimerRunning = true;
        }
    }

    private stopSaveChangesTimer(): void {
        if (this.saveChangesTimerRunning) this.$timeout.cancel(this.saveChangesTimer);
        this.saveChangesTimerRunning = false;
    }

}