export const userGroupPermissionsItem: angular.IComponentOptions = {
    template: require("./userGroupPermissionsItem.html"),
    controller: userGroupPermissionsItemController,
    bindings: {
        userGroup: "<",
        permissionList: "<",
        canEdit: "<",
        hasOwnerColumn: "<",
        highestPermission: "<",
        permissionChangeCallback: "&"
    }
}

userGroupPermissionsItemController.$inject = ["$timeout"];
function userGroupPermissionsItemController($timeout) {
    this.collapsed = true;
    this.tooltipTimer = null;
    this.tooltipVisible = false;
    this.tooltipDelay = 1000;
    this.hasChildNodes = this.userGroup && this.userGroup.nodes && this.userGroup.nodes.length > 0;

    this.onCollapseExpand = () => {
        this.collapsed = !this.collapsed;
    }
    this.onPermissionCheckboxClick = (permission: number): void => {
        if (!this.canEdit) return;
        if (permission <= this.highestPermission)
            this.highestPermission = permission - 1;
        else
            this.highestPermission = permission;

        this.permissionChangeCallback({userGroupId: this.userGroup.id, permission: permission, newPermission: this.highestPermission, shouldShowDialog: this.hasChildNodes});
    }
    this.onCallback = (userGroupId: number, permission: number, newPermission: number, shouldShowDialog: boolean) => {
        this.permissionChangeCallback({ userGroupId: userGroupId, permission: permission, newPermission: newPermission, shouldShowDialog: shouldShowDialog });
    }
    this.startTooltipTimer = () => {
        if (this.tooltipTimer) {
            $timeout.cancel(this.tooltipTimer);
            this.tooltipTimer = null;
        }
        this.tooltipTimer = $timeout(() => { this.tooltipVisible = true; }, this.tooltipDelay);
    }
    this.cancelTooltipTimer = () => {
        if (this.tooltipTimer) {
            $timeout.cancel(this.tooltipTimer);
            this.tooltipTimer = null;
        }
        this.tooltipVisible = false;
    }
    this.$onChanges = (changes) => {
        this.hasChildNodes = this.userGroup && this.userGroup.nodes && this.userGroup.nodes.length > 0;
    }
}