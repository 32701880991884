import { BasicControl } from './basicControl';
import { ScrollBarControl } from './scrollBarControl';
import { PositionList } from './../utils/positionlist';
import { Rect } from './../utils/rect';
import * as Globals from './../utils/globals';

/**
    * area/grid control
    */
export class AreaControl extends BasicControl {
    vBar: ScrollBarControl; // the vertical scrollbar control
    hBar: ScrollBarControl; // the horizontal scrollbar control
    vBarVisible = true; // if the vertical scrollbar should be visible
    hBarVisible = true; // if the horizontal scrollbar should be visible
    innerWidth: number; // the total inner with in pixels
    innerHeight: number; // the total inner height in pixels
    skipRedraw = false; // indicates if the draw function should do something
    cols: PositionList; // information about the columns
    rows: PositionList; // information about the rows
    gridColor = ""; // the color of the gridlines, "" = do not draw
    clearColor = ""; // the color of the background, only used when backbuffering is used
    protected linkedV: AreaControl[]; // the vertical linked areas for automatic scrolling
    protected linkedH: AreaControl[]; // the horizontal linked areas for automatic scrolling
    backbuffer: CanvasRenderingContext2D = null; // the backbuffer canvas element
    private useBackbuffer = false; // indicates if backbuffering should be used
    private lastPos: Rect; // last inner position and size of the backbuffer
    private lastMouse: [number, number, number, number]; // innerX, innerY, col, row
    private lastMouseDown: [number, number, number, number, number]; // innerX, innerY, screenX, screenY, button
    enableDragging = true; // indicates if the area can be scrolled by dragging with the mouse
    enableColumnResize = false; // indicates that columns can be resized
    enableRowResize = false; // indicates that rows can be resized
    useCells = true;
    private resizeColNr = -1; // column number currently being resized
    private resizeRowNr = -1; // row number currently being resized

    /**
        * drawCell callback event: override to do something interesting
        */
    drawCell = (t: AreaControl, ctx: CanvasRenderingContext2D, col: number, row: number, colWidth: number, rowHeight: number, contextX: number, contextY: number) => { }
    /**
        * mouseDownCell callback event: override to do something interesting
        */
    mouseDownCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => { }
    /**
        * mouseUpCell callback event: override to do something interesting
        */
    mouseUpCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => { }
    /**
        * mouseMoveCell callback event: override to do something interesting
        */
    mouseMoveCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => { }
    /**
        * dragDropCell calback event: override to do something interesting
        * The parameters in this callback event refer to the place where something is dropped.
        * There is no event for starting the dragDrop, this is manually handled in the mouseDown or mouseMove.
        */
    dragDropCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => { }
    /**
        * wheelScrollingStart calback event: override to do something interesting
        */
    wheelScrollingStart = (t: AreaControl) => { }
    /**
        * scrollEnd callback event: override to do something interesting
        */
    scrollEnd = (t: AreaControl, sb: ScrollBarControl, action: number) => { }

    /**
        * create a new area control
        * @param owner the owner control
        * @param left x position from owner
        * @param top y position from owner
        * @param width width of the control
        * @param height height of the control
        * @param innerWidth the total scrollable width
        * @param innerHeight the total scrollable height
        */
    constructor(owner: BasicControl, left: number, top: number, width: number, height: number, innerWidth: number, innerHeight: number) {
        super(owner, left, top, width, height);
        this.useMouseWheel = true;
        this.vBar = new ScrollBarControl(this, 0, 0, Globals.scrollBarSize, Globals.scrollBarSize * 2);
        this.hBar = new ScrollBarControl(this, 0, 0, Globals.scrollBarSize * 2, Globals.scrollBarSize);
        this.innerWidth = innerWidth;
        this.innerHeight = innerHeight;
        this.cols = new PositionList();
        this.rows = new PositionList();
        this.vBar.useClientArea = false;
        this.vBar.useMouseWheel = false; // use the one for this area instead
        this.vBar.maxValue = Math.max(0, this.innerHeight - height);
        this.vBar.largeChange = height;
        this.vBar.value = 0;
        this.hBar.useClientArea = false;
        this.hBar.useMouseWheel = false; // use the one for this area instead
        this.hBar.maxValue = Math.max(0, this.innerWidth - width);
        this.hBar.largeChange = width;
        this.hBar.value = 0;
        this.vBar.change = (t: ScrollBarControl, action: number) => {
            var myowner = t.owner as AreaControl;
            if (action === 1 || action === 2 || action === 4 || action === 5) // smallchange+- or largechange+-
                t.value = myowner.snapPositionToGrid(t.value, action, false, true);
            if (myowner.linkedV.length > 0) { // find total changing area
                const pos = myowner.screenPos.copy();
                let area: AreaControl;
                for (let i = 0; i < myowner.linkedV.length; i++) {
                    area = myowner.linkedV[i];
                    area.stopScrollTimers();
                    area.vBar.value = myowner.innerTop;
                    pos.combineWith(area.screenPos);
                }
                this.controller.drawControl(this.controller.desktop, 0, 0, pos.left, pos.top, pos.width, pos.height); // redraw partial without clearing first
            } else
                this.controller.redrawControl(myowner);
            this.scrollEnd(this, this.vBar, action);
        }
        this.hBar.change = (t: ScrollBarControl, action: number) => {
            var myowner = t.owner as AreaControl;
            if (action === 1 || action === 2 || action === 4 || action === 5) // smallchange+- or largechange+-
                t.value = myowner.snapPositionToGrid(t.value, action, true, false);
            if (myowner.linkedH.length > 0) { // find total changing area
                const pos = myowner.screenPos.copy();
                let area: AreaControl;
                for (let i = 0; i < myowner.linkedH.length; i++) {
                    area = myowner.linkedH[i];
                    area.stopScrollTimers();
                    area.hBar.value = myowner.innerLeft;
                    pos.combineWith(area.screenPos);
                }
                this.controller.drawControl(this.controller.desktop, 0, 0, pos.left, pos.top, pos.width, pos.height); // redraw partial without clearing first
            } else
                this.controller.redrawControl(myowner);
            this.scrollEnd(this, this.hBar, action);
        }
        this.linkedV = [];
        this.linkedH = [];
        this.lastMouse = [0, 0, 0, 0];
        this.lastMouseDown = [-1, -1, -1, -1, -1];
    }

    /**
        * redraw all content in this area
        */
    redrawAll() {
        this.lastPos.change(0, 0, 0, 0);
        this.redraw();
    }

    /**
        * redraw only specific cells in this area
        * @param startCol starting column number
        * @param startRow starting row number
        * @param endCol up to including column number
        * @param endRow up to including row number
        */
    redrawCells(startCol: number, startRow: number, endCol: number, endRow: number) {
        if (!this.useCells || this.cols.count <= 0 || this.rows.count <= 0) return;
        const x1 = Math.max(this.cols.getPos(startCol), this.innerLeft);
        const y1 = Math.max(this.rows.getPos(startRow), this.innerTop);
        const x2 = Math.min(this.cols.getPos(endCol + 1), this.innerLeft + this.position.width);
        const y2 = Math.min(this.rows.getPos(endRow + 1), this.innerTop + this.position.height);
        if (x2 > x1 && y2 > y1 && x2 > this.innerLeft && y2 > this.innerTop && x1 < this.innerLeft + this.position.width && y1 < this.innerTop + this.position.height) {
            if (this.useBackbuffer)
                this.drawPart(x1, y1, x2 - x1, y2 - y1);
            else
                this.lastPos.change(0, 0, 0, 0);
            this.redraw();
        }
    }

    /**
        * scroll to a specific position instantly and redraw this control
        * @param x position from the left to show
        * @param y position from the top to show
        */
    showArea(x: number, y: number) {
        if (x !== this.innerLeft || y !== this.innerTop) {
            this.stopScrollTimers();
            let i: number;
            if (x !== this.innerLeft)
                for (i = 0; i < this.linkedH.length; i++) {
                    this.linkedH[i].stopScrollTimers();
                    this.linkedH[i].innerLeft = x;
                }
            if (y !== this.innerTop)
                for (i = 0; i < this.linkedV.length; i++) {
                    this.linkedV[i].stopScrollTimers();
                    this.linkedV[i].innerTop = y;
                }
            this.hBar.value = x;
            this.vBar.value = y;
            this.redraw();
        }
    }

    /**
        * change the size of a column and redraw this control
        * @param col the column to change
        * @param newSize the new size
        * @param applyToLinkedAreas set to true to also change the column size of linked areas
        */
    resizeColumn(col: number, newSize: number, applyToLinkedAreas: boolean) {
        const oldSize = this.cols.getSize(col);
        if (oldSize === newSize) return;
        this.cols.setSize(col, newSize);
        if (this.cols.getPos(col) <= this.innerLeft + this.position.width)
            if (this.useBackbuffer && this.backbuffer.canvas.width > 0 && this.backbuffer.canvas.height > 0) {
                var w: number;
                var x: number;
                if (newSize < oldSize) { // got smaller: copy cols on the right a bit to the left
                    w = oldSize - newSize;
                    x = Math.max(this.cols.getPos(col) - this.innerLeft + oldSize, w);
                    if (x < this.position.width && this.position.width - w > 0)
                        this.backbuffer.drawImage(this.backbuffer.canvas, x, 0, this.position.width - w, this.position.height, x - w, 0, this.position.width - w, this.position.height);
                    if (this.lastPos.width > this.position.width - w) this.lastPos.width = this.position.width - w;
                } else { // got larger: copy cols on the right a bit to the right
                    w = newSize - oldSize;
                    x = Math.max(this.cols.getPos(col) - this.innerLeft + oldSize, 0);
                    if (x + w < this.position.width && this.position.width - w > 0)
                        this.backbuffer.drawImage(this.backbuffer.canvas, x, 0, this.position.width - w, this.position.height, x + w, 0, this.position.width - w, this.position.height);
                    if (this.lastPos.width > this.position.width) this.lastPos.width = this.position.width;
                    if (this.cols.getPos(col) + oldSize < this.innerLeft) // left position of the changing column is not visible
                        this.drawPart(this.innerLeft, this.innerTop, w, this.position.height); // redraw the left side
                }
                // redraw the changing column if it is in the visible part
                const x1 = Math.max(this.cols.getPos(col), this.innerLeft);
                const x2 = Math.max(this.cols.getPos(col) + newSize - 1, this.innerLeft);
                if (x2 > x1) this.drawPart(x1, this.innerTop, (x2 - x1) + 1, this.position.height);
                // backbuffer to screen
                this.redraw();
            } else
                this.redrawAll();

        // now the same for all linked horizontal areas
        if (applyToLinkedAreas)
            for (let i = 0; i < this.linkedH.length; i++)
                this.linkedH[i].resizeColumn(col, newSize, false);
    }

    /**
        * change the size of a row and redraw this control
        * @param row the row to change
        * @param newSize the new size
        * @param applyToLinkedAreas set to true to also change the column size of linked areas
        */
    resizeRow(row: number, newSize: number, applyToLinkedAreas: boolean) {
        const oldSize = this.rows.getSize(row);
        if (oldSize === newSize) return;
        this.rows.setSize(row, newSize);
        if (this.rows.getPos(row) <= this.innerTop + this.position.height)
            if (this.useBackbuffer && this.backbuffer.canvas.width > 0 && this.backbuffer.canvas.height > 0) {
                var h: number;
                var y: number;
                if (newSize < oldSize) { // got smaller: copy rows on the bottom a bit to the top
                    h = oldSize - newSize;
                    y = Math.max(this.rows.getPos(row) - this.innerTop + oldSize, h);
                    if (y < this.position.height && this.position.height - h > 0)
                        this.backbuffer.drawImage(this.backbuffer.canvas, 0, y, this.position.width, this.position.height - h, 0, y - h, this.position.width, this.position.height - h);
                    if (this.lastPos.height > this.position.height - h) this.lastPos.height = this.position.height - h;
                } else { // got larger: copy rows on the bottom a bit to the bottom
                    h = newSize - oldSize;
                    y = Math.max(this.rows.getPos(row) - this.innerTop + oldSize, 0);
                    if (y + h < this.position.height && this.position.height - h > 0)
                        this.backbuffer.drawImage(this.backbuffer.canvas, 0, y, this.position.width, this.position.height - h, 0, y + h, this.position.width, this.position.height - h);
                    if (this.lastPos.height > this.position.height) this.lastPos.height = this.position.height;
                    if (this.rows.getPos(row) + oldSize < this.innerTop) // top position of the changing row is not visible
                        this.drawPart(this.innerLeft, this.innerTop, this.position.width, h); // redraw the top side
                }
                // redraw the changing row if it is in the visible part
                const y1 = Math.max(this.rows.getPos(row), this.innerTop);
                const y2 = Math.max(this.rows.getPos(row) + newSize - 1, this.innerTop);
                if (y2 > y1) this.drawPart(this.innerLeft, y1, this.position.width, (y2 - y1) + 1);
                // backbuffer to screen
                this.redraw();
            } else
                this.redrawAll();

        // now the same for all linked horizontal areas
        if (applyToLinkedAreas)
            for (let i = 0; i < this.linkedV.length; i++)
                this.linkedV[i].resizeRow(row, newSize, false);
    }

    /**
        * mouseMove callback to replace the default from basicControl
        * @param x mouse x position on screen
        * @param y mouse y position on screen
        * @param button the mouse button that was pressed
        */
    mouseMove(x: number, y: number, button: number) {
        x -= this.screenPos.left;
        y -= this.screenPos.top;
        this.findLastMouse(x, y);
        if (this.resizeColNr < 0 && this.resizeRowNr < 0 && this.enableDragging && this.lastMouseDown[4] >= 0) {
            const targetX = Math.max(0, (this.lastMouseDown[0] - this.lastMouseDown[2]) + (this.lastMouseDown[2] - x));
            const targetY = Math.max(0, (this.lastMouseDown[1] - this.lastMouseDown[3]) + (this.lastMouseDown[3] - y));
            this.showArea(targetX, targetY);
            this.findLastMouse(x, y);
            this.controller.mouseCursor = "all-scroll";
        }
        const cellX = this.lastMouse[0] - this.cols.getPos(this.lastMouse[2]);
        const cellY = this.lastMouse[1] - this.rows.getPos(this.lastMouse[3]);
        if (this.enableColumnResize) {
            if (this.resizeColNr >= 0 || (cellX < 4 && this.lastMouse[2] > 0) || cellX > this.cols.getSize(this.lastMouse[2]) - 4)
                this.controller.mouseCursor = "col-resize";
            if (this.resizeColNr >= 0 && this.lastMouseDown[4] >= 0) {
                const colStartPos = this.cols.getPos(this.resizeColNr) - this.innerLeft;
                const newSizeX = Math.max(x - colStartPos, this.cols.getMinimum(this.resizeColNr));
                this.resizeColumn(this.resizeColNr, newSizeX, true);
            }
        }
        if (this.enableRowResize) {
            if (this.resizeRowNr >= 0 || (cellY < 4 && this.lastMouse[3] > 0) || cellY > this.rows.getSize(this.lastMouse[3]) - 4)
                this.controller.mouseCursor = "row-resize";
            if (this.resizeRowNr >= 0 && this.lastMouseDown[4] >= 0) {
                const rowStartPos = this.rows.getPos(this.resizeRowNr) - this.innerTop;
                const newSizeY = Math.max(y - rowStartPos, this.rows.getMinimum(this.resizeRowNr));
                this.resizeRow(this.resizeRowNr, newSizeY, true);
            }
        }
        this.mouseMoveCell(this, this.lastMouse[2], this.lastMouse[3], cellX, cellY, this.lastMouseDown[4], x, y);
    }

    /**
        * mouseDown callback to replace the default from basicControl
        * @param x mouse x position on screen
        * @param y mouse y position on screen
        * @param button the mouse button that was pressed
        */
    mouseDown(x: number, y: number, button: number) {
        x -= this.screenPos.left;
        y -= this.screenPos.top;
        this.findLastMouse(x, y);
        this.lastMouseDown[0] = this.lastMouse[0];
        this.lastMouseDown[1] = this.lastMouse[1];
        this.lastMouseDown[2] = x;
        this.lastMouseDown[3] = y;
        this.lastMouseDown[4] = button;
        const cellX = this.lastMouse[0] - this.cols.getPos(this.lastMouse[2]);
        const cellY = this.lastMouse[1] - this.rows.getPos(this.lastMouse[3]);
        this.mouseDownCell(this, this.lastMouse[2], this.lastMouse[3], cellX, cellY, button, x, y);
        if (this.enableColumnResize) {
            if (cellX < 4 && this.lastMouse[2] > 0) this.resizeColNr = this.lastMouse[2] - 1;
            else if (cellX > this.cols.getSize(this.lastMouse[2]) - 4) this.resizeColNr = this.lastMouse[2];
        }
        if (this.enableRowResize) {
            if (cellY < 4 && this.lastMouse[3] > 0) this.resizeRowNr = this.lastMouse[3] - 1;
            else if (cellY > this.rows.getSize(this.lastMouse[3]) - 4) this.resizeRowNr = this.lastMouse[3];
        }
    }

    /**
        * mouseUp callback to replace the default from basicControl
        * @param x mouse x position on screen
        * @param y mouse y position on screen
        * @param button the mouse button that was pressed
        */
    mouseUp(x: number, y: number, button: number) {
        x -= this.screenPos.left;
        y -= this.screenPos.top;
        this.mouseUpCell(this, this.lastMouse[2], this.lastMouse[3], this.lastMouse[0] - this.cols.getPos(this.lastMouse[2]), this.lastMouse[1] - this.rows.getPos(this.lastMouse[3]), this.lastMouseDown[4], x, y);
        this.lastMouseDown[4] = -1;
        this.resizeColNr = -1;
        this.resizeRowNr = -1;
    }

    /**
        * dragDrop callback to replace the default from basicControl
        * @param x mouse x position on screen
        * @param y mouse y position on screen
        */
    dragDrop(x: number, y: number) {
        x -= this.screenPos.left;
        y -= this.screenPos.top;
        this.findLastMouse(x, y);
        this.dragDropCell(this, this.lastMouse[2], this.lastMouse[3], this.lastMouse[0] - this.cols.getPos(this.lastMouse[2]), this.lastMouse[1] - this.rows.getPos(this.lastMouse[3]), this.lastMouseDown[4], x, y);
    }

    /**
        * find the last mouse positions and store them in lastMouse
        * @param x mouse x position on this control
        * @param y mouse y position on this control
        */
    private findLastMouse(x: number, y: number) {
        x += this.innerLeft;
        y += this.innerTop;
        if (x >= this.cols.maxPos)
            this.lastMouse[2] = -1;
        else if (x < this.cols.getPos(this.lastMouse[2]) || x >= this.cols.getPos(this.lastMouse[2] + 1))
            this.lastMouse[2] = this.cols.nrAtPos(x);
        if (y >= this.rows.maxPos)
            this.lastMouse[3] = -1;
        else if (y < this.rows.getPos(this.lastMouse[3]) || y >= this.rows.getPos(this.lastMouse[3] + 1))
            this.lastMouse[3] = this.rows.nrAtPos(y);
        this.lastMouse[0] = x;
        this.lastMouse[1] = y;
    }

    /**
        * round a value up and make sure it is divisible by a specific pow2
        * @param value the value to round up
        * @param pow2 the power to round up to
        */
    private roundUp(value: number, pow2: number): number {
        let r = (value >> pow2) << pow2;
        if (r < value) r += (1 << pow2);
        return r;
    }

    /**
        * destroy backbuffer canvas element created by this control
        */
    destroy() {
        if (this.backbuffer != null) {
            const previousElement = document.getElementById(this.name + "_buffer");
            if (previousElement && previousElement.parentElement)
                previousElement.parentElement.removeChild(previousElement);
            else
                this.backbuffer.canvas.remove();
            this.backbuffer.canvas.remove();
            this.backbuffer = null;
            this.setUseBackbuffer(false);
        }
    }

    /**
        * initialize the backbuffer
        * @param value set to true to use the backbuffer
        */
    setUseBackbuffer(value: boolean) {
        if (value) {
            if (this.backbuffer == null) {
                const canvas = document.createElement("canvas");
                this.backbuffer = canvas.getContext("2d");
                canvas.id = this.name + "_buffer";
                const hiddenElements = document.getElementById("hiddenElements");
                if (hiddenElements) hiddenElements.appendChild(canvas);
            }
            this.backbuffer.canvas.width = 0;
            this.backbuffer.canvas.height = 0;
            this.lastPos = new Rect(0, 0, 0, 0);
        }
        this.useBackbuffer = value;
        this.userDraw = value;
    }

    /**
        * reset the backbuffer
        */
    resetBuffer() {
        if (this.backbuffer == null) return;
        const previousElement = document.getElementById(this.name + "_buffer");
        if (previousElement && previousElement.parentElement)
            previousElement.parentElement.removeChild(previousElement);
        else
            this.backbuffer.canvas.remove();
        this.backbuffer = null;
        this.setUseBackbuffer(this.useBackbuffer);
    }

    /**
        * stop the scrolling timers of both the vertical and horizontal scrollbar
        */
    stopScrollTimers() {
        this.vBar.disableScrollTimer();
        this.hBar.disableScrollTimer();
    }

    /**
        * remove all links between this area and other areas (not recursive)
        */
    removeAreaLinks() {
        this.linkedH.length = 0;
        this.linkedV.length = 0;
    }

    /**
        * add a horizontal linked area (recursive: this area will also be added to the other controls linked array)
        * @param a the area to link to this one
        */
    linkHorizontal(a: AreaControl) {
        this.linkedH.push(a);
        a.linkedH.push(this);
        return this;
    }

    /**
        * add a vertical linked area (recursive: this area will also be added to the other controls linked array)
        * @param a the area to link to this one
        */
    linkVertical(a: AreaControl) {
        this.linkedV.push(a);
        a.linkedV.push(this);
        return this;
    }

    /**
        * get or set the inner left position (the horizontal scrollbars value)
        */
    get innerLeft(): number {
        return this.hBar.value;
    }
    set innerLeft(value: number) {
        this.hBar.value = value;
        this.redraw();
    }

    /**
        * get or set the inner top position (the vertical scrollbars value)
        */
    get innerTop(): number {
        return this.vBar.value;
    }
    set innerTop(value: number) {
        this.vBar.value = value;
        this.redraw();
    }

    /**
        * scroll to a specific position (slower than showArea)
        * @param x the innerLeft position to scroll to
        * @param y the innerTop position to scroll to
        */
    scrollToTarget(x: number, y: number) {
        this.hBar.scrollToTarget(x);
        this.vBar.scrollToTarget(y);
    }

    /**
        * snap a specific position so it aligns with a column or row, returns the new position
        * @param target the position
        * @param action the mouse action (2 or 5 = smalchange or largechange +-)
        * @param horizontal align with columns
        * @param vertical align with rows
        */
    snapPositionToGrid(target: number, action: number, horizontal: boolean, vertical: boolean): number {
        if (vertical && this.rows.count > 0) {
            let row = this.rows.nrAtPos(target);
            if (action === 2 || action === 5) { // smallchange+ or largechange+
                if (row === this.rows.nrAtPos(this.vBar.value)) row++;
            }
            target = this.rows.getPos(row);
        }
        else if (horizontal && this.cols.count > 0) {
            let col = this.cols.nrAtPos(target);
            if (action === 2 || action === 5) { // smallchange+ or largechange+
                if (col === this.cols.nrAtPos(this.hBar.value)) col++;
            }
            target = this.cols.getPos(col);
        }
        return target;
    }

    /**
        * mouseWheel callback to replace the default from basicControl
        * @param x mouse x position on screen
        * @param y mouse y position on screen
        * @param delta value between -1 (up) and 1 (down)
        */
    mouseWheel(x: number, y: number, delta: number) {
        if (this.resizeColNr >= 0 || this.resizeRowNr >= 0) return;
        x -= this.screenPos.left;
        y -= this.screenPos.top;
        const xDist = Math.min(x, this.position.width - x);
        let yDist = Math.min(y, this.position.height - y) * 2; // horizontal scrollbar has lower priority
        // test if we are on the horizontal scrollbar, if not then give it even less priority
        if (this.hBar.maxValue !== 0 && (y < this.hBar.position.top || y > this.hBar.position.bottom)) yDist += xDist;
        let target: number;
        if ((xDist < yDist && this.vBar.maxValue !== 0) || this.hBar.maxValue === 0) {
            target = this.vBar.value + delta * this.vBar.largeChange * 0.5;
            target = this.snapPositionToGrid(target, delta < 0 ? 1 : 2, false, true);
            this.wheelScrollingStart(this);
            this.vBar.scrollToTarget(target);
        }
        else if (this.hBar.maxValue !== 0) {
            target = this.hBar.value + delta * this.hBar.largeChange * 0.5;
            target = this.snapPositionToGrid(target, delta < 0 ? 1 : 2, true, false);
            this.wheelScrollingStart(this);
            this.hBar.scrollToTarget(target);
        }
    }

    /**
        * draw a specific part of the area, only usable if rows and columns are used
        * @param leftPos the innerLeft position
        * @param topPos the innerTop position
        * @param width the width to draw
        * @param height the height to draw
        */
    private drawPart(leftPos: number, topPos: number, width: number, height: number) {
        if (!this.useCells) return;
        var toprow = this.rows.nrAtPos(topPos);
        var leftcol = this.cols.nrAtPos(leftPos);
        var topy = this.rows.getPos(toprow) - this.innerTop;
        var leftx = this.cols.getPos(leftcol) - this.innerLeft;
        var y = topy; var x = leftx; var row = toprow; var col = leftcol;
        var colWidth = 0; var rowHeight = 0;
        var clipLeft = leftPos - this.innerLeft;
        var clipTop = topPos - this.innerTop;
        var maxX = 0; var maxY = 0;
        var cellClipRight = 0, cellClipBottom = 0, cellClipLeft = 0, cellClipTop = 0;

        this.backbuffer.save();
        this.backbuffer.beginPath();
        this.backbuffer.rect(clipLeft, clipTop, width, height);
        this.backbuffer.closePath();
        this.backbuffer.clip();

        // clear
        if (this.clearColor !== "") {
            this.backbuffer.fillStyle = this.clearColor;
            this.backbuffer.fillRect(clipLeft, clipTop, width, height);
        }

        // fire DrawCell events
        while (y < clipTop + height && row < this.rows.count) {
            rowHeight = this.rows.getSize(row);
            x = leftx; col = leftcol;
            while (x < clipLeft + width && col < this.cols.count) {
                colWidth = this.cols.getSize(col);
                cellClipRight = Math.min(clipLeft + width, x + colWidth);
                cellClipBottom = Math.min(clipTop + height, y + rowHeight);
                cellClipLeft = Math.max(x, clipLeft);
                cellClipTop = Math.max(y, clipTop);
                if (cellClipRight > cellClipLeft && cellClipBottom > cellClipTop) {
                    this.backbuffer.save();
                    this.backbuffer.beginPath();
                    this.backbuffer.rect(cellClipLeft, cellClipTop, cellClipRight - cellClipLeft, cellClipBottom - cellClipTop);
                    this.backbuffer.closePath();
                    this.backbuffer.clip();
                    this.drawCell(this, this.backbuffer, col, row, colWidth, rowHeight, x, y);
                    this.backbuffer.restore();
                }
                x += colWidth; col++;
            }
            y += rowHeight; row++;
        }
        maxY = y; maxX = x;

        // draw rasterlines
        if (this.gridColor !== "") {
            this.backbuffer.fillStyle = this.gridColor;
            y = topy; row = toprow;
            while (y < clipTop + height && row < this.rows.count) {
                y += this.rows.getSize(row); row++;
                this.backbuffer.fillRect(0, y - 1, maxX, 1);
            }
            x = leftx; col = leftcol;
            while (x < clipLeft + width && col < this.cols.count) {
                x += this.cols.getSize(col); col++;
                this.backbuffer.fillRect(x - 1, 0, 1, maxY);
            }
        }

        // clear unused areas
        if (this.clearColor === "") {
            this.backbuffer.fillStyle = this.backcolor;
            if (maxX < clipLeft + width) this.backbuffer.fillRect(maxX, clipTop, (clipLeft + width) - maxX, height);
            if (maxY < clipTop + height) this.backbuffer.fillRect(clipLeft, maxY, width, (clipTop + height) - maxY);
        }

        this.backbuffer.restore();
    }

    /**
        * the draw callback
        * @param left the left position on the screen
        * @param top the top position on the screen
        */
    draw(left: number, top: number) {
        super.draw(left, top);
        if (this.skipRedraw) return;
        const context = this.controller.ctx;
        this.skipRedraw = true;
        var visibleHeight = this.position.height - (this.hBarVisible ? this.hBar.position.height : 0);
        var visibleWidth = this.position.width - (this.vBarVisible ? this.vBar.position.width : 0);

        // vertical scrollbar
        if (this.useCells) this.innerHeight = this.rows.maxPos;
        this.vBar.visible = this.vBarVisible;
        this.vBar.position.left = this.position.width - this.vBar.position.width;
        this.vBar.position.height = visibleHeight;
        this.vBar.largeChange = visibleHeight;
        this.vBar.maxValue = Math.max(0, this.innerHeight - visibleHeight);
        this.vBar.value = Math.min(this.vBar.value, this.vBar.maxValue);
        // horizontal scrollbar
        if (this.useCells) this.innerWidth = this.cols.maxPos;
        this.hBar.visible = this.hBarVisible;
        this.hBar.position.top = this.position.height - this.hBar.position.height;
        this.hBar.position.width = visibleWidth;
        this.hBar.largeChange = visibleWidth;
        this.hBar.maxValue = Math.max(0, this.innerWidth - visibleWidth);
        this.hBar.value = Math.min(this.hBar.value, this.hBar.maxValue);

        this.clientArea.change(-this.innerLeft, -this.innerTop, visibleWidth, visibleHeight);
        this.clientAreaSet = true;

        if (this.useBackbuffer) {
            if (this.position.width > this.backbuffer.canvas.width || this.position.height > this.backbuffer.canvas.height) {
                this.backbuffer.canvas.width = this.roundUp(Math.max(this.position.width, this.backbuffer.canvas.width), 4);
                this.backbuffer.canvas.height = this.roundUp(Math.max(this.position.height, this.backbuffer.canvas.height), 4);
                Globals.initContext(this.backbuffer);
                this.lastPos.change(0, 0, 0, 0);
            }
            var destRect = new Rect(0, 0, 0, 0);
            destRect.left = Math.max(this.controller.currentClipt.left, this.screenPos.left);
            destRect.top = Math.max(this.controller.currentClipt.top, this.screenPos.top);
            destRect.right = Math.min(this.controller.currentClipt.right, this.screenPos.right);
            destRect.bottom = Math.min(this.controller.currentClipt.bottom, this.screenPos.bottom);
            if (destRect.width <= 0 || destRect.height <= 0) return;
            var destInnerLeft = this.innerLeft + (destRect.left - this.screenPos.left);
            var destInnerTop = this.innerTop + (destRect.top - this.screenPos.top);

            if (destInnerLeft >= this.lastPos.right || destInnerTop >= this.lastPos.bottom ||
                destInnerLeft + destRect.width <= this.lastPos.left ||
                destInnerTop + destRect.height <= this.lastPos.top) {
                // desired rect is completely outside of the backbuffer, need to draw everything
                this.drawPart(this.innerLeft, this.innerTop, this.screenPos.width, this.screenPos.height);
                this.lastPos.change(this.innerLeft, this.innerTop, this.screenPos.width, this.screenPos.height);
            }
            else if (destInnerLeft >= this.lastPos.left && destInnerTop >= this.lastPos.top &&
                destInnerLeft + destRect.width - 1 <= this.lastPos.right &&
                destInnerTop + destRect.height - 1 <= this.lastPos.bottom) {
                // desired rect is completely inside the backbuffer, nothing to do
            }
            else {
                // partial redraw
                if (this.innerLeft !== this.lastPos.left || this.innerTop !== this.lastPos.top) {
                    var destX = 0; var srcX = 0; var destY = 0; var srcY = 0;
                    if (this.innerLeft < this.lastPos.left)
                        destX = this.lastPos.left - this.innerLeft;
                    else
                        srcX = this.innerLeft - this.lastPos.left;
                    if (this.innerTop < this.lastPos.top)
                        destY = this.lastPos.top - this.innerTop;
                    else
                        srcY = this.innerTop - this.lastPos.top;
                    var copyWidth = this.lastPos.width - Math.max(destX, srcX);
                    var copyHeight = this.lastPos.height - Math.max(destY, srcY);
                    if (copyWidth > 0 && copyHeight > 0)
                        this.backbuffer.drawImage(this.backbuffer.canvas, srcX, srcY, copyWidth, copyHeight, destX, destY, copyWidth, copyHeight);
                    if (destX > 0) // new part on left side
                        this.drawPart(this.innerLeft, this.innerTop + destY, destX, copyHeight);
                    if (destY > 0) // new part on top side
                        this.drawPart(this.innerLeft, this.innerTop, destX + copyWidth, destY);
                    this.lastPos.change(this.innerLeft, this.innerTop, destX + copyWidth, destY + copyHeight);
                }
                if (this.lastPos.width < this.screenPos.width) // new part on right side
                    this.drawPart(this.innerLeft + this.lastPos.width, this.innerTop, this.screenPos.width - this.lastPos.width, this.screenPos.height);
                if (this.lastPos.height < this.screenPos.height) // new part on bottom side
                    this.drawPart(this.innerLeft, this.innerTop + this.lastPos.height, this.screenPos.width, this.screenPos.height - this.lastPos.height);
                this.lastPos.change(this.innerLeft, this.innerTop, this.screenPos.width, this.screenPos.height);
            }
            // copy desired part from backbuffer
            context.drawImage(this.backbuffer.canvas, destInnerLeft - this.lastPos.left, destInnerTop - this.lastPos.top, destRect.width, destRect.height, destRect.left, destRect.top, destRect.width, destRect.height);
        }
        else if (this.cols.count > 0 && this.rows.count > 0) {
            visibleHeight = this.position.height - (this.hBarVisible ? this.hBar.position.height : 0);
            visibleWidth = this.position.width - (this.vBarVisible ? this.vBar.position.width : 0);
            var toprow = this.rows.nrAtPos(this.innerTop);
            var leftcol = this.cols.nrAtPos(this.innerLeft);
            var topy = this.rows.getPos(toprow) - this.innerTop;
            var leftx = this.cols.getPos(leftcol) - this.innerLeft;
            var y = topy; var x = leftx; var row = toprow; var col = leftcol;
            var usedx = Math.min(this.position.width, this.cols.getPos(this.cols.count) - this.innerLeft);
            var usedy = Math.min(this.position.height, this.rows.getPos(this.rows.count) - this.innerTop);
            var colWidth = 0; var rowHeight = 0;

            // fire DrawCell events
            while (y < visibleHeight && row < this.rows.count) {
                rowHeight = this.rows.getSize(row);
                x = leftx; col = leftcol;
                if (top + y <= this.controller.currentClipt.bottom && top + y + rowHeight > this.controller.currentClipt.top)
                    while (x < visibleWidth && col < this.cols.count) {
                        colWidth = this.cols.getSize(col);
                        if (left + x <= this.controller.currentClipt.right && left + x + colWidth > this.controller.currentClipt.left)
                            this.drawCell(this, context, col, row, colWidth, rowHeight, left + x, top + y);
                        x += colWidth; col++;
                    }
                y += rowHeight; row++;
            }

            if (this.gridColor !== "") { // draw rasterlines
                context.fillStyle = this.gridColor;
                y = topy; row = toprow;
                while (y < visibleHeight && row < this.rows.count) {
                    y += this.rows.getSize(row); row++;
                    if (top + y - 1 <= this.controller.currentClipt.bottom && top + y - 1 >= this.controller.currentClipt.top)
                        context.fillRect(left, top + y - 1, usedx, 1);
                }
                x = leftx; col = leftcol;
                while (x < visibleWidth && col < this.cols.count) {
                    x += this.cols.getSize(col); col++;
                    if (left + x - 1 <= this.controller.currentClipt.right && left + x - 1 >= this.controller.currentClipt.left)
                        context.fillRect(left + x - 1, top, 1, usedy);
                }
            }
        }

        // right bottom corner
        if (this.vBarVisible && this.hBarVisible) {
            context.fillStyle = Globals.scrollBarColor1;
            context.fillRect(left + this.vBar.position.left, top + this.hBar.position.top, this.vBar.position.width, this.hBar.position.height);
        }
        this.skipRedraw = false;
    }

}