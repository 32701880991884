import { INumberService } from './../../shared/numberService';
import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import { ActivityType } from './../planboard/entities/activitytype';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import * as Constants from './../utils/constants';
import { Dictionary } from './../utils/dictionary';
import * as Timezone from './../utils/timezone';

export class YearPlanningController {

    userOrganizationUnits = new Dictionary();
    filterOrganizationUnits = new Dictionary();
    userRootActivityTypes = new Dictionary();
    filteredRootActivityTypesBySelectedOrgUnit = new Dictionary();
    filterYearNumbers = new Dictionary();
    yearplanOrganizationUnitId: number = -1;
    filterOrganizationUnitId: number = -1;
    filterOrganizationUnitIds = new Dictionary();
    filterAllActivityTypes: boolean = true;
    filterActivityTypeIds: Array<number> = [];
    filterYear: number;
    reductionPeriods: Array<any> = [];
    requiredVisible: boolean = true;
    priorityVisible: boolean = true;
    perWeekVisible: boolean = false;
    weekNumbers: Array<string> = [];
    dayNames: Array<string> = [];
    dayNameWidth: string = "6em";
    totalDayNameWidth: string = "42em";
    editActivityRows: Array<any> = [];
    editActivityRowsPage: number = 1;
    editActivityRowsPageNrs: Array<number> = [];
    activityTypesPerPage: string;
    rollOutStart: Date;
    rollOutEnd: Date;
    rollOutInProgress: boolean = false;

    private commonSvc: any;
    private date: Date = new Date();
    private today: Date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    private tomorrow: Date;
    private rollOutRetry: number = 0;
    private lastRowIndex: number = -1;
    private saveSettingsTimer: angular.IPromise<any> = null;
    private changeFilterTimer: angular.IPromise<any> = null;
    private saveDataTimer: angular.IPromise<any> = null;
    private sceCache: any = Object.create(null);
    private userActivityTypes = new Dictionary();
    private nrWeeks: number;
    private allWeekNumbers: Array<string> = [];
    private shortDayNames: Array<string> = [];
    private longDayNames: Array<string> = [];
    private editRow: number = -1; // row number to show input text elements on (values range from 0 to editActivityRows.length-1)
    private editWeekNr: number = -1; // week number to show input text elements on (values range from 1 to 53)
    private editDay: number = -1; // day number to show input text elements on (values range from 0 to 6)
    private changedPerWeekRowNrs: Array<number> = [];
    private changedRequiredRowNrs: Array<number> = [];
    private editActivityRowsAll: Array<any> = [];
    private editActivityRowsPages: number = 1;
    private percentagePerWeek: Array<number> = [];
    private savePerWeekPending: boolean = false;
    private savePriorityPending: boolean = false;

    private readonly dialogToken: string = "yearPlanInfo";
    private readonly permission: string = "YearPlanning";
    private readonly apiUrl: string = "api/YearPlanning";
    private readonly organizationUnitApiUrl: string = "api/OrganizationUnits";
    private readonly activityTypeApiUrl: string = "api/ActivityTypes";
    private readonly automaticSaveDelay: number = 5000;

    static $inject = [
        "$scope",
        "$filter",
        "$sce",
        "$timeout",
        "numberService",
        "pageStartService",
        "translationService",
        "userService"
    ];

    constructor(
        public $scope: ITreeListScope,
        private $filter: ng.IFilterService,
        private $sce: ng.ISCEService,
        private $timeout: ng.ITimeoutService,
        private numberService: INumberService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, this.permission, this.dialogToken);

        this.tomorrow = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()); this.tomorrow.setDate(this.tomorrow.getDate() + 1);
        this.filterYear = this.date.getFullYear();
        this.rollOutStart = new Date(this.today.getTime());
        this.rollOutEnd = new Date(this.tomorrow.getTime());
        this.nrWeeks = Timezone.getNumberOfWeeks(Number(this.filterYear));
        this.activityTypesPerPage = this.userService.getDisplaySetting("yearplan.activityTypesPerPage", "10");

        this.readSettingColumnsVisible();
        this.initializeVariables();

        this.$scope.$on("$destroy", () => {
            this.saveUserSettings(0);
            this.saveDataWithTimeOut(0, false, false);
            if (this.changeFilterTimer) { this.$timeout.cancel(this.changeFilterTimer); this.changeFilterTimer = null; }
        });

        this.commonSvc.start(() => { this.loadData(); });
    }
    private loadData(): void {
        this.commonSvc.loadData(this.organizationUnitApiUrl, this.userOrganizationUnits,
            (success) => {
                // Register all organization units as children to their parents.
                this.userOrganizationUnits.forEach((key, value) => {
                    value.selectable = value.maxPermissionForCurrentUser >= Constants.permWrite;
                    this.registerChildToParent(value);
                });

                // Read user setting to see if user previously has selected any organization unit.
                this.yearplanOrganizationUnitId = this.userService.getDisplaySettingNumber("yearplan.organizationUnitId", -1);

                // Read user setting to see if user previously has selected any organization unit for a filter.
                this.filterOrganizationUnitId = this.userService.getDisplaySettingNumber("yearplan.filterUnitId", -1);

                if (this.filterOrganizationUnitId >= 0 && this.yearplanOrganizationUnitId >= 0) {
                    this.rebuildFilterOrganizationUnits(this.yearplanOrganizationUnitId);
                    this.filterOrganizationUnitIds.clear();
                    this.addFilterOrganizationUnitId(this.filterOrganizationUnitId);
                }

                if (this.yearplanOrganizationUnitId >= 0) {
                    this.reloadForYearAndOrganizationUnit(this.filterYear, this.yearplanOrganizationUnitId);
                }
            }, null, true, true);

        this.commonSvc.loadData(this.activityTypeApiUrl, this.userActivityTypes,
            (success) => {
                // Only top level activity types that are not absence are selectable.
                var sortedActivityTypes = [];
                this.userRootActivityTypes.clear();
                this.userActivityTypes.forEach((key, value) => {
                    if (this.isTopLevelActivityType(value) &&
                        !this.hasActivityTypeExpired(value) &&
                        value.maxPermissionForCurrentUser >= Constants.permWrite) {
                        value.originalDisplayName = value.displayName;
                        value.displayName = "[".concat(value.shortName, "] ", value.displayName);
                        this.userRootActivityTypes.add(key, value);
                        sortedActivityTypes.push(value);
                    }
                });
                sortedActivityTypes = this.$filter('orderBy')(sortedActivityTypes, (item) => { return item.originalDisplayName });
                for (var i = 0; i < sortedActivityTypes.length; i++)
                    sortedActivityTypes[i].order = i + 1;
                this.rebuildEditActivityRows();
            }, null, true, true);
    }

    switchPage(page: number): void {
        this.editActivityRowsPage = Math.max(Math.min(page, this.editActivityRowsPages), 1);
        this.rebuildEditActivityVisibleRows();
    }

    activityTypesPerPageChange(): void {
        this.calculateMaximumPages();
        this.rebuildEditActivityVisibleRows();
    }

    getWeekNrText(): string {
        return this.$scope.textLabels.WEEK.toLowerCase();
    }

    onRollOutClick(): void {
        this.rollOutInProgress = true;

        // first save current pending data
        this.rollOutRetry = 0;
        this.savePriorityPending = false;
        this.savePerWeekPending = false;
        this.saveDataWithTimeOut(0, true, true);

        this.$timeout(() => { this.rollOutIfNoSavePending(); }, 0);
    }

    getPlannedCellText(row: number): string {
        var value = this.editActivityRowsAll[row].planned;
        var text = value == null || isNaN(value) ? null : value.toString();
        if (text == null || text === "" || text === "0") text = "<font color='LightGray'>0</font>";
        return this.getTrustedHtml(text);
    }

    getRequiredCellText(row: number): string {
        var value = this.editActivityRowsAll[row].required;
        var text = value == null || isNaN(value) ? null : value.toString();
        if (text == null || text === "") text = "0";
        return this.getTrustedHtml(text);
    }

    onTextRequiredBlur(event: any): void {
        this.applyChanges();
    }

    onTextRequiredInput(event: any): void {
        var value = Number(event.target.value);
        this.editActivityRowsAll[this.editRow].required = isNaN(value) ? null : value;
        if (this.changedRequiredRowNrs.indexOf(this.editRow) < 0) this.changedRequiredRowNrs.push(this.editRow);
    }

    onTextRequiredKeyDown(event: any): void {
        if (event.which === 38 || event.keyCode === 38 || event.key.toLowerCase() === "up") {
            this.setCellFocus(this.editRow - 1); event.preventDefault();
        }
        else if (event.which === 40 || event.keyCode === 40 || event.key.toLowerCase() === "down") {
            this.setCellFocus(this.editRow + 1); event.preventDefault();
        }
        else if (event.which === 9 || event.keyCode === 9 || event.which === 13 || event.keyCode === 13 ||
            event.key.toLowerCase() === "tab" || event.key.toLowerCase() === "enter") {
            this.setCellFocus(this.editRow); event.preventDefault();
        }
        else this.numberService.filterTextValue(event, event.target.value, false, 5);
    }

    isRequiredCellInputVisible(row: number): boolean {
        if (row === this.editRow) {
            return true;
        }
        return false;
    }

    getRequiredCellStyle(row: number): object {
        var value = this.editActivityRowsAll[row].required;
        var text = value == null || isNaN(value) ? null : value.toString();
        if (text == null || text === "" || text === "0") return { 'color': 'LightGray' };
        return { 'color': 'black' };
    }

    getDayPriorityCellText(row: number, day: number): string {
        var value = this.editActivityRowsAll[row].dayPriorities[day];
        var text = value == null || isNaN(value) ? null : value.toString();
        if (text == null || text === "") text = "0";
        return this.getTrustedHtml(text);
    }

    onTextDayPriorityInput(event: any): void {
        var value = Number(event.target.value);
        this.editActivityRowsAll[this.editRow].dayPriorities[this.editDay] = isNaN(value) ? null : value;
        this.editActivityRowsAll[this.editRow].dayPrioritiesChanged = true;
        this.saveDataWithTimeOut(this.automaticSaveDelay, true, false);
    }

    onTextDayPriorityKeyDown(event: any): void {
        if (event.which === 38 || event.keyCode === 38 || event.key.toLowerCase() === "up") {
            this.setCellFocus(this.editRow - 1, this.editDay); event.preventDefault();
        }
        else if (event.which === 40 || event.keyCode === 40 || event.key.toLowerCase() === "down") {
            this.setCellFocus(this.editRow + 1, this.editDay); event.preventDefault();
        }
        else if (event.which === 9 || event.keyCode === 9 || event.which === 13 || event.keyCode === 13 ||
            event.key.toLowerCase() === "tab" || event.key.toLowerCase() === "enter") {
            var value = (this.editDay + 7 + (event.shiftKey || event.ctrlKey ? -1 : 1)) % 7;
            this.setCellFocus(this.editRow, value); event.preventDefault();
        }
        else this.numberService.filterTextValue(event, event.target.value, false, 3);
    }

    isDayPriorityCellInputVisible(row: number, day: number): boolean {
        if (row === this.editRow && day === this.editDay) {
            return true;
        }
        return false;
    }

    getDayPriorityCellStyle(row: number, day: number): object {
        var value = this.editActivityRowsAll[row].dayPriorities[day];
        var text = value == null || isNaN(value) ? null : value.toString();
        if (text == null || text === "" || text === "0") return { 'color': 'LightGray' };
        return { 'color': 'black' };
    }

    getPerWeekCellText(row: number, week: number): string {
        var weekNr = Number(week);
        var value = this.editActivityRowsAll[row].nrPerWeek[weekNr];
        var plannedValue = this.editActivityRowsAll[row].alreadyPlannedPerWeek[weekNr];
        if (value == null || isNaN(value)) value = plannedValue;
        if (plannedValue != null && !isNaN(plannedValue)) value = Math.max(value, plannedValue);
        var text = value == null || isNaN(value) ? null : value.toString();
        if (text == null || text === "") text = "0";
        return this.getTrustedHtml(text);
    }

    onTextPerWeekBlur(event: any): void {
        this.applyChanges();
    }

    onTextPerWeekInput(event: any): void {
        var nrPerWeek = Number(event.target.value);
        this.editActivityRowsAll[this.editRow].nrPerWeek[this.editWeekNr] = isNaN(nrPerWeek) ? null : nrPerWeek;
        this.editActivityRowsAll[this.editRow].nrPerWeekChanged = true;
        var nrPlannedPerWeek = this.editActivityRowsAll[this.editRow].alreadyPlannedPerWeek[this.editWeekNr];
        if (nrPlannedPerWeek != null && !isNaN(nrPlannedPerWeek) && (isNaN(nrPerWeek) || nrPerWeek < nrPlannedPerWeek)) {
            // required number per week can not be less than what is already planned that week
            nrPerWeek = nrPlannedPerWeek;
            this.editActivityRowsAll[this.editRow].nrPerWeek[this.editWeekNr] = nrPlannedPerWeek;
        }
        if (this.changedPerWeekRowNrs.indexOf(this.editRow) < 0) this.changedPerWeekRowNrs.push(this.editRow);
        this.saveDataWithTimeOut(this.automaticSaveDelay, true, false);
    }

    onTextPerWeekKeyDown(event: any): void {
        if (event.which === 38 || event.keyCode === 38 || event.key.toLowerCase() === "up") {
            this.setCellFocus(this.editRow - 1, null, this.editWeekNr); event.preventDefault();
        }
        else if (event.which === 40 || event.keyCode === 40 || event.key.toLowerCase() === "down") {
            this.setCellFocus(this.editRow + 1, null, this.editWeekNr); event.preventDefault();
        }
        else if (event.which === 9 || event.keyCode === 9 || event.which === 13 || event.keyCode === 13 ||
            event.key.toLowerCase() === "tab" || event.key.toLowerCase() === "enter") {
            var value = ((this.editWeekNr - 1 + this.nrWeeks + (event.shiftKey || event.ctrlKey ? -1 : 1)) % this.nrWeeks) + 1;
            this.setCellFocus(this.editRow, null, value); event.preventDefault();
        }
        else this.numberService.filterTextValue(event, event.target.value, false, 3);
    }

    isPerWeekCellInputVisible(row: number, week: number): boolean {
        var weekNr = Number(week);
        if (row === this.editRow && weekNr === this.editWeekNr) {
            return true;
        }
        return false;
    }

    getPerWeekCellStyle(row: number, week: number): object {
        var weekNr = Number(week);
        var value = this.editActivityRowsAll[row].nrPerWeek[weekNr];
        var plannedValue = this.editActivityRowsAll[row].alreadyPlannedPerWeek[weekNr];
        if (value == null || isNaN(value)) value = plannedValue;
        if (plannedValue != null && !isNaN(plannedValue)) value = Math.max(value, plannedValue);
        var text = value == null || isNaN(value) ? null : value.toString();
        if (text == null || text === "" || text === "0") return { 'color': 'LightGray' };
        return { 'color': 'black' };

    }

    onPerWeekCellClick(row: number, week: number): void {
        this.$timeout(() => {
            this.editRow = row;
            this.editWeekNr = Number(week);
            this.editDay = -1;
            this.$timeout(() => {
                var txtPerWeek = $("#txtPerWeek"); // TODO: find alternative to jquery
                if (txtPerWeek) txtPerWeek = txtPerWeek.first();
                if (txtPerWeek) txtPerWeek.focus();
            }, 20);
        }, 0);
    }

    onPriorityPerDayCellClick(row: number, day: number): void {
        this.$timeout(() => {
            this.editRow = row;
            this.editDay = day;
            this.editWeekNr = -1;
            this.$timeout(() => {
                var txtDayPriority = $("#txtDayPriority"); // TODO: find alternative to jquery
                if (txtDayPriority) txtDayPriority = txtDayPriority.first();
                if (txtDayPriority) txtDayPriority.focus();
            }, 20);
        }, 0);
    }

    onRequiredCellClick(row: number): void {
        this.$timeout(() => {
            this.editRow = row;
            this.editDay = -1;
            this.editWeekNr = -1;
            this.$timeout(() => {
                var txtRequired = $("#txtRequired"); // TODO: find alternative to jquery
                if (txtRequired) txtRequired = txtRequired.first();
                if (txtRequired) txtRequired.focus();
            }, 20);
        }, 0);
    }

    getPriorityPerWeekButtonText(): string {
        if (this.priorityVisible && this.perWeekVisible)
            return this.$scope.textLabels.PRIORITY_PER_DAY + " & " + this.$scope.textLabels.NR_PER_WEEK;
        else if (this.priorityVisible)
            return this.$scope.textLabels.PRIORITY_PER_DAY;
        else
            return this.$scope.textLabels.NR_PER_WEEK;
    }

    onPeriodStartDateChanged(date: Date, period): void {
        this.percentagePerWeek = null;
        period.itemNotComplete = date >= period.end;
        this.saveDataWithTimeOut(this.automaticSaveDelay, true, false);
    }

    onPeriodEndDateChanged(date: Date, period): void {
        this.percentagePerWeek = null;
        period.itemNotComplete = date <= period.start;
        this.saveDataWithTimeOut(this.automaticSaveDelay, true, false);
    }

    onRemovePeriodClick(period): void {
        this.percentagePerWeek = null;
        if (period.id > 0) this.commonSvc.deleteData(this.apiUrl + "/ReductionPeriods/" + period.id.toString(), null, null, false);
        var i = this.reductionPeriods.length;
        while (i > 0) {
            i--;
            if (this.reductionPeriods[i].id === period.id) {
                this.reductionPeriods.splice(i, 1);
                i = 0;
            }
        }
    }

    onAddPeriodClick(): void {
        this.percentagePerWeek = null;
        var lowestId = 0;
        for (var i = 0; i < this.reductionPeriods.length; i++) lowestId = Math.min(this.reductionPeriods[i].id, lowestId);
        lowestId--;
        this.reductionPeriods.push({
            id: lowestId,
            organizationUnitId: this.yearplanOrganizationUnitId,
            start: this.today,
            end: this.tomorrow,
            percentage: 0,
            itemNotComplete: false
        });
    }

    onOrganizationUnitChanged(itemId: number): void {
        this.rebuildFilterOrganizationUnits(itemId);
        this.filterOrganizationUnitId = itemId;
        this.filterOrganizationUnitIds.clear();
        this.addFilterOrganizationUnitId(itemId);
        this.saveUserSettings(this.automaticSaveDelay);
        this.reloadForYearAndOrganizationUnit(this.filterYear, itemId);
    }

    onYearChanged(itemId: number): void {
        this.percentagePerWeek = null;
        this.nrWeeks = Timezone.getNumberOfWeeks(Number(itemId));
        if (this.nrWeeks !== this.allWeekNumbers.length) {
            var newAllWeekNumbers = [];
            for (var i = 1; i <= this.nrWeeks; i++) newAllWeekNumbers.push(i.toString());
            this.allWeekNumbers = newAllWeekNumbers;
            this.$timeout(() => {
                if (this.perWeekVisible) this.weekNumbers = this.allWeekNumbers;
            }, 0);
        }
        this.reloadForYearAndOrganizationUnit(itemId, this.yearplanOrganizationUnitId);
    }

    onFilterOrganizationUnitChanged(itemId: number): void {
        this.saveUserSettings(this.automaticSaveDelay);
        this.filterOrganizationUnitIds.clear();
        this.addFilterOrganizationUnitId(itemId);
        this.startRebuildEditActivityRows(0);
    }

    onActivityTypeSelectionChanged(timeout: number): void {
        this.startRebuildEditActivityRows(timeout);
    }

    showHideNrRequired(): void {
        this.requiredVisible = !this.requiredVisible;
        this.saveUserSettings(this.automaticSaveDelay);
    }

    showHidePriority(): void {
        if (this.priorityVisible && this.perWeekVisible) this.perWeekVisible = false;
        else if (this.priorityVisible) { this.priorityVisible = false; this.perWeekVisible = true; }
        else { this.priorityVisible = true; this.perWeekVisible = true; }

        this.initializeVariables();
        this.saveUserSettings(this.automaticSaveDelay);
    }

    getPriorityWidth(): string {
        if (!this.priorityVisible) return "0";
        if (this.perWeekVisible) return this.totalDayNameWidth;
        return "auto";
    }

    filterTextValue($event: any, oldValue: string, allowDecimal: boolean): void {
        this.numberService.filterTextValue($event, oldValue, allowDecimal);
        this.saveDataWithTimeOut(this.automaticSaveDelay, true, false);
    }

    private rebuildFilterOrganizationUnits(rootId: number): void {
        var dict = new Dictionary();

        // Add the unit and all underlying units to the filter.
        this.addFilterOrganizationUnit(this.userOrganizationUnits, dict, rootId);

        // Also add all parent units to the filter.
        var unit = this.userOrganizationUnits.value(rootId);
        while (unit != null && unit.parentId != null) {
            unit = this.userOrganizationUnits.value(unit.parentId);
            if (unit != null) {
                dict.add(unit.id, unit);
            }
        }

        this.filterOrganizationUnits = dict;
    }

    private addFilterOrganizationUnit(sourceUnits: Dictionary, destUnits: Dictionary, id: number): void {
        if (destUnits.containsKey(id) || !sourceUnits.containsKey(id)) {
            return;
        }
        const unit = sourceUnits.value(id);
        if (unit != null) {
            destUnits.add(id, unit);
        }
        if (unit == null || unit.childIds == null || unit.childIds.length === 0) {
            return;
        }
        for (let i = 0; i < unit.childIds.length; i++) {
            this.addFilterOrganizationUnit(sourceUnits, destUnits, unit.childIds[i]);
        }
    }

    private addFilterOrganizationUnitId(id: number): void {
        if (this.filterOrganizationUnitIds.containsKey(id)) {
            return;
        }
        this.filterOrganizationUnitIds.add(id, id);
        const unit = this.filterOrganizationUnits.value(id);
        if (unit == null || unit.childIds == null || unit.childIds.length === 0) {
            return;
        }
        for (let i = 0; i < unit.childIds.length; i++) {
            this.addFilterOrganizationUnitId(unit.childIds[i]);
        }
    }

    private startRebuildEditActivityRows(timeout: number): void {
        if (this.changeFilterTimer) {
            this.$timeout.cancel(this.changeFilterTimer); this.changeFilterTimer = null;
        }
        this.changeFilterTimer = this.$timeout(() => { this.rebuildEditActivityRows(); }, timeout);
    }

    private setCellFocus(row, day?, week?): void {
        this.$timeout(() => {
            this.applyChanges();
            if (row != null) {
                this.editRow = Math.min(Math.max(Number(row), 0), this.editActivityRowsAll.length - 1);
                var maxRowsPerPage = parseInt(this.activityTypesPerPage);
                var pageNr = Math.floor(this.editRow / maxRowsPerPage) + 1;
                if (pageNr !== this.editActivityRowsPage) this.switchPage(pageNr);
            }
            if (day != null) this.editDay = Number(day);
            if (week != null) this.editWeekNr = Number(week);
            this.$timeout(() => {
                var txtFocus = day != null ? $("#txtDayPriority") : week != null ? $("#txtPerWeek") : $("#txtRequired");// TODO: find alternative to jquery
                if (txtFocus) txtFocus = txtFocus.first();
                if (txtFocus) txtFocus.focus();
            }, 20);
        }, 0);
    }

    private reloadForYearAndOrganizationUnit(yearNr: number, orgUnitId: number): void {
        // first save current pending data
        this.saveDataWithTimeOut(0, true, true);

        // load reduction periods
        if (orgUnitId >= 0)
            this.commonSvc.loadData(this.apiUrl + "/ReductionPeriods/" + yearNr.toString() + "/" + orgUnitId.toString(), null,
                (success) => {
                    // result.data
                    var reductionPeriods = [];
                    for (var i = 0; i < success.data.length; i++) {
                        var item = success.data[i];
                        var newItem = {
                            id: item.id,
                            organizationUnitId: item.organizationUnitId,
                            percentage: item.percentage,
                            start: Timezone.parseDate(item.start),
                            end: Timezone.parseDate(item.end),
                            itemNotComplete: false,
                            originalStart: null,
                            originalEnd: null,
                            originalPercentage: null
                        };
                        newItem.originalStart = newItem.start;
                        newItem.originalEnd = newItem.end;
                        newItem.originalPercentage = newItem.percentage;
                        reductionPeriods.push(newItem);
                    }
                    this.reductionPeriods = reductionPeriods;
                }, null, true, true);

        this.$timeout(() => {
            this.rebuildEditActivityRows();
        }, 0);
    }

    private savePendingData(reloadDataIfNeccesary: boolean, reloadDataAlways: boolean): void {
        // save changes to reduction periods
        var changedReductionPeriods = [];
        var reloadReductionPeriods = false;
        for (var i = 0; i < this.reductionPeriods.length; i++) {
            var item = this.reductionPeriods[i];
            if (item.itemNotComplete || item.newItemSaved) continue;
            if (item.id < 0 ||
                item.start.getTime() !== item.originalStart.getTime() ||
                item.end.getTime() !== item.originalEnd.getTime() ||
                item.percentage !== item.originalPercentage) {
                item.originalStart = item.start;
                item.originalEnd = item.end;
                item.originalPercentage = item.percentage;
                if (item.id < 0) {
                    item.newItemSaved = true;
                    reloadReductionPeriods = reloadDataIfNeccesary;
                }
                changedReductionPeriods.push(item);
            }
        }
        if (changedReductionPeriods.length > 0)
            this.commonSvc.putData(this.apiUrl + "/ReductionPeriods", changedReductionPeriods,
                (success) => {
                    if (reloadReductionPeriods || reloadDataAlways)
                        this.reloadForYearAndOrganizationUnit(this.filterYear, this.yearplanOrganizationUnitId);
                },
                (error) => {
                    this.commonSvc.httpErrorResponse(error, () => { });
                    this.reloadForYearAndOrganizationUnit(this.filterYear, this.yearplanOrganizationUnitId);
                }, false);

        // save changes to day priorities
        var changedPriorities = [];
        for (var i = 0; i < this.editActivityRowsAll.length; i++)
            if (this.editActivityRowsAll[i].dayPrioritiesChanged) {
                this.editActivityRowsAll[i].dayPrioritiesChanged = false;
                for (var j = 0; j < this.editActivityRowsAll[i].dayPriorities.length; j++)
                    if (this.editActivityRowsAll[i].dayPriorities[j] !== this.editActivityRowsAll[i].originalDayPriorities[j]) {
                        this.editActivityRowsAll[i].originalDayPriorities[j] = this.editActivityRowsAll[i].dayPriorities[j];
                        changedPriorities.push({
                            organizationUnitId: this.yearplanOrganizationUnitId,
                            year: this.filterYear,
                            activityTypeId: this.editActivityRowsAll[i].activityType.id,
                            timeSlot: {
                                startMinutes: this.editActivityRowsAll[i].startMinutes,
                                endMinutes: this.editActivityRowsAll[i].endMinutes
                            },
                            dayOfWeek: (j + 1) % 7,
                            priority: this.editActivityRowsAll[i].dayPriorities[j]
                        });
                    }
            }
        if (changedPriorities.length > 0) {
            this.savePriorityPending = true;
            this.commonSvc.putData(this.apiUrl + "/PriorityPerWeekday", changedPriorities,
                (success) => {
                    this.savePriorityPending = false;
                    if (reloadDataAlways)
                        this.reloadForYearAndOrganizationUnit(this.filterYear, this.yearplanOrganizationUnitId);
                },
                (error) => {
                    this.commonSvc.httpErrorResponse(error, () => { });
                    this.reloadForYearAndOrganizationUnit(this.filterYear, this.yearplanOrganizationUnitId);
                }, false);
        }

        // save changes to required per week
        var changedPerWeek = [];
        for (var i = 0; i < this.editActivityRowsAll.length; i++)
            if (this.editActivityRowsAll[i].nrPerWeekChanged) {
                this.editActivityRowsAll[i].nrPerWeekChanged = false;
                var nrPerWeek = [];
                if (this.editActivityRowsAll[i].nrPerWeek)
                    for (var k = 0; k < this.editActivityRowsAll[i].nrPerWeek.length; k++)
                        nrPerWeek.push(this.editActivityRowsAll[i].nrPerWeek[k] == null ? 0 : this.editActivityRowsAll[i].nrPerWeek[k]);
                changedPerWeek.push({
                    organizationUnitId: this.yearplanOrganizationUnitId,
                    year: this.filterYear,
                    activityTypeId: this.editActivityRowsAll[i].activityType.id,
                    timeSlot: {
                        startMinutes: this.editActivityRowsAll[i].startMinutes,
                        endMinutes: this.editActivityRowsAll[i].endMinutes
                    },
                    numberSetPerWeek: nrPerWeek
                });
            }
        if (changedPerWeek.length > 0) {
            this.savePerWeekPending = true;
            this.commonSvc.putData(this.apiUrl + "/NumberPerWeek", changedPerWeek,
                (success) => {
                    this.savePerWeekPending = false;
                    if (reloadDataAlways)
                        this.reloadForYearAndOrganizationUnit(this.filterYear, this.yearplanOrganizationUnitId);
                },
                (error) => {
                    this.commonSvc.httpErrorResponse(error, () => { });
                    this.reloadForYearAndOrganizationUnit(this.filterYear, this.yearplanOrganizationUnitId);
                }, false);
        }
    }

    private recalculateTotalPerWeek(row: number): void {
        var required = this.editActivityRowsAll[row].required;
        if (required == null || isNaN(required)) return;

        // make sure we have a percentage for each week
        if (this.percentagePerWeek == null || this.percentagePerWeek.length === 0)
            this.calculateActivityPercentagePerWeek();

        // count total of already planned per week and calculate the average percentage
        var weeks = this.allWeekNumbers.length;
        var perWeek = []; perWeek.push(0); // skip week nr 0
        var i = 0, j = 0, total = 0, avgPercentage = 0;
        for (i = 1; i <= weeks; i++) {
            perWeek.push(0);
            if (this.editActivityRowsAll[row].alreadyPlannedPerWeek[i] != null)
                perWeek[i] = this.editActivityRowsAll[row].alreadyPlannedPerWeek[i];
            total += perWeek[i];
            avgPercentage += this.percentagePerWeek[i];
        }
        avgPercentage /= weeks;
        var requiredPerWeekFactor = (required / weeks) / avgPercentage;

        // plan until total equals or exceeds required, or until there was no week where still can be planned
        var availableWeeks = [], availableWeeksCount = 1;
        var weekNr = 0, maxWeekOffset = 0, weekOffset = 0, weekOffsetSign = 0, extraToPlan = 0, maxPerWeek = 0;
        while (total < required && availableWeeksCount > 0) {
            extraToPlan = Math.min(required - total, weeks);
            maxWeekOffset = Math.floor((weeks / extraToPlan) / 2); // how many week numbers to skip, so not everything will be planned in week 1

            // create list of available weeks, sorted by least planned
            availableWeeksCount = 0;
            weekOffset = 0;
            for (i = 0; i < availableWeeks.length; i++) availableWeeks[i] = 0;
            while (availableWeeksCount < weeks && weekOffset <= maxWeekOffset) {
                i = extraToPlan;
                while (i-- > 0) {
                    weekOffsetSign = weekOffset > 0 ? -1 : 1;
                    while (weekOffsetSign <= 1) { // weekOffsetSign is -1 or 1, used to add or subtract weekOffset
                        /* Pick a week number between 1 and weeks.
                           For example, if extraToPlan = 8 and weeks = 52, the following week numbers would be chosen with:
                           1 + Math.floor((i * weeks) / extraToPlan): 46, 40, 33, 27, 20, 14, 7, 1
                           + maxWeekOffset: +3
                           + weekOffset * weekOffsetSign: +0, -1, +1, -2, +2, -3, +3
                           Lastly we add the row number so not every activity type will be planned on the same week number if the same required number is entered.
                        */
                        weekNr = 1 + ((Math.floor((i * weeks) / extraToPlan) + maxWeekOffset + weekOffset * weekOffsetSign + row) % weeks);
                        maxPerWeek = requiredPerWeekFactor * this.percentagePerWeek[weekNr];
                        if (this.percentagePerWeek[weekNr] < 1) maxPerWeek = Math.round(maxPerWeek); // round for percentages less than 100%
                        else maxPerWeek = Math.ceil(maxPerWeek + 1); // add extra space for percentages at (or above) 100%
                        if (perWeek[weekNr] < maxPerWeek && availableWeeks.indexOf(weekNr) < 0) {
                            availableWeeks[availableWeeksCount++] = weekNr;
                            // sort to have the weeks with the most availability in front of the array
                            j = availableWeeksCount;
                            while (j-- > 1) {
                                if (requiredPerWeekFactor * this.percentagePerWeek[availableWeeks[j]] - perWeek[availableWeeks[j]] >
                                    requiredPerWeekFactor * this.percentagePerWeek[availableWeeks[j - 1]] - perWeek[availableWeeks[j - 1]]) {
                                    weekNr = availableWeeks[j - 1];
                                    availableWeeks[j - 1] = availableWeeks[j];
                                    availableWeeks[j] = weekNr;
                                } else j = 0;
                            }
                        }
                        weekOffsetSign += 2;
                    }
                }
                weekOffset++;
            }

            // plan on available weeks
            i = 0;
            while (i < availableWeeksCount && total < required) {
                weekNr = availableWeeks[i++];
                perWeek[weekNr]++;
                total++;
            }
        }

        // copy results in perWeek to $scope.editActivityRows[row].nrPerWeek
        for (i = 0; i <= weeks; i++)
            this.editActivityRowsAll[row].nrPerWeek[i] = perWeek[i];

        this.editActivityRowsAll[row].nrPerWeekChanged = true;
        this.saveDataWithTimeOut(this.automaticSaveDelay, true, false);
    }

    private recalculateTotalRequired(row: number): void {
        var totalRequired = 0, required = 0, planned = 0;
        var i = Math.max(this.editActivityRowsAll[row].nrPerWeek.length, this.editActivityRowsAll[row].alreadyPlannedPerWeek.length);
        while (i-- > 0) {
            required = this.editActivityRowsAll[row].nrPerWeek[i] != null ? this.editActivityRowsAll[row].nrPerWeek[i] : 0;
            planned = this.editActivityRowsAll[row].alreadyPlannedPerWeek[i] != null ? this.editActivityRowsAll[row].alreadyPlannedPerWeek[i] : 0;
            totalRequired += Math.max(required, planned);
        }
        this.editActivityRowsAll[row].required = totalRequired;
    }

    private applyChanges(skipRowNr?: number): void {
        if (skipRowNr == null) skipRowNr = -1;
        var changes = false;
        var i = this.changedRequiredRowNrs.length;
        while (i-- > 0 && !changes) if (this.changedRequiredRowNrs[i] !== skipRowNr) changes = true;
        i = this.changedPerWeekRowNrs.length;
        while (i-- > 0 && !changes) if (this.changedPerWeekRowNrs[i] !== skipRowNr) changes = true;
        if (!changes) return;
        this.$timeout(() => {
            i = this.changedRequiredRowNrs.length;
            while (i-- > 0) if (this.changedRequiredRowNrs[i] !== skipRowNr) {
                this.recalculateTotalPerWeek(this.changedRequiredRowNrs[i]);
                this.changedRequiredRowNrs.splice(i, 1);
            }
            i = this.changedPerWeekRowNrs.length;
            while (i-- > 0) if (this.changedPerWeekRowNrs[i] !== skipRowNr) {
                this.recalculateTotalRequired(this.changedPerWeekRowNrs[i]);
                this.changedPerWeekRowNrs.splice(i, 1);
            }
        }, 0);
    }

    private rollOutIfNoSavePending(): void {
        if (this.savePriorityPending || this.savePerWeekPending) {
            this.rollOutRetry++;
            if (this.rollOutRetry < 10) this.$timeout(() => { this.rollOutIfNoSavePending(); }, 1000);
            return;
        }

        // make sure the planboard will reload data
        Planboard.clearData();

        var postData = {
            yearPlanningSpec: {
                organizationUnitId: this.yearplanOrganizationUnitId,
                year: this.filterYear
            },
            startDate: Timezone.rollDateForWebApi(this.rollOutStart),
            endDate: Timezone.rollDateForWebApi(this.rollOutEnd)
        };

        this.commonSvc.post(this.apiUrl + "/RollOut", postData,
            (success) => {
                this.rollOutInProgress = false;
                this.commonSvc.showDialog(
                    this.$scope.textLabels.YEARPLANNING_PAGE_TITLE,
                    this.$scope.textLabels.YEARPLANNING_APPLIED_FOR_ACTIVITY_TYPES + ": " + success.data.length + ". " + this.$scope.textLabels.ACTIVITY_TYPES_SKIPPED,
                    this.$scope.textLabels.OK,
                    () => {
                        this.rebuildEditActivityRows();
                    });
            },
            (error) => {
                this.rollOutInProgress = false;
                this.commonSvc.httpErrorResponse(error, () => { });
                this.reloadForYearAndOrganizationUnit(this.filterYear, this.yearplanOrganizationUnitId);
            }, true);
    }

    private hasActivityTypeExpired(activityType): boolean {
        if (!activityType.validTo) return false;
        var atToDate = Timezone.parseDate(activityType.validTo);
        return atToDate < this.date;
    }

    private isTopLevelActivityType(activityType): boolean {
        return !activityType.parentId &&
            activityType.categoryId !== ActivityType.absenceCategoryId &&
            activityType.categoryId !== ActivityType.daymarkCategoryId;
    }

    private registerChildToParent(organizationUnit: any, childId?:number): void {
        if (!organizationUnit.childIds) organizationUnit.childIds = [];
        if (!organizationUnit.parentId) return;
        if (!childId) childId = organizationUnit.id;
        var parent = this.userOrganizationUnits.value(organizationUnit.parentId);
        if (parent) {
            if (!parent.childIds) parent.childIds = [childId];
            else parent.childIds.push(childId);
            this.registerChildToParent(parent, childId);
        }
    }

    private saveDataWithTimeOut (timeout: number, reloadDataIfNeccesary: boolean, reloadDataAlways: boolean): void {
        if (this.saveDataTimer) { this.$timeout.cancel(this.saveDataTimer); this.saveDataTimer = null; }
        if (timeout == null || timeout <= 0) {
            this.savePendingData(reloadDataIfNeccesary, reloadDataAlways);
            return;
        }
        this.saveDataTimer = this.$timeout(() => {
            this.savePendingData(reloadDataIfNeccesary, reloadDataAlways);
        }, timeout);
    }

    private saveUserSettings(timeout?: number): void {
        if (this.saveSettingsTimer) { this.$timeout.cancel(this.saveSettingsTimer); this.saveSettingsTimer = null; }
        if (timeout == null || timeout <= 0) {
            this.saveUserVariables();
            return;
        }
        this.saveSettingsTimer = this.$timeout(() => {
            this.saveUserVariables();
        }, timeout);
    }

    private saveUserVariables(): void {
        this.writeSettingColumnsVisible();
        this.userService.setDisplaySettingNumber("yearplan.organizationUnitId", this.yearplanOrganizationUnitId);
        this.userService.setDisplaySettingNumber("yearplan.filterUnitId", this.filterOrganizationUnitId);
        this.userService.setDisplaySetting("yearplan.activityTypesPerPage", this.activityTypesPerPage);
    }

    private initializeVariables(): void {
        if (this.allWeekNumbers.length === 0) {
            for (var i = 1; i <= this.nrWeeks; i++) this.allWeekNumbers.push(i.toString());
        }

        if (this.shortDayNames.length === 0) {
            // initialize day of week names
            var text = "";
            var weekStart = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
            while (weekStart.getDay() !== 1) weekStart.setDate(weekStart.getDate() - 1);
            for (var i = 0; i < 7; i++) {
                text = this.$filter("date")(weekStart, "EEE");
                text = text.charAt(0).toUpperCase() + text.substring(1);
                this.shortDayNames.push(text);
                text = this.$filter("date")(weekStart, "EEEE");
                text = text.charAt(0).toUpperCase() + text.substring(1);
                this.longDayNames.push(text);
                weekStart.setDate(weekStart.getDate() + 1);
            }
        }

        // initialize year numbers to select
        if (this.filterYearNumbers.count === 0) {
            for (var i = this.filterYear - 10; i <= this.filterYear + 5; i++)
                this.filterYearNumbers.add(i, { id: i, order: i, displayName: i.toString() });
        }

        // set arrays for repeaters and column widths
        if (!this.perWeekVisible) this.weekNumbers = [];
        else this.weekNumbers = this.allWeekNumbers;
        if (!this.priorityVisible) this.dayNames = [];
        else if (this.perWeekVisible) {
            this.dayNames = this.shortDayNames;
            this.dayNameWidth = "3em";
            this.totalDayNameWidth = "21em";
        }
        else {
            this.dayNames = this.longDayNames;
            this.dayNameWidth = "6em";
            this.totalDayNameWidth = "42em";
        }
    }

    private readSettingColumnsVisible(): void {
        var columnsVisible = JSON.parse(this.userService.getDisplaySetting("yearplan.columnsVisible", this.getStringSettingColumnsVisible()));
        if (!columnsVisible) return;
        if (columnsVisible.priority != null) this.priorityVisible = columnsVisible.priority;
        if (columnsVisible.perWeek != null) this.perWeekVisible = columnsVisible.perWeek;
        if (columnsVisible.required != null) this.requiredVisible = columnsVisible.required;
    }

    private writeSettingColumnsVisible(): void {
        this.userService.setDisplaySetting("yearplan.columnsVisible", this.getStringSettingColumnsVisible());
    }

    private getStringSettingColumnsVisible(): string {
        return JSON.stringify({
            priority: this.priorityVisible,
            perWeek: this.perWeekVisible,
            required: this.requiredVisible
        });
    }

    private getTrustedHtml(text: string) {
        if (text == null || text === "") return null;
        var cached = this.sceCache[text];
        if (!cached) {
            cached = this.$sce.trustAsHtml(text);
            this.sceCache[text] = cached;
        }
        return cached;
    }

    private calculateActivityPercentagePerWeek(): Array<number> {
        var result = [];
        var weekStart = null, weekEnd = null;
        var i = 54;
        while (i-- > 0) result.push(1);
        i = this.reductionPeriods.length;
        while (i-- > 0)
            if (!this.reductionPeriods[i].itemNotComplete) {
                weekStart = Timezone.getWeekNumber(this.reductionPeriods[i].start);
                weekEnd = Timezone.getWeekNumber(this.reductionPeriods[i].end);

                if (weekStart.year < this.filterYear) weekStart.week = 0; // start at begin of year
                else if (weekStart.year > this.filterYear) weekStart.week = 54; // skip this period

                if (weekEnd.year > this.filterYear) weekEnd.week = 53; // end at end of year
                else if (weekEnd.year < this.filterYear) weekEnd.week = -1; // skip this period

                while (weekStart.week <= weekEnd.week) {
                    result[weekStart.week] = Math.min(Number(this.reductionPeriods[i].percentage) / 100, result[weekStart.week]);
                    weekStart.week++;
                }
            }
        this.percentagePerWeek = result;
        return result;
    }

    private setActivityRowPriorities(rowArray: Array<any>, activityType, startMinutes, endMinutes, dayOfWeek, priority): void {
        dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
        var index = rowArray.length - 1;
        if (this.lastRowIndex >= 0 &&
            rowArray[this.lastRowIndex].activityType.id === activityType.id &&
            rowArray[this.lastRowIndex].startMinutes === startMinutes &&
            rowArray[this.lastRowIndex].endMinutes === endMinutes)
            index = this.lastRowIndex;
        while (index >= 0) {
            if (rowArray[index].activityType.id === activityType.id &&
                rowArray[index].startMinutes === startMinutes &&
                rowArray[index].endMinutes === endMinutes) {
                this.lastRowIndex = index;
                while (dayOfWeek >= rowArray[index].dayPriorities.length) {
                    rowArray[index].dayPriorities.push(0);
                    rowArray[index].originalDayPriorities.push(0);
                }
                rowArray[index].dayPriorities[dayOfWeek] = priority;
                rowArray[index].originalDayPriorities[dayOfWeek] = priority;
                return;
            }
            index--;
        }
    }

    private setActivityNumberPerWeek(rowArray: Array<any>, activityType, startMinutes, endMinutes, numberSetPerWeek): void {
        var index = rowArray.length - 1;
        if (this.lastRowIndex >= 0 &&
            rowArray[this.lastRowIndex].activityType.id === activityType.id &&
            rowArray[this.lastRowIndex].startMinutes === startMinutes &&
            rowArray[this.lastRowIndex].endMinutes === endMinutes)
            index = this.lastRowIndex;
        while (index >= 0) {
            if (rowArray[index].activityType.id === activityType.id &&
                rowArray[index].startMinutes === startMinutes &&
                rowArray[index].endMinutes === endMinutes) {
                this.lastRowIndex = index;
                rowArray[index].nrPerWeek = numberSetPerWeek;
                rowArray[index].required = 0;
                for (var k = 0; k < rowArray[index].nrPerWeek.length; k++) rowArray[index].required += rowArray[index].nrPerWeek[k];
                return;
            }
            index--;
        }
    }

    private addActivityRow(rowArray: Array<any>, activityType, startMinutes, endMinutes, insertAfterSameActivityType) {
        if (endMinutes <= startMinutes) endMinutes += 1440;
        var newRow = {
            nr: 0,
            activityType: activityType,
            startMinutes: startMinutes,
            endMinutes: endMinutes,
            startTime: Timezone.minutesToStr(startMinutes),
            endTime: Timezone.minutesToStr(endMinutes < 1440 ? endMinutes : endMinutes - 1440),
            dayPriorities: [], // an array with a priority for each day (on positions 0 to 6)
            originalDayPriorities: [], // an array with the original priorities for each day
            nrPerWeek: [], // an array with a number required per week (on positions 1 to 53)
            alreadyPlannedPerWeek: [], // an array with a number of activities of this type that are already planned per week (on positions 1 to 53)
            planned: 0,
            required: null,
            plannedLoaded: false,
            dayPrioritiesChanged: false,
            nrPerWeekChanged: false
        };
        if (insertAfterSameActivityType) {
            var index = rowArray.length - 1;
            // find same activity type
            while (index >= 0 && activityType.id !== rowArray[index].activityType.id) index--;
            // keep moving up if the startMinutes is earlier
            while (index >= 0 && activityType.id === rowArray[index].activityType.id && startMinutes < rowArray[index].startMinutes) index--;
            // keep moving up if the startMinutes is equal and the end minutes is earlier
            while (index >= 0 && activityType.id === rowArray[index].activityType.id && startMinutes === rowArray[index].startMinutes
                && endMinutes < rowArray[index].endMinutes) index--;
            if (index >= 0) {
                this.lastRowIndex = index;
                if (rowArray[index].activityType.id === activityType.id &&
                    rowArray[index].startMinutes === startMinutes &&
                    rowArray[index].endMinutes === endMinutes)
                    return; // already exists in array
                index++;
                rowArray.splice(index, 0, newRow);
                while (index < rowArray.length) { rowArray[index].nr = index; index++; }
                return;
            }
        }
        newRow.nr = rowArray.length;
        rowArray.push(newRow);
    }

    private rebuildEditActivityVisibleRows(): void {
        var requestPlanned = {
            year: this.filterYear,
            activityTypesPerTimeslot: []
        };
        var rowArray = this.editActivityRowsAll;
        var visibleRowArray = [];
        var maxRowsPerPage = parseInt(this.activityTypesPerPage);
        var fromNr = (this.editActivityRowsPage - 1) * maxRowsPerPage;
        var i = fromNr;
        while (i < rowArray.length && maxRowsPerPage > 0) {
            // extra check to see if the activityType is selected
            if (this.isActivityTypeVisible(rowArray[i].activityType)) {
                visibleRowArray.push(rowArray[i]);
                // construct object for loading planned data
                if (!rowArray[i].plannedLoaded) {
                    rowArray[i].plannedLoaded = true;
                    requestPlanned.activityTypesPerTimeslot.push({
                        activityTypeId: rowArray[i].activityType.id,
                        timeSlot: {
                            startMinutes: rowArray[i].startMinutes,
                            endMinutes: rowArray[i].endMinutes,
                        }
                    });
                }
                maxRowsPerPage--;
            }
            i++;
        }

        // load planned data if neccesary
        if (requestPlanned.activityTypesPerTimeslot.length > 0)
            this.commonSvc.post(this.apiUrl + "/NumberPlannedPerWeek", requestPlanned,
                (success) => {
                    for (var i = 0; i < success.data.length; i++)
                        for (var j = 0; j < this.editActivityRowsAll.length; j++) {
                            var row = this.editActivityRowsAll[j];
                            if (success.data[i].activityTypeId === row.activityType.id &&
                                success.data[i].timeSlot.startMinutes === row.startMinutes &&
                                success.data[i].timeSlot.endMinutes === row.endMinutes) {
                                row.alreadyPlannedPerWeek = success.data[i].numberPlannedPerWeek;
                                row.planned = 0;
                                for (var k = 0; k < row.alreadyPlannedPerWeek.length; k++) row.planned += row.alreadyPlannedPerWeek[k];
                                break;
                            }
                        }
                }, null, true);

        this.editActivityRows = visibleRowArray;
    }

    private isActivityTypeVisible(activityType: any): boolean {
        if ((activityType == null) ||
            (this.filterOrganizationUnitId > 0 && !this.filterOrganizationUnitIds.containsKey(activityType.ownerOrganizationUnitId)) ||
            (!this.filterAllActivityTypes && this.filterActivityTypeIds.length === 0) ||
            (!this.filterAllActivityTypes && this.filterActivityTypeIds.length > 0 && this.filterActivityTypeIds.indexOf(activityType.id) < 0)) {
            return false;
        }
        return true;
    }

    private isActivityTypeFilteredBySelectedOrganizationUnit(activityType: any): boolean {
        if ((activityType == null) ||
            (this.filterOrganizationUnitId > 0 && !this.filterOrganizationUnitIds.containsKey(activityType.ownerOrganizationUnitId))) {
            return false;
        }
        return true;
    }

    private rebuildEditActivityRows(): void {
        var rowArray = [];

        var activityTypesToAdd = [];
        this.filteredRootActivityTypesBySelectedOrgUnit.clear();

        this.userRootActivityTypes.forEach((key, value) => {
            if (value.defaultTimeSlotList.length > 0 && this.isActivityTypeVisible(value)) {
                activityTypesToAdd.push(value);
            }

            if (value.defaultTimeSlotList.length > 0 && this.isActivityTypeFilteredBySelectedOrganizationUnit(value)) {
                this.filteredRootActivityTypesBySelectedOrgUnit.add(key, value);
            }
        });

        // sort by short name of the activity type
        activityTypesToAdd.sort((a, b) => {
            if (a.shortName === b.shortName) return 0;
            return a.shortName.toLowerCase() < b.shortName.toLowerCase() ? -1 : 1;
        });
        // add the sorted activity types
        for (var j = 0; j < activityTypesToAdd.length; j++) {
            var activityType = activityTypesToAdd[j];
            for (var i = 0; i < activityType.defaultTimeSlotList.length; i++) {
                this.addActivityRow(rowArray, activityType,
                    activityType.defaultTimeSlotList[i].startMinutes,
                    activityType.defaultTimeSlotList[i].endMinutes, false);
            }
        }

        this.editRow = -1;
        this.editActivityRowsAll = rowArray;

        if (this.yearplanOrganizationUnitId >= 0) {
            // load data for weekday priorities
            this.commonSvc.loadData(this.apiUrl + "/PriorityPerWeekday/" + this.yearplanOrganizationUnitId.toString() + "/" + this.filterYear.toString(), null,
                (success) => {
                    for (var i = 0; i < success.data.length; i++) {
                        var item = success.data[i];
                        var activityType = this.userRootActivityTypes.value(item.activityTypeId);
                        if (this.isActivityTypeVisible(activityType)) {
                            this.addActivityRow(this.editActivityRowsAll, activityType, item.timeSlot.startMinutes, item.timeSlot.endMinutes, true);
                            this.setActivityRowPriorities(this.editActivityRowsAll, activityType, item.timeSlot.startMinutes, item.timeSlot.endMinutes, item.dayOfWeek, item.priority);
                        }
                        this.calculateMaximumPages();
                        this.rebuildEditActivityVisibleRows();
                    }
                }, null, true, true);

            // load data for required per week
            this.commonSvc.loadData(this.apiUrl + "/NumberPerWeek/" + this.yearplanOrganizationUnitId.toString() + "/" + this.filterYear.toString(), null,
                (success) => {
                    for (var i = 0; i < success.data.length; i++) {
                        var item = success.data[i];
                        var activityType = this.userRootActivityTypes.value(item.activityTypeId);
                        if (this.isActivityTypeVisible(activityType)) {
                            this.addActivityRow(this.editActivityRowsAll, activityType, item.timeSlot.startMinutes, item.timeSlot.endMinutes, true);
                            this.setActivityNumberPerWeek(this.editActivityRowsAll, activityType, item.timeSlot.startMinutes, item.timeSlot.endMinutes, item.numberSetPerWeek);
                        }
                        this.calculateMaximumPages();
                        this.rebuildEditActivityVisibleRows();
                    }
                }, null, true, true);
        }

        this.calculateMaximumPages();
        this.rebuildEditActivityVisibleRows();
    }

    private calculateMaximumPages(): void {
        var maxRowsPerPage = parseInt(this.activityTypesPerPage);
        this.editActivityRowsPages = Math.ceil(this.editActivityRowsAll.length / maxRowsPerPage);
        this.editActivityRowsPage = Math.max(Math.min(this.editActivityRowsPage, this.editActivityRowsPages), 1);
        this.editActivityRowsPageNrs = [];
        for (var i = 1; i <= this.editActivityRowsPages; i++)
            this.editActivityRowsPageNrs.push(i);
    }

}