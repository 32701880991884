export class ResourcePropertyName {
    resourcePropertyId: number;
    languageCode: string;
    text: string;

    constructor(rpId: number = -1, langCode: string = "nolang", text: string = "") {
        this.resourcePropertyId = rpId;
        this.languageCode = langCode;
        this.text = text;
    }        
};