$(function () {
    var focusedElement;
    $(document).on('focus', 'input', function () {
        if (focusedElement == this) return;
        focusedElement = this;
        setTimeout(function () {
            if (focusedElement) focusedElement.select();
        }, 1);
    });
    $(document).on('blur', 'input', function () {
        focusedElement = null;
    });
});