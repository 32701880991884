import * as Globals from './../../app/components/planboard/utils/globals';
import * as Timezone from './../../app/components/utils/timezone';

export var omrpDatepicker = [
    "$rootScope", "$filter", "$timeout",
    function ($rootScope, $filter, $timeout) {
        return {
            // can be used as an attribute or element or class
            restrict: "AEC",

            template: require("../template/datepicker.html"),

            transclude: true,

            require: "ngModel",

            scope: {
                autosizewidth: "=", // true or false, indicates if the dropdown list will be the same width as the tag list 
                changedisabled: "=", // true to disable this control
                customdropdownbtn: "@", // true to hide the default day/month/year input text, the custom contents inside the directive will be transcluded
                triggersamedate: "@", // also trigger datechanged if the chosen (clicked on) date is the same as the previous value
                datevalue: "=ngModel", // the chosen date, use ng-model to bind a date object
                datechanged: "&?", // callback function to call after a date has changed with the date as parameter
                ondropdown: "&?", // callback function to call after the datepicker dropdown is shown or hidden with visible as parameter 
                getcleartext: "&?", // callback function to get the text to display for clearing the date
                displayweektext: "&?", // callback function to get the text to display the week number
                firstweekday: "@" // 0 = sunday, 1 = monday (default)
            },

            link: function (scope, element, attrs, ngModelCtrl) {
                scope.triggerElement = element[0];
                scope.dropdownVisible = false;
                scope.dropdownLeft = "0px";
                scope.dropdownTop = "0px";
                scope.dropdownWidth = "250px";
                scope.dropdownHeight = "200px";
                scope.dropdownvisibility = "visible";
                scope.validChars = "0123456789"; // valid characters for input in the textboxes
                scope.dayvalue = scope.lastdayvalue = null;
                scope.monthvalue = scope.lastmonthvalue = null;
                scope.yearvalue = scope.lastyearvalue = null;
                scope.defaulttextcolor = "black"; // color of text in input and calendar
                scope.errortextcolor = "red"; // color of invalid text in input (if it is not a correct date)
                scope.disabledtextcolor = "gray"; // color of text in calendar for other months
                scope.defaultblocksize = "30px"; // the default block size for the monthview
                scope.doubleblocksize = "60px"; // the double block size for the yearview
                scope.blocksize = "30px"; // height and width of one cell in the calendar
                scope.firstdate = null; // first date in the calendar (not neccesarily the first of the month)
                scope.selecteddate = null; // the currently selected or entered date (should be the same as datevalue)
                scope.daynames = []; // will be initialized when making the calendar
                scope.calendar = []; // array of arrays for the calendar table
                scope.yearview = false; // if the year view (all months for 1 year) is visible instead of only 1 month

                var lastmonthyear = ""; // remember the last month and year so makeCalendar is not doing unnecesary work

                /**
                 * get the id of the html element or give the html element an unique id if it has none.
                 */
                scope.getTriggerElementId = function () {
                    if (scope.triggerElement.id === "") scope.triggerElement.id = "genId" + Math.floor(Math.random() * Globals.maxInt53);
                    return scope.triggerElement.id;
                }

                /**
                 * convert a string with px value into an integer: e.g.: 15px -> 15
                 */
                scope.pxToInt = function (pxvalue) {
                    return parseInt(pxvalue.substring(0, pxvalue.length - 2));
                }

                /**
                 * function to call when the dropdown is shown or hidden.
                 */
                scope.triggerDropdownChanged = function () {
                    if (scope.ondropdown != undefined) scope.ondropdown({ visible: scope.dropdownVisible });
                }

                /**
                 * change the position of the dropdown with a timeout.
                 */
                scope.repositionDropdown = function () {
                    $timeout(function () {
                        if (!scope.dropdownVisible) return;
                        if (!scope.dropdownElement) return;
                        if (!scope.displayElement) return;

                        var rectDropdown = scope.dropdownElement.getBoundingClientRect();
                        var rect = scope.displayElement.getBoundingClientRect();
                        var leftPos = rect.left;
                        var topPos = rect.bottom - 1;
                        if (topPos + rectDropdown.height >= window.innerHeight) {
                            topPos = Math.max(rect.top - rectDropdown.height, 0);
                        }
                        // make the dropdownElement just as wide as the triggerElement
                        if (scope.autosizewidth) scope.dropdownWidth = "" + rect.width + "px";

                        if (leftPos + scope.pxToInt(scope.dropdownWidth) >= window.innerWidth) {
                            leftPos = Math.max(window.innerWidth - scope.pxToInt(scope.dropdownWidth), 0);
                        }
                        leftPos = Math.round(leftPos + window.pageXOffset);
                        topPos = Math.round(topPos + window.pageYOffset);
                        scope.dropdownLeft = "" + leftPos + "px";
                        scope.dropdownTop = "" + topPos + "px";
                        scope.dropdownvisibility = "visible";
                    }, 1);
                }

                /**
                 * return and correct if neccesary the value in scope.datevalue (wich is bound trough ngModel)
                 */
                scope.getdatevalue = function () {
                    var dt = scope.datevalue;
                    // test if scope.datevalue is something else than a date object, for example a string
                    if (dt != undefined && dt.getFullYear == undefined) dt = new Date("" + dt + (dt.charAt(dt.length - 1) !== "Z" ? "Z" : ""));
                    // test if dt is a valid date
                    if (dt == undefined || dt.getFullYear == undefined || isNaN(dt.getTime())) dt = new Date();
                    return dt;
                }

                /**
                 * function that is called when the user makes the dropdown visible
                 */
                scope.show = function () {
                    if (scope.changedisabled) return;

                    if (!scope.dropdownElement) {
                        // find the dropdown-list and move it to the end of the document body
                        // this is neccesary so it wont be restricted by a parents bounding div element
                        scope.displayElement = scope.triggerElement.firstElementChild.firstElementChild;
                        scope.dropdownElement = scope.triggerElement.firstElementChild.lastElementChild;
                        scope.dropdownElement = document.body.appendChild(scope.dropdownElement);
                    }

                    // initialize values
                    lastmonthyear = "";
                    scope.yearview = false;
                    scope.blocksize = scope.defaultblocksize;

                    // determine width and height of the dropdown
                    var extraheight = 12 + (scope.pxToInt(scope.blocksize) + 4) * (scope.canClearDate() ? 9 : 8);
                    var extrawidth = 12 + (scope.pxToInt(scope.blocksize) + 4) * (scope.displayweektext != null ? 8 : 7);
                    scope.dropdownHeight = "" + extraheight + "px";
                    scope.dropdownWidth = "" + extrawidth + "px";

                    // position the dropdownElement near triggerElement
                    var rect = scope.displayElement.getBoundingClientRect();
                    var leftPos = rect.left;
                    var topPos = rect.bottom - 1;
                    if (topPos + scope.pxToInt(scope.dropdownHeight) >= window.innerHeight) {
                        topPos = Math.max(rect.top - scope.pxToInt(scope.dropdownHeight), 0);
                        scope.dropdownvisibility = "hidden";
                    }

                    // make the dropdownElement just as wide as the triggerElement
                    if (scope.autosizewidth) scope.dropdownWidth = "" + rect.width + "px";

                    if (leftPos + scope.pxToInt(scope.dropdownWidth) >= window.innerWidth) {
                        leftPos = Math.max(window.innerWidth - scope.pxToInt(scope.dropdownWidth), 0);
                    }
                    leftPos = Math.round(leftPos + window.pageXOffset);
                    topPos = Math.round(topPos + window.pageYOffset);
                    scope.dropdownLeft = "" + leftPos + "px";
                    scope.dropdownTop = "" + topPos + "px";

                    // toggle visibility of the dropdownElement
                    scope.makeCalendar(true);
                    scope.dropdownVisible = !scope.dropdownVisible;
                    scope.triggerDropdownChanged();
                    scope.repositionDropdown();
                    if (!scope.dropdownVisible) {
                        var dt = scope.getdatevalue();
                        scope.setSelectedDateValue(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
                    }
                };

                /**
                 * function that is called when a key is released in the day input text
                 */
                scope.daykeyup = function ($event) {
                    if ($event.keyCode === 189) {
                        var inputelement = $(scope.triggerElement).find(".omrptextmonth");
                        (inputelement[0] as any).focus();
                    }
                }

                /**
                 * function that is called when a key is released in the month input text
                 */
                scope.monthkeyup = function ($event) {
                    if ($event.keyCode === 189) {
                        var inputelement = $(scope.triggerElement).find(".omrptextyear");
                        (inputelement[0] as any).focus();
                    }
                }

                /**
                 * function that is called onfocus
                 */
                scope.onFocus = function ($event) {
                    $event.target.select();
                }

                /**
                 * fill the 3 input texts with day, month, and year
                 */
                scope.setSelectedDateValue = function (numYear, numMonth, numDay) {
                    var strYear = "0000" + numYear;
                    var strMonth = "00" + numMonth;
                    var strDay = "00" + numDay;
                    strYear = strYear.substring(strYear.length - 4);
                    strMonth = strMonth.substring(strMonth.length - 2);
                    strDay = strDay.substring(strDay.length - 2);
                    if (strYear !== scope.yearvalue) scope.yearvalue = scope.lastyearvalue = strYear;
                    if (strMonth !== scope.monthvalue) scope.monthvalue = scope.lastmonthvalue = strMonth;
                    if (strDay !== scope.dayvalue) scope.dayvalue = scope.lastdayvalue = strDay;
                }

                /**
                 * returns the style for a table cell in the calendar
                 */
                scope.getCellStyle = function (cell) {
                    return {
                        color: cell.color,
                        width: scope.blocksize,
                        height: scope.blocksize,
                        border: !scope.yearview && scope.selecteddate && cell.datetime === scope.selecteddate.getTime()
                            ? "1px solid " + scope.disabledtextcolor
                            : "1px solid #f9f9f9"
                    }
                }

                /**
                 * returns the outer style for a table cell in the calendar
                 */
                scope.getOuterCellStyle = function (cell) {
                    var styleObject = { padding: "1px" }
                    if (cell.datetime >= 8 && cell.datetime < 15) styleObject["border-right"] = "1px solid " + scope.disabledtextcolor;
                    return styleObject;
                }

                /**
                 * function that is called when the user clicks on previous month
                 */
                scope.prevmonth = function () {
                    if (!scope.selecteddate) return;
                    var d = new Date(scope.selecteddate.getTime());
                    if (scope.yearview)
                        d.setFullYear(d.getFullYear() - 1);
                    else
                        d.setMonth(d.getMonth() - 1);
                    scope.setSelectedDateValue(d.getFullYear(), d.getMonth() + 1, d.getDate());
                }

                /**
                 * function that is called when the user clicks on next month
                 */
                scope.nextmonth = function () {
                    if (!scope.selecteddate) return;
                    var d = new Date(scope.selecteddate.getTime());
                    if (scope.yearview)
                        d.setFullYear(d.getFullYear() + 1);
                    else
                        d.setMonth(d.getMonth() + 1);
                    scope.setSelectedDateValue(d.getFullYear(), d.getMonth() + 1, d.getDate());
                }

                /**
                 * get the number of cols in the calendar
                 */
                scope.getCalendarCols = function () {
                    return scope.displayweektext == null ? 7 : 8;
                }

                /**
                 * fill the array for the calendar table
                 */
                scope.makeCalendar = function (makeWhenHidden) {
                    if (!scope.firstdate || !scope.selecteddate) return;
                    if (!scope.dropdownVisible && !makeWhenHidden) return;
                    if (scope.calendar.length > 0 && scope.yearvalue + "-" + scope.monthvalue === lastmonthyear) return;
                    lastmonthyear = scope.yearvalue + "-" + scope.monthvalue;
                    var calendar = [];
                    var row = [];
                    var currmonth = scope.selecteddate.getMonth();
                    var currdate = new Date(scope.firstdate.getTime());
                    // make a calendar with the 12 months for a year
                    if (scope.yearview) {
                        currdate = new Date(scope.selecteddate.getFullYear(), 0, 1);
                        for (var rowNr = 0; rowNr < 3; rowNr++) {
                            for (var colNr = 0; colNr < 4; colNr++) {
                                row.push({
                                    datetime: currdate.getTime(),
                                    text: $filter("date")(currdate, "MMM").replace(".", ""),
                                    color: scope.defaulttextcolor
                                });
                                currdate.setMonth(currdate.getMonth() + 1);
                            }
                            calendar.push(row);
                            row = [];
                        }
                        scope.calendar = calendar;
                        return;
                    }
                    if (scope.daynames.length === 0) {
                        // initialize short names for the days of the week
                        for (var i = 0; i < 7; i++) {
                            scope.daynames.push($filter("date")(currdate, "EEE").substring(0, 2).toLowerCase());
                            currdate.setDate(currdate.getDate() + 1);
                        }
                        currdate = new Date(scope.firstdate.getTime());
                    }
                    // begin with 1 line with the days of the week
                    var maxCols = scope.displayweektext == null ? 7 : 8;
                    if (maxCols === 8)
                        row.push({ datetime: 7, text: scope.displayweektext(), color: scope.disabledtextcolor });
                    for (var dayNr = 0; dayNr < 7; dayNr++)
                        row.push({ datetime: dayNr, text: scope.daynames[dayNr], color: scope.disabledtextcolor });
                    calendar.push(row);
                    row = [];
                    while (currdate.getMonth() === currmonth || row.length !== 0 || calendar.length < 3) {
                        if (row.length === 0 && maxCols === 8)
                            row.push({
                                datetime: 8 + calendar.length,
                                text: Timezone.getWeekNumber(currdate).week.toString(),
                                color: scope.disabledtextcolor
                            });
                        row.push({
                            datetime: currdate.getTime(),
                            text: currdate.getDate().toString(),
                            color: currdate.getMonth() === currmonth ? scope.defaulttextcolor : scope.disabledtextcolor
                        });
                        currdate.setDate(currdate.getDate() + 1);
                        if (row.length >= maxCols) {
                            calendar.push(row);
                            row = [];
                        }
                    }
                    scope.calendar = calendar;
                    //scope.repositionDropdown(); // too springy, but would be correct
                }

                /**
                 * returns if two dates are the same day
                 */
                scope.isSameDate = function (date1, date2) {
                    if (date1 == undefined || date2 == undefined) return false;
                    if (date1.getFullYear == undefined)
                        date1 = new Date("" + date1 + (date1.charAt(date1.length - 1) !== "Z" ? "Z" : ""));
                    if (date2.getFullYear == undefined)
                        date2 = new Date("" + date2 + (date2.charAt(date2.length - 1) !== "Z" ? "Z" : ""));
                    if (date1.getFullYear == undefined || date2.getFullYear == undefined) return false;
                    if (date1.getTime() === date2.getTime()) return true;
                    return date1.getFullYear() === date2.getFullYear() &&
                        date1.getMonth() === date2.getMonth() &&
                        date1.getDate() === date2.getDate();
                }

                /**
                 * function that is called when the current date is null and the user clicks in the date input text
                 */
                scope.setInitialDate = function () {
                    var dt = scope.getdatevalue();
                    scope.setSelectedDateValue(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
                    scope.triggerDateChange(dt, false);
                }

                /**
                 * returns if the datepicker allows the user to clear the selected date to null
                 */
                scope.canClearDate = function () {
                    return scope.getcleartext != undefined;
                }

                /**
                 * get the text to display in the button to clear the selected date to null
                 */
                scope.getClearDateText = function () {
                    if (scope.getcleartext != undefined)
                        return scope.getcleartext();
                    return "";
                }

                /**
                 * get the title to display in the dropdown
                 */
                scope.getMonthYearTitle = function () {
                    return $filter("date")(scope.selecteddate, scope.yearview ? "yyyy" : "MMMM yyyy");
                }

                /**
                 * toggle between month view and year view
                 */
                scope.toggleYearView = function () {
                    $timeout(function () {
                        lastmonthyear = "";
                        scope.yearview = !scope.yearview;
                        scope.blocksize = scope.yearview ? scope.doubleblocksize : scope.defaultblocksize;
                        scope.makeCalendar(false);
                    }, 1);
                }

                /**
                 * function that is called when the user clicks the button the clear the selected date to null
                 */
                scope.setNoDate = function () {
                    scope.triggerDateChange(null, true);
                    scope.dropdownVisible = false;
                    scope.triggerDropdownChanged();
                }

                /**
                 * returns if the current date is null
                 */
                scope.isNoDateSelected = function () {
                    return scope.datevalue == null;
                }

                /**
                 * function to call when the selected date changes
                 */
                scope.triggerDateChange = function (newdate, userchange) {
                    if (scope.changedisabled) return;
                    if (scope.datechanged != undefined &&
                        (!scope.isSameDate(newdate, scope.datevalue) || (userchange && scope.triggersamedate)))
                        scope.datechanged({ date: newdate });
                    ngModelCtrl.$setViewValue(newdate); // this will trigger ng-change if the value passed is different than the one in ng-scope
                    scope.datevalue = newdate;
                }

                var lastdatevalid = false; // remembered return value for datevalid
                var lastdatetext = ""; // remember the last date so datevalid is not doing unnecesary work

                /**
                 * test and return if the current date is a valid date
                 */
                scope.datevalid = function () {
                    if (scope.yearvalue == null) {
                        var dt = scope.getdatevalue();
                        scope.setSelectedDateValue(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
                    }
                    if (scope.yearvalue + "-" + scope.monthvalue + "-" + scope.dayvalue === lastdatetext) return lastdatevalid;
                    lastdatetext = scope.yearvalue + "-" + scope.monthvalue + "-" + scope.dayvalue;
                    lastdatevalid = false;
                    var numYear = parseInt(scope.yearvalue);
                    var numMonth = parseInt(scope.monthvalue) - 1;
                    var numDay = parseInt(scope.dayvalue);
                    var d = new Date(numYear, numMonth, numDay);
                    if (numYear >= 1000 && d.getFullYear() === numYear && d.getMonth() === numMonth && d.getDate() === numDay) {
                        scope.selecteddate = d;
                        var firstdate = new Date(numYear, numMonth, 1);
                        if (!scope.firstweekday || scope.firstweekday === "" || isNaN(scope.firstweekday)) scope.firstweekday = 1;
                        while (firstdate.getDay() !== scope.firstweekday) firstdate.setDate(firstdate.getDate() - 1);
                        scope.firstdate = firstdate;
                        scope.makeCalendar(false);
                        if (!scope.dropdownVisible && !scope.isNoDateSelected()) scope.triggerDateChange(scope.selecteddate, false);
                        lastdatevalid = true;
                    }
                    return lastdatevalid;
                }

                /**
                 * return if a text string contains only valid characters defined in scope.validChars
                 */
                scope.containsValidChars = function (strValue) {
                    for (var i = 0; i < strValue.length; i++)
                        if (scope.validChars.indexOf(strValue.charAt(i)) < 0) return false;
                    return true;
                }

                /**
                 * watch on the value bound trough ng-model, to update the current values in the 3 textboxes for day, month, year
                 */
                scope.$watch("datevalue", function (value) {
                    var dt = value;
                    // test if the date is something else than a date object, for example a string
                    if (dt != undefined && dt.getFullYear == undefined)
                        dt = new Date("" + dt + (dt.charAt(dt.length - 1) !== "Z" ? "Z" : ""));
                    if (dt != undefined && dt.getFullYear != undefined)
                        scope.setSelectedDateValue(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
                });

                /**
                 * watch on the day input focus to validate the input
                 */
                scope.$watch("dayvalue", function (value) {
                    if (value !== "" && (isNaN(parseInt(value)) || !scope.containsValidChars(value)) || value.length > 2)
                        scope.dayvalue = scope.lastdayvalue; else scope.lastdayvalue = value;
                });

                /**
                 * watch on the month input focus to validate the input
                 */
                scope.$watch("monthvalue", function (value) {
                    if (value !== "" && (isNaN(parseInt(value)) || !scope.containsValidChars(value)) || value.length > 2)
                        scope.monthvalue = scope.lastmonthvalue; else scope.lastmonthvalue = value;
                });

                /**
                 * watch on the year input focus to validate the input
                 */
                scope.$watch("yearvalue", function (value) {
                    if (value !== "" && (isNaN(parseInt(value)) || !scope.containsValidChars(value)) || value.length > 4)
                        scope.yearvalue = scope.lastyearvalue; else scope.lastyearvalue = value;
                });

                /**
                 * when the directive is destroyed we need to remove attached html elements
                 */
                scope.$on("$destroy", function () {
                    // if the dropdownElement was attached to the body, we also need to remove it when the scope is destroyed
                    if (scope.dropdownElement) document.body.removeChild(scope.dropdownElement);
                });

                /**
                 * when the user clicks on the document behind the dropdown we close the dropdown.
                 * when the user clicks a date in the dropdown, we choose that date.
                 */
                $rootScope.$on("documentClicked", function (inner, target) {
                    if (!scope.dropdownVisible) return; // exit early if the dropdown is not visible
                    // clicked in date block?
                    if ($(target[0]).is(".date-block")) {
                        var idsplit = target[0].id.split("-");
                        var datevalue = parseInt(idsplit[idsplit.length - 1]); // extract datevalue out of the id
                        if (datevalue < 15) return;
                        var d = new Date(datevalue);
                        if (scope.yearview) {
                            scope.$apply(function () {
                                scope.setSelectedDateValue(d.getFullYear(), d.getMonth() + 1, d.getDate());
                                scope.toggleYearView();
                            });
                            return;
                        }
                        scope.triggerDateChange(d, true);
                        scope.$apply(function () {
                            scope.setSelectedDateValue(d.getFullYear(), d.getMonth() + 1, d.getDate());
                            scope.dropdownVisible = false;
                            scope.triggerDropdownChanged();
                        });
                        return;
                    }
                    // check if the clicked on control has a specific class or has any parent with that specific class, then do not close the dropdown
                    var parents = $(target[0]).parents();
                    for (var i = 0; i < parents.length; i++)
                        if (parents[i] === scope.triggerElement) return;
                    if (!$(target[0]).is(".ignore-document-clicked") &&
                        !(<any>($(target[0]).parents(".ignore-document-clicked").length) > 0))
                        scope.$apply(function () {
                            var dt = scope.getdatevalue();
                            scope.setSelectedDateValue(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
                            scope.dropdownVisible = false;
                            scope.triggerDropdownChanged();
                        });
                });

            }
        };
    }
];