import { StateParams } from '@uirouter/angularjs';
import { INumberService } from './../../shared/numberService';
import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';
import { IConfigurationService } from './../../shared/configurationService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import { ActivityType } from './../planboard/entities/activitytype';
import { Cooperation } from './../planboard/entities/cooperation';
import * as Globals from './../planboard/utils/globals';
import { TimeSpan } from './../planboard/utils/timespan';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import * as Constants from './../utils/constants';
import { Dictionary } from './../utils/dictionary';
import * as Timezone from './../utils/timezone';

export class ActivityPasteController {

    private date = new Date();

    rootActivity: any = null;
    selectedActivity: any = null;
    activityTree: Array<any> = [];
    emptyTree: object = Object.create(null);
    repetitionPeriods: Array<object>;
    repetitionDayOfMonth: Array<object>;
    selectedRepetitionPeriod = "1";
    copyToDate = new Date(this.date.getTime());
    copyUpToIncludingDate = new Date(this.date.getTime());
    copyCount = "1";
    copyToInvalidEnd = false;
    periodValue = "1";
    periodMonthSpecification = "1";
    periodWeekDaySpecification = "1";
    copyUpToIncludingInvalidDate = false; // indicates if the copied date is valid based on limit setting
    copyToInvalidDate = false; // indicates if the copied date is valid based on limit setting
    
    private commonSvc;
    private planboard = Planboard;
    private viewLoaded = false;
    private elActivityTree = $("#elActivityTree"); // TODO: find alternative to jquery
    private activityDict: object = null;
    private resourceDisplayNames: object = Object.create(null);
    private resourceDisplayNamesLoading = false;
    private resourceTypes: object = Object.create(null);
    private weekDays: Array<object> = [];
    private copyDateMaxDifferenceYears = null;

    private readonly dialogToken = "activityPaste";
    private readonly apiUrl = "api/Activities";
    private readonly urlGetResourceTypes = "api/ResourceTypes/ForPlanningBoard";
    private readonly resourceNotRequiredId: number = -2;

    static $inject = [
        "$scope",
        "$filter",
        "$stateParams",
        "$timeout",
        "numberService",
        "pageStartService",
        "translationService",
        "userService",
        "configurationService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $filter: ng.IFilterService,
        private $stateParams: StateParams,
        private $timeout: ng.ITimeoutService,
        private numberService: INumberService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService,
        private configurationService: IConfigurationService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.configurationService.getLimitSettings(() => {
            this.copyDateMaxDifferenceYears = this.configurationService.limitSettings.planboardCopyPasteMaxYearsDifference;
        });

        this.planboard.scenarioId = userService.getDisplaySettingNumber("planboard.scenarioId", this.planboard.scenarioId);

        this.repetitionPeriods = [
            { id: "1", name: this.$scope.textLabels.TIME_RANGE_TYPE_1 }, // days
            { id: "2", name: this.$scope.textLabels.TIME_RANGE_TYPE_2 }, // weeks
            { id: "3", name: this.$scope.textLabels.TIME_RANGE_TYPE_3 }, // months
            { id: "4", name: this.$scope.textLabels.TIME_RANGE_TYPE_4 }, // years
            { id: "5", name: this.$scope.textLabels.DAY_OF_THE_MONTH } // day of the month
        ];
        this.repetitionDayOfMonth = [
            { id: "1", name: this.$scope.textLabels.COPY_FIRST },
            { id: "2", name: this.$scope.textLabels.COPY_SECOND },
            { id: "3", name: this.$scope.textLabels.COPY_THIRD },
            { id: "4", name: this.$scope.textLabels.COPY_FOURTH },
            { id: "5", name: this.$scope.textLabels.COPY_LAST }
        ];

        this.$scope.$on("$viewContentLoaded", () => {
            this.$timeout(() => { this.viewLoaded = true; }, 100);
        });

        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);
        this.commonSvc.start(() => { this.loadData(); });
    }

    private loadData(): void {
        // make sure the planboard has loaded all activityTypes
        if (this.planboard.activityTypes == null || this.planboard.activityTypes.count <= 0)
            this.planboard.readActivityTypes();

        // boolean for planboard active
        var planboardActive = this.planboard.activities != null && this.planboard.controller != null;

        // try to get the activity structure from the planboard
        var activityTree = planboardActive
            ? this.activityArrayToTree(this.planboard.getAllActivitiesInGroup(this.$stateParams.activityId))
            : [];

        this.activityTree = activityTree;
        this.rootActivity = this.activityTree.length > 0 ? this.activityTree[0] : null;
        this.selectedActivity = this.rootActivity;

        // if unable to get data from the planboard, then query the webapi
        if (this.rootActivity == null)
            this.loadGroupFromWebApi();

        // load all resourcetypes from the webapi
        this.resourceTypes[-1] = this.newTreeItem(-1, this.$scope.textLabels.FILTER_NONE, -1, true);
        this.commonSvc.loadData(this.urlGetResourceTypes, this.resourceTypes, null, null, true, false);

        // load all resource cooperations if not already loaded
        if (this.planboard.resourceCooperations.count === 0)
            this.loadResourceCooperationsFromWebApi();

        // sort the activity tree by the activity types' display names
        this.activityTree = this.sortActivityTree(this.activityTree);
        this.commonSvc.onAllDataLoaded(() => { this.activityTree = this.sortActivityTree(this.activityTree) });

        // set destination date
        if (this.$stateParams.pasteDate && this.$stateParams.pasteDate.getTime) {
            this.copyToDate = new Date(this.$stateParams.pasteDate.getTime());
            this.copyUpToIncludingDate = new Date(this.$stateParams.pasteDate.getTime());

            this.copyToInvalidDate = this.copyToDate.getFullYear() - new Date().getFullYear() > this.copyDateMaxDifferenceYears;
            this.copyUpToIncludingInvalidDate = this.copyUpToIncludingDate.getFullYear() - new Date().getFullYear() > this.copyDateMaxDifferenceYears;
        }
    }

    userHasPermission(permissionName: string): boolean {
        return this.pageStartService.userHasPermission(permissionName, this.$scope, null);
    }

    getIsDisabled(): boolean {
        return !this.userHasPermission("EditActivity");
    }

    getResourceTypesForSelectedActivity(): object {
        // get activity type object
        var actType = this.selectedActivity == null ? null : this.getActivityType(this.selectedActivity.activityTypeId);
        // set selectable property of each item in the tree
        for (var key in this.resourceTypes)
            this.resourceTypes[key].selectable = actType != null && actType.resourceTypeIdList.indexOf(Number(key)) >= 0;
        // the filter_none option is not usable if there is only one resourceType to select
        this.resourceTypes[-1].selectable = actType != null && actType.resourceTypeIdList.length !== 1;
        // select default if nothing is selected and there is only one to select
        this.getSelectedActivityResourceTypeId();
        return this.resourceTypes;
    }

    getDefaultResourceTypeText(): string {
        var resourceTypeId = this.getSelectedActivityResourceTypeId();
        var resourceType = this.resourceTypes[resourceTypeId];
        return resourceType == null ? "" : resourceType.displayName;
    }

    getResourceTreeForActivity(node: any): object {
        if (!this.isLeafActivity(node)) return this.emptyTree;
        var activity = this.activityDict[node.id];
        if (activity == undefined) return this.emptyTree;
        var loadForResourceTypeId = this.getActivityResourceTypeId(activity, false);
        if (activity.resourceTree == undefined || activity.availableResourcesForTypeId !== loadForResourceTypeId) {
            activity.availableResourcesForTypeId = loadForResourceTypeId;
            activity.availableResourcesLoaded = false;
            activity.resourceTree = Object.create(null);
            var dayDiff = TimeSpan.getDayNr(activity.endDate) - TimeSpan.getDayNr(activity.startDate);
            var copyAct = this.createActivityNodeCopy(activity);
            copyAct.id = -1; // let the ForActivity route know that it is not yet an existing activity
            copyAct.startDate = new Date(this.copyToDate.getFullYear(), this.copyToDate.getMonth(), this.copyToDate.getDate(), activity.startDate.getHours(), activity.startDate.getMinutes(), 0, 0);
            copyAct.endDate = new Date(this.copyToDate.getFullYear(), this.copyToDate.getMonth(), this.copyToDate.getDate(), activity.endDate.getHours(), activity.endDate.getMinutes(), 0, 0);
            if (dayDiff !== 0) copyAct.endDate.setDate(copyAct.endDate.getDate() + dayDiff);

            // add special choices if the activity is not a daymark
            var actType = this.getActivityType(activity.activityTypeId);
            if (actType && actType.categoryId !== ActivityType.daymarkCategoryId) {
                // add empty resource
                activity.resourceTree[-1] = this.newTreeItem(-1, this.$scope.textLabels.FILTER_NONE, 0, undefined);
                // add not required
                activity.resourceTree[this.resourceNotRequiredId] = this.newTreeItem(this.resourceNotRequiredId, this.$scope.textLabels.NOT_REQUIRED, 1, undefined);
            }

            // add current resource (might be replaced if it is also in the received array, that is okay)
            if (activity.resourceId != null && activity.resourceId > 0)
                activity.resourceTree[activity.resourceId] =
                    this.newTreeItem(activity.resourceId, this.getDefaultResourceText(activity), 1);
            this.commonSvc.post("api/Resources/ForActivity", this.planboard.prepareActivityForWebApi(copyAct),
                (success) => {
                    activity.availableResourcesLoaded = true;
                    var resourceOrder = 2;
                    // add preferred(+skilled) and skilled and unskilled parent nodes, using OMRP.Globals.maxInt + x for an unique node Id.
                    activity.resourceTree[Globals.maxInt] =
                        this.newTreeItem(Globals.maxInt, this.$scope.textLabels.RESOURCES_PREFERRED, resourceOrder++, false);
                    activity.resourceTree[Globals.maxInt + 1] =
                        this.newTreeItem(Globals.maxInt, this.$scope.textLabels.RESOURCES_SKILLED, resourceOrder++, false);
                    activity.resourceTree[Globals.maxInt + 2] =
                        this.newTreeItem(Globals.maxInt + 1, this.$scope.textLabels.RESOURCES_UNSKILLED, resourceOrder++, false);
                    activity.resourceTree[Globals.maxInt].open = true;
                    activity.resourceTree[Globals.maxInt + 1].open = true;
                    // receiving two arrays in response.data, first is skilled resources, second is unskilled resources
                    if (success && success.data && success.data.length > 0)
                        for (var i = 0; i < success.data.length; i++) {
                            var resourceList = success.data[i];
                            if (resourceList && resourceList.length > 0)
                                for (var j = 0; j < resourceList.length; j++) {
                                    var resource = resourceList[j];
                                    resource.order = resourceOrder++;
                                    resource.parentId = Globals.maxInt + 1 + i;
                                    resource.visible = true;
                                    activity.resourceTree[resource.id] = resource;
                                }
                        }
                    this.applyResourceCooperations(activity);
                    activity.itemVersion++; // to refresh the dropdownTree
                },
                null, false);
        } else {
            this.applyResourceCooperations(activity);
        }
        return activity.resourceTree;
    }

    getDefaultResourceText(node: any): string {
        if (node.resourceId == null || node.resourceId < 0) {
            if (node.status == Constants.StatusActivityNotRequired)
                return this.$scope.textLabels.NOT_REQUIRED;
            return "";
        }

        // try to get name from $scope.resourceDisplayNames
        var name = this.resourceDisplayNames[node.resourceId];
        if (name != undefined) return name;

        // try to get name from planboard memory
        if (this.planboard.activities) {
            var resource = this.planboard.activities.getResource(node.resourceId, true);
            if (resource)
                if (resource.displayName !== "") return resource.displayName;
                else return this.$scope.textLabels.RESOURCE_ASSIGNED_PLACEHOLDER;
        }

        // request specific resources from webApi if not already doing so
        if (!this.resourceDisplayNamesLoading) this.loadResourceDisplayNames();

        return "";
    }

    selectActivity(node: any): void {
        this.selectedActivity = node;
    }

    isSelectedActivity(node: any): boolean {
        if (node == null || this.selectedActivity == null) return false;
        return node.id === this.selectedActivity.id;
    }

    isLeafActivity(node: any): boolean {
        var actType = node == null ? null : this.getActivityType(node.activityTypeId);
        return actType == null ? false : actType.resourceTypeIdList.length > 0;
    }

    showResourceCell(node: any): boolean {
        if (this.activityIsDayMark(node) && node.nodes.length !== 0) {
            return false
        }
        else {
            return this.isLeafActivity(node);
        }
    }

    activityIsDayMark(node: any): boolean {
        if (!node) return false;
        var activity = this.activityDict[node.id];
        if (!activity) return false;
        var actType = this.getActivityType(activity.activityTypeId);
        if (!actType) return false;
        return actType.categoryId === ActivityType.daymarkCategoryId;
    }

    getActivityBackColor(node: any): string {
        var actType = this.getActivityType(node.activityTypeId);
        return this.toHtmlColor(actType == null ? "" : actType.backColor);
    }

    getActivityTextColor(node: any): string {
        var actType = this.getActivityType(node.activityTypeId);
        return this.toHtmlColor(actType == null ? "" : actType.textColor);
    }

    getActivityShortName(node: any): string {
        var actType = this.getActivityType(node.activityTypeId);
        return actType == null ? "" : actType.shortText;
    }

    getActivityDisplayName(node: any): string {
        var actType = this.getActivityType(node.activityTypeId);
        return actType == null ? "" : actType.displayName;
    }

    filterTextValue($event: any, oldValue: string, allowDecimal: boolean): void {
        this.numberService.filterTextValue($event, oldValue, allowDecimal, 4);
    }

    toPreviousState(): void {
        this.$timeout(() => { window.history.back(); }, 100);
    }

    getTitle(): string {
        if (this.rootActivity != null) {
            var actType = this.getActivityType(this.rootActivity.activityTypeId);
            return this.$scope.textLabels.MENU_PASTE + (actType != null ? ": " + actType.displayName : "");
        }
        return "";
    }

    showActivityDisplayname(): boolean {
        if (!this.viewLoaded || !this.elActivityTree || !this.elActivityTree[0]) return true;
        return this.elActivityTree[0].offsetWidth > 500;
    }

    getWeekNrText(): string {
        return this.$scope.textLabels.WEEK.toLowerCase();
    }

    isDayOfMonthPeriod(): boolean {
        return parseInt(this.selectedRepetitionPeriod) === 5;
    }

    getWeekDays(): object {
        if (this.weekDays.length === 0) {
            var weekStart = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
            while (weekStart.getDay() !== 1) weekStart.setDate(weekStart.getDate() - 1);
            for (var i = 1; i <= 7; i++) {
                this.weekDays.push({
                    id: i.toString(),
                    name: this.$filter("date")(weekStart, "EEEE")
                });
                weekStart.setDate(weekStart.getDate() + 1);
            }
        }
        return this.weekDays;
    }

    calculateCopyUpToDate(copyToDate: Date): void {
        var copyCount = parseInt(this.copyCount);
        var periodValue = parseInt(this.periodValue);
        if (isNaN(copyCount) || isNaN(periodValue)) return;
        if (copyCount < 1) { copyCount = 1; this.copyCount = "1"; }
        if (periodValue < 1) { periodValue = 1; this.periodValue = "1"; }
        if (copyToDate == null) copyToDate = this.copyToDate; // use scope variable if nothing was specified
        var upToIncluding = new Date(copyToDate.getTime()); // clone copyToDate

        switch (parseInt(this.selectedRepetitionPeriod)) {
            case 1: // days
                upToIncluding.setDate(upToIncluding.getDate() + (copyCount - 1) * periodValue);
                break;
            case 2: // weeks
                upToIncluding.setDate(upToIncluding.getDate() + (copyCount - 1) * 7 * periodValue);
                break;
            case 3: // months
                upToIncluding.setMonth(upToIncluding.getMonth() + (copyCount - 1) * periodValue);
                break;
            case 4: // years
                upToIncluding.setFullYear(upToIncluding.getFullYear() + (copyCount - 1) * periodValue);
                break;
            case 5: // day of the month
                upToIncluding = this.dayOfMonthLoop(copyToDate, copyCount, null).upToIncluding;
                break;
            default:
                return;
        }

        this.copyUpToIncludingDate = upToIncluding;
        this.copyToInvalidEnd = false;
        this.copyUpToIncludingInvalidDate = this.copyUpToIncludingDate.getFullYear() - new Date().getFullYear() > this.copyDateMaxDifferenceYears;
    }

    onCopyToDateChanged(date: Date): void {
        this.calculateCopyUpToDate(date);
        this.copyToInvalidDate = date.getFullYear() - new Date().getFullYear() > this.copyDateMaxDifferenceYears;
    }

    onCopyCountChanged(): void {
        this.calculateCopyUpToDate(this.copyToDate);
    }

    onCopyUpToIncludingDateChanged(date: Date): void {
        this.copyToInvalidEnd = this.copyToDate && date && TimeSpan.getDayNr(date) < TimeSpan.getDayNr(this.copyToDate);
        this.copyUpToIncludingInvalidDate = date.getFullYear() - new Date().getFullYear() > this.copyDateMaxDifferenceYears;
        if (this.copyToInvalidEnd) this.copyCount = "0";
        else this.calculateCopyCount(date);
    }

    doPaste(): void {
        var isCutAction = this.userService.getUserVariable("planboard.isCutAction") == true;
        this.userService.setUserVariable("planboard.isCutAction", false); // cut action is only once, multiple pastes create additional copies

        // create copies of the activities to paste, including new dates
        var activities = this.addActivityToPostObject(this.activityTree[0], this.copyToDate);

        // construct the copy parameters
        var copyParams = {
            copyToDate: Timezone.dateToStr(this.copyToDate),
            copyUpToIncludingDate: Timezone.dateToStr(this.copyUpToIncludingDate),
            selectionStartDate: Timezone.dateToStr(this.rootActivity.startDate),
            periodType: parseInt(this.selectedRepetitionPeriod), // see CopyPeriodType enum
            periodEvery: parseInt(this.periodValue), // for example, every 3 days
            dayOfMonthSpecification: {
                weekOfMonth: parseInt(this.periodMonthSpecification), // see WeekOfMonth enum
                dayOfWeek: parseInt(this.periodWeekDaySpecification) % 7 // see DayOfWeek enum (% 7 to convert sunday to 0)
            },
            repeat: Math.max(parseInt(this.copyCount), 1)
        }

        // construct the action DTO
        var actionDto = {
            originActivityIdForDeletion: isCutAction ? this.rootActivity.id : null,
            activityDTO: activities,
            pasteParams: copyParams
        }

        this.commonSvc.post(this.apiUrl + "/Paste", actionDto,
            (response) => { this.showActionResult(response); },
            (response) => { this.showActionResult(response); }, true);

        this.toPreviousState();
    }

    private loadGroupFromWebApi(): void {
        var id = this.rootActivity ? this.rootActivity.id : this.$stateParams.activityId;
        this.commonSvc.loadData(this.apiUrl + "/GetActivityGroup/" + id, null,
            (success) => {
                if (success && success.data && success.data.length > 0) {
                    if (this.planboard.activities != null && this.planboard.controller != null) {
                        // also refresh this group in planboard memory
                        this.planboard.removeActivityGroupFromMemory(success.data[0].id, false);
                        this.planboard.addActivitiesToMemory(success.data, true);
                        this.planboard.activities.checkFilled(success.data[0].id);
                        this.planboard.timedRefresh();
                    }

                    this.activityTree = this.activityArrayToTree(success.data);
                    this.rootActivity = this.activityTree.length > 0 ? this.activityTree[0] : null;
                    this.selectedActivity = this.rootActivity;
                }
            },
            null, true, false);
    }

    private loadResourceCooperationsFromWebApi(): void {
        // clear and add dummy, so we can test if this dictionary is filled
        this.planboard.resourceCooperations.clear();
        this.planboard.resourceCooperations.add(0, null); // empty object for resource with id 0

        this.commonSvc.loadData("api/Resources/Cooperations", null,
            (success) => {
                var item = null, list = null, resourceId = 0, otherResourceId = 0;
                if (success && success.data && success.data.length > 0)
                    for (var step = 1; step <= 2; step++) // once for resourceId => otherResourceId and once the other way around
                        for (var i = 0; i < success.data.length; i++) {
                            item = success.data[i];
                            resourceId = step == 1 ? item.resourceId : item.otherResourceId;
                            otherResourceId = step == 1 ? item.otherResourceId : item.resourceId;
                            var dict = this.planboard.resourceCooperations.value(resourceId);
                            if (dict == undefined) {
                                dict = new Dictionary();
                                this.planboard.resourceCooperations.add(resourceId, dict);
                            }
                            dict.add(otherResourceId, new Cooperation(resourceId, otherResourceId, item.value));
                        }
            },
            null, true, false);
    }

    private loadResourceDisplayNames(): void {
        this.resourceDisplayNamesLoading = true;
        if (!this.userService.isAuthenticated) return; // do not post request if it is known that we are not authenticated
        var resourceSelection = { idList: [] }
        if (this.activityDict != null)
            for (var key in this.activityDict) {
                var activity = this.activityDict[key];
                if (activity != null && activity.resourceId != null && activity.resourceId > 0)
                    resourceSelection.idList.push(this.activityDict[key].resourceId);
            }
        if (resourceSelection.idList.length > 0)
            this.commonSvc.post("api/Resources/WithId", resourceSelection,
                (success) => {
                    // add empty strings for all the requested resources so that this will not be asked again
                    for (var i = resourceSelection.idList.length - 1; i >= 0; i--)
                        if (this.resourceDisplayNames[resourceSelection.idList[i]] == undefined)
                            this.resourceDisplayNames[resourceSelection.idList[i]] = "";
                    // now add the names of resources that we actually received
                    if (success && success.data && success.data.length && success.data.length > 0)
                        for (var i = 0; i < success.data.length; i++)
                            this.resourceDisplayNames[success.data[i].id] = success.data[i].displayName;
                    this.resourceDisplayNamesLoading = false;
                },
                null, false);
    }

    private activityArrayToTree(activities: Array<any>): Array<any> {
        var activityTree = [];
        var activityDict = Object.create(null);
        var i = 0;

        // first make copies of all activities and add them to activityDict
        for (i = 0; i < activities.length; i++) {
            var originalActivity = activities[i];
            // date might be in string format if just received from webApi
            if (originalActivity.startDate.getFullYear == undefined || originalActivity.endDate.getFullYear == undefined) {
                originalActivity.startDate = this.stringToDate(originalActivity.startDate);
                originalActivity.endDate = this.stringToDate(originalActivity.endDate);
            }
            var copyAct = this.createActivityNodeCopy(originalActivity);
            activityDict[copyAct.id] = copyAct;
        }

        //we want to replace the resource only in case 
        //- we have a target resource to copy on and 
        //- the activity is either not a daymark 
        //- or is a simple daymark (not nested) 
        //- which already has the target resource to copy on already assigned to it 
        var actType = this.getActivityType(activities[0].activityTypeId);
        
        if (this.$stateParams.resourceId > 0 &&
            (actType.categoryId !== ActivityType.daymarkCategoryId || 
            activities[0].activityTypeId !== activityDict[this.$stateParams.activityId].activityTypeId ||
            !activities.some(obj => obj.resourceId === this.$stateParams.resourceId))
        ) {
            var activity = activities.find(activity => activity.id === this.$stateParams.activityId)
            if (activity != null)
                activityDict[activity.id].resourceId = this.$stateParams.resourceId;
        }

        // next add to eachothers nodes list
        for (i = 0; i < activities.length; i++) {
            var act = activityDict[activities[i].id];
            if (act.parentId != null && act.parentId > 0) {
                var parent = activityDict[act.parentId];
                if (parent != undefined) { // add to parents nodes array
                    act.parentNode = parent;
                    parent.nodes.push(act);
                } else
                    activityTree.push(act); // parent could not be found, treat this activity as a root
            } else
                activityTree.push(act); // this is a root activity
        }
        this.activityDict = activityDict;
        return activityTree;
    }

    private createActivityNodeCopy(originalActivity: any): any {
        var copyAct = Object.create(null);
        // copy all properties
        this.copyObjectProperties(originalActivity, copyAct);
        copyAct.startDate = new Date(copyAct.startDate.getTime()); // make a new object for startDate instead of referencing the one in originalActivity
        copyAct.startDateCopy = new Date(copyAct.startDate.getTime()); // copy startDate for datepicker
        copyAct.endDate = new Date(copyAct.endDate.getTime()); // make a new object for endDate instead of referencing the one in originalActivity
        copyAct.endDateCopy = new Date(copyAct.endDate.getTime()); // copy endDate for datepicker
        copyAct.startTimeStr = this.dateToDisplayStr(copyAct.startDate, false, true); // duplicate start time component for text input
        copyAct.endTimeStr = this.dateToDisplayStr(copyAct.endDate, false, true); // duplicate end time component for text input
        copyAct.nodes = []; // array with child nodes
        copyAct.originalResourceId = originalActivity.resourceId; // store the original resourceId
        copyAct.originalActivity = originalActivity; // also store the original activity
        copyAct.availableResourcesLoaded = false; // indicates if the list of available resources have finished loading (for spinner)
        copyAct.availableResourcesForTypeId = undefined; // indicates for what resourceTypeId the list of available resources have been loaded
        // if the status of the activity is "not required" then set the resourceId so it matches the "not required" item in the dropdown list
        if (copyAct.status == Constants.StatusActivityNotRequired) copyAct.resourceId = this.resourceNotRequiredId;
        else if (copyAct.resourceId === this.resourceNotRequiredId) copyAct.resourceId = null;
        copyAct.itemVersion = 0; // used to refresh the dropdownTree after a list of resources have been received
        return copyAct;
    }

    private copyObjectProperties(leadingObject: object, copyToObject: object): void {
        for (var key in leadingObject)
            if (Object.prototype.hasOwnProperty.call(leadingObject, key))
                copyToObject[key] = leadingObject[key];
    }

    private stringToDate(dateStr: any): Date {
        if (dateStr.getFullYear == undefined) {
            dateStr = new Date("" + dateStr + (dateStr.charAt(dateStr.length - 1) !== "Z" ? "Z" : ""));
            dateStr = new Date(dateStr.getTime() + dateStr.getTimezoneOffset() * 60000);
        }
        return dateStr;
    }

    private dateToDisplayStr(dt: Date, includeDate: boolean, includeTime: boolean): string {
        return includeDate ? this.$filter("date")(dt, "EEE") + " " +
            this.$filter("date")(dt, "mediumDate").replace(dt.getFullYear().toString(), "").replace(",", "").replace(".", "").trim() +
            (includeTime ? " " + this.$filter("date")(dt, "HH:mm") : "")
            : this.$filter("date")(dt, "HH:mm");
    }

    private newTreeItem(id: number, name: string, order: number, selectable: boolean = null): object {
        return { id: id, displayName: name, order: order, selectable: selectable, visible: true }
    }

    private sortActivityTree(tree: Array<any>): Array<any> {
        // only sort if there is anything to sort
        if (this.planboard.activityTypes == null || this.planboard.activityTypes.count <= 0) return;

        tree = this.$filter("orderBy")(tree,
            (item) => {
                return this.planboard.activityTypes.getObject(item.activityTypeId).sortOrder;
            });

        for (var i = 0; i < tree.length; i++) {
            if (tree[i].nodes) tree[i].nodes = this.sortActivityTree(tree[i].nodes);
        }

        return tree;
    }

    private toHtmlColor(colorString: string): string {
        if (colorString === "" || colorString == undefined) return "transparent";
        return colorString.charAt(0) === "#" ? colorString : "#" + colorString;
    }
    
    private getActivityType(id: number): any {
        if (this.planboard.activityTypes == null) return null;
        return this.planboard.activityTypes.getObject(id);
    }

    private getActivityResourceTypeId(activity: any, update: boolean): number {
        if (activity == null) return -1;
        // convert null to -1 for the activities resourceTypeId
        if (activity.resourceTypeId == null) {
            activity.resourceTypeId = -1;
            activity.originalActivity.resourceTypeId = -1; // also for the original so this will not trigger a send to webapi change
        }
        // if there is only one selectable item for the activity type and none is selected, then select that one item
        if (activity.resourceTypeId < 0) {
            var actType = this.getActivityType(activity.activityTypeId);
            if (actType != null && actType.resourceTypeIdList.length === 1) {
                if (!update) return actType.resourceTypeIdList[0];
                activity.resourceTypeId = actType.resourceTypeIdList[0];
                activity.originalActivity.resourceTypeId = actType.resourceTypeIdList[0]; // also for the original so this will not trigger a send to webapi change
            }
        }
        return activity.resourceTypeId;
    }

    private getSelectedActivityResourceTypeId(): number {
        return this.getActivityResourceTypeId(this.selectedActivity, true);
    }

    private applyResourceCooperations(activity: any): void {
        if (this.planboard.resourceCooperations.count <= 1) return; // there are no cooperations
        var preferredId = Globals.maxInt; // category id of the preferred list in the dropdowntree
        var skilledId = Globals.maxInt + 1; // category id of the skilled list in the dropdowntree

        // make an array with all planned resource Ids on this group
        var resourceIdList = [];
        for (var activityId in this.activityDict) {
            var act = this.activityDict[activityId];
            if (act.resourceId != null && act.resourceId > 0)
                resourceIdList.push(act.resourceId);
        }

        // loop over all candidates in resourceTree
        for (var resourceId in activity.resourceTree) {
            var item = activity.resourceTree[resourceId];
            if (item.id <= 0 || item.id >= preferredId) continue; // only for resource items
            // make the item visible again if it was hidden because of cooperation avoidance
            item.visible = true;
            // move the item back from preferred to skilled
            if (item.parentId === preferredId) item.parentId = skilledId;
            // apply cooperations
            var cooperations = this.planboard.resourceCooperations.value(item.id);
            if (cooperations == undefined) continue; // there are no cooperations for this resource item
            cooperations.forEach((key, cooperation) => {
                if (resourceIdList.indexOf(cooperation.otherResourceId) >= 0) {
                    // preferred: move this item from skilled to preferred
                    if (cooperation.value === 1 && item.parentId === skilledId)
                        item.parentId = preferredId;
                    // avoid cooperation: make this item invisible
                    if (cooperation.value === 2)
                        item.visible = false;
                }
            });
        }
    }

    /**
     * Calculate the last date and number of copies, depending on what input parameter was supplied
     * In case copyCount is specified and maxDate is null: determine the last date where the last copy will be placed
     * In case copyCount is null and maxDate is specified: determine the number of copies that can be placed between copyToDate and maxDate (inclusive)
     * In case both copyCount and maxDate are null: return 0 for copyCount and upToIncluding equals copyToDate
     */
    private dayOfMonthLoop(copyToDate: Date, copyCount: number, maxDate:Date): any {
        var upToIncluding = new Date(copyToDate.getFullYear(), copyToDate.getMonth(), 1); // first date of the month
        var monthSpec = parseInt(this.periodMonthSpecification); // value 1-5: every first/second/third/fourth/last weekday of the month
        var weekDaySpec = parseInt(this.periodWeekDaySpecification); // value 1-7: weekday of the month, monday/tuesday/etc...
        var occurancePerMonth = 0; // how many times has a day of the week been seen during the month
        var nrOfCopies = 0; // number of copies counted
        var lastMatchingDate = null; // the last mathcing day of the week during the month

        if (weekDaySpec === 7) weekDaySpec = 0; // sunday = 0 for the date.getDay function

        while ((copyCount == null || nrOfCopies < copyCount) && // copyCount has a value, continue until nrOfCopies has reached this value
            (maxDate == null || upToIncluding <= maxDate) && // maxDate has a value, continue until upToIncluding has reached this date
            (copyCount != null || maxDate != null)) { // neither has a value, skip the while loop
            if (upToIncluding.getDay() === weekDaySpec) { // day of the week matches
                lastMatchingDate = new Date(upToIncluding.getTime()); // remember the matching date in case we need to paste on the last week of the month
                occurancePerMonth++;
                // only include if upToIncluding is on or after the date where pasting begins (=copyToDate)
                if (occurancePerMonth === monthSpec && monthSpec < 5 && upToIncluding >= copyToDate)
                    nrOfCopies++;
            }
            if (copyCount == null || nrOfCopies < copyCount) {
                upToIncluding.setDate(upToIncluding.getDate() + 1); // increment upToIncluding to the next day
                if (upToIncluding.getDate() === 1) { // next month reached
                    if (monthSpec === 5) { // last weekday of the month
                        if (lastMatchingDate >= copyToDate) // only include if lastMatchingDate is on or after the date where pasting begins (=copyToDate)
                            nrOfCopies++;
                        if (copyCount != null && nrOfCopies >= copyCount) // last copy has just been pasted -> adjust to lastMatchingDate
                            upToIncluding = lastMatchingDate;
                    }
                    occurancePerMonth = 0; // reset number of matching weekDays for next month
                }
            }
        }
        return {
            copyCount: nrOfCopies,
            upToIncluding: upToIncluding
        }
    }

    private calculateCopyCount(copyUpToIncludingDate: Date): void {
        var periodValue = parseInt(this.periodValue);
        if (periodValue < 1) { periodValue = 1; this.periodValue = "1"; }
        var copyCount = 1;

        switch (parseInt(this.selectedRepetitionPeriod)) {
            case 1: // days
                var fromDayNr = TimeSpan.getDayNr(this.copyToDate);
                var toDayNr = TimeSpan.getDayNr(copyUpToIncludingDate);
                copyCount = 1 + Math.floor((toDayNr - fromDayNr) / periodValue);
                break;
            case 2: // weeks
                var fromDayNr = TimeSpan.getDayNr(this.copyToDate);
                var toDayNr = TimeSpan.getDayNr(copyUpToIncludingDate);
                copyCount = 1 + Math.floor((toDayNr - fromDayNr) / (periodValue * 7));
                break;
            case 3: // months
                var fromMonthNr = this.copyToDate.getMonth() + this.copyToDate.getFullYear() * 12;
                var toMonthNr = copyUpToIncludingDate.getMonth() + copyUpToIncludingDate.getFullYear() * 12;
                copyCount = 1 + Math.floor((toMonthNr - fromMonthNr) / periodValue);
                break;
            case 4: // years
                var fromYearNr = this.copyToDate.getFullYear();
                var toYearNr = copyUpToIncludingDate.getFullYear();
                copyCount = 1 + Math.floor((toYearNr - fromYearNr) / periodValue);
                break;
            case 5: // day of the month
                copyCount = this.dayOfMonthLoop(this.copyToDate, null, copyUpToIncludingDate).copyCount;
                break;
            default:
                return;
        }

        this.copyCount = copyCount.toString();

        // replace the copy up to date with an exact value
        this.$timeout(() => { this.calculateCopyUpToDate(this.copyToDate); }, 0);
    }

    private showActionResult(response: any): void {
        var text = "";
        // loop over all failure reasons
        if (response.data && response.data.failureReasons) {
            for (var i = 0; i < response.data.failureReasons.length; i++) {
                var reason = response.data.failureReasons[i];
                var reasonText = this.$scope.textLabels["MULTISELECT_ACTION_FAILED_" + reason.toString()];
                if (reasonText) text += "\n" + reasonText;
            }
        } else if (response.data && response.data.statusText && response.data.statusText !== "")
            text += "\n" + response.data.statusText;

        var successCount = response.data.successfulActivities;
        var failedCount = response.data.failedActivities;
        var isFailureReasonResourceSkill = true;
        var activityIds = [];
        if (successCount > 0) {
            if (text !== "") text += "\n\n";
            text += this.$scope.textLabels.MULTISELECT_SUCCESS_COUNT + ": " + successCount.toString();
            if (response.data.addedActivityIds)
                for (var i = 0; i < response.data.addedActivityIds.length; i++)
                    activityIds.push(response.data.addedActivityIds[i]);
            if (response.data.removedActivityIds)
                for (var i = 0; i < response.data.removedActivityIds.length; i++)
                    this.planboard.removeActivityGroupFromMemory(response.data.removedActivityIds[i], false);
        }
        if (failedCount > 0) {
            if (text !== "") text += "\n\n";
            text += this.$scope.textLabels.MULTISELECT_FAILED_COUNT + ": " + failedCount.toString();
            // loop over all individual failure reasons
            if (response.data && response.data.datesForFailures) {
                for (var reasonId in response.data.datesForFailures) {
                    if (reasonId != Constants.activityActionFailureResourceNotSkilled.toString())
                        isFailureReasonResourceSkill = false;
                    var reasonText = this.$scope.textLabels["MULTISELECT_ACTION_FAILED_" + reasonId.toString()];
                    if (reasonText) text += "\n• " + reasonText;
                    else continue;
                    var dates = response.data.datesForFailures[reasonId];
                    for (var i = 0; i < dates.length; i++)
                        text += " " + this.$filter("date")(dates[i], "mediumDate") + ".";
                }
            }
        }

        if (failedCount <= 0 && successCount >= 0) {
            this.planboard.readActivitiesWithRootIds(activityIds);
            this.planboard.timedRefresh();
        }
        else {
            var title = this.$scope.textLabels.MENU_PASTE;
            var postData = response.config.data;
            var postDataIsArray = postData.length != undefined && postData.length > 0;
            var alreadyRetried =
                (postDataIsArray
                    ? postData[0].activityDTO.ignoreResourceRestrictions
                    : postData.activityDTO.ignoreResourceRestrictions) ||
                (postDataIsArray
                    ? postData[0].activityDTO.ignoreSkillCheck
                    : postData.activityDTO.ignoreSkillCheck);

            if (!alreadyRetried) {
                // refresh for success copies
                if (activityIds.length > 0) {
                    this.planboard.readActivitiesWithRootIds(activityIds);
                    this.planboard.timedRefresh();
                }

                // retry only on specific dates, the dates where the copy failed with restrictions
                postData.pasteParams.retryDates = [];
                if (response.data && response.data.datesForFailures) {
                    for (var reasonId in response.data.datesForFailures) {
                        var dates = response.data.datesForFailures[reasonId];
                        for (var i = 0; i < dates.length; i++) {
                            postData.pasteParams.retryDates.push(dates[i]);
                        }
                    }
                }

                this.commonSvc.showYesNoDialog(title, text + "\n\n" + this.$scope.textLabels.ACTIVITY_SAVE_LESS_CHECKS,
                    () => {
                        // yes action: retry with less restrictions
                        if (postDataIsArray) {
                            for (var i = 0; i < postData.length; i++) {
                                isFailureReasonResourceSkill
                                    ? postData[i].activityDTO.ignoreSkillCheck = true
                                    : postData[i].activityDTO.ignoreResourceRestrictions = true;
                            }
                        } else {
                            isFailureReasonResourceSkill
                                ? postData.activityDTO.ignoreSkillCheck = true
                                : postData.activityDTO.ignoreResourceRestrictions = true;
                        }
                        // Retry paste
                        this.commonSvc.post(this.apiUrl + "/Paste", postData,
                            (response) => { this.showActionResult(response); },
                            (response) => { this.showActionResult(response); }, true);

                    },
                    () => {
                        // no action: do not retry
                        this.commonSvc.showDialog(this.$scope.textLabels.MENU_PASTE, text, this.$scope.textLabels.OK,
                            () => {
                                this.planboard.readActivitiesWithRootIds(activityIds);
                                this.planboard.timedRefresh();
                            });
                    });
            } else {
                this.commonSvc.showDialog(this.$scope.textLabels.MENU_PASTE, text, this.$scope.textLabels.OK,
                    () => {
                        this.planboard.readActivitiesWithRootIds(activityIds);
                        this.planboard.timedRefresh();
                    });
            }
        }
    }

    private copyActivityWithDateAndResource(activity: any, newDate: Date): any {
        var copyAct = this.createActivityNodeCopy(activity);
        var dayDiff = TimeSpan.getDayNr(activity.endDate) - TimeSpan.getDayNr(activity.startDate);
        copyAct.startDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), activity.startDate.getHours(), activity.startDate.getMinutes(), 0, 0);
        copyAct.endDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), activity.endDate.getHours(), activity.endDate.getMinutes(), 0, 0);
        if (dayDiff !== 0) copyAct.endDate.setDate(copyAct.endDate.getDate() + dayDiff);
        var result = this.planboard.prepareActivityForWebApi(copyAct);
        result.children = [];
        result.cooperationCheckRequired = false;
        if (activity.resourceId > 0 && activity.resourceId !== activity.originalResourceId)
            result.cooperationCheckRequired = true;
        if (activity.resourceId === this.resourceNotRequiredId)
            result.status = Constants.StatusActivityNotRequired;
        else if (activity.status == Constants.StatusActivityNotRequired)
            result.status = Constants.StatusActivityPlanned;
        return result;
    }

    private addActivityToPostObject(activity: any, newDate: Date): any {
        var act = this.copyActivityWithDateAndResource(activity, newDate);
        if (activity.nodes && activity.nodes.length > 0)
            for (var i = 0; i < activity.nodes.length; i++)
                act.children.push(this.addActivityToPostObject(activity.nodes[i], newDate));
        return act;
    }
}
