import { IAuthenticationService } from "./authenticationService";
import { currentUserStorageKey, refreshTokenStorageKey } from "../utils/constants";

// The interceptor intercepts all http requests and checks if they are being send with an expired token
// If the token is expired and the routes requires authentication, the user gets redirected to logout

export var tokenExpiredInterceptorService = ["$window", "$injector",
    function ($window, $injector) {
        var tokenExpiredInterceptorService: any = {};

        var _request = function (config) {
            if (config.url.indexOf('api') > -1) {
                const authenticationService: IAuthenticationService = $injector.get('authenticationService');
                const tokenString = $window.localStorage.getItem(currentUserStorageKey);
                const token = tokenString ? JSON.parse(tokenString) : undefined;
                const tokenIsExpired = token ? new Date().getTime() > +token.expires : false;

                if (tokenIsExpired) {
                    const refreshTokenString = $window.localStorage.getItem(refreshTokenStorageKey);
                    const refreshToken = refreshTokenString ? JSON.parse(refreshTokenString) : undefined;
                    const refreshTokenIsExpired = refreshToken ? new Date().getTime() > +refreshToken.refreshMoment : false;
                    if (!refreshTokenIsExpired) {
                        authenticationService.tryLoginWithRefreshToken();
                    }
                }
            }

            return config;
        };

        tokenExpiredInterceptorService.request = _request;

        return tokenExpiredInterceptorService;
    }];