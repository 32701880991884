import { StateService } from '@uirouter/angularjs';
import { INumberService } from './../../shared/numberService';
import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import * as Globals from './../planboard/utils/globals';
import { ObjectList } from './../planboard/utils/objectlist';
import { TimeSpan } from './../planboard/utils/timespan';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import * as Constants from './../utils/constants';
import { Dictionary } from './../utils/dictionary';
import * as Timezone from './../utils/timezone';

export class ChangesInPlanningController {
    
    shouldBuildChangelist: boolean = true;
    currentWeekString: string; // Current ISO week & date
    futureWeekString: string; // Future ISO week & date
    changesList: Array<any> = [];
    timeString: string = "";
    isTimeValid: boolean = true;
    private timeStyleGrey: object = { border: '1px solid lightgrey' };
    private timeStyleOrange: object = { border: '1px solid orange' };
    timeStyle: object = this.isTimeValid ? this.timeStyleGrey : this.timeStyleOrange;

    private commonSvc: any;
    private planboard = Planboard;
    private planboardActivityTypes: ObjectList = this.planboard.activityTypes;
    private resourceDisplayNames: Dictionary = new Dictionary();
    private resourceDisplayNamesLoaded: boolean = true;
    private today: Date = TimeSpan.today.toDate();
    private future: Date = TimeSpan.today.addDays(14).toDate();

    private readonly dialogToken: string = "changesInPlanning";
    private readonly findNew: string = "[new]"; // string to be replaced with new parameter in descriptions
    private readonly findOld: string = "[old]"; // string to be replaced with old parameter in descriptions
    private readonly newLine: string = "\r\n";;

    static $inject = [
        "$scope",
        "$filter",
        "$state",
        "$timeout",
        "numberService",
        "pageStartService",
        "translationService",
        "userService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $filter: ng.IFilterService,
        private $state: StateService,
        private $timeout: ng.ITimeoutService,
        private numberService: INumberService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);

        this.currentWeekString = this.$scope.textLabels.WEEK + " " +
            Timezone.getWeekNumber(this.today).week.toString() +
            " (" + this.$filter("date")(this.today, "shortDate") + ")";
        this.futureWeekString = this.$scope.textLabels.WEEK + " " +
            Timezone.getWeekNumber(this.future).week.toString() +
            " (" + this.$filter("date")(this.future, "shortDate") + ")";

        this.commonSvc.start(() => { this.initializeView(); });
    }

    private initializeView(): void {
        let dayStartHour: number = this.planboard.dayStartHour;
        dayStartHour = this.userService.getDisplaySettingNumber("planboard.dayStartHour", dayStartHour);
        let dayStartHourString: string = dayStartHour.toString() + ":" + "00";
        this.timeString = this.userService.getUserVariable("changes.timeString") || dayStartHourString;
        this.isTimeValid = this.validateTimeString(this.timeString);
        this.timeStyle = this.isTimeValid ? this.timeStyleGrey : this.timeStyleOrange;
        this.$timeout(() => { this.onRefreshClick(); }, 0);
    }

    onActivityClick(activityId: number, isActivityDeleted: boolean): void {
        if (isActivityDeleted) return;
        if (activityId > 0) {
            this.$state.transitionTo("planboard.activity", { activityId: activityId });
        }
    }

    onRefreshClick() {
        this.changesList = [];
        this.isTimeValid = false;
        if (this.validateTimeString(this.timeString)) {
            let fromDateTime = this.timeStringToDateTime(this.timeString, this.today);
            let nowDateTime = new Date();
            if (fromDateTime.getTime() - nowDateTime.getTime() <= 0) {
                this.isTimeValid = true;
                this.userService.setUserVariable("changes.timeString", this.timeString);
                this.getChanges(fromDateTime);
            }
        }
        this.timeStyle = this.isTimeValid ? this.timeStyleGrey : this.timeStyleOrange;
    }

    onTimeUpdated($event, oldValue) {
        if ($event) this.numberService.filterTextTime($event, oldValue);
        this.$timeout(() => {
            if (this.timeString.length === 4 && this.timeString.indexOf(":") < 0)
                this.timeString = this.timeString.substring(0, 2) + ":" + this.timeString.substring(2);
            if (this.validateTimeString(this.timeString))
                this.timeStyle = this.timeStyleGrey;
        }, 0);
    }

    private validateTimeString(timeString: string): boolean {
        let index = timeString.indexOf(":");
        if (timeString.length < 4 || timeString.length > 5 || index < 0)
            return false;
        let hours = parseInt(timeString.substring(0, index));
        let minutes = parseInt(timeString.substring(index + 1));
        if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 24 || minutes < 0 || minutes > 59 || (hours > 23 && minutes > 0))
            return false;

        return true;
    }

    private timeStringToDateTime(timeString: string, date: Date): Date {
        let index = timeString.indexOf(":");
        let hours = parseInt(timeString.substring(0, index));
        let minutes = parseInt(timeString.substring(index + 1));
        let result = new Date(date.getTime());
        result.setHours(hours, minutes, 0, 0);

        return result;
    }

    private getChanges(fromDate: Date): void {
        this.changesList = [];
        this.shouldBuildChangelist = true;

        let activitySelection = {
            scenarioId: this.planboard.scenarioId,
            fromDateInclusive: Timezone.rollDateForWebApi(fromDate),
            toDateExclusive: null,
            resourceIdList: [],
            activitiesWithoutResource: true,
            skipPlanningAbsences: false,
            activityTypeIdList: [],
            resourceTypeIdList: []
        };
        this.commonSvc.post("api/Activities/Changes", activitySelection,
            (success) => {
                let resourceIds: Array<any> = [];
                for (let i = 0; i < success.data.length; i++) {
                    let changes = success.data[i].changes;
                    for (let j = 0; j < changes.length; j++) {
                        let params = changes[j].parameters;
                        if (params) {
                            if (this.shouldAddResourceId(params.resourceId, resourceIds))
                                resourceIds.push(params.resourceId);
                            if (this.shouldAddResourceId(params.resourceIdNew, resourceIds))
                                resourceIds.push(params.resourceIdNew);
                            if (this.shouldAddResourceId(params.resourceIdOld, resourceIds))
                                resourceIds.push(params.resourceIdOld);
                        }
                    }
                }
                if (resourceIds.length > 0)
                    this.loadResourceDisplayNames(resourceIds, success.data);
                this.buildChangeList(success.data);
            },
            null);
    }

    private shouldAddResourceId(resourceId: number, resourceIdList: Array<any>): boolean {
        if (resourceId) {
            if (!this.resourceDisplayNames.containsKey(resourceId) ||
                !this.resourceDisplayNames.value(resourceId)) {
                if (resourceIdList.indexOf(resourceId) < 0)
                    return true;
            }
        }
        return false;
    }

    private loadResourceDisplayNames(resourceIds: Array<number>, changeListData: Array<any>): void {
        this.resourceDisplayNamesLoaded = false;
        let resources = { idList: resourceIds }
        this.commonSvc.post("api/Resources/WithId", resources,
            (success) => {
                // add null for all the requested resources
                for (let i = resources.idList.length - 1; i >= 0; i--)
                    if (this.resourceDisplayNames[resources.idList[i]] == undefined)
                        this.resourceDisplayNames[resources.idList[i]] = null;
                // now add the names of resources that we actually received
                for (let i = 0; i < success.data.length; i++)
                    this.resourceDisplayNames.add(success.data[i].id, success.data[i].displayName);
                this.resourceDisplayNamesLoaded = true;
                this.buildChangeList(changeListData);
            },
            (error) => {
                this.resourceDisplayNamesLoaded = true;
                this.buildChangeList(changeListData);
            });
    }

    private buildChangeList(changeListData: Array<any>): void {
        if (!this.resourceDisplayNamesLoaded || !this.shouldBuildChangelist) return;
        this.shouldBuildChangelist = false;
        this.changesList = [];

        let changeItemsForActivityIds = new Dictionary(); // dictionary with lists of changeItems for each activity id

        for (let i = 0; i < changeListData.length; i++) {
            let changeItem = this.buildChangeItem(changeListData[i]);
            this.changesList.push(changeItem);

            // store a list of changeItems per activity id
            let listForActivity = changeItemsForActivityIds.value(changeListData[i].activityId);
            if (listForActivity) listForActivity.push(changeItem);
            else changeItemsForActivityIds.add(changeListData[i].activityId, [changeItem]);
        }

        // determine the current status of each activity and set the isActivityDeleted property
        changeItemsForActivityIds.forEach((activityId, list) => {
            // list is the list of changeItems that we stored per activity id
            if (list == null || list.length === 0) return; // return from just this forEach step, will continue with the next activity
            let isActivityDeleted = false;
            let listIndex;
            // loop trough all changeItems for this activity and see if the status was changed to deleted or cancelled
            for (listIndex = 0; listIndex < list.length; listIndex++)
                for (let changeIndex = 0; changeIndex < list[listIndex].changeResponse.changes.length; changeIndex++) {
                    let change = list[listIndex].changeResponse.changes[changeIndex];
                    if (change.changeType === Constants.activityChangeTypeStatus &&
                        (change.parameters.activityStatusNew == Constants.StatusActivityDeleted ||
                            change.parameters.activityStatusNew == Constants.StatusActivityCancelled)) {
                        isActivityDeleted = true;
                    }  
                    // Check if activity is set from deleted to planned
                    if (change.changeType === Constants.activityChangeTypeStatus && change.parameters.activityStatusNew == Constants.StatusActivityPlanned) {
                        isActivityDeleted = false;
                    }
                }
            // set the isActivityDeleted for all changeItems for this activity
            if (isActivityDeleted)
                for (listIndex = 0; listIndex < list.length; listIndex++)
                    list[listIndex].isActivityDeleted = true;
        });
    }

    private buildChangeItem(changeResponse: any): any {
        let change = {
            changeTime: this.getParsedTimeString(changeResponse.timestamp),
            userDisplayName: changeResponse.userDisplayName,
            activityId: changeResponse.activityId,
            activityShortText: this.getActivityShortText(changeResponse.activityTypeId),
            activityBackColor: this.getActivityBackColor(changeResponse.activityTypeId),
            activityTextColor: this.getActivityTextColor(changeResponse.activityTypeId),
            activityDate: this.getParsedDateTimeString(changeResponse.activityStart),
            isActivityDeleted: false, // will be set in buildChangeList function
            description: this.buildChangeDescription(changeResponse.changes),
            changeResponse: changeResponse
        }
        return change;
    }

    private getParsedTimeString(dateTimeString: string): string {
        let date = Timezone.correctTimeZoneInfo(dateTimeString);
        let timeStr = Globals.dateFormat(date, 'shortTime');
        return timeStr;
    }

    private getActivityShortText(activityTypeId: number): string {
        let activityType = activityTypeId ? this.planboardActivityTypes.getObject(activityTypeId) : null;
        if (activityType)
            return activityType.shortText;

        return "-";
    }

    private getActivityBackColor(activityTypeId: number): string {
        let activityType = activityTypeId ? this.planboardActivityTypes.getObject(activityTypeId) : null;
        if (activityType)
            return activityType.backColor;

        return "#eeeeee";
    }

    private getActivityTextColor(activityTypeId: number): string {
        let activityType = activityTypeId ? this.planboardActivityTypes.getObject(activityTypeId) : null;
        if (activityType)
            return activityType.textColor;

        return "#222222";
    }

    private buildChangeDescription(changesList: Array<any>): string {
        let description = "";

        for (let i = 0; i < changesList.length; i++) {
            let change = changesList[i];
            let params = change.parameters;
            switch (change.changeType) {
                case Constants.activityChangeTypeNew: // New Activity
                    description += this.$scope.textLabels.CHANGE_NEW_ACTIVITY;
                    description += this.newLine;
                    // resource can be added at same time as activity created
                    if (params && params.resourceId)
                        description += this.getDescriptionResourceChange(params.resourceId, null);
                    break;
                case Constants.activityChangeTypeStatus: // Status Change
                    description += this.getDescriptionStatusChange(params ? params.activityStatusNew : null,
                        params ? params.activityStatusOld : null);
                    break;

                case Constants.activityChangeTypeResource: // Resource Change
                    description += this.getDescriptionResourceChange(params ? params.resourceIdNew : null,
                        params ? params.resourceIdOld : null);
                    break;

                case Constants.activityChangeTypeDateTime: // Date Time Change
                    description += this.getDescriptionTimeChange(params ? params.startNew : null,
                        params ? params.startOld : null,
                        params ? params.endNew : null,
                        params ? params.endOld : null);
                    break;

                case Constants.activityChangeTypeDetails: // Details Change
                    description += this.getDescriptionDetailChange(params ? params.detailType : null,
                        params ? params.detailValueNew : null,
                        params ? params.detailValueOld : null);
                    break;

                case Constants.activityChangeTypeMemo: // Memo Change
                    description += this.$scope.textLabels.CHANGE_MEMO;
                    description += this.newLine;
                    break;
                default:
            }
        }
        return description;
    }

    private getDescriptionStatusChange(statusNew: number, statusOld: number): string {
        if (statusNew == Constants.StatusActivityDeleted)// Deleted
            return this.$scope.textLabels.CHANGE_STATUS_DELETED + this.newLine;
        if (statusNew == Constants.StatusActivityCancelled)// Cancelled
            return this.$scope.textLabels.CHANGE_STATUS_CANCELLED + this.newLine;
        if (statusNew == Constants.StatusActivityLocked)// Locked
            return this.$scope.textLabels.CHANGE_STATUS_LOCKED + this.newLine;
        if (statusNew == Constants.StatusActivityNotRequired)// Not required
            return this.$scope.textLabels.CHANGE_STATUS_NOT_REQUIRED + this.newLine;
        if (statusOld == Constants.StatusActivityLocked && statusNew == Constants.StatusActivityPlanned)// Unlocked
            return this.$scope.textLabels.CHANGE_STATUS_UNLOCKED + this.newLine;
        if (statusOld == Constants.StatusActivityNotRequired && statusNew == Constants.StatusActivityPlanned)// Required
            return this.$scope.textLabels.CHANGE_STATUS_REQUIRED + this.newLine;
        if (statusOld == Constants.StatusActivityDeleted && statusNew == Constants.StatusActivityPlanned)// Required
            return this.$scope.textLabels.CHANGE_STATUS_REPLANNED + this.newLine;

        return this.parseDescriptionNewOld(this.$scope.textLabels.CHANGE_STATUS, statusNew.toString(), statusOld.toString());
    }

    private parseDescriptionNewOld(description: string, replaceNew: string, replaceOld: string): string {
        if (description) {
            if (replaceNew) description = description.replace(this.findNew, replaceNew);
            else description = description.replace(this.findNew, " - ");
            if (replaceOld) description = description.replace(this.findOld, replaceOld);
            else description = description.replace(this.findOld, " - ");
        }

        return description + this.newLine;
    }

    private getDescriptionResourceChange(newResourceId: number, oldResourceId: number): string {
        let isNewResource = newResourceId && newResourceId > 0;
        let isOldResource = oldResourceId && oldResourceId > 0;
        let description = "";

        if (isNewResource && !isOldResource) { // Resource Added
            description = this.$scope.textLabels.CHANGE_RESOURCE_ADD;
            return this.parseDescriptionNewOld(description, this.getResourceDisplayName(newResourceId), null);
        }
        else if (isOldResource && !isNewResource) { // Resource Removed
            description = this.$scope.textLabels.CHANGE_RESOURCE_REMOVE;
            return this.parseDescriptionNewOld(description, null, this.getResourceDisplayName(oldResourceId));
        }
        else if (isNewResource && isOldResource) { // Resource Changed
            description = this.$scope.textLabels.CHANGE_RESOURCE;
            return this.parseDescriptionNewOld(description,
                this.getResourceDisplayName(newResourceId),
                this.getResourceDisplayName(oldResourceId));
        }

        return description; // No resourceIds
    }

    private getResourceDisplayName(resourceId: number): string {
        let displayName = "-";
        if (resourceId) {
            displayName = this.resourceDisplayNames.value(resourceId);
            // Using RESOURCE instead of RESOURCE_ASSIGNED_PLACEHOLDER here for legibility
            // e.g. "Resource was planned" vs. "assigned was planned" (in case of missing permissions)
            return displayName || this.$scope.textLabels.RESOURCE;
        }
        return displayName;
    }

    private getDescriptionTimeChange(startNew: string, startOld: string, endNew: string, endOld: string): string {
        let didStartChange = startNew && startOld && startNew !== startOld;
        let didEndChange = endNew && endOld && endNew !== endOld;
        let description = "";
        if (didStartChange)
            description += this.parseDescriptionNewOld(this.$scope.textLabels.CHANGE_DATE_TIME_START,
                this.getParsedDateTimeString(startNew),
                this.getParsedDateTimeString(startOld));
        if (didEndChange)
            description += this.parseDescriptionNewOld(this.$scope.textLabels.CHANGE_DATE_TIME_END,
                this.getParsedDateTimeString(endNew),
                this.getParsedDateTimeString(endOld));

        return description;
    }

    private getParsedDateTimeString(dateTimeString: string): string {
        let date = Timezone.correctTimeZoneInfo(dateTimeString);
        let dateStr = Globals.dateFormat(date, 'short');
        return dateStr;
    }

    private getDescriptionDetailChange(detailType: string, detailNew: any, detailOld: any): string {
        let description = this.$scope.textLabels.CHANGE_DETAILS + this.newLine;
        switch (Number(detailType)) {
            case Constants.activityDetailDescription:// Description
                description = this.parseDescriptionNewOld(
                    detailNew == null || detailNew === "" ? this.$scope.textLabels.CHANGE_DESCRIPTION_REMOVE
                        : detailOld == null || detailOld === "" ? this.$scope.textLabels.CHANGE_DESCRIPTION_ADD
                            : this.$scope.textLabels.CHANGE_DESCRIPTION, detailNew, detailOld);
                break;

            case Constants.activityDetailHyperlink:// Hyperlink
                detailNew = detailNew != null && detailNew !== "" ? JSON.parse(detailNew) : detailNew;
                detailOld = detailOld != null && detailOld !== "" ? JSON.parse(detailOld) : detailOld;
                description = this.parseDescriptionNewOld(
                    detailNew == null || detailNew.text == null || detailNew.text === "" ? this.$scope.textLabels.CHANGE_HYPERLINK_REMOVE
                        : detailOld == null || detailOld.text == null || detailOld.text === "" ? this.$scope.textLabels.CHANGE_HYPERLINK_ADD
                            : detailNew.text !== detailOld.text ? this.$scope.textLabels.CHANGE_HYPERLINK_TEXT
                                : detailNew.url !== detailOld.url ? this.$scope.textLabels.CHANGE_HYPERLINK_URL
                                    : this.$scope.textLabels.CHANGE_DETAILS, detailNew ? detailNew.text : null, detailOld ? detailOld.text : null);
                break;

            case Constants.activityDetailRisk:// Imposes risk
                if (detailNew === "1") description = this.$scope.textLabels.CHANGE_IMPOSES_RISK_ON + this.newLine;
                else if (detailOld === "1") description = this.$scope.textLabels.CHANGE_IMPOSES_RISK_OFF + this.newLine;
                break;

            case Constants.activityNumberOfSubjects:// Number of detainees
                description = this.parseDescriptionNewOld(this.$scope.textLabels.CHANGE_NR_SUBJECTS,
                    detailNew == null || detailNew === "" ? "0" : detailNew,
                    detailOld == null || detailOld === "" ? "0" : detailOld);
                break;

            case Constants.activityCancellationReason:// Cancellation reason
                description = this.parseDescriptionNewOld(this.$scope.textLabels.CHANGE_CANCELLED_REASON, detailNew, detailOld);
                break;

            default:
                break;
        }
        return description;
    }

}