import { IUserService } from './../../shared/userService';
import { IConfigurationService } from './../../shared/configurationService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import { PlanboardCounters } from './../planboard/components/planboardCounters';
import { TimeSpan } from './../planboard/utils/timespan';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import * as Timezone from './../utils/timezone';

export class CustomCounterPeriodController {

    customPeriodStartDate: Date = this.getDateSettingFromUserService("CUSTOM_PERIOD.START_DATE");
    customPeriodEndDate: Date = this.getDateSettingFromUserService("CUSTOM_PERIOD.END_DATE");
    isStartDateValid: boolean = this.getIsStartDateValid();
    isEndDateValid: boolean = this.getIsEndDateValid();

    private customPeriodMaxYearsDifference = null;

    static $inject = [
        "$scope",
        "$timeout",
        "translationService",
        "userService",
        "configurationService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $timeout: ng.ITimeoutService,
        private translationService: ITranslationService,
        private userService: IUserService,
        private configurationService: IConfigurationService
    ) {
        this.translationService.getTextLabels(this.$scope);

        this.configurationService.getLimitSettings(() => {
            this.customPeriodMaxYearsDifference = this.configurationService.limitSettings.planboardPeriodCountersMaxYearsDifference;
        });
    }

    startDateChanged(date: Date): void {
        var newStartDate = new Date(date.getTime());
        newStartDate.setHours(0, 0, 0, 0);
        this.customPeriodStartDate = newStartDate;
        this.isStartDateValid = this.getIsStartDateValid();
        this.isEndDateValid = this.getIsEndDateValid();
    }

    endDateChanged(date: Date): void {
        var newEndDate = new Date(date.getTime());
        newEndDate.setHours(0, 0, 0, 0);
        this.customPeriodEndDate = newEndDate;
        this.isStartDateValid = this.getIsStartDateValid();
        this.isEndDateValid = this.getIsEndDateValid();
    }

    setCustomDate(): void {
        var countersObj = PlanboardCounters;
        countersObj.countersStartDate = this.customPeriodStartDate;
        countersObj.countersEndDate = this.customPeriodEndDate;
        countersObj.countersWeekNr = countersObj.countersMonthNr = -1;
        countersObj.countersYearNr = countersObj.countersStartDate.getFullYear();
        this.saveDateSettingToUserService(countersObj.countersStartDate, "CUSTOM_PERIOD.START_DATE");
        this.saveDateSettingToUserService(countersObj.countersEndDate, "CUSTOM_PERIOD.END_DATE");
        var planboard = Planboard;
        planboard.timedRefresh();
        this.toPreviousState();
    }

    private getIsStartDateValid(): boolean {
        if (!this.customPeriodStartDate) return false; // No start date
        if (this.customPeriodStartDate.getFullYear() - new Date().getFullYear() > this.customPeriodMaxYearsDifference) return false;
        return true;
    }

    private getIsEndDateValid(): boolean {
        if (!this.customPeriodEndDate) return false; // No end date
        if (!this.customPeriodStartDate) return true; // No start date
        if (TimeSpan.getDayNr(this.customPeriodEndDate) - TimeSpan.getDayNr(this.customPeriodStartDate) < 0) return false; // end date earlier than start
        if (this.customPeriodEndDate.getFullYear() - new Date().getFullYear() > this.customPeriodMaxYearsDifference) return false;
        return true;
    }

    private getDateSettingFromUserService(dateSettingName: string): Date {
        var dateStr = this.userService.getDisplaySetting(dateSettingName, null);
        if (dateStr == null) return null;
        return Timezone.parseDate(dateStr);
    }

    private saveDateSettingToUserService(dateObj: Date, dateSettingName: string) {
        var dateStr = Timezone.rollDateForWebApi(dateObj);
        this.userService.setDisplaySetting(dateSettingName, dateStr);
    }

    private toPreviousState(): void {
        this.$timeout(() => { window.history.back(); }, 100);
    }

}