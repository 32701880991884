import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import * as Globals from './../planboard/utils/globals';
import { TimeSpan } from './../planboard/utils/timespan';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import { Dictionary } from './../utils/dictionary';
import * as Timezone from './../utils/timezone';

export class ResourceCombinationsController {

    periodStartDate = new Date();
    isStartDateValid: boolean;
    periodEndDate = new Date();
    isEndDateValid: boolean;

    activityTypes = new Dictionary(); 
    activityTypeId = -1;
    dropdownVersion = 0;

    activityTree: Array<any> = [];

    resourceTrees = new Dictionary();
    isResourceSelected = false;

    selectedActivityIds = new Dictionary();
    selectedResult: any = null;
    searchComplete = false;
    searchResults: Array<any> = [];
    selectAllActivities = false;
    selectedResultTree: Array<any> = [];
    resourceDisplayNamesDict = new Dictionary();
    resourceCombinationsDict = new Dictionary();

    private commonSvc: any;
    private selectedActivityTypeDict = new Dictionary();
    private selectedActivity: any = null;
    private searchResultsDict = new Dictionary();

    private readonly dialogToken = "resourceCombinations";

    static $inject = [
        "$scope",
        "$filter",
        "$timeout",
        "pageStartService",
        "translationService",
        "userService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $filter: ng.IFilterService,
        private $timeout: ng.ITimeoutService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);


        this.commonSvc.start(() => { this.initializePage(); });
    }

    private initializePage(): void {
        this.periodStartDate = this.userService.getUserVariable("COMBINATIONS_START_DATE") || new Date();
        this.periodEndDate = this.userService.getUserVariable("COMBINATIONS_END_DATE") || new Date();
        this.isStartDateValid = this.getIsStartDateValid();
        this.isEndDateValid = this.getIsEndDateValid();
        this.activityTypeId = this.userService.getUserVariable("COMBINATIONS_ACTIVITY_TYPE_ID") || -1;

        this.loadActivityTypes();
    }


    onPeriodStartDateChanged(date: Date): void {
        this.$timeout(() => {
            this.isStartDateValid = this.getIsStartDateValid();
            this.isEndDateValid = this.getIsEndDateValid();
            this.loadActivityTree(this.activityTypeId, true);
        }, 0);

        this.clearSearchResults();
        
    }

    onPeriodEndDateChanged(date: Date): void {
        this.$timeout(() => {
            this.isStartDateValid = this.getIsStartDateValid();
            this.isEndDateValid = this.getIsEndDateValid();
            this.loadActivityTree(this.activityTypeId, true);
        }, 0);

        this.clearSearchResults();
        
    }

    onActivityTypeChanged(itemId: number): void {
        this.loadActivityTree(itemId);
        this.clearSearchResults();
        this.resourceCombinationsDict.clear();
    }

    onResourceChanged(itemId: number, node: any): void {
        var resources = this.resourceTrees.value(node.id);
        var selectedResource = resources[itemId];
        node.selectedResourceId = selectedResource.id
        this.$timeout(() => {
            this.isResourceSelected = this.getIsResourceSelected(this.activityTree);
        }, 0);

        this.clearSearchResults();

        if (selectedResource.id < 0) {
            this.resourceCombinationsDict.remove(node.id);
            return;
        }
        this.resourceCombinationsDict.add(node.id, selectedResource.id);
    }

    onSearchButtonClick(): void {
        if (!this.isStartDateValid || !this.isEndDateValid || !this.isResourceSelected) return;
        this.userService.setUserVariable("COMBINATIONS_START_DATE", this.periodStartDate);
        this.userService.setUserVariable("COMBINATIONS_END_DATE", this.periodEndDate);
        this.userService.setUserVariable("COMBINATIONS_ACTIVITY_TYPE_ID", this.activityTypeId);
        this.getActivities();
    }

    onResultActivitySelected(activity: any): void {
        activity.selected = !activity.selected;

        if (this.selectedResult)
            this.selectedResult.selected = false;

        if (activity.selected) {
            this.selectedResult = activity;
            this.selectedResultTree = this.selectedResult.nodes;
        }
        else {
            this.selectedResult = null;
            this.selectedResultTree = [];
        }
    }

    onSelectActivity(node: any): void { this.selectedActivity = node; }

    onSelectAllActivities(checked: boolean): void {
        for (var i = 0; i < this.searchResults.length; i++) {
            var activity = this.searchResults[i];
            if (activity.previewResourceCount > 0) {
                activity.checked = checked;
                this.onCheckbox(activity, checked);
            }
        }
    }

    onCheckbox(activity: any, checked: boolean): void {
        if (checked) this.selectedActivityIds.add(activity.id, activity.id);
        else this.selectedActivityIds.remove(activity.id);

        this.selectAllActivities = this.updateSelectAll(this.selectAllActivities, activity);
    }

    onPlanButtonClick(): void {
        this.selectedResult = null;
        this.selectedResultTree = [];

        var activitiesToUpdate = [];
        var rootActivityIdList = [];
        this.selectedActivityIds.forEach((id) => {
            var rootActivity = this.searchResultsDict.value(id);
            rootActivityIdList.push(id);
            activitiesToUpdate = activitiesToUpdate.concat(this.getAllCombinationActivities(rootActivity));
        });
        this.updateActivities(activitiesToUpdate, rootActivityIdList);
    }

    getActivityBackColor(node): string { return this.toHtmlColor(node.backColor == null ? "" : node.backColor); }
    getActivityDisplayName(node): string { return node.displayName == null ? "" : node.displayName; }
    getActivityShortName(node): string { return node.shortName == null ? "" : node.shortName; }
    getActivityTextColor(node): string { return this.toHtmlColor(node.textColor == null ? "" : node.textColor); }
    isLeafActivity(node): boolean { return node.parentId == null ? false : node.resourceTypeIdList.length > 0; }
    isSelectedActivity(node): boolean { return node == null ? false : this.selectedActivity == null ? false : node.id === this.selectedActivity.id; }

    private loadActivityTypes(): void {
        this.commonSvc.loadData("api/ActivityTypes/MainActivityTypes/WithUserGroupReadPermissions", null,
            (success, loadInto) => {
                for (var i = 0; i < loadInto.length; i++) {
                    var actType = loadInto[i];
                    if (actType.parentId == null) {
                        var categoryId = actType.categoryId + Globals.maxInt;
                        if (!this.activityTypes.value(categoryId)) // placeholder until response from categories
                            this.activityTypes.add(categoryId,
                                { id: categoryId, displayName: "" + categoryId, open: true });

                        actType.parentId = categoryId;
                        this.activityTypes.add(actType.id, actType);
                        this.dropdownVersion++;
                    }
                }
                if (this.activityTypeId >= 0)
                    this.loadActivityTree(this.activityTypeId);
            },
            null, true, false);

        // load activity type categories
        this.commonSvc.loadData("api/ActivityTypes/Categories", null,
            (success, loadInto) => {
                var categoriesOrdered: any = this.$filter("orderBy")(loadInto, "-displayName");

                for (var i = 0; i < categoriesOrdered.length; i++) {
                    categoriesOrdered[i].order = -i; // caution, this overrides any order the server may have set
                    categoriesOrdered[i].id += Globals.maxInt;
                    categoriesOrdered[i].open = true; // to expand the node in dropdown
                    this.activityTypes.add(categoriesOrdered[i].id, categoriesOrdered[i]);
                }
            },
            null, true, false);
    }

    private loadActivityTree(activityTypeId: number, reloadResources: boolean = false): void {
        if (activityTypeId <= 0)
            return;

        this.activityTree = [];

        this.commonSvc.loadData("api/ActivityTypes/Group/" + activityTypeId, null,
            (success, loadInto) => {
                for (var i = 0; i < loadInto.length; i++)
                    this.selectedActivityTypeDict.add(loadInto[i].id, loadInto[i]);

                this.activityTree = this.buildTree(loadInto);
                this.loadActivityTreeResources(reloadResources);
            },
            null, true, false);

    }

    private loadActivityTreeResources(reloadResources: boolean = false): void {
        if (reloadResources) {
            this.resourceDisplayNamesDict = new Dictionary();
        }
        for (var i = 0; i < this.activityTree.length; i++) {
            this.getResourceTreeForActivity(this.activityTree[i], reloadResources); // TODO: make this call on dropdown click?
        }
    }

    private getResourceTreeForActivity(node: any, reloadResources: boolean = false): void {
        if (node == undefined) return;
        var resourceTree = this.resourceTrees.value(node.id);
        if (resourceTree == undefined || reloadResources) {
            if (node.resourceTypeIdList.length > 0) {
                node.availableResourcesLoaded = false;
                this.loadResources(node);
            }
        }
        for (var i = 0; i < node.nodes.length; i++)
            this.getResourceTreeForActivity(node.nodes[i], reloadResources);
    }

    private loadResources(node: any): void {
        var activity = {
            activityTypeId: node.id,
            startDate: Planboard.adjustToUtc(this.periodStartDate),
            endDate: Planboard.adjustToUtc(this.periodEndDate)
        };
        this.commonSvc.post("api/Resources/ForActivityType/", activity, (response) => { this.loadResourcesRequestHandler(node, response) });
    }

    private loadResourcesRequestHandler(node: any, response: any) {
        if (response.error) {
            node.availableResourcesLoaded = true;
        } else {
            var resourceOrder = 0;
            var resourceTree = Object.create(null);
            resourceTree[-1] = this.newTreeItem(-1, this.$scope.textLabels.FILTER_NONE, resourceOrder, undefined);
            resourceTree[Globals.maxInt] = this.newTreeItem(Globals.maxInt, this.$scope.textLabels.RESOURCES_SKILLED, resourceOrder, false);
            resourceTree[Globals.maxInt + 1] = this.newTreeItem(Globals.maxInt + 1, this.$scope.textLabels.RESOURCES_UNSKILLED, resourceOrder, false);
            resourceTree[Globals.maxInt].open = true;

            // Two arrays Skilled & Unskilled
            for (var i = 0; i < response.data.length; i++) {
                var resourceList = response.data[i];
                if (resourceList && resourceList.length > 0)
                    for (var j = 0; j < resourceList.length; j++) {
                        var resource = resourceList[j];
                        resource.order = resourceOrder++;
                        resource.parentId = Globals.maxInt + i;
                        i == 0 ? resource.skilled = true : resource.skilled = false; // 0: skilled, 1: unskilled
                        resource.visible = true;
                        resourceTree[resource.id] = resource;
                        this.resourceDisplayNamesDict.add(resource.id, resource.displayName);
                    }
            }

            this.resourceTrees.add(node.id, resourceTree);

            node.availableResourcesLoaded = true;
        }
    }



    private newTreeItem(id: number, name: string, order: number, selectable: boolean): any {
        return { id: id, displayName: name, order: order, selectable: selectable, visible: true }
    }

    private getActivities(): void {
        this.clearSearchResults();

        var resourceCombinations = [];
        this.resourceCombinationsDict.forEach((activityTypeId, resourceId) => {
            resourceCombinations.push({
                activityTypeId: activityTypeId,
                resourceId: resourceId
            });
        });

        var activityResourceCombination = {
            scenarioId: Planboard.scenarioId,
            fromDateInclusive: Timezone.dateToStr(this.periodStartDate),
            toDateExclusive: Timezone.dateToStr(TimeSpan.fromDateNoTime(this.periodEndDate).addDays(1).toDate()),
            resourceIdList: [],
            activitiesWithoutResource: true,
            skipPlanningAbsences: true,
            activityTypeIdList: [this.activityTypeId],
            resourceTypeIdList: [],
            resourceCombinations: resourceCombinations
        }
        this.commonSvc.post("api/Activities/GetActivitiesResourceCombination", activityResourceCombination,
            (success) => {
                var resultResourceIds = [];
                for (var i = 0; i < success.data.length; i++) {
                    if (success.data[i].resourceId)
                        resultResourceIds.push(success.data[i].resourceId);
                }
                this.searchResults = this.buildTree(success.data);
                for (var i = 0; i < this.searchResults.length; i++) {
                    this.searchResultsDict.add(this.searchResults[i].id, this.searchResults[i]);
                    this.searchResults[i].previewResourceCount = 0;
                    this.handleDuplicateActivityTypeNodes(this.searchResults[i], new Dictionary(), this.searchResults[i]);
                }

                this.loadResourceDisplayNamesForResults(resultResourceIds);
                this.searchComplete = true;
            },
            null, false);
    }

    private loadResourceDisplayNamesForResults(resourceIds: Array<number>): void {
        if (resourceIds.length > 0) {
            var resources = { idList: resourceIds }
            this.commonSvc.post("api/Resources/WithId", resources,
                (success) => {
                    for (var i = 0; i < success.data.length; i++)
                        this.resourceDisplayNamesDict.add(success.data[i].id, success.data[i].displayName);
                }, null, false);
        }
    }

    private updateActivities(activities: Array<any>, rootActivityIdList: Array<number>): void {
        if (!activities || activities.length <= 0) return;

        for (var i = 0; i < activities.length; i++)
            activities[i] = this.formatActivity(activities[i]);
        this.commonSvc.post("api/Activities/ChangeActivityGroupStrict", activities,
            (success) => {
                this.commonSvc.showDialog(this.$scope.textLabels.RESOURCE_COMBINATION_TITLE,
                    this.$scope.textLabels.RESOURCE_COMBINATION_PLAN_SUCCESS,
                    this.$scope.textLabels.OK,
                    () => {
                        this.onSearchButtonClick();
                        Planboard.readActivitiesWithRootIds(rootActivityIdList);
                    });
            },
            (error) => {
                // (Future TODO: current error handling does not support a retry option to try again with less resource restrictions)
                var dialogText = "";
                var successCount = 0;
                var failureCount = 0;

                successCount =
                    error.data
                        ? error.data.successActivityCount
                        : error.successActivityCount;

                failureCount = activities.length - successCount;

                if (successCount > 0)
                    dialogText += this.$scope.textLabels.RESOURCE_COMBINATION_SUCCESS_COUNT + successCount.toString() + "\n\n";
                dialogText += this.$scope.textLabels.RESOURCE_COMBINATION_FAILURE_COUNT + failureCount.toString() + "\n\n";

                var failureReasons = Planboard.getActivityFailureReasons(error && error.data ? error.data : error);

                dialogText += failureReasons;

                this.commonSvc.showDialog(this.$scope.textLabels.ACTIVITY_SAVE_FAILED,
                    dialogText,
                    this.$scope.textLabels.OK,
                    () => {
                        this.onSearchButtonClick();
                        Planboard.readActivitiesWithRootIds(rootActivityIdList);
                    });
            }, true);
    }

    private formatActivity(activity: any): any {
        var isResourceSkilled = true;

        if (this.resourceTrees.value(activity.activityTypeId) && this.resourceTrees.value(activity.activityTypeId)[activity.resourceId])
            isResourceSkilled = this.resourceTrees.value(activity.activityTypeId)[activity.resourceId].skilled;

        var formattedActivity = {
            ignoreResourceRestrictions: false,
            ignoreSkillCheck: !isResourceSkilled,
            scenarioId: activity.scenarioId,
            id: activity.id,
            parentId: (activity.parentId < 0 ? null : activity.parentId),
            memoId: activity.memoId,
            status: activity.status,
            activityTypeId: activity.activityTypeId,
            startDate: activity.startDate,
            endDate: activity.endDate,
            resourceId: activity.resourceId == null || activity.resourceId < 0 ? null : activity.resourceId,
            resourceTypeId: activity.resourceTypeId == null || activity.resourceTypeId < 0 ? null : activity.resourceTypeId
        }
        return formattedActivity;
    }

    private handleDuplicateActivityTypeNodes(node: any, foundActivityTypesDict: Dictionary, rootNode: any): void {
        if (this.resourceCombinationsDict.containsKey(node.activityTypeId)) { // resource combination
            if (foundActivityTypesDict.containsKey(node.activityTypeId)) { // activityType id already found
                if (node.resourceId && node.resourceId == this.resourceCombinationsDict.value(node.activityTypeId)) { // has the combination resource assigned
                    foundActivityTypesDict.value(node.activityTypeId).previewResourceId = null;
                    rootNode.previewResourceCount--;
                }

            } else { // first time finding activityType id
                if (!node.resourceId) { // no resource assigned yet
                    node.previewResourceId = this.resourceCombinationsDict.value(node.activityTypeId); // for displaying preview of resource
                    rootNode.previewResourceCount++; // for formatting the search results
                    foundActivityTypesDict.add(node.activityTypeId, node);
                } else if (node.resourceId == this.resourceCombinationsDict.value(node.activityTypeId)) {
                    foundActivityTypesDict.add(node.activityTypeId, node);
                }
            }
        }
        for (var i = 0; i < node.nodes.length; i++)
            this.handleDuplicateActivityTypeNodes(node.nodes[i], foundActivityTypesDict, rootNode);
    }

    private buildTree(activityList: Array<any>): Array<any> {
        var activityTrees = [];
        var activityDict = Object.create(null);
        for (var i = 0; i < activityList.length; i++)
            activityDict[activityList[i].id] = activityList[i];

        for (var i = 0; i < activityList.length; i++) {
            // Add necessary values to activity
            var activity = activityDict[activityList[i].id];
            if (!activity.nodes)
                activity.nodes = [];
            activity.dateRange = this.getDateRange(activity.startDate, activity.endDate) || null;
            activity.timeRange = this.getTimeRange(activity.startDate, activity.endDate) || null;
            activity.selected = false;
            activity.checked = false;
            var activityType = this.getActivityType(activity.activityTypeId);
            if (activityType) {
                activity.backColor = activityType.backColor;
                activity.displayName = activityType.displayName;
                activity.shortName = activityType.shortName;
                activity.textColor = activityType.textColor;
                activity.sortOrder = activityType.sortOrder;
            }

            // Check if Leaf or Parent
            if (activity.parentId != null && activity.parentId > 0) {
                var parent = activityDict[activity.parentId];
                if (parent != undefined) { // add to parents nodes array
                    activity.parentNode = parent;
                    if (parent.nodes == undefined) parent.nodes = [];
                    parent.nodes.push(activity);
                } else
                    activityTrees.push(activity); // parent could not be found, treat this activity as a root
            } else
                activityTrees.push(activity); // this is a root activity
        }
        return activityTrees;
    }

    private getActivityType(id: number): any {
        return this.selectedActivityTypeDict.value(id);
    }

    private clearSearchResults(): void {
        this.$timeout(() => {
            this.searchComplete = false;
            this.searchResults = [];
            this.searchResultsDict.clear();
            this.selectAllActivities = false;
            this.selectedActivityIds.clear();
            this.isResourceSelected = this.getIsResourceSelected(this.activityTree);
            this.selectedResult = null;
            this.selectedResultTree = [];
        }, 0);
    }

    private getIsStartDateValid(): boolean {
        if (!this.periodStartDate) return false; // No date
        var today = TimeSpan.today.toDate();
        if (TimeSpan.getDayNr(this.periodStartDate) - TimeSpan.getDayNr(today) < 0) return false; // date in past
        return true;
    }

    private getIsEndDateValid(): boolean {
        if (!this.periodEndDate) return false; // No date
        if (TimeSpan.getDayNr(this.periodEndDate) - TimeSpan.getDayNr(this.periodStartDate) < 0) return false; // date earlier than start
        if (TimeSpan.getDayNr(this.periodEndDate) - TimeSpan.getDayNr(this.periodStartDate) > 365) return false; // period longer than 1 year
        return true;
    }

    private getIsResourceSelected(tree: Array<any>): boolean {
        for (var i = 0; i < tree.length; i++) {
            var node = tree[i];
            if (node.selectedResourceId && node.selectedResourceId >= 0)
                return true;
            if (node.nodes.length > 0) {
                var leafHasSelectedResource = this.getIsResourceSelected(node.nodes);
                if (leafHasSelectedResource)
                    return true;
            }
        }
        return false;
    }

    private toHtmlColor(colorString: string): string {
        if (colorString === "" || colorString == undefined) return "transparent";
        return colorString.charAt(0) === "#" ? colorString : "#" + colorString;
    }

    private getDateRange(start: Date, end: Date): string {
        if (!start || !end) return null;
        var startDate = Timezone.correctTimeZoneInfo(start);
        var endDate = Timezone.correctTimeZoneInfo(end);
        var startDateStr = Globals.dateFormat(startDate, 'shortDate');
        var endDateStr = Globals.dateFormat(endDate, 'shortDate');

        if (startDateStr == endDateStr)
            return startDateStr;
        else
            return startDateStr + " - " + endDateStr;
    }

    private getTimeRange(start: Date, end: Date): string {
        if (!start || !end) return null;
        var startDate = Timezone.correctTimeZoneInfo(start);
        var endDate = Timezone.correctTimeZoneInfo(end);
        var startTimeStr = Globals.dateFormat(startDate, 'shortTime');
        var endTimeStr = Globals.dateFormat(endDate, 'shortTime');

        return startTimeStr + " - " + endTimeStr;
    }

    private updateSelectAll(selectAllChecked: boolean, activity: any): boolean {
        if (selectAllChecked) {
            if (!activity.checked)
                return false;
        } else {
            if (activity.checked) {
                var allActivitiesAreSelected = true;
                for (var i = 0; i < this.searchResults.length; i++) {
                    if (!this.searchResults[i].checked) {
                        allActivitiesAreSelected = false;
                        break;
                    }
                }
                return allActivitiesAreSelected;
            }
        }
        return selectAllChecked;
    }

    private getAllCombinationActivities(node: any): Array<any> {
        var activitiesFound = [];
        if (this.resourceCombinationsDict.containsKey(node.activityTypeId)) { // is a combination leaf
            if (node.previewResourceId) {
                node.resourceId = node.previewResourceId;
                activitiesFound.push(node);
            }
        }
        for (var i = 0; i < node.nodes.length; i++)
            activitiesFound = activitiesFound.concat(this.getAllCombinationActivities(node.nodes[i]));

        return activitiesFound;
    }
}