import { BasicControl } from './../controls/basicControl';
import { Rect } from './../utils/rect';
import { Dictionary } from './../../utils/dictionary';
import * as Globals from './../utils/globals';
import { Planboard } from './../entities/planboard';

/**
    * the main controller for user interaction
    */
export class MainController {
    ctx: CanvasRenderingContext2D;
    desktop: BasicControl;
    mouseOverControl: BasicControl = null;
    mouseDownControl: BasicControl = null;
    mouseDownButton = -1;
    lastMouseDownEvent: MouseEvent = null;
    currentClipt: Rect;
    mouseCursor: string;
    redrawInAnimationFrame: boolean = false;
    partialRedrawPending: boolean = false;
    partialRedrawClear: boolean = false;
    partialRedrawArea = new Rect(0, 0, 0, 0);

    /**
        * when set to true, will trigger a dragDrop callback on the mouseUp
        */
    dragDrop = false;

    /**
        * indicates if this controller is enabled, when false all mouse events are ignored. Also see the setEnabled method.
        */
    enabled = true;

    /**
        * callback event when a scrollbar is used (or mousewheel)
        */
    onScrollStart = () => { }

    /**
        * callback event when drawing is finished
        */
    onDrawEnd = () => { }

    private static controllerPerCanvas = new Dictionary(); // what controller belongs to what canvas HTML element

    /**
        * link a canvas and controller
        * @param canvasId the unique id of the canvas HTML element
        * @param controller the controller
        */
    private static addcontroller(canvasId: string, controller: MainController) {
        MainController.controllerPerCanvas.add(canvasId, controller);
    }

    /**
        * get the controller for a canvas
        * @param canvasId the unique id of the canvas HTML element
        */
    static getcontroller(canvasId: string): MainController {
        return MainController.controllerPerCanvas.value(canvasId) as MainController;
    }

    /**
        * attach this controller to a new canvas element
        * @param canvasElement the canvas html element to use
        */
    attachToCanvas(canvasElement: string) {
        document.getElementById(canvasElement).ondragstart = () => false; // disable dragging of the element
        const canvas = (document.getElementById(canvasElement) as HTMLCanvasElement);
        this.ctx = canvas.getContext("2d", {alpha: false});
        MainController.addcontroller(canvas.id, this);

        canvas.addEventListener("mousedown", this.mouseDownCanvas, false);
        canvas.addEventListener("mousemove", this.mouseMoveCanvas, false);
        canvas.addEventListener("mouseup", this.mouseUpCanvas, false);
        canvas.addEventListener("mousewheel", this.mouseWheelCanvas, false);
        canvas.addEventListener("DOMMouseScroll", this.mouseWheelCanvas, false);
        canvas.addEventListener("dblclick", this.mouseDblClickCanvas, false);
        canvas.addEventListener("mouseout", this.mouseOutCanvas, false);
        canvas.addEventListener("touchstart", this.touchStartCanvas, false);

        // remove and add the event listener for mouseup outside of the browser window
        window.removeEventListener("mouseup", this.windowMouseUp, false);
        window.addEventListener("mouseup", this.windowMouseUp, false);
    }

    /**
        * create a new controller
        * @param canvasElement the canvas html element to use
        */
    constructor(canvasElement: string) {
        this.attachToCanvas(canvasElement);

        this.desktop = new BasicControl(null, 0, 0, 100, 100);
        this.desktop.name = "desktop";
        this.desktop.controller = this;
        this.currentClipt = new Rect(0, 0, 100, 100);
    }

    /**
        * Set the enabled state if it is different and call a dragDropCancel if neccesary
        * @param value true to enable or false to disable
        */
    setEnabled(value: boolean) {
        if (this.enabled === value) return; // no change
        // also do a cancel dragDrop if currently dragdropping and the state is diabled
        if (!value && this.dragDrop) {
            this.dragDrop = false;
            if (this.mouseDownControl != null)
                this.mouseDownControl.dragDropCancel();
        }
        if (!value) this.mouseDownControl = null; // in case a mouseUp event will be ignored because the state is disabled
        this.enabled = value; // set new value
    }

    mouseX(clientX: number): number {
        return clientX - this.ctx.canvas.getBoundingClientRect().left;
    }

    mouseY(clientY: number): number {
        return clientY - this.ctx.canvas.getBoundingClientRect().top;
    }

    /**
        * act like the mouse button was down on a specific control
        * @param control the control to send the mouseDown call to
        * @param x the x position on screen
        * @param y the y position on screen
        * @param button the mouse button number
        */
    doMouseDown(control: BasicControl, x: number, y: number, button: number) {
        if (this.mouseDownControl != null && control !== this.mouseDownControl)
            this.mouseDownControl.mouseLeave();
        this.mouseDownControl = control;
        this.mouseDownButton = button;
        control.mouseDown(x, y, button);
    }

    /**
        * callback for the event listener: tochstart
        * @param evt touch event parameters
        */
    private touchStartCanvas(evt: TouchEvent) {
        evt.stopPropagation();
        evt.preventDefault(); // prevent default iOS panning behaviour
    }

    /**
        * callback for the event listener: mousedown
        * @param evt touch event parameters
        */
    private mouseDownCanvas(evt: MouseEvent) {
        const me = MainController.getcontroller((this as any).id); // attention! "this" refers to the canvas, not the Maincontroller
        if (me == null || !me.enabled) return;
        me.lastMouseDownEvent = evt;
        const clientX = me.mouseX(evt.clientX);
        const clientY = me.mouseY(evt.clientY);
        const foundControl = me.getControlAt(me.desktop, clientX, clientY, 0, 0);
        if (me.mouseDownControl != null && foundControl !== me.mouseDownControl)
            me.mouseDownControl.mouseLeave();
        if (foundControl != null) {
            foundControl.mouseMove(clientX, clientY, evt.button);
            me.mouseDownControl = foundControl;
            me.mouseDownButton = evt.button;
            foundControl.mouseDown(clientX, clientY, evt.button);
        }
        evt.stopPropagation();
        evt.preventDefault();
    }

    /**
        * callback for the event listener: mousemove
        * @param evt mouse event parameters
        */
    private mouseMoveCanvas(evt: MouseEvent) {
        const me = MainController.getcontroller((this as any).id); // attention! "this" refers to the canvas, not the Maincontroller
        if (me == null || !me.enabled) return;
        const clientX = me.mouseX(evt.clientX);
        const clientY = me.mouseY(evt.clientY);
        me.mouseCursor = "default";
        if (me.mouseDownControl != null) {
            me.mouseDownControl.mouseMove(clientX, clientY, me.mouseDownButton);
        } else {
            const foundControl = me.getControlAt(me.desktop, clientX, clientY, 0, 0);
            if (me.mouseOverControl != null && me.mouseOverControl !== foundControl)
                me.mouseOverControl.mouseLeave();
            me.mouseOverControl = foundControl;
            if (foundControl != null)
                foundControl.mouseMove(clientX, clientY, me.mouseDownButton);
        }
        if (me.ctx.canvas.style.cursor !== me.mouseCursor) me.ctx.canvas.style.cursor = me.mouseCursor;
        evt.stopPropagation();
        evt.preventDefault();
        Planboard.onMouseMove();
    }

    /**
        * callback when the mouse button is released outside of the window
        * @param evt mouse event parameters
        */
    private windowMouseUp(evt: MouseEvent) {
        if (evt.clientX < 0 || evt.clientY < 0 || evt.clientX >= window.innerWidth || evt.clientY >= window.innerHeight) {
            MainController.controllerPerCanvas.forEach((key, value) => {
                const controller = value as MainController;
                if (controller.enabled && controller.dragDrop) {
                    controller.dragDrop = false;
                    if (controller.mouseDownControl != null)
                        controller.mouseDownControl.dragDropCancel();
                }
                if (controller.enabled && controller.mouseDownControl != null) {
                    controller.mouseDownControl.mouseUp(-Globals.maxInt, -Globals.maxInt, evt.button);
                    controller.mouseDownControl = null;
                    controller.mouseDownButton = -1;
                }                
            });
        }
    }

    /**
        * callback for the event listener: mouseup
        * @param evt mouse event parameters
        */
    private mouseUpCanvas(evt: MouseEvent) {
        const me = MainController.getcontroller((this as any).id); // attention! "this" refers to the canvas, not the Maincontroller
        if (me == null || !me.enabled) return;
        const clientX = me.mouseX(evt.clientX);
        const clientY = me.mouseY(evt.clientY);
        if (me.dragDrop) {
            me.dragDrop = false;
            let foundControl = me.getControlAt(me.desktop, clientX, clientY, 0, 0);
            if (foundControl != null) {
                if (foundControl === me.mouseDownControl && foundControl.dragAble) {
                    me.mouseDownControl.capturesMouse = false;
                    foundControl = me.getControlAt(me.desktop, clientX, clientY, 0, 0);
                    me.mouseDownControl.capturesMouse = true;
                }
                if (foundControl != null)
                    foundControl.dragDrop(clientX, clientY);
            } else if (me.mouseDownControl != null)
                me.mouseDownControl.dragDrop(clientX, clientY);
        }
        if (me.mouseDownControl != null) {
            me.mouseDownControl.mouseUp(clientX, clientY, evt.button);
            me.mouseDownControl = null;
            me.mouseDownButton = -1;
        }
        evt.stopPropagation();
        evt.preventDefault();
    }

    /**
        * callback for the event listeners: mousewheel, DOMMouseScroll
        * @param evt mouse wheel event parameters
        */
    private mouseWheelCanvas(evt: MouseWheelEvent) {
        const me = MainController.getcontroller((this as any).id); // attention! "this" refers to the canvas, not the Maincontroller
        if (me == null || !me.enabled) return;
        me.onScrollStart();
        const clientX = me.mouseX(evt.clientX);
        const clientY = me.mouseY(evt.clientY);
        const value = -Math.max(-1, Math.min(1, ((evt as any).wheelDelta || -evt.detail)));
        // for wheel scrolling we will always find the control the mouse is currently over, no matter where the mouse button was pressed
        let bc = me.getControlAt(me.desktop, clientX, clientY, 0, 0);
        // remember mouseOverControl if it is null
        if (me.mouseOverControl == null) me.mouseOverControl = bc;
        // find the first parent control that has wheel scrolling enabled if it is disabled for the control we just found
        while (bc != null && !bc.useMouseWheel && bc.owner != null) bc = bc.owner;
        if (bc != null) bc.mouseWheel(clientX, clientY, value);
        evt.stopPropagation();
        evt.preventDefault();
    }

    /**
        * callback for the event listener: dblclick
        * @param evt mouse evet parameters
        */
    private mouseDblClickCanvas(evt: MouseEvent) {
        const me = MainController.getcontroller((this as any).id); // attention! "this" refers to the canvas, not the Maincontroller
        if (me == null || !me.enabled) return;
        const clientX = me.mouseX(evt.clientX);
        const clientY = me.mouseY(evt.clientY);
        const foundControl = me.getControlAt(me.desktop, clientX, clientY, 0, 0);
        if (foundControl != null) {
            foundControl.mouseUp(clientX, clientY, evt.button); // make sure the mouseUp is called to not break dragdrop
            foundControl.mouseDblClick(clientX, clientY, evt.button);
        }
        evt.stopPropagation();
        evt.preventDefault();
    }

    /**
        * callback for the event listener: mouseout
        * @param evt mouse event parameters
        */
    private mouseOutCanvas(evt: MouseEvent) {
        const me = MainController.getcontroller((this as any).id); // attention! "this" refers to the canvas, not the Maincontroller
        if (me == null || !me.enabled) return;
        if (me.mouseDownControl != null)
            return;
        if (me.mouseOverControl != null) {
            me.mouseOverControl.mouseLeave();
            me.mouseOverControl = null;
        }
    }

    /**
        * resize the desktop to fit the canvas
        * @param fullScreen make the canvas fit the whole browser window
        */
    resize(fullScreen: boolean) {
        if (fullScreen) {
            this.ctx.canvas.width = window.innerWidth;
            this.ctx.canvas.height = window.innerHeight;
        }
        Globals.initContext(this.ctx);
        this.desktop.position.width = this.ctx.canvas.width;
        this.desktop.position.height = this.ctx.canvas.height;
    }

    /**
        * get the control at a specific screen position
        * @param control the control to test
        * @param x screen x position
        * @param y screen y position
        * @param leftOffset left offset of the controls parent
        * @param topOffset top offset of the controls parent
        */
    getControlAt(control: BasicControl, x: number, y: number, leftOffset: number, topOffset: number): BasicControl {
        if (control.visible &&
            x >= leftOffset + control.position.left && x <= leftOffset + control.position.right &&
            y >= topOffset + control.position.top && y <= topOffset + control.position.bottom) {
            let foundControl: BasicControl = null;
            let i = control.clients.length;
            while (i > 0) {
                i--;
                if (control.clients[i].visible && control.clients[i].capturesMouse) {
                    if (control.clientAreaSet && control.clients[i].useClientArea &&
                        x <= leftOffset + control.position.left + control.clientArea.width &&
                        y <= topOffset + control.position.top + control.clientArea.height)
                        foundControl = this.getControlAt(control.clients[i], x, y,
                            leftOffset + control.position.left + control.clientArea.left, topOffset + control.position.top + control.clientArea.top);
                    else
                        foundControl = this.getControlAt(control.clients[i], x, y,
                            leftOffset + control.position.left, topOffset + control.position.top);
                    if (foundControl != null)
                        return foundControl;
                }
            }
            return control;
        }
        return null;
    }

    /**
        * draw a control and all of it's children
        * @param control the control to draw
        * @param leftOffset left offset of the controls parent
        * @param topOffset top offset of the controls parent
        * @param clipLeft clipping left position
        * @param clipTop clipping top position
        * @param clipWidth clipping width
        * @param clipHeight clipping height
        */
    drawControl(control: BasicControl, leftOffset: number, topOffset: number, clipLeft: number, clipTop: number, clipWidth: number, clipHeight: number) {
        if (control.align != null && control.owner != null) {
            if (control.align.left !== Globals.maxInt) control.position.left = control.align.left;
            if (control.align.top !== Globals.maxInt) control.position.top = control.align.top;
            if (control.align.right !== Globals.maxInt) control.position.right = (control.owner.position.width - 1) - control.align.right;
            if (control.align.bottom !== Globals.maxInt) control.position.bottom = (control.owner.position.height - 1) - control.align.bottom;
            if (control.position.width < 0) control.position.width = 0;
            if (control.position.height < 0) control.position.height = 0;
        }
        var newLeft = leftOffset + control.position.left;
        var newTop = topOffset + control.position.top;
        if (control.visible &&
            newLeft + control.position.width > clipLeft && newTop + control.position.height > clipTop &&
            newLeft < clipLeft + clipWidth && newTop < clipTop + clipHeight) {
            if (clipLeft < newLeft) {
                clipWidth -= newLeft - clipLeft;
                clipLeft = newLeft;
            }
            if (clipTop < newTop) {
                clipHeight -= newTop - clipTop;
                clipTop = newTop;
            }
            if (clipLeft + clipWidth > newLeft + control.position.width)
                clipWidth = (newLeft + control.position.width) - clipLeft;
            if (clipTop + clipHeight > newTop + control.position.height)
                clipHeight = (newTop + control.position.height) - clipTop;
            if (clipWidth > 0 && clipHeight > 0) {
                this.currentClipt.change(clipLeft, clipTop, clipWidth, clipHeight);
                this.ctx.save();
                this.ctx.beginPath();
                this.ctx.rect(clipLeft, clipTop, clipWidth, clipHeight);
                this.ctx.closePath();
                this.ctx.clip();
                control.draw(newLeft, newTop);
                this.ctx.restore();
                var clientClipWidth = clipWidth;
                var clientClipHeight = clipHeight;
                if (control.clientAreaSet) {
                    if (clipLeft + clientClipWidth > newLeft + control.clientArea.width)
                        clientClipWidth = (newLeft + control.clientArea.width) - clipLeft;
                    if (clipTop + clientClipHeight > newTop + control.clientArea.height)
                        clientClipHeight = (newTop + control.clientArea.height) - clipTop;
                }
                for (var i = 0; i < control.clients.length; i++)
                    if (control.clients[i].visible)
                        if (control.clientAreaSet && control.clients[i].useClientArea)
                            this.drawControl(control.clients[i], newLeft + control.clientArea.left, newTop + control.clientArea.top,
                                clipLeft, clipTop, clientClipWidth, clientClipHeight);
                        else
                            this.drawControl(control.clients[i], newLeft, newTop,
                                clipLeft, clipTop, clipWidth, clipHeight);
            }
        }
    }

    /**
        * move the control to a new position and redraw only affected areas
        * @param control the control to move
        * @param newPos the new position relative to the controls parent
        */
    moveControl(control: BasicControl, newPos: Rect) {
        const oldPos = control.screenPos.copy();
        control.position.change(newPos.left, newPos.top, newPos.width, newPos.height);
        // find screen position of the new position
        let bc = control;
        while (bc != null) {
            if (bc.owner != null) {
                if (bc.owner.clientAreaSet && bc.useClientArea) {
                    newPos.left += bc.owner.clientArea.left;
                    newPos.top += bc.owner.clientArea.top;
                }
                newPos.left += bc.owner.position.left;
                newPos.top += bc.owner.position.top;
            }
            bc = bc.owner;
        }
        if (newPos.left > oldPos.right || newPos.right < oldPos.left ||
            newPos.top > oldPos.bottom || newPos.bottom < oldPos.top) {
            // no overlap between old and new position -> redraw both locations
            this.redrawPartial(oldPos.left, oldPos.top, oldPos.width, oldPos.height);
            this.redrawPartial(newPos.left, newPos.top, newPos.width, newPos.height);
        } else {
            // overlap between old and new position -> redraw the shared location
            const sharedPos = newPos.copy();
            sharedPos.combineWith(oldPos);
            this.redrawPartial(sharedPos.left, sharedPos.top, sharedPos.width, sharedPos.height);
        }
    }

    /**
        * request to redraw part of the screen in an animation frame and test if it can be added to the pending request
        * @param left x position on screen
        * @param top y position on screen
        * @param width the width to draw
        * @param height the height to draw
        * @param clearArea true or false, indicating if the area should first be cleared
        */
    addPartialRedraw(left: number, top: number, width: number, height: number, clearArea: boolean) {
        if (this.partialRedrawPending) {
            // there already is a pending request, enlarge the pending area if needed
            if (clearArea) this.partialRedrawClear = clearArea;
            if (left + width > this.partialRedrawArea.left + this.partialRedrawArea.width) {
                this.partialRedrawArea.left = Math.min(this.partialRedrawArea.left, left);
                this.partialRedrawArea.width = (left + width) - this.partialRedrawArea.left;
            } else if (left < this.partialRedrawArea.left) {
                this.partialRedrawArea.width = (this.partialRedrawArea.left + this.partialRedrawArea.width) - left;
                this.partialRedrawArea.left = left;
            }
            if (top + height> this.partialRedrawArea.top + this.partialRedrawArea.height) {
                this.partialRedrawArea.top = Math.min(this.partialRedrawArea.top, top);
                this.partialRedrawArea.height = (top + height) - this.partialRedrawArea.top;
            } else if (top < this.partialRedrawArea.top) {
                this.partialRedrawArea.height = (this.partialRedrawArea.top + this.partialRedrawArea.height) - top;
                this.partialRedrawArea.top = top;
            }
            return;
        }
        // there is nothing pending, add a new pending request
        this.partialRedrawPending = true;
        this.partialRedrawClear = clearArea;
        this.partialRedrawArea.left = left;
        this.partialRedrawArea.top = top;
        this.partialRedrawArea.width = width;
        this.partialRedrawArea.height = height;
        requestAnimationFrame(() => {
            // handle the partial redraw inside the next available browsers animation frame
            this.partialRedrawPending = false;
            if (this.partialRedrawClear) this.ctx.clearRect(this.partialRedrawArea.left, this.partialRedrawArea.top, this.partialRedrawArea.width, this.partialRedrawArea.height);
            this.drawControl(this.desktop, 0, 0, this.partialRedrawArea.left, this.partialRedrawArea.top, this.partialRedrawArea.width, this.partialRedrawArea.height);
            this.onDrawEnd();
        });
    }

    /**
        * redraw only the area used by a specific control without clearing first
        * @param control the control to redraw
        */
    redrawControl(control: BasicControl) {
        if (!requestAnimationFrame || !this.redrawInAnimationFrame) {
            this.drawControl(this.desktop, 0, 0, control.screenPos.left, control.screenPos.top, control.screenPos.width, control.screenPos.height);
            this.onDrawEnd();
            return;
        }
        this.addPartialRedraw(control.screenPos.left, control.screenPos.top, control.screenPos.width, control.screenPos.height, false);
    }

    /**
        * clear and redraw a specific area
        * @param left x position on screen
        * @param top y position on screen
        * @param width the width to draw
        * @param height the height to draw
        */
    redrawPartial(left: number, top: number, width: number, height: number) {
        if (width <= 0 || height <= 0) return;
        if (!requestAnimationFrame || !this.redrawInAnimationFrame) {
            this.ctx.clearRect(left, top, width, height);
            this.drawControl(this.desktop, 0, 0, left, top, width, height);
            this.onDrawEnd();
            return;
        }
        this.addPartialRedraw(left, top, width, height, true);
    }

    /**
        * clear and redraw everything
        * @param resized when true the draw action will start immediately
        */
    redraw(resized: boolean = false) {
        if (resized) {
            this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
            this.drawControl(this.desktop, 0, 0, 0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
            this.onDrawEnd();
        } else 
            this.redrawPartial(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    }
}