import { isDictionary } from './../../app/components/utils/dictionary';
import * as Globals from './../../app/components/planboard/utils/globals';

export var omrpTaglist = [
    "$rootScope", "$timeout",
    function ($rootScope, $timeout) {
        return {
            // can be used as an attribute or element or class
            restrict: "AEC",

            template: require("../template/taglist.html"),

            scope: {
                autosizewidth: "@", // true or false, indicates if the dropdown list will be the same width as the tag list
                selchanged: "&", // callback function to call when a change is made in the tag list
                displayproperty: "@", // the property name of an individual item that will be used to display a text for that tag
                enabledproperty: "@", // the property name of an individual item that will determine if the item can be changed
                minimumselected: "@", // the minimum amount of tags that must be selected
                changedisabled: "=", // indicates if the tag list is in disabled mode
                itemvisible: "&?", // the callback function to determine if an individual item is visible
                allitems: "=", // the list (dictionary, object, array) of all items
                selectedids: "=", // the array of selected item ids
                selecteditemadded: "&?", // function executed when an item is added to the selected items
                selecteditemremoved: "&?", // function executed when an item is removed from the selected items
                visibleproperty: "@", // whether the item is visible in the dropdown or not
                gettexttoomanyitems: "&?", // callback function to return the text to show for too many items
                showspinner: "=", // true or false, indicates if the animated wait spinner should be visible
                ondropdown: "&?", // callback function to call after the taglist dropdown is shown or hidden with visible as parameter 
                itemsversion: "=", // when this variable changes the taglist will rebuild the item tree
                getallitems: "&?" // callback function the get all items trough a function instead of the bound allitems property
            },

            link: function (scope, element) {
                scope.triggerElement = element[0];
                scope.dropdownVisible = false;
                scope.dropdownLeft = "0px";
                scope.dropdownTop = "0px";
                scope.dropdownWidth = "250px";
                scope.dropdownHeight = "200px";
                scope.selectedhash = Object.create(null);
                scope.itemids = [];
                scope.selectedchanged = false;
                scope.itemschanged = false;
                scope.textfilter = "";
                scope.textwidth = "7em";
                scope.dropdownvisibility = "visible";
                scope.visibleidlist = [];
                scope.newVisibleidlist = [];
                scope.firstitemid = null;
                scope.tree = Object.create(null);
                scope.dropDownClicked = false; // indicates that the tree has been dropped down at least once
                scope.lastDictVersion = -1;
                scope.items = scope.allitems;
                scope.itemCount = 0; // the total number of items
                scope.maxVisibleItems = 256; // the maximum number of visible items
                scope.additionalHiddenItems = false; // if the hint should be shown that there are more hidden items
                scope.highlightedItemIndex = -1;

                var itemHeight = 0; // height of an item element

                scope.getTooManyItemsText = function () {
                    if (scope.gettexttoomanyitems != undefined)
                        return scope.gettexttoomanyitems();
                    return "...";
                }

                scope.triggerDropdownChanged = function () {
                    if (scope.ondropdown != undefined)
                        scope.ondropdown({ visible: scope.dropdownVisible });
                }

                scope.pxToInt = function (pxvalue) {
                    return parseInt(pxvalue.substring(0, pxvalue.length - 2));
                }

                // recreate the items dropdowntree
                scope.refreshItems = function () {
                    scope.firstitemid = null;
                    scope.items = scope.allitems;
                    if (scope.dropdownVisible) {
                        scope.sanitizeAllItems();
                        scope.buildVisibleList(scope.firstitemid);
                        scope.visibleidlist = scope.newVisibleidlist;
                        scope.repositionDropdown();
                    }
                }

                scope.$watch("allitems", function (value) {
                    scope.itemschanged = true;
                    scope.refreshItems();
                });

                // recreate the items dropdowntree if version changes
                scope.$watch("itemsversion", function (value) {
                    scope.itemschanged = true;
                    scope.refreshItems();
                });

                scope.$watch("selectedids", function (value) {
                    scope.selectedchanged = true;
                });

                scope.$watch("textfilter", function (value) {
                    if (scope.textfilter !== "" && !scope.dropdownVisible) scope.show();
                    var inputElement = $(scope.triggerElement).find(".omrptextfilter");
                    var spanElement = $(scope.triggerElement).find(".omrptextautosize");
                    var containerElement = $(scope.triggerElement).find(".omrptagcontainer");
                    var spanRect = spanElement[0].getBoundingClientRect();
                    var containerRect = containerElement[0].getBoundingClientRect();
                    var inputRect = inputElement[0].getBoundingClientRect();
                    var maxWidth = containerRect.left + (containerRect.width * 0.99) - inputRect.left - 10;
                    var minWidth = maxWidth / 3;
                    var newWidth = Math.max(Math.min(spanRect.width, maxWidth), minWidth);
                    if (newWidth > 0) scope.textwidth = "" + newWidth + "px";
                    if (scope.itemCount > scope.maxVisibleItems) {
                        scope.buildVisibleList(scope.firstitemid);
                        scope.visibleidlist = scope.newVisibleidlist;
                    }
                    scope.repositionDropdown();
                });

                // computes the nextId and childId properties of items in scope.allitems
                scope.sanitizeAllItems = function () {
                    // See if allItems is either a dictionary or an object.
                    scope.selectedchanged = true;
                    var dict = null;
                    var items = scope.allitems;
                    if (scope.getallitems != undefined && scope.dropDownClicked)
                        items = scope.getallitems();
                    if (isDictionary(items)) { // dictionary
                        dict = scope.allitems.getStore();
                        scope.lastDictVersion = items.version;
                    } else // object
                        dict = items;
                    if (!dict) return; // Sadly, no.
                    scope.items = items;

                    var lastRootId = null;
                    var key = -1;
                    var index = -1;
                    var item = null;
                    var treeItem = null;
                    var orderedItems = [];
                    scope.firstitemid = null;
                    scope.tree = Object.create(null);
                    for (<any>key in dict) {
                        if (dict.hasOwnProperty == undefined || dict.hasOwnProperty(key)) {
                            item = dict[key];
                            treeItem = {
                                nextId: null,
                                childId: null,
                                lastChildId: null,
                                open: item.open == undefined ? false : item.open,
                                parentId: item.parentId,
                                order: item.order || item.receivedOrder || 0
                            };
                            scope.tree[key] = treeItem;
                            // remember and sort based upon the items order property
                            orderedItems.push(key);
                            index = orderedItems.length - 1;
                            while (index > 0 && treeItem.order < scope.tree[orderedItems[index - 1]].order) {
                                orderedItems[index] = orderedItems[index - 1];
                                orderedItems[index - 1] = key;
                                index--;
                            }
                        }
                    }
                    scope.itemCount = orderedItems.length;
                    for (var i = 0; i < orderedItems.length; i++) {
                        key = orderedItems[i];
                        item = scope.tree[key];
                        if (!item.parentId || item.parentId < 0 || !scope.tree[item.parentId]) {
                            if (scope.firstitemid == null) scope.firstitemid = key;
                            if (lastRootId != null) scope.tree[lastRootId].nextId = key;
                            lastRootId = key;
                        }
                        if (item.parentId && scope.tree[item.parentId]) {
                            var parent = scope.tree[item.parentId];
                            if (parent.lastChildId)
                                scope.tree[parent.lastChildId].nextId = key;
                            else
                                parent.childId = key;
                            parent.lastChildId = key;
                        }
                    }

                    if (scope.firstitemid && scope.tree[scope.firstitemid]) {
                        scope.tree[scope.firstitemid].open = true;
                    }
                }

                // build an array of all visible items, based upon the open state of each item
                scope.buildVisibleList = function (itemid) {
                    if (scope.firstitemid == null || itemid === scope.firstitemid) scope.newVisibleidlist = [];
                    var item = scope.tree[itemid];
                    while (item != undefined && scope.newVisibleidlist.length < scope.maxVisibleItems) {
                        if (scope.itemCount <= scope.maxVisibleItems || scope.isTagVisible(itemid)) {
                            scope.newVisibleidlist.push(itemid);
                            if (item.open && item.childId)
                                scope.buildVisibleList(item.childId);
                        }
                        if (item.nextId) {
                            itemid = item.nextId;
                            item = scope.tree[itemid];
                        } else
                            item = undefined;
                    }
                    scope.additionalHiddenItems = scope.itemCount > scope.maxVisibleItems && scope.newVisibleidlist.length >= scope.maxVisibleItems;
                }

                scope.focusInput = function () {
                    var inputelement = $(scope.triggerElement).find(".omrptextfilter");
                    (inputelement[0] as any).focus();
                }

                scope.getItemOpen = function (itemid) {
                    var item = scope.tree[itemid];
                    return (item && item.open && item.childId && scope.tree[item.childId]);
                }

                scope.toggleItemOpen = function (itemid) {
                    var item = scope.tree[itemid];
                    if (item && item.childId && scope.tree[item.childId]) {
                        item.open = !item.open;
                        scope.buildVisibleList(scope.firstitemid);
                        scope.visibleidlist = scope.newVisibleidlist;
                        scope.repositionDropdown();
                    }
                }

                scope.getItemIsLeaf = function (itemid) {
                    var item = scope.tree[itemid];
                    return (item && (!item.childId || !scope.tree[item.childId]));
                }

                scope.getItemIndent = function (itemid) {
                    var indent = 0;
                    var item = scope.tree[itemid];
                    while (item && indent < 20) {
                        if (!item.childId || !scope.tree[item.childId]) indent--;
                        item = scope.tree[item.parentId];
                        if (item) indent++;
                    }
                    if (indent < 0) indent = 0;
                    return "" + indent + "em";
                }

                scope.getTagText = function (tagid) {
                    if (isDictionary(scope.items)) { // scope.items = OMRP.Utils.Dictionary
                        if (scope.displayproperty && scope.displayproperty !== "") {
                            var tagitem = scope.items.value(tagid);
                            if (tagitem != undefined) {
                                return scope.items.value(tagid)[scope.displayproperty];
                            }
                            return "" + tagid;
                        }
                        return scope.items.value(tagid);
                    }
                    else if (scope.items.length == undefined) { // scope.items = Object
                        if (scope.displayproperty && scope.displayproperty !== "")
                            return scope.items[tagid] != undefined ? scope.items[tagid][scope.displayproperty] : "";
                    }
                    return scope.items[tagid]; // scope.items = Array or Object
                }

                scope.getAllTagIds = function () {
                    if (scope.itemschanged || scope.itemids.length === 0) {
                        scope.itemschanged = false;
                        scope.itemids = [];
                        var key;
                        if (scope.items != undefined) {
                            if (isDictionary(scope.items)) { // scope.items = OMRP.Utils.Dictionary
                                for (key in scope.items.dict) {
                                    if (scope.items.dict.hasOwnProperty == undefined || scope.items.dict.hasOwnProperty(key)) {
                                        scope.itemids.push(key);
                                    }
                                }
                            } else if (scope.items.length == undefined) { // scope.items = Object
                                for (key in scope.items) {
                                    if (scope.items.hasOwnProperty == undefined || scope.items.hasOwnProperty(key)) {
                                        scope.itemids.push(key);
                                    }
                                }
                            } else { // scope.allitem = Array
                                for (var i = 0; i < scope.items.length; i++) {
                                    if (scope.items[i] !== undefined) scope.itemids.push(i);
                                }
                            }
                            //console.log("recreated list of all tag ids. allitems count = " + scope.itemids.length);
                        }
                    }
                    return scope.itemids;
                }

                scope.isTagSelected = function (tagid) {
                    tagid = parseInt(tagid);
                    if (scope.selectedids == undefined) return false;
                    if (scope.selectedchanged || scope.selectedhash == undefined) {
                        scope.selectedchanged = false;
                        scope.selectedhash = scope.selectedids.length > 0 ? Object.create(null) : undefined;
                        for (var i = 0; i < scope.selectedids.length; i++) {
                            var id = scope.selectedids[i];
                            scope.selectedhash[id] = scope.getTagText(id);
                        }
                        //console.log("recreated hash of selected tags. selected count = " + scope.selectedids.length);
                    }
                    if (scope.selectedhash == undefined) return false;
                    return scope.selectedhash[tagid] != undefined;
                }

                // returns if a tagid exists in the scope.items (allitems)
                scope.tagExists = function (tagid) {
                    tagid = parseInt(tagid);
                    if (isDictionary(scope.items)) // scope.items = OMRP.Utils.Dictionary
                        return scope.items.value(tagid) != undefined;
                    return scope.items[tagid] != undefined; // scope.items = Array or Object
                }

                scope.isTagVisible = function (tagid) {
                    tagid = parseInt(tagid);
                    if (scope.items.value && !scope.items.value(tagid)) return false;
                    if (scope.itemvisible != undefined)
                        if (!scope.itemvisible({ itemId: tagid })) return false;
                    if (scope.visibleproperty && scope.visibleproperty !== "")
                        if (scope.items.value(tagid)[scope.visibleproperty] != undefined && !scope.items.value(tagid)[scope.visibleproperty]) return false;
                    if (scope.getItemIsLeaf(tagid)) {
                        if (scope.isTagSelected(tagid)) return false;
                        var filter = scope.textfilter.trim().toLowerCase();
                        if (filter !== "") {
                            if (scope.getTagText(tagid).trim().toLowerCase().indexOf(filter) < 0) return false;
                        }
                    }
                    return true;
                }

                scope.getItemEnabled = function (tagid) {
                    tagid = parseInt(tagid);
                    if (scope.isTagSelected(tagid)) return false;
                    if (scope.items.value && !scope.items.value(tagid)) return false;
                    if (scope.enabledproperty && scope.enabledproperty !== "")
                        if (!scope.isTagEnabled(tagid)) return false;
                    if (scope.itemvisible != undefined)
                        if (!scope.itemvisible({ itemId: tagid })) return false;
                    var filter = scope.textfilter.trim().toLowerCase();
                    if (filter !== "") {
                        if (scope.getTagText(tagid).trim().toLowerCase().indexOf(filter) < 0) return false;
                    }
                    return true;
                }

                // Returns if the item is highlighted (for formatting)
                scope.getItemHighlighted = function (itemIndex) {
                    if (itemIndex === scope.highlightedItemIndex)
                        return true;
                    return false;
                }

                // Sets scope.highlightedItemIndex to the provided itemIndex (used for mouse over highlighting)
                scope.setItemHighlighted = function (itemIndex) {
                    $timeout(function () {
                        scope.highlightedItemIndex = itemIndex;
                    }, 0);
                }

                // Get the id of the html element or give the html element an unique id if it has none
                scope.getTriggerElementId = function () {
                    if (scope.triggerElement.id === "") scope.triggerElement.id = "genId" + Math.floor(Math.random() * Globals.maxInt53);
                    return scope.triggerElement.id;
                }

                scope.isTagRemovable = function (tagid) {
                    if (scope.minimumselected && scope.minimumselected !== "") {
                        if (scope.selectedids.length <= parseInt(scope.minimumselected)) return false;
                    }
                    return scope.isTagEnabled(tagid);
                }

                scope.isTagEnabled = function (tagid) {
                    if (scope.changedisabled) return false;
                    if (scope.enabledproperty && scope.enabledproperty !== "") {
                        var enabled = true;
                        if (isDictionary(scope.items))
                            enabled = scope.items.value(tagid) && scope.items.value(tagid)[scope.enabledproperty];
                        else if (scope.items.length == undefined)
                            enabled = scope.items[tagid] != undefined && scope.items[tagid][scope.enabledproperty];
                        if (enabled !== undefined && enabled === false) return false;
                    }
                    return true;
                }

                scope.addSelectedTag = function (tagid) {
                    if (scope.changedisabled) return;
                    tagid = parseInt(tagid);
                    if (scope.selectedids == undefined) scope.selectedids = [];
                    scope.selectedids.push(tagid);
                    if (scope.selectedhash == undefined) scope.selectedhash = Object.create(null);
                    scope.selectedhash[tagid] = scope.getTagText(tagid);
                    scope.textfilter = "";
                    scope.dropdownVisible = false;
                    // stop listening for keyboard input / re-enable scroll
                    window.removeEventListener("keydown", keyboardInputHandler, false);
                    scope.triggerDropdownChanged();
                    scope.focusInput();
                    scope.repositionDropdown();
                    if (scope.selchanged != undefined) scope.selchanged();

                    // Signal adding the item to a callback function, if applicable.
                    if (scope.selecteditemadded != undefined) scope.selecteditemadded({ id: tagid });
                }

                scope.removeSelectedTag = function (tagid) {
                    if (scope.changedisabled) return;
                    tagid = parseInt(tagid);
                    var i = scope.selectedids.length;
                    while (i > 0) {
                        i--;
                        if (scope.selectedids[i] === tagid) {
                            scope.selectedhash[tagid] = undefined;
                            scope.selectedids.splice(i, 1);
                        }
                    }
                    scope.repositionDropdown();
                    if (scope.selchanged != undefined) scope.selchanged();

                    // Signal adding the item to a callback function, if applicable.
                    if (scope.selecteditemremoved != undefined) scope.selecteditemremoved({ id: tagid });
                }

                scope.repositionDropdown = function () {
                    $timeout(function () {
                        if (!scope.dropdownVisible) return;
                        if (!scope.dropdownElement) return;
                        if (!scope.displayElement) return;

                        var rectDropdown = scope.dropdownElement.getBoundingClientRect();
                        var rect = scope.displayElement.getBoundingClientRect();
                        var leftPos = rect.left;
                        var topPos = rect.bottom - 1;
                        if (topPos + rectDropdown.height >= window.innerHeight) {
                            topPos = Math.max(rect.top - rectDropdown.height, 0);
                        }
                        if (scope.autosizewidth) {
                            // make the dropdownElement just as wide as the triggerElement
                            scope.dropdownWidth = "" + rect.width + "px";
                        }
                        if (leftPos + scope.pxToInt(scope.dropdownWidth) >= window.innerWidth) {
                            leftPos = Math.max(window.innerWidth - scope.pxToInt(scope.dropdownWidth), 0);
                        }
                        leftPos = Math.round(leftPos + window.pageXOffset);
                        topPos = Math.round(topPos + window.pageYOffset);
                        scope.dropdownLeft = "" + leftPos + "px";
                        scope.dropdownTop = "" + topPos + "px";
                        scope.dropdownvisibility = "visible";
                    }, 1);
                }

                scope.show = function () {
                    if (scope.changedisabled) return;
                    if (!scope.dropdownElement) {
                        // find the dropdown-list and move it to the end of the document body
                        // this is neccesary so it wont be restricted by a parents bounding div element
                        // scope.triggerElement = the element triggering this directive
                        // scope.triggerElement.firstElementChild = "dropdown-container" element
                        // scope.triggerElement.firstElementChild.lastElementChild = "dropdown-list" element
                        // scope.triggerElement.firstElementChild.firstElementChild = "dropdown-display" element
                        scope.displayElement = scope.triggerElement.firstElementChild.firstElementChild;
                        scope.dropdownElement = scope.triggerElement.firstElementChild.lastElementChild;
                        scope.dropdownElement = document.body.appendChild(scope.dropdownElement);
                    }

                    // Listen for keyboard input
                    window.addEventListener("keydown", keyboardInputHandler, false);

                    // Set the highlightedItemIndex to the currently selected item, or -1
                    $timeout(function () {
                        scope.highlightedItemIndex = scope.visibleidlist.indexOf(String(scope.selectedid));
                        scrollIfNeeded(scope.highlightedItemIndex, "UP");
                    }, 0);

                    scope.dropDownClicked = true;
                    // initialize, if either sanitizeAllItems has not been called before (firstitemid == null) or always if getallitems callback function is specified
                    if (scope.firstitemid == null || scope.getallitems != undefined)
                        scope.sanitizeAllItems();
                    else if (isDictionary(scope.allitems) && scope.lastDictVersion !== scope.allitems.version) {
                        // dictionary has different version
                        scope.sanitizeAllItems();
                    }
                    scope.buildVisibleList(scope.firstitemid);
                    scope.visibleidlist = scope.newVisibleidlist;

                    // position the dropdownElement near triggerElement
                    var rect = scope.displayElement.getBoundingClientRect();
                    var leftPos = rect.left;
                    var topPos = rect.bottom - 1;
                    if (topPos + scope.pxToInt(scope.dropdownHeight) >= window.innerHeight) {
                        topPos = Math.max(rect.top - scope.pxToInt(scope.dropdownHeight), 0);
                        scope.dropdownvisibility = "hidden";
                    }
                    if (scope.autosizewidth) {
                        // make the dropdownElement just as wide as the triggerElement
                        scope.dropdownWidth = "" + rect.width + "px";
                    }
                    if (leftPos + scope.pxToInt(scope.dropdownWidth) >= window.innerWidth) {
                        leftPos = Math.max(window.innerWidth - scope.pxToInt(scope.dropdownWidth), 0);
                    }
                    leftPos = Math.round(leftPos + window.pageXOffset);
                    topPos = Math.round(topPos + window.pageYOffset);
                    scope.dropdownLeft = "" + leftPos + "px";
                    scope.dropdownTop = "" + topPos + "px";

                    // toggle visibility of the dropdownElement
                    scope.dropdownVisible = !scope.dropdownVisible;
                    if (!scope.dropdownVisible) {
                        // stop listening for keyboard input / re-enable scroll
                        window.removeEventListener("keydown", keyboardInputHandler, false);
                    }
                    scope.triggerDropdownChanged();
                    scope.repositionDropdown();
                };

                // Event handler for Keyboard input
                // Arrow keys, Tab, Enter, Escape
                function keyboardInputHandler(e) {
                    // event.keyCode is deprecated. If it doesn't exist, use event.key.
                    var keyCode = e.keyCode;
                    if (keyCode === undefined || keyCode === 0)
                        keyCode = e.key;

                    // handle keys
                    switch (keyCode) {
                        case 9:
                        case "Tab":
                            e.preventDefault();
                            onTabKeyPress();
                            break;
                        case 13:
                        case "Enter":
                            e.preventDefault();
                            onEnterKeyPress();
                            break;
                        case 27:
                        case "Escape":
                        case "Esc":
                            e.preventDefault();
                            onEscapeKeyPress();
                            break;
                        case 37:
                        case "ArrowLeft":
                        case "Left":
                            onArrowLeftKeyPress();
                            break;
                        case 38:
                        case "ArrowUp":
                        case "Up":
                            e.preventDefault();
                            onArrowUpKeyPress();
                            break;
                        case 39:
                        case "ArrowRight":
                        case "Right":
                            onArrowRightKeyPress();
                            break;
                        case 40:
                        case "ArrowDown":
                        case "Down":
                            e.preventDefault();
                            onArrowDownKeyPress();
                            break;
                        default:
                            break;
                    }
                }

                // Handle Up Arrow key press
                // Allows user to navigate up through the dropdown items
                function onArrowUpKeyPress() {
                    if (scope.highlightedItemIndex > 0) {
                        scope.$apply(function () {
                            scope.highlightedItemIndex -= 1;
                        });
                    }
                    scrollIfNeeded(scope.highlightedItemIndex, "UP");
                }

                // Handle Down Arrow key press
                // Allows user to navigate down through the dropdown items
                function onArrowDownKeyPress() {
                    if (scope.highlightedItemIndex < scope.visibleidlist.length - 1) {
                        scope.$apply(function () {
                            scope.highlightedItemIndex += 1;
                        });
                    }
                    scrollIfNeeded(scope.highlightedItemIndex, "DOWN");
                }

                // Handle Enter key press
                // Selects the currently highlighted item and closes the dropdown
                function onEnterKeyPress() {
                    if (scope.highlightedItemIndex > -1 && scope.highlightedItemIndex < scope.visibleidlist.length) {
                        var itemId = scope.visibleidlist[scope.highlightedItemIndex];
                        scope.addSelectedTag(itemId);
                        scope.setItemHighlighted(-1);
                    }
                }

                // Handle Tab key press
                function onTabKeyPress() {
                    // Future TODO: handle tab key press?
                    // If they are using keyboard to scroll through dropdown, perhaps they're using tab to go to next form item
                    // Should this select the item and move to the next form element?
                    // Or perhaps simply close the dropdown without selecting and focus the next form element?
                }

                // Handle Escape key press
                // Closes the dropdown without changing the selection
                function onEscapeKeyPress() {
                    $timeout(function () {
                        scope.dropdownVisible = false;
                        // stop listening for keyboard input / re-enable scroll
                        window.removeEventListener("keydown", keyboardInputHandler, false);
                        scope.triggerDropdownChanged();
                    }, 0);
                }

                // Handle Left Arrow key press
                function onArrowLeftKeyPress() {
                    // Future TODO: collapse a tree node
                }

                // Handle Right Arrow key press
                function onArrowRightKeyPress() {
                    // Future TODO: expand a tree node
                }

                // Performs the provided scroll action if needed
                function scrollIfNeeded(itemIndex, scrollDirection) {
                    // Escape early if there is no overflow or if itemIndex is < 0
                    if (scope.dropdownElement.clientHeight >= scope.dropdownElement.scrollHeight || itemIndex < 0) return;
                    // Get an item element to calculate the height if it hasn't been calculated before
                    if (itemHeight <= 0) {
                        var firstItemElement = scope.dropdownElement.firstElementChild.firstElementChild;
                        itemHeight = firstItemElement.offsetHeight;
                    }
                    // Calculate the item top and bottom positions relative to the entire item list container
                    // +1 to account for the 1 pixel space between items (potentially due to a return character)
                    var itemTop = itemIndex * (itemHeight + 1);
                    var itemBottom = itemTop + itemHeight;

                    // Calculate the approximate height of the dropdown.
                    // Removing 2X the vertical padding gives us a height that is a bit smaller than the visible area
                    // When only removing 1X the vertical padding, the height was a bit bigger than the visible area which clipped the bottom highlighted item
                    var computedStyle = getComputedStyle(scope.dropdownElement);
                    var dropdownHeight = scope.dropdownElement.clientHeight -
                        2 * (parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom));

                    // Calculate the top and bottom positions of the visible view
                    var dropdownTop = scope.dropdownElement.scrollTop;
                    var dropdownBottom = dropdownTop + dropdownHeight;

                    if (itemTop < dropdownTop || itemBottom > dropdownBottom) {
                        if (scrollDirection === "UP")
                            scrollUpTo(itemTop);
                        else if (scrollDirection === "DOWN")
                            scrollDownTo(itemBottom, dropdownHeight);
                    }
                }

                // Set the scrollTop to the correct value so that the item will be at the top of the dropdown
                function scrollUpTo(itemTop) {
                    scope.dropdownElement.scrollTop = itemTop;
                }

                // Set the scrollTop to the correct value so that the item will be at the bottom of the dropdown
                function scrollDownTo(itemBottom, dropdownHeight) {
                    scope.dropdownElement.scrollTop = itemBottom - dropdownHeight;
                }

                scope.$on("$destroy", function () {
                    // if the dropdownElement was attached to the body, we also need to remove it when the scope is destroyed
                    if (scope.dropdownElement) document.body.removeChild(scope.dropdownElement);
                });

                $rootScope.$on("documentClicked", function (inner, target) {
                    if (!scope.dropdownVisible) return; // exit early if the dropdown is not visible
                    // check if the clicked on control has a specific class or has any parent with that specific class, then do not close the dropdown
                    var parents = $(target[0]).parents();
                    for (var i = 0; i < parents.length; i++)
                        if (parents[i] === scope.triggerElement) return;
                    if (!$(target[0]).is(".ignore-document-clicked") &&
                        !(<any>($(target[0]).parents(".ignore-document-clicked").length) > 0)
                        //&& !$(target[0]).is(".dropdown-display.clicked") &&
                        //!$(target[0]).parents(".dropdown-display.clicked").length > 0
                    )
                        scope.$apply(function () {
                            scope.dropdownVisible = false;
                            // stop listening for keyboard input / re-enable scroll
                            window.removeEventListener("keydown", keyboardInputHandler, false);
                            scope.triggerDropdownChanged();
                        });
                });
            }
        };
    }
];