
import { Dictionary } from './../utils/dictionary';
import { ITreeListScope } from '../treeListController/ITreeListScope';
import { ITranslationService } from '../i18n/translationService';
import { IPageStartService } from '../../shared/pageStartService';
import { INumberService } from '../../shared/numberService';
import { IModalConfirmationWindowService } from '../utils/modalConfirmationWindowService';

export class NarrowCastingConfigCtrl {
    $scope: ITreeListScope;
    userOrganizationUnits = new Dictionary();
    userResourceTypes = new Dictionary();
    configurations = [];
    selectedConfigId = "0";
    noSelectedConfig = { id: "0", displayName: "", organizationUnitId: -1, activityTypeIds: [] };
    selectedConfig: any;
    selectAllActivityTypesCheck = false

    activityTypeFilter = "";
    activityTypesTree: Array<any> = []; // list of all root activity types
    activityTypesPage = 1; // currently active pagination page
    activityTypesPageNrs = []; // array of pagination page numbers
    activityTypesPerPage = 20; // amount of activity types to show per page in pagination
    filteredActivityTypes: Array<any> = []; // activity types filtered by categories
    filteredActivityTypesTree = []; // array of activity types based on user-entered filter
    visibleActivityTypes = []; // array of activity types that will be visible on a given page
    activityTypeSearch = ""; // empty string from filter search query
    organizationUnitTree: Array<any> = []; // for the tree template
    categories = new Dictionary(); // dictionary with activity type categories
    activityTypes: Array<any> = []; // all activity types
    availableActivityTypes: Array<any> = []; // all available activity types to be filtered
    modifiedActivityTypes: Array<any> = []; // activity type settings modifiedvar 
    activityCategory = 2; // for filtering out non-activity activities (absence and the like).
    activitySearchTimer = null;
    daysOfWeek = new Dictionary();
    extraEmails: Array<any> = [];
    activePageNr: number = 1;
    pageNrList: Array<number> = [];
    resultsPerPage = 10;
    activePages: Array<number> = [];
    updatingActivityTypeSettings: boolean = false;
    existingActivityTypeSettings: Array<any> = [];


    private commonSvc: any;
    private saveConfigurationsTimer: angular.IPromise<any> = null;
    private automaticSaveDelay = 5000;
    
    private readonly apiUrl = "api/NarrowCastingConfiguration";
    private readonly organizationUnitApiUrl = "api/OrganizationUnits";
    private readonly activityTypeApiUrl = "api/ActivityTypes/MainActivityTypes";
    private readonly resourceTypeApiUrl = "api/ResourceTypes";
    private readonly dialogToken = "narrowCastingConfig";

    static $inject = [
        "$scope",
        "$filter",
        "$timeout",
        "translationService",
        "pageStartService",
        "numberService",
        "modalConfirmationWindowService"
    ];
    constructor(
        $scope: ITreeListScope,
        private $filter: ng.IFilterService,
        private $timeout: ng.ITimeoutService,
        private translationService: ITranslationService,
        private pageStartService: IPageStartService,
        private numberService: INumberService,
        private modalConfirmationWindowService: IModalConfirmationWindowService) {

        this.translationService.getTextLabels($scope);

        this.$scope = $scope;
        this.$timeout = $timeout;

        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);
        this.commonSvc.start(() => { this.loadData(); });

        /**
        * call save changes on destroy.
        */
        this.$scope.$on("$destroy", () => {
            this.saveConfigurations(0);
            if (this.saveConfigurationsTimer) {
                this.$timeout.cancel(this.saveConfigurationsTimer);
                this.saveConfigurationsTimer = null;
            }
        });

    }

    /**
    * Registers an organization unit as child to its parent.
    * @param organizationUnit Organization to register to its parent.
    * @param childId Id of the child to register/propagate.
    */
    private registerChildToParent(organizationUnit: any, childId?: number): void {
        if (!organizationUnit.childIds) organizationUnit.childIds = [];
        if (!organizationUnit.parentId) return;
        if (!childId) childId = organizationUnit.id;
        var parent = this.userOrganizationUnits.value(organizationUnit.parentId);
        if (parent) {
            if (!parent.childIds) parent.childIds = [childId];
            else parent.childIds.push(childId);
            this.registerChildToParent(parent, childId);
        }
    }

    /**
    * Checks whether an organization unit is the child of another organization unit.
    * @param {} rootUnitId The root of the subtree which should be checked for the presence of the unit to check.
    * @param {} unitIdToCheck Id of the unit to check for: the function returns true if it is found in the subtree denoted by rootUnitId.
    */
    private isChildOfOrganizationUnit(rootUnitId: number, unitIdToCheck: number) {
        var result = rootUnitId === unitIdToCheck; // for this purpose, we say that a unit can be its own child
        var rootUnit = this.userOrganizationUnits.value(rootUnitId);
        if (rootUnit) {
            for (var i = 0; i < rootUnit.childIds.length; i++) {
                result = result || this.isChildOfOrganizationUnit(rootUnit.childIds[i], unitIdToCheck);
            }
        }

        return result;
    }

    /**
    * Filters the available activity types based on the selected organization unit and the correct category.
    */
    private filterActivityTypes(): void {
        this.createFilteredActivityTypeList();
        this.switchPage(0);
        this.updateSelectAllActivityTypesCheck();
    }

    /**
    * load all data for this view, will be called by commonSvc.start (see below)
    * @param scope The scope that the service was initialized with
    */
    private loadData(): void {

        this.commonSvc.loadData(this.activityTypeApiUrl,
            this.activityTypesTree,
            () => {
                this.activityTypesTree.sort((a, b) => a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1);
            }, null, true, true);

        // load organization units
        this.commonSvc.loadData(this.organizationUnitApiUrl, this.userOrganizationUnits, (success) => {
            // Register all organization units as children to their parents.
            this.userOrganizationUnits.forEach((key, value) => {
                value.selectable = value.maxPermissionForCurrentUser >= 2;
                this.registerChildToParent(value, undefined);
            });
        }, null, true, true);

        // load resource types
        this.commonSvc.loadData(this.resourceTypeApiUrl, this.userResourceTypes, null, null, true, true);

        // load configurations
        this.commonSvc.loadData(this.apiUrl, this.configurations, null, null, true, true);
    }

    private reloadConfigurationFromWebApi(configurationId: number) {
        this.commonSvc.loadData(this.apiUrl + "/" + configurationId.toString(), null, (response) => {
            var configuration = response.data;
            for (var i = 0; i < this.configurations.length; i++) {
                if (this.configurations[i].id === configuration.id) {
                    this.configurations[i] = configuration;
                    break;
                }
            }
        });
    }

    /**
    * Saves a single configuration to the WebAPI and updates/inserts the client-side version by what is returned by the server.
    * @param {} config Configuration to save.
    */
    private saveConfigurationToWebApi(config: any) {
        this.commonSvc.putData(this.apiUrl,
            config,
            (response) => {
                var configuration = response.data;
                if (config.id) {
                    for (var i = 0; i < this.configurations.length; i++) {
                        if (this.configurations[i].id === configuration.id) {
                            this.configurations[i] = configuration;
                            if (configuration.id.toString() === this.selectedConfigId) this.selectedConfig = configuration;
                            break;
                        }
                    }
                } else {
                    this.configurations.push(configuration);
                    this.selectedConfig = configuration;
                    this.selectedConfigId = this.selectedConfig.id.toString();
                    this.filterActivityTypes();
                }
            },
            (response) => {
                this.commonSvc.httpErrorResponse(response, () => { });
                if (config.id) this.reloadConfigurationFromWebApi(config.id);
            },
            true);
    }

    /**
    * Saves all configurations that have been marked dirty (i.e.: changes have been made to them by the user).
    */
    private saveConfigurationsToWebApi() {
        for (var i = 0; i < this.configurations.length; i++) {
            if (this.configurations[i].dirty) this.saveConfigurationToWebApi(this.configurations[i]);
        }
    }

    /**
    * filter textbox input to only accept integer values
    */
    private filterTextValue($event, oldValue) {
        this.numberService.filterTextValue($event, oldValue, false);
    }


    /**
    * Adds a new, empty configuration.
    */
    private addNewConfiguration() {
        var configuration = {
            courtName: this.$scope.textLabels["NARROWCASTING_NEW_COURT_NAME"]
        }
        this.saveConfigurationToWebApi(configuration);
    }

    /**
    * Deletes the currently selected configuration.
    */
    private deleteConfiguration() {
        this.modalConfirmationWindowService.showModalDialog(this.$scope.textLabels.EXCHANGE_SERVER_DELETE,
            this.$scope.textLabels.EXCHANGE_UPDATE_REMOVE_CONFIRM,
            () => {
                var configId = this.selectedConfig.id;
                this.commonSvc.deleteData(this.apiUrl + "/" + configId,
                    () => {
                        for (var i = 0; i < this.configurations.length; i++) {
                            if (this.configurations[i].id === configId) {
                                this.configurations.splice(i, 1);
                                this.selectedConfigId = "0";
                                this.selectedConfig = this.noSelectedConfig;
                                break;
                            }
                        }
                    },
                    null,
                    true);
            },
            null
        );
    }

    /**
    * Save configurations.
    * @param timeout number of milliseconds to wait before saving, set to 0 to save immediately.
    */
    private saveConfigurations(timeout: any) {
        if (this.saveConfigurationsTimer) { this.$timeout.cancel(this.saveConfigurationsTimer); this.saveConfigurationsTimer = null; }
        if (timeout == null || timeout <= 0) {
            this.saveConfigurationsToWebApi();
            return;
        }
        this.saveConfigurationsTimer = this.$timeout(() => {
            this.saveConfigurationsToWebApi();
        }, timeout);
    }

    /**
    * when the user selects a configuration from the option list
    */
    private onSelectConfig() {
        var foundConfig = null;
        for (var i = 0; i < this.configurations.length; i++)
            if (this.configurations[i].id.toString() === this.selectedConfigId) {
                foundConfig = this.configurations[i];
                break;
            }
        this.selectedConfig = foundConfig ? foundConfig : this.noSelectedConfig;
        this.filterActivityTypes();
    }

    /**
    * When the user changes a configuration, it is marked as dirty for automatic saving.
    */
    private onConfigChanged(): void {
        this.selectedConfig.dirty = true;
        this.saveConfigurations(this.automaticSaveDelay);
    }

    /**
    * When the user changes the organization unit, we need to filter the available activity types to choose from.
    */
    private onOrganizationUnitChanged() {
        this.$timeout(() => {
            this.filterActivityTypes();
            this.onConfigChanged();
        },
            0);
    }




    // everything under this point is logic for "activity types"
    private updateSelectAllActivityTypesCheck() {
        for (var i = 0; i < this.filteredActivityTypesTree.length; i++) {
            if (!this.filteredActivityTypesTree[i].selected) {
                this.selectAllActivityTypesCheck = false;
                return;
            }
        }
        this.selectAllActivityTypesCheck = true;
    }

    private changeActivityTypeSelectedState(activityType, selected, updateCallback?) {
        var changes = false;
        if (selected) {
            if (this.selectedConfig.activityTypeIds.indexOf(activityType.id) < 0) {
                this.selectedConfig.activityTypeIds.push(activityType.id);
                changes = true;
            }
        }
        else {
            var indexToRemove = this.selectedConfig.activityTypeIds.indexOf(activityType.id);
            if (indexToRemove !== -1) {
                this.selectedConfig.activityTypeIds.splice(indexToRemove, 1);
                changes = true;
            }
        }

        activityType.selected = selected;
        if (updateCallback != undefined) {
            this[updateCallback]();
            //updateCallback();
        }

        if (changes) {
            this.onConfigChanged();
        }
    }

    private  selectAllActivityTypesCheckChanged() {
        this.$timeout(() => {
            for (var i = 0; i < this.filteredActivityTypesTree.length; i++) {
                this.changeActivityTypeSelectedState(this.filteredActivityTypesTree[i], this.selectAllActivityTypesCheck);
            }
        }, 0);
    }

    private selectActivityTypeCheckChanged(activityType) {
        this.$timeout(() => {
            this.changeActivityTypeSelectedState(activityType, activityType.selected, "updateSelectAllActivityTypesCheck");
        }, 0);
    }



    private  onActivitySearchChanged() {
        if (this.activitySearchTimer) this.$timeout.cancel(this.activitySearchTimer);
        this.activitySearchTimer = this.$timeout(() => {
            this.activityTypesPage = 1;
            this.createFilteredActivityTypeList();
            this.setActivityTypesToShow();
            this.updateSelectAllActivityTypesCheck();
        }, 1000);
    }



    private switchPage(page): void  {
        this.activityTypesPage = Math.max(Math.min(page, this.activityTypesPageNrs.length), 1);
        this.setActivityTypesToShow();
    }

    private setActivityTypesToShow() {
        this.activityTypesPageNrs = [];
        var amountOfPages = Math.ceil(this.filteredActivityTypesTree.length / this.activityTypesPerPage);
        for (var i = 0; i < amountOfPages; i++)
            this.activityTypesPageNrs.push(i + 1);

        this.visibleActivityTypes = [];
        var activityIndex = (this.activityTypesPage - 1) * this.activityTypesPerPage;
        for (var i = activityIndex; i < this.filteredActivityTypesTree.length && i < (this.activityTypesPerPage + activityIndex); i++) {
            var activityType = this.filteredActivityTypesTree[i];
            activityType.selected = this.selectedConfig.activityTypeIds.indexOf(activityType.id) > -1;
            this.visibleActivityTypes.push(activityType);
        }
    }

    private createFilteredActivityTypeList = () => {
        this.filteredActivityTypesTree = [];

        let filter = this.activityTypeSearch.trim().toLowerCase();
        let today = new Date();
        for (var i = 0; i < this.activityTypesTree.length; i++) {
            let activityType = this.activityTypesTree[i];
            activityType.selected = false;
            if (activityType.categoryId === this.activityCategory &&
                this.isChildOfOrganizationUnit(this.selectedConfig.organizationUnitId, activityType.ownerOrganizationUnitId)) {
                activityType.selected = this.selectedConfig.activityTypeIds.indexOf(activityType.id) > -1;

                let showActivityType = filter === '' || activityType.displayName.trim().toLowerCase().indexOf(filter) >= 0 ||
                    activityType.shortName.trim().toLowerCase().indexOf(filter) >= 0;

                // hide activity types that are expired unless it is selected
                if (showActivityType && !activityType.selected && activityType.validTo != null) {
                    if (activityType.validTo.getTime == null) {
                        activityType.validTo = new Date(activityType.validTo);
                    }
                    showActivityType = !(activityType.validTo < today);
                }

                if (showActivityType) this.filteredActivityTypesTree.push(activityType);
            }
        }
    }
}