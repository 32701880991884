import { Dictionary } from './../utils/dictionary';

export interface IModalConfirmationWindowService {

    /**
        * Shows a modal confirmation window with yes/no buttons. Buttons execute the specified actions.
        * @param windowTitle Title of the window.
        * @param windowText Text of the window.
        * @param yesButtonAction Action to be performed when the "yes" button is clicked.
        * @param noButtonAction Action to be performed when the "no" button is clicked.
        */
    showModalDialog(windowTitle: string, windowText: string, yesButtonAction: () => void, noButtonAction: () => void): ng.ui.bootstrap.IModalInstanceService | null;

    /**
        * Show a modal question dialog window with multiple buttons
        * @param windowTitle title of the dialog
        * @param windowText text in the dialog
        * @param buttonTextArray array with text of each button
        * @param buttonActionArray array with action of each button
        */
    showModalQuestionDialog(windowTitle: string, windowText: string, buttonTextArray: string[], buttonActionArray: any[]): ng.ui.bootstrap.IModalInstanceService;

    /**
        * Show a modal information dialog with 1 button.
        * @param windowTitle Title of the dialog.
        * @param windowText Text in the dialog.
        * @param buttonText Text in the button.
        * @param buttonAction Action after the button is pressed.
        * @param timeoutBeforeShow Milliseconds to wait before the dialog is shown.
        * @param versionToken Unique identifier for this type of dialog. When delayed starting multiple windows with the same token,
        * only the last one is shown. Also, the same token is needed to close this modal window again. This allows for multiple
        * parallel sets of windows that do not interfere with one another.
        */
    showModalInfoDialog(windowTitle: string, windowText: string, buttonText: string, buttonAction: () => void, timeoutBeforeShow: number, versionToken: string): ng.ui.bootstrap.IModalInstanceService | null;

    /**
        * Close the modal window
        * @param versionToken unique identifier for this type of dialog
        */
    closeModalWindow(versionToken: string): void;
}

// Service that can be used to show modal confirmation windows for critical operations.
export var modalConfirmationWindowService = [
    "$uibModal", "$timeout",
    function ($uibModal: ng.ui.bootstrap.IModalService, $timeout: ng.ITimeoutService) {
        var svc = this;

        /**
         * An array of all question windows that are currently open.
         */
        svc.stackedQuestionWindows = [];

        /**
         * An array of all information windows that are currently open.
         */
        svc.stackedInformationWindows = [];

        /**
         * Array with button question objects, each object has a button text and button action.
         */
        svc.questionButtons = [{ text: "", action: function () { } }];

        /**
         * Default text for yes/no buttons, will be overridden in controller.
         */
        svc.yesButtonText = "Yes";
        svc.noButtonText = "No";

        /**
         * openedWindow is the instance of the window that is opened with the $uibModal.open call.
         */
        svc.openedWindow = null;

        /**
         * version nr of the window. This is used by a $watch in the controller, with every change the text in the modal window is replaced.
         */
        svc.windowVersion = 1;

        /**
         * used to remember if an information window with an unique token is already opened (or about to be opened).
         */
        svc.tokens = new Dictionary();

        /**
         * used to signal to the controller that the window should be closed, this is a failsafe in case the window can not be closed from the service.
         */
        svc.closeNow = false;

        /**
         * used to indicate that a dialog (showModalDialog, showModalQuestionDialog) is currently open.
         * an information window (showModalInfoDialog) will not show if a dialog is still open.
         */
        svc.dialogOpen = false;

        /**
         * Timer to close an information window if it remains open too long.
         */
        svc.timeoutCloseButtonVisible = false;
        var informationWindowTimeout = null;
        var informationWindowTimeoutTime = 0; // set to a value geater than 0 (e.g. 30000) to allow the user to close information windows if the remain open too long.

        /**
         * Get the total number of stacked question windows and information windows.
         */
        svc.getOpenWindowCount = function () { return svc.stackedQuestionWindows.length + svc.stackedInformationWindows.length; }

        /**
         * Returns if the close button is visible.
         * This happens when the informationWindowTimeoutTime is reached (if it is not 0) or if the window has no version token and no other buttons.
         */
        svc.isTimeoutCloseButtonVisible = function () {
            if (svc.informationWindowToken == null && svc.questionButtons.length === 0) return true;
            return svc.timeoutCloseButtonVisible;
        }

        /**
         * Initialize the variables for the next dialog.
         * @param useStackedInformationWindows when true use the stackedInformationWindows array instead of the stackedQuestionWindows array.
         */
        var initNextDialog = function (useStackedInformationWindows) {
            var dialog = useStackedInformationWindows == true ? svc.stackedInformationWindows[0] : svc.stackedQuestionWindows[0];
            svc.timeoutCloseButtonVisible = false;
            if (informationWindowTimeout != null) {
                $timeout.cancel(informationWindowTimeout);
                informationWindowTimeout = null;
            }
            if (useStackedInformationWindows == true && informationWindowTimeoutTime > 0)
                informationWindowTimeout = $timeout(function () {
                    svc.timeoutCloseButtonVisible = true;
                }, informationWindowTimeoutTime);
            svc.windowTitle = dialog.windowTitle;
            svc.windowText = dialog.windowText;
            svc.multiLine = dialog.multiLine;
            svc.singleLine = dialog.singleLine;
            svc.textValue = dialog.textValue;
            svc.showSpinner = dialog.showSpinner;
            svc.informationWindowToken = dialog.versionToken;
            var questionButtons = [];
            if (dialog.buttonTextArray != null && dialog.buttonTextArray.length > 0)
                for (var i = 0; i < dialog.buttonTextArray.length; i++)
                    questionButtons.push({
                        text: dialog.buttonTextArray[i],
                        action: dialog.buttonActionArray != null && i < dialog.buttonActionArray.length ? dialog.buttonActionArray[i] : null
                    });
            svc.questionButtons = questionButtons;
            svc.windowVersion++;
        }

        /**
         * Used by showModalQuestionDialog. Called directly or from $timeout.
         */
        var showDialog = function (windowTitle, windowText, buttonTextArray, buttonActionArray, multiLine, singleLine, textValue, showSpinner, informationWindowToken): ng.ui.bootstrap.IModalInstanceService {
            // should it still be shown? An information window opened with timeout may have already been closed before this code is even reached.
            if (informationWindowToken != null) {
                var item = svc.tokens.value(informationWindowToken);
                if (item == null) return;
            }

            // use either the stackedInformationWindows or stackedQuestionWindows
            var stack = informationWindowToken != null ? svc.stackedInformationWindows : svc.stackedQuestionWindows;

            // push the variables for this dialog on the stack
            stack.push(
                {
                    windowTitle: windowTitle,
                    windowText: windowText,
                    buttonTextArray: buttonTextArray,
                    buttonActionArray: buttonActionArray,
                    multiLine: multiLine,
                    singleLine: singleLine,
                    textValue: textValue,
                    showSpinner: showSpinner,
                    versionToken: informationWindowToken
                });

            // situation: this is the only question dialog, an information dialog might be open -> show the contents of this question dialog
            if (svc.stackedQuestionWindows.length === 1) initNextDialog(false);
            // situation: no dialog is open -> show the contents of this question or information dialog
            else if (!svc.dialogOpen) initNextDialog(informationWindowToken != null);

            // show the modal window if no modal window is currently open
            if (!svc.dialogOpen) {
                svc.dialogOpen = true;
                svc.closeNow = false;
                svc.openedWindow = $uibModal.open({
                    animation: true,
                    backdrop: "static",
                    keyboard: false,
                    template: require("./modalQuestionWindowView.html"),
                    controller: "ModalConfirmationWindowCtrl"
                });
            }

            return svc.openedWindow;
        }

        /**
         * Show the next dialog on the stack or close the dialog if nothing else is on the stack.
         * The question windows are always shown first, only if there are no question windows remaining will information windows be shown.
         * Returns true if there was a next dialog, returns false if the dialog was closed.
         * @param removeCurrent true to remove the current question window from the stack
         */
        svc.showNextDialog = function (removeCurrent, result: any) {
            if (removeCurrent == true && svc.stackedQuestionWindows.length > 0) // remove current question dialog from the stack
                svc.stackedQuestionWindows.splice(0, 1);
            if (svc.stackedQuestionWindows.length > 0) // show the next question dialog
                initNextDialog(false);
            else if (svc.stackedInformationWindows.length > 0) // show the next information dialog
                initNextDialog(true);
            else { // nothing remains on either stack -> close the modal window
                svc.closeNow = true;
                if (svc.openedWindow != null) svc.openedWindow.close(result);
                svc.openedWindow = null;
                svc.dialogOpen = false;
                return false;
            }
            return true;
        }

        /**
         * Close the information dialog where the versionToken matches.
         * Returns true if there was a next dialog, returns false if the dialog was closed.
         */
        svc.closeModalWindow = function (versionToken) {
            if (versionToken != null) {
                svc.tokens.remove(versionToken);
                var i = svc.stackedInformationWindows.length;
                while (i > 0) {
                    i--;
                    if (svc.stackedInformationWindows[i].versionToken === versionToken)
                        svc.stackedInformationWindows.splice(i, 1);
                }
            }
            return svc.showNextDialog(false);
        }

        /**
         * Show a modal question dialog window with multiple buttons
         * @param windowTitle title of the dialog
         * @param windowText text in the dialog
         * @param buttonTextArray array with text of each button
         * @param buttonActionArray array with action of each button
         * @param multiLine (optional) true to show the multi line text edit
         * @param singleLine (optional) true to show the single line text edit
         * @param textValue (optional) text value for the edit box
         * @param showSpinner (optional) true to show animated wait spinner
         * @param timeoutBeforeShow (optional) miliseconds to wait before showing the dialog
         * @param informationWindowToken (optional) unique token for the information window
         */
        svc.showModalQuestionDialog = function (windowTitle, windowText, buttonTextArray, buttonActionArray,
            multiLine, singleLine, textValue, showSpinner, timeoutBeforeShow, informationWindowToken): ng.ui.bootstrap.IModalInstanceService | null {
            if (timeoutBeforeShow != null && timeoutBeforeShow > 0)
                $timeout(function () {
                    showDialog(windowTitle, windowText, buttonTextArray, buttonActionArray, multiLine, singleLine, textValue, showSpinner, informationWindowToken);
                }, timeoutBeforeShow);
            else
                return showDialog(windowTitle, windowText, buttonTextArray, buttonActionArray, multiLine, singleLine, textValue, showSpinner, informationWindowToken);
            return null;
        }

        /**
         * Show a modal confirmation dialog window with a Yes and No button
         * @param windowTitle title of the dialog
         * @param windowText text in the dialog
         * @param yesButtonAction action after the Yes button is pressed
         * @param noButtonAction action after the No button is pressed
         */
        svc.showModalDialog = function (windowTitle, windowText, yesButtonAction, noButtonAction): ng.ui.bootstrap.IModalInstanceService | null {
            return svc.showModalQuestionDialog(windowTitle, windowText, [svc.yesButtonText, svc.noButtonText], [yesButtonAction, noButtonAction]);
        }

        /**
         * Show a modal text dialog window with multiple buttons and a text input field
         * @param windowTitle title of the dialog
         * @param windowText text in the dialog
         * @param buttonTextArray array with text of each button
         * @param buttonActionArray array with action of each button
         * @param textValue the initial text value
         * @param multiLine boolean value to allow entering in a multi line field
         */
        svc.showModalTextDialog = function (windowTitle, windowText, buttonTextArray, buttonActionArray, textValue, multiLine) {
            svc.showModalQuestionDialog(windowTitle, windowText, buttonTextArray, buttonActionArray, multiLine, !multiLine, textValue);
        }

        /**
         * Show a modal information dialog with 1 button
         * @param windowTitle title of the dialog
         * @param windowText text in the dialog
         * @param buttonText text in the button, null or empty text to show no button
         * @param buttonAction action after the button is pressed
         * @param timeoutBeforeShow miliseconds to wait before showing the dialog
         * @param versionToken required unique token for this information dialog
         */
        svc.showModalInfoDialog = function (windowTitle, windowText, buttonText, buttonAction, timeoutBeforeShow, versionToken) {
            if (versionToken != null) {
                var item = svc.tokens.value(versionToken);
                if (item != null) return; // information window with this token is already open (or already on the stack)
                svc.tokens.add(versionToken, versionToken);
            }

            var buttonTextArray = [];
            if (buttonText != null && buttonText != "") buttonTextArray.push(buttonText);
            var showSpinner = buttonTextArray.length === 0;
            return svc.showModalQuestionDialog(windowTitle, windowText, buttonTextArray, [buttonAction], false, false, null, showSpinner, timeoutBeforeShow, versionToken);
        }

    }
]

export var modalConfirmationWindowCtrl = ["$scope", "$uibModalInstance", "modalConfirmationWindowService", "translationService",
    function ($scope, $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance, modalConfirmationWindowService, translationService) {

        translationService.getTextLabels($scope);

        modalConfirmationWindowService.yesButtonText = $scope.textLabels.YES;
        modalConfirmationWindowService.noButtonText = $scope.textLabels.NO;

        $scope.modalService = modalConfirmationWindowService;
        $scope.isTimeoutCloseButtonVisible = modalConfirmationWindowService.isTimeoutCloseButtonVisible;
        $scope.getOpenWindowCount = modalConfirmationWindowService.getOpenWindowCount;

        /**
         * Copies variables from the service to the controlers scope for the current model window.
         */
        var copyVariables = function () {
            $scope.windowTitle = modalConfirmationWindowService.windowTitle;
            $scope.windowText = modalConfirmationWindowService.windowText;
            $scope.buttonText = modalConfirmationWindowService.buttonText;
            $scope.questionButtons = modalConfirmationWindowService.questionButtons;
            $scope.textValue = modalConfirmationWindowService.textValue;
            $scope.multiLine = modalConfirmationWindowService.multiLine;
            $scope.singleLine = modalConfirmationWindowService.singleLine;
            $scope.showSpinner = modalConfirmationWindowService.showSpinner;
        }
        copyVariables();

        /**
         * Close the currently open information or question window.
         */
        var closeCurrentWindow = function (result: any = undefined) {
            if (modalConfirmationWindowService.informationWindowToken == null) {
                if (!modalConfirmationWindowService.showNextDialog(true, result)) $uibModalInstance.close(result);
            } else {
                if (!modalConfirmationWindowService.closeModalWindow(modalConfirmationWindowService.informationWindowToken, result)) $uibModalInstance.close(result);
            }
        }

        /**
         * The service indicates that the variables for the current model window need to be refreshed.
         */
        $scope.$watch("modalService.windowVersion", function (newValue, oldValue, serviceScope) {
            copyVariables();
        });

        /**
         * The service indicates that the modal window should be closed.
         */
        $scope.$watch("modalService.closeNow", function (newValue, oldValue, serviceScope) {
            if (newValue === true) {
                $uibModalInstance.close();
                $scope.modalService.openedWindow = null;
                $scope.modalService.closeNow = false;
                $scope.modalService.dialogOpen = false;
            }
        });

        /**
         * User clicks the close button that appears for information windows after a certain timeout. This will close the current information window.
         */
        $scope.closeButtonClicked = function () {
            closeCurrentWindow();
        }

        /**
         * User clicks one of the defined buttons.
         */
        $scope.customButtonClicked = function (button) {
            let result = undefined;
            if (button != null && button.action != null) {
                result = button.action($scope.textValue);
            }

            closeCurrentWindow(result);
        }
    }
];