import { IPageStartService } from './../../shared/pageStartService';
import { IPlanningStatusService } from './../../shared/planningStatusService';
import { IUserService } from './../../shared/userService';
import { IConfigurationService } from './../../shared/configurationService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import { TimeSpan } from './../planboard/utils/timespan';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import { Dictionary } from './../utils/dictionary';
import * as Timezone from './../utils/timezone';

export class PlanningStatusController {

    filterDate: Date = new Date();
    organizationUnits = new Dictionary();
    organizationUnitId: number = -1;
    planningStatusObjs: Array<any> = [];
    isPlanningStatusLoaded: boolean = false;
    earliestPublishDate: Date = null;
    actionStatusObjs: Array<any> = [];  // list of status objects for which the date can be updated by the user
    publishScheduleMaxYearsDifference = null;
    advancePublicationDate: boolean = false;
    resourceTypes = new Dictionary();
    allResourceTypeIds: Array<number> = [];
    resourceTypeIds: Array<number> = [];
    allResourceTypes: boolean = true;

    private commonSvc: any;

    private readonly dialogToken: string = "planningStatus";
    private readonly GET_ORG_UNIT_URL = "api/OrganizationUnits";
    private readonly GET_RESOURCE_TYPE_URL = "api/ResourceTypes";
    private readonly GET_EARLIEST_PUBLISHED_DATE = "api/Resources/GetEarliestPublishedDate";
    private readonly GET_PLANNING_STATUS_URL = "api/Resources/GetPlanningStatus";
    private readonly SET_STATUS_DATE_URL = "api/Resources/SetPlanningStatus";
    private readonly USER_VARIABLE_ACTION_DATE = "planningStatus.actionDate.";
    private readonly STATUS_COUNT = 2;  // TODO: increment anytime a new Status is added
    private readonly PERMISSIONS = {    // TODO: add permission anytime a new status is added (key = status, value = permission string)
        2: "ResourcePlanningStatusPublished"
    }; 

    static $inject = [
        "$scope",
        "$filter",
        "pageStartService",
        "planningStatusService",
        "translationService",
        "userService",
        "configurationService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $filter: ng.IFilterService,
        private pageStartService: IPageStartService,
        private planningStatusService: IPlanningStatusService,
        private translationService: ITranslationService,
        private userService: IUserService,
        private configurationService: IConfigurationService
    ) {
        this.translationService.getTextLabels(this.$scope);

        this.configurationService.getLimitSettings(() => {
            this.publishScheduleMaxYearsDifference = this.configurationService.limitSettings.publishScheduleMaxYearsDifference;
        });

        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);

        this.$scope.$on("$destroy", () => {
            if (Planboard) Planboard.timedRefresh();
        });

        this.commonSvc.start(() => { this.initializePage(); });
    }

    private initializePage(): void {
        this.initializeDate();
        this.loadOrganizationUnits();
        this.loadResourceTypes();
        // Clear the stored dates as they may not be relevant anymore
        this.userService.deleteUserVariables(this.USER_VARIABLE_ACTION_DATE);
    }

    onFilterDateChanged(date: Date): void {
        this.filterDate = date;
        if (this.isFilterDateValid()) {
            this.userService.setUserVariable("planningStatus.filterDate", this.filterDate);
            this.loadPlanningStatus();
        }
    }

    onOrganizationUnitChanged(itemId: number): void {
        this.organizationUnitId = itemId;
        this.userService.setDisplaySettingNumber("planningStatus.organizationUnitId", this.organizationUnitId);
        this.loadPlanningStatus();
    }

    onResourceTypeChanged(): void {
        this.loadPlanningStatus();
    }

    onResourceTypeSelectAllChanged(): void {
        // Switch boolean
        this.allResourceTypes = !this.allResourceTypes;
        if (this.allResourceTypes) {
            this.resourceTypeIds = this.allResourceTypeIds;
        }
        else {
            this.resourceTypeIds = [];
        }
        this.loadPlanningStatus();
    }

    onActionDateChanged(date: Date, statusObj: any): void {
        statusObj.actionDate = date;
    }

    onUpdateButtonClick(statusObj: any): void {
        if (this.isActionDateValid(statusObj.actionDate)) {
            statusObj.actionDate = new Date(statusObj.actionDate.getFullYear(), statusObj.actionDate.getMonth(), statusObj.actionDate.getDate(), 0, 0, 0, 0);

            if (this.earliestPublishDate > statusObj.actionDate && this.advancePublicationDate) {
                this.commonSvc.showYesNoDialog(this.$scope.textLabels.PLANNING_STATUS_TITLE,
                    this.$scope.textLabels.PLANNING_STATUS_WARNING,
                    () => {
                        // Yes
                        // Store Date
                        // DATE.status.organizationUnit
                        const userVarActionDateStatusOrg = this.USER_VARIABLE_ACTION_DATE +
                            statusObj.status +
                            "." +
                            this.organizationUnitId;
                        this.userService.setUserVariable(userVarActionDateStatusOrg, statusObj.actionDate);
                        // DATE.status
                        const userVarActionDateStatus = this.USER_VARIABLE_ACTION_DATE + statusObj.status;
                        this.userService.setUserVariable(userVarActionDateStatus, statusObj.actionDate);

                        // Make API call
                        this.updatePlanningStatus(statusObj.actionDate, statusObj.status, this.advancePublicationDate);
                    },
                    null);
            } else {
                // Store Date
                // DATE.status.organizationUnit
                const userVarActionDateStatusOrg = this.USER_VARIABLE_ACTION_DATE +
                    statusObj.status +
                    "." +
                    this.organizationUnitId;
                this.userService.setUserVariable(userVarActionDateStatusOrg, statusObj.actionDate);
                // DATE.status
                const userVarActionDateStatus = this.USER_VARIABLE_ACTION_DATE + statusObj.status;
                this.userService.setUserVariable(userVarActionDateStatus, statusObj.actionDate);

                // Make API call
                this.updatePlanningStatus(statusObj.actionDate, statusObj.status, this.advancePublicationDate);
            }
        }
    }

    isFilterDateValid(): boolean {
        if (!this.filterDate) return false; // No date
        return true;
    }

    isUpdateButtonVissible(date: Date): boolean {
        return this.isActionDateValid(date) && this.resourceTypesSelected();
    }

    resourceTypesSelected(): boolean {
        return this.resourceTypeIds.length > 0;
    }

    isActionDateValid(date: Date): boolean {
        if (!date) return false; // No date
        var today = TimeSpan.today.toDate();
        if (TimeSpan.getDayNr(date) - TimeSpan.getDayNr(today) < 0) return false; // date in past
        if (this.publishScheduleMaxYearsDifference != null && date.getFullYear() - today.getFullYear() > this.publishScheduleMaxYearsDifference) return false; // later than limit setting
        return true;
    }

    private initializeDate(): void {
        if (this.userService.getUserVariable("planningStatus.filterDate")) {
            this.filterDate = this.userService.getUserVariable("planningStatus.filterDate");
            return;
        }
        var today = TimeSpan.today.toDate();
        this.filterDate = TimeSpan.fromDateNoTime(today).addDays(27).toDate();
    }

    private loadOrganizationUnits(): void {
        this.commonSvc.loadData(this.GET_ORG_UNIT_URL, null,
            (success) => {
                this.organizationUnits.clear();
                // Sort the organization units
                var sortedOrganizationUnits: Array<any> = this.$filter("orderBy")(success.data, "displayName");
                for (var i = 0, len = sortedOrganizationUnits.length; i < len; i++) {
                    var organizationUnit = sortedOrganizationUnits[i];
                    organizationUnit.order = i;
                    this.organizationUnits.add(organizationUnit.id, organizationUnit);
                }
                // Register all organization units as children to their parents.
                this.organizationUnits.forEach((key, value) => {
                    value.selectable = value.maxPermissionForCurrentUser >= 2;
                    this.registerChildToParent(value);
                });
                // Read user setting to see if user previously has selected any organization unit.
                this.organizationUnitId = this.userService.getDisplaySettingNumber("planningStatus.organizationUnitId", -1);
                if (this.organizationUnitId >= 0) {
                    this.onOrganizationUnitChanged(this.organizationUnitId);
                }
            }, null, true, false);
    }

    private loadResourceTypes(): void {
        this.commonSvc.loadData(this.GET_RESOURCE_TYPE_URL, null,
            (success) => {
                this.resourceTypes.clear();
                // Sort the resource types
                var sortedResourceTypes: Array<any> = this.$filter("orderBy")(success.data, "displayName");
                for (var i = 0, len = sortedResourceTypes.length; i < len; i++) {
                    var resourceType = sortedResourceTypes[i];
                    resourceType.order = i;
                    this.resourceTypes.add(resourceType.id, resourceType);
                    this.allResourceTypeIds.push(resourceType.id);
                }
                this.resourceTypeIds = this.allResourceTypeIds;
            }, null, true, false);
    }

    private registerChildToParent(organizationUnit: any, childId: number = null): void {
        if (!organizationUnit.childIds) organizationUnit.childIds = [];
        if (!organizationUnit.parentId) return;
        if (!childId) childId = organizationUnit.id;
        var parent = this.organizationUnits.value(organizationUnit.parentId);
        if (parent) {
            if (!parent.childIds) parent.childIds = [childId];
            else parent.childIds.push(childId);
            this.registerChildToParent(parent, childId);
        }
    }

    private loadPlanningStatus(): void {
        var params = {
            date: Timezone.rollDateForWebApi(this.filterDate),
            fromDate: null,
            organizationUnitId: this.organizationUnitId,
            resourceTypeIds: this.resourceTypeIds
        };
        this.commonSvc.post(this.GET_PLANNING_STATUS_URL, params,
            (success) => {
                var statusObjDict = new Dictionary(success.data);
                this.setPlanningStatus(statusObjDict);
            }, null, true);
        this.commonSvc.post(this.GET_EARLIEST_PUBLISHED_DATE, params,
           (response) => {
               this.earliestPublishDate = Timezone.correctTimeZoneInfo(response.data);
            }, null, true);
    }

    private updatePlanningStatus(date: Date, status: any, advancePublicationDate: boolean): void {
        var postObj = {
            date: Timezone.rollDateForWebApi(date),
            organizationUnitId: this.organizationUnitId,
            resourceTypeIds: this.resourceTypeIds,
            status: status,
            advancePublicationDate: advancePublicationDate
        };
        this.commonSvc.post(this.SET_STATUS_DATE_URL, postObj,
            (success) => {
                this.loadPlanningStatus();
                this.planningStatusService.updatePlanningStatus(true);
                if (Planboard && Planboard.activities) {
                    Planboard.activities.clearResourceExtraData();
                }
            }, null, true);
    }

    private setPlanningStatus(statusObjDict: Dictionary): void {
        this.isPlanningStatusLoaded = false;
        this.planningStatusObjs = [];
        this.actionStatusObjs = [];

        for (var status = 1; status <= this.STATUS_COUNT; status++) {
            var formattedStatusObj = {
                displayName: this.getStatusDisplayName(status),
                totalResources: this.getTotalResources(status, statusObjDict),
                actionDate: this.getInitialActionDate(status),
                status: status
                // Future improvement: store the latest (and earliest?) date that a resource has this status (nice for default actionDate)
            }
            this.planningStatusObjs.push(formattedStatusObj);

            if (status > 1) {
                if (this.userHasPermission(this.PERMISSIONS[status]))
                    this.actionStatusObjs.push(formattedStatusObj);
            }
        }

        this.isPlanningStatusLoaded = true;
    }

    private getStatusDisplayName(status: any): string {
        if (status) {
            var displayName = this.$scope.textLabels["PLANNING_STATUS_" + status.toString()];
            return displayName;
        }
        return "-";
    }

    private getTotalResources(status: any, dict: Dictionary): number {
        return dict.value(status) ? dict.value(status) : 0;
    }

    private getInitialActionDate(status: any): any {
        // DATE.status.organizationUnit
        const userVarActionDateStatusOrg = this.USER_VARIABLE_ACTION_DATE + status + "." + this.organizationUnitId;
        if (this.userService.getUserVariable(userVarActionDateStatusOrg)) {
            return this.userService.getUserVariable(userVarActionDateStatusOrg);
        }
        // DATE.status
        const userVarActionDateStatus = this.USER_VARIABLE_ACTION_DATE + status;
        if (this.userService.getUserVariable(userVarActionDateStatus)) {
            return this.userService.getUserVariable(userVarActionDateStatus);
        }
        return null;
    }

    private userHasPermission(permissionName: string): boolean {
        return this.pageStartService.userHasPermission(permissionName, this.$scope, null);
    }

}