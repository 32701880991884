import { StateService } from '@uirouter/angularjs';
import { IPageStartService } from './../../../shared/pageStartService';
import { IUserService } from './../../../shared/userService';

import { ITranslationService } from './../../i18n/translationService';
import { IPermissionService } from './../../permissions/permissionService';

import { ITreeListScope } from './../../treeListController/ITreeListScope';
import { TreeEntity } from './../../treeListController/TreeEntity';
import { TreeListController } from './../../treeListController/TreeListController';

import { IModalConfirmationWindowService } from './../../utils/modalConfirmationWindowService';

import { IResourcePropertiesScope } from './IResourcePropertyScope';
import { ResourceProperty } from './resourceProperty';
import { ResourcePropertyName } from './resourcePropertyName';

export class ResourcePropertiesController extends TreeListController {

    scope: IResourcePropertiesScope;

    static $inject = [
        "$http",
        "$q",
        "$scope",
        "$state",
        "$timeout",
        "$filter",
        "$window",
        "permissionService",
        "modalConfirmationWindowService",
        "translationService",
        "pageStartService",
        "userService"
    ];

    constructor(
        $http: ng.IHttpService,
        $q: ng.IQService,
        $scope: IResourcePropertiesScope,
        $state: StateService,
        $timeout: ng.ITimeoutService,
        $filter: ng.IFilterService,
        $window: ng.IWindowService,
        permissionService: IPermissionService,
        modalConfirmationWindowService: IModalConfirmationWindowService,
        translationService: ITranslationService,
        protected pageStartService: IPageStartService,
        userService: IUserService) {

        super($http, $q, $scope, $state, $timeout, $filter, permissionService, modalConfirmationWindowService, translationService, pageStartService, userService);

        $window.location.href = "/angularomrp/program-management/resource-properties";

        this.apiUrl = "api/ResourceProperties";

        //See whether the currenly logged in user has the "ResourceProperties" permission and set flags accordingly.
        permissionService.userHasPermission("ResourceProperties", $scope.verificationStatus, $scope);

        this.getEntities();
    } 

    protected createNewEntity(): TreeEntity {
        const entity = new ResourceProperty();
        entity.id = -1;
        entity.displayName = this.newEntityDisplayName(); 

        // Create a ResourcePropertyName for this entity type (ResourceProperty)
        const rpn = new ResourcePropertyName();
        rpn.resourcePropertyId = entity.id;
        rpn.languageCode = this.scope.language;
        rpn.text = this.newEntityDisplayName();
        entity.resourcePropertyNames.push(rpn);

        return entity;
    }

    /**
        * Writability of ResourceProperty items only depend on the user permission for the ResourceProperty page
        */
    protected isSelectedItemWritable(): boolean { return true; }

    /**
    * Initialize entity values that may not be set by the webAPI
    * @param entity the entity to initialize
    */
    protected setEntityDefaultValues(entity: TreeEntity, myScope: ITreeListScope) {
        super.setEntityDefaultValues(entity, myScope);

        // Set the display name to the resource property name defined for the user's language.
        const rp = entity as ResourceProperty;
        rp.displayName = null;
        for (let rpn of rp.resourcePropertyNames) {
            if (rpn.languageCode === myScope.language) {
                rp.displayName = rpn.text;
            }
        }

        // Default to a resource property name in another language if a name in the user's language is not available.
        if (rp.displayName == null) {
            if (rp.resourcePropertyNames.length > 0) {
                rp.displayName = rp.resourcePropertyNames[0].text + " " + myScope.textLabels.RESOURCE_PROPERTY_TRANSLATION_NEEDED;
            }
            else {
                rp.displayName = myScope.textLabels.RESOURCE_PROPERTY_TRANSLATION_NEEDED;
            }
        }
    }

    /**
        * Sets the entity.displayName to the ResourcePropertyName.text of the user selected language
        */
    protected newEntityDisplayName(): string {
        return this.scope.textLabels.NEW_RESOURCEPROPERTY;
    }

    /**
        * Processes the ResourceProperty specific renaming and calls this.onEntityChanged(false) afterwards
        */
    protected onDisplayNameChanged() {
        const selItem = this.scope.selectedItem as ResourceProperty;
        const len = selItem.resourcePropertyNames.length;
        let found = false;
        for (let i = 0; i < len; i++)
        {
            const rpn = selItem.resourcePropertyNames[i];
            found = rpn.languageCode === this.scope.language;
            if (found) {
                rpn.text = this.scope.selectedItem.displayName;
                break;
            }
        }
        if (!found) {
            selItem.resourcePropertyNames.push(
                new ResourcePropertyName(selItem.id, this.scope.language, selItem.displayName));
        }

        this.onEntityChanged(false, this.scope.selectedItem);
    }

    /**
    * Returns the title of the modal delete confirmation window.
    * ResourceProperty specific override
    */
    protected getDeleteConfirmationWindowTitle(): string {
        return this.scope.textLabels.RESOURCE_PROPERTIES_DELETION_MODAL_TITLE;
    }

    /**
        * Returns the text of the modal delete confirmation window.
        * ResourceProperty specific override 
    */
    protected getDeleteConfirmationWindowText(): string {
        return this.scope.textLabels.RESOURCE_PROPERTIES_DELETION_MODAL_TEXT;
    }
       
}
