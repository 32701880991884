import { Dictionary } from './../../utils/dictionary';

import { AreaControl } from './../controls/areaControl';
import { PictureControl } from './../controls/pictureControl';

import { DrawHelper } from './../drawing/drawing';
import * as CachedObjectIds from './../drawing/cachedObjectIds';
import * as Symbols from './../drawing/symbols';

import * as Globals from './../utils/globals';

import { Planboard } from './../entities/planboard';
import { Resource } from './../entities/resource';

import { PlanboardSplitters } from './planboardSplitters'
import { PlannedActivities } from './plannedActivities'

export class PlanboardResources {
    /**
        * the Id of a resource for who the whole row should be highlighted
        */
    static selectedResourceId = -1;

    /**
        * rememberd initialized sizes for columns per resourcePropertyId
        */
    static initializedColumnSizes = new Dictionary();

    /**
        * the number of fixed columns before resource properties
        * currently these are: displayname, organization unit
        */
    static fixedColumns = 2;

    /**
        * the resource where the user last clicked on the violations icon
        */
    static mouseDownViolationsResource = null;

    /**
        * helper method to iterate over all visible unit memberships for a resource
        */
    static forEachVisibleUnitForResource(r: Resource, action: (key: any, value: any) => void) {
        const units = Planboard.activities.getResourceUnits(r);
        let lastDate = new Date(Planboard.leftDate.getTime());
        lastDate.setDate(lastDate.getDate() + Planboard.areaMain.cols.count - 1);
        // The variable units is a dictionary where the key is the organization unit id and the value is a list of organization unit memberships.
        units.forEach((key, value) => {
            let unitList: any[] = value;
            if (unitList.length > 0) {
                let unit = unitList[unitList.length - 1]; // get the last membership for the organization unit
                if ((unit.start == null || unit.start <= lastDate) &&
                    (unit.end == null || unit.end >= Planboard.leftDate) &&
                    unit.organizationUnitDisplayName != null && unit.organizationUnitDisplayName !== "")
                    action(key, unit);
            }
        });
    }

    static initialize() {
        const areaResources = Planboard.areaResources = new AreaControl(Planboard.split1.getSplitArea(0, 1), 0, 0, 100, 100, 3000, 2000);
        areaResources.name = "areaResources";
        areaResources.setUseBackbuffer(true);
        areaResources.setAlign(0, 0, 0, 0);
        areaResources.backcolor = Globals.windowColor;
        areaResources.gridColor = Globals.gridLinesColor;
        areaResources.clearColor = areaResources.backcolor;
        areaResources.cols.setSize(0, Planboard.split1.getHorizontalSize(0), 16);
        areaResources.rows.setSize(Planboard.activities.getResourceCount() - 1, Globals.fontHeight + 1 + Planboard.getPlanningStatusRowHeight(), 8);
        areaResources.linkVertical(Planboard.areaMain);
        areaResources.vBarVisible = false;
        areaResources.drawCell = (t: AreaControl, ctx: CanvasRenderingContext2D, col: number, row: number, colWidth: number, rowHeight: number, contextX: number, contextY: number) => {
            const r = Planboard.activities.getResource(Planboard.activities.getResourceId(row));
            if (!r) return;
            if (r.id === PlanboardResources.selectedResourceId) {
                ctx.fillStyle = Globals.highlightColor;
                ctx.fillRect(contextX, contextY, colWidth - 1, rowHeight - 1);
            }
            const resPropCol = col - PlanboardResources.fixedColumns;
            let text = "";
            if (col === 0) text = r.displayName;
            else if (col === 1) {
                ctx.fillStyle = Globals.windowTextColor;
                ctx.textAlign = Globals.alignLeft;
                ctx.textBaseline = Globals.alignMiddle;
                let rowY = 0;
                let enlargeRow = false;
                this.forEachVisibleUnitForResource(r, (key, value) => {
                    if (rowY + Globals.fontHeight <= rowHeight)
                        ctx.fillText(`${value.organizationUnitDisplayName}`, Math.floor(contextX + 4), Math.floor(contextY + Globals.fontHeight * 0.5 + rowY));
                    else
                        enlargeRow = true;
                    rowY += Globals.fontHeight;
                });
                if (enlargeRow) { // draw enlarge row corner
                    let cornerSize = (Globals.fontHeight >> 1) + 1;
                    Symbols.drawCorner(CachedObjectIds.enlargeRowId, ctx, contextX + colWidth - cornerSize, contextY + rowHeight - cornerSize, cornerSize,
                        true, true, Globals.face3DColor, Globals.gridLinesColor, "+", Globals.darker3DColor);
                }
            }
            else if (Planboard.resourceProperties != null && resPropCol >= 0 && resPropCol < Planboard.resourceProperties.length) {
                const props = Planboard.activities.getResourceProperties(r);
                props.tryGetValue(Planboard.resourceProperties[resPropCol].resourcePropertyId, (value) => { text = value; });
            }
            if (text !== "") {
                ctx.fillStyle = Globals.windowTextColor;
                ctx.textAlign = Globals.alignLeft;
                ctx.textBaseline = Globals.alignMiddle;
                ctx.fillText(`${text}`, Math.floor(contextX + 4), Math.floor(contextY + rowHeight * 0.5));
            }
        }
        areaResources.mouseDownCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            const r = Planboard.activities.getResource(Planboard.activities.getResourceId(row));
            let selectRow = true;
            this.mouseDownViolationsResource = null;
            if (button === 0 && celY < Globals.fontHeight && mouseX > t.clientArea.width - Globals.fontHeight) {
                // mouse down on violations icon with left mouse button
                if (r && Planboard.activities.getResourceViolations(r.id) > 0) {
                    this.mouseDownViolationsResource = r;
                    selectRow = false;
                }
            }
            if (r && col === 1) { // enlarge row corner click on organization units column?
                const colWidth = t.cols.getSize(col);
                const rowHeight = t.rows.getSize(row);
                const cornerSize = (Globals.fontHeight >> 1) + 1;
                if (celX >= colWidth - cornerSize && celY >= rowHeight - cornerSize && (colWidth - celX) + (rowHeight - celY) <= cornerSize) {
                    let newRowHeight = 1;
                    this.forEachVisibleUnitForResource(r, (key, value) => { newRowHeight += Globals.fontHeight; });
                    if (newRowHeight > t.rows.getSize(row)) {
                        const resourceId = Planboard.activities.getResourceId(row);
                        Planboard.saveResourceLineheight(resourceId, newRowHeight);
                        t.resizeRow(row, newRowHeight, true);
                        selectRow = false;
                    }
                }
            }
            if (r && selectRow) {
                if (button === 0 || PlanboardResources.selectedResourceId !== r.id) // left mouse button (===0) to toggle between select and deselect, other mouse button is select only
                    PlanboardResources.selectedResourceId = r.id === PlanboardResources.selectedResourceId ? -1 : r.id;
                Planboard.areaMain.redrawAll();
                Planboard.areaResources.redrawAll();
                Planboard.areaCounters.redrawAll();
                Planboard.controller.redraw();
            }
            Planboard.onMouseDownCell(t, col, row, celX, celY, button, mouseX, mouseY);
        }
        areaResources.mouseUpCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            if (celY < Globals.fontHeight && mouseX > t.clientArea.width - Globals.fontHeight) {
                const r = Planboard.activities.getResource(Planboard.activities.getResourceId(row));
                if (r && r === this.mouseDownViolationsResource && Planboard.activities.getResourceViolations(r.id) > 0) {
                    // clicked on violations icon
                    Planboard.onViolationIconClicked(r);
                }
            }
            Planboard.onMouseUpCell(t, col, row, celX, celY, button, mouseX, mouseY);
        }
        areaResources.dragDropCell = Planboard.areaMain.dragDropCell;
        this.initMouseMoveCell();

        // create an unclickable overlay picture that lays on top of the resources area
        const areaResourcesOverlay = new PictureControl(Planboard.split1.getSplitArea(0, 1), 0, 0, 100, 100);
        areaResourcesOverlay.setAlign(0, 0, 0, Globals.scrollBarSize); // set anchors to always fill the whole area (except scrollbar at the bottom)
        areaResourcesOverlay.capturesMouse = false; // ignore all mouse actions
        areaResourcesOverlay.drawPicture = (t: PictureControl, ctx: CanvasRenderingContext2D, contextX: number, contextY: number, width: number, height: number) => {
            const area = Planboard.areaResources;
            let row = area.rows.nrAtPos(area.innerTop);
            let y = area.rows.getPos(row) - area.innerTop;
            while (y < height && row < area.rows.count) {
                const rowHeight = area.rows.getSize(row);
                const resourceId = Planboard.activities.getResourceId(row);
                if (Planboard.activities.getResourceViolations(resourceId) > 0) {
                    // draw a warning symbol if this resource has any violations
                    Symbols.drawWarning(CachedObjectIds.symbolWarningId, ctx, contextX + area.clientArea.width - Globals.fontHeight, contextY + y + 1, Globals.fontHeight - 2);
                }
                y += rowHeight; row++;
            }
        }

        const areaResourceHeader = Planboard.areaResourceHeader = new AreaControl(Planboard.split1.getSplitArea(0, 0), 0, 0, 100, 100, 3000, 2000);
        areaResourceHeader.name = "areaResourceHeader";
        areaResourceHeader.setUseBackbuffer(true);
        areaResourceHeader.setAlign(0, 0, 0, 0);
        areaResourceHeader.backcolor = Globals.windowColor;
        areaResourceHeader.gridColor = "";
        areaResourceHeader.clearColor = areaResourceHeader.backcolor;
        areaResourceHeader.cols.setSize(areaResources.cols.count - 1, areaResources.cols.getSize(0), areaResources.cols.getMinimum(0));
        areaResourceHeader.rows.setSize(0, PlanboardSplitters.timelineHeight(), 0);
        areaResourceHeader.linkHorizontal(areaResources);
        areaResourceHeader.hBarVisible = false;
        areaResourceHeader.vBarVisible = false;
        areaResourceHeader.enableColumnResize = true;
        areaResourceHeader.drawCell = (t: AreaControl, ctx: CanvasRenderingContext2D, col: number, row: number, colWidth: number, rowHeight: number, contextX: number, contextY: number) => {
            DrawHelper.draw3DRect(ctx, contextX, contextY, colWidth, rowHeight);
            ctx.fillStyle = Globals.darker3DColor;
            ctx.textAlign = Globals.alignLeft;
            ctx.textBaseline = Globals.alignMiddle;
            const resPropCol = col - PlanboardResources.fixedColumns;
            const text = col == 0 ? Planboard.getTextLabel("DISPLAY_NAME") :
                col == 1 ? Planboard.getTextLabel("ORGANIZATION_UNIT") :
                    Planboard.resourceProperties != null && resPropCol >= 0 && resPropCol < Planboard.resourceProperties.length ? Planboard.resourceProperties[resPropCol].text : "";
            ctx.fillText(text, Math.floor(contextX + 4), Math.floor(contextY + rowHeight * 0.5));
        }
        areaResourceHeader.dragDropCell = Planboard.areaMain.dragDropCell;
        areaResourceHeader.mouseDownCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            Planboard.onMouseDownCell(t, col, row, celX, celY, button, mouseX, mouseY);
        }
        areaResourceHeader.mouseUpCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            Planboard.onMouseUpCell(t, col, row, celX, celY, button, mouseX, mouseY);
        }
    }

    private static initMouseMoveCell() {
        Planboard.areaResources.mouseMoveCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            PlannedActivities.moveIndicators(t, mouseX, mouseY);
        }
    }

}