export class TreeEntity {
    /**
        * Id of this entity.
        */
    id: number;

    /**
        * Display name of this entity.
        */
    displayName: string;

    /**
        * Id of the parent entity. Null/undefined denotes that this entity is a root node.
        */
    parentId: number;

    /**
        * Array of children of this entity. Will not have any items for leaf nodes or if the tree is actually a list.
        */
    nodes: TreeEntity[];

    /**
        * Indicates if this entity was changed and is not yet saved.
        */
    changed: boolean;

    /**
        * Indicates that saving has failed.
        */
    updateFailed: boolean;

    /**
        * Entity may not be deleted if this change is still pending.
        */
    changeOfDeletabilityPending: boolean;

    /**
        * Maximum permission for the current user, based on user groups. Only relevant for entities that have user group permissions.
        */
    maxPermissionForCurrentUser: number;

    /**
        * List of user group permissions.
        */
    userGroupPermissionList: any[]; // TODO: do not use any, define the type

    /**
        * The order number in what order this entity was received.
        */
    receivedOrder: number;

    /**
        * Level in the tree for this entity. 0 for root entities, children have the parent's level + 1.
        */
    treeLevel: number;
};