import { ITranslationService } from './../i18n/translationService';
import { ITreeListScope } from './../treeListController/ITreeListScope';
import { IConfigurationService } from "./../../shared/configurationService";

export const indexPage: angular.IComponentOptions = {
    template: require("./indexPage.html"),
    controller: indexPageController
}

indexPageController.$inject = [
    "$scope",
    "$rootScope",
    "translationService",
    "configurationService",
    "$document"
];

function indexPageController(
    $scope: ITreeListScope,
    $rootScope: ng.IRootScopeService,
    translationService: ITranslationService,
    configurationService: IConfigurationService,
    $document
) {
    this.$scope = $scope;
    translationService.getTextLabels($scope);
    this.showRedTestBorder = false;
    this.enableTestBorderHiding = false;

    $rootScope.$on("severeError", (event, data) => {
        this.severeErrorTitle = data.errorTitle;
        this.severeErrorMessage = data.errorText;
        $("#severeError").modal("toggle");
    });

    $document.bind('keyup', ((event) => {
        if (event.key === 'ArrowDown' && event.ctrlKey && event.shiftKey && this.enableTestBorderHiding) {
            sessionStorage.setItem('redBorder', 'false');
            this.showRedTestBorder = false;
            this.$scope.$apply();
        }
    }).bind(this));

    $rootScope.$on("isUserLoggedIn", (event, data) => {
        if (data.isUserLoggedIn) {
            configurationService.getAppConfiguration(() => {
                var environmentSettings = configurationService.appConfiguration.environmentSettings;
                this.enableTestBorderHiding = String(configurationService.appConfiguration.featureManagement.enableTestBorderHiding).toLowerCase() === "true";

                if (environmentSettings) {
                    const isNonProductionEnvironment = String(environmentSettings.isNonProductionEnvironment).toLowerCase() === "true";

                    if (isNonProductionEnvironment) {
                        const redBorderFromSession = sessionStorage.getItem('redBorder');
                        this.showRedTestBorder = redBorderFromSession !== 'false';
                    }
                }
            });
        }
    });
}