import moment = require("moment");
/**
* Corrects the timezone offset for a date.
* @param date Date to correct.
* @returns The corrected date.
*/
export function correctTimeZoneInfo(date: any): Date {
    if (date == null) return null;
    if (!date.getTime) date = new Date(date);
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000); //  remove timezone
}

/**
* Corrects the timezone offset for a date using Moment.js.
* @param date Date to correct.
* @returns The corrected date as a Moment.js object in UTC.
*/
export function correctTimeZoneInfoWithMoment(date: any): moment.Moment {
    if (date == null) return null;
    if (!date.getTime) date = new Date(date);
    let utcDate = moment.utc(date);

    return utcDate;
}

/**
    * Prepares a date so that it is correctly passed to the WebApi, without any timezone shifting.
    * @param date
    */
export function rollDateForWebApi(date: any): string {
    if (date == null) return null;
    if (!date.getTime) date = new Date(date);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString(); //  remove timezone
}

/**
    * Parses a date string and makes sure it is timezone-independent.
    * @param dateString Date string to parse.
    * @returns Timezone-independent date representation of the input string.
    */
export function parseDate(dateString: string) {
    return new Date("" + dateString + (dateString.charAt(dateString.length - 1) !== "Z" ? "Z" : ""));
}

/**
    * Convert a number of minutes to a string respresentation of hours and minutes.
    */
export function minutesToStr(minutes: any) {
    var minValue = Number(minutes);
    if (minValue < 0) return "";
    var hourPart = Math.floor(minValue / 60);
    var minutePart = minValue - (hourPart * 60);
    var result = "" +
        (hourPart < 10 ? "0" + hourPart : "" + hourPart) +
        ":" +
        (minutePart < 10 ? "0" + minutePart : "" + minutePart);
    return result;
}

// Get number of weeks in a year
export function getNumberOfWeeks(year: number) {
    var date1 = new Date();
    date1.setFullYear(year, 11, 31);

    var date2 = new Date();
    date2.setFullYear(year, 0, 1);

    // ISO week rule: a year with Jan 1 or Dec 31 on a Thursday has 53 weeks.
    if (date1.getDay() === 4 || date2.getDay() === 4)
        return 53;
    else
        return 52;
} 

/* For a given date, get the ISO week number
    * Algorithm is to find nearest thursday, it's year
    * is the year of the week number. Then get weeks
    * between that date and the first day of that year.
    * Note that dates in one year can be weeks of previous
    * or next year, overlap is up to 3 days.
    * e.g. 2014/12/29 is Monday in week  1 of 2015
    *      2012/1/1   is Sunday in week 52 of 2011
    * @param d the date to get the week number for
    */
export function getWeekNumber(d: Date) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d.valueOf() - yearStart.valueOf()) / 86400000) + 1) / 7);
    // Return week number and year
    return { week: weekNo, year: d.getUTCFullYear() }
}

/**
    * convert a date to a string with format: yyyy-mm-dd
    * @param d the date to convert to string
    */
export function dateToStr(d: Date): string {
    let s = `${d.getDate()}`;
    while (s.length < 2) s = `0${s}`;
    s = `${d.getMonth() + 1}-${s}`;
    while (s.length < 5) s = `0${s}`;
    s = `${d.getFullYear()}-${s}`;
    while (s.length < 10) s = `0${s}`;
    return s;
}