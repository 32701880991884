import { CanvasBuffer } from './../utils/canvasbuffer';
import * as Globals from './../utils/globals';
import { ObjectList } from './../utils/objectlist';
import { Rect } from './../utils/rect';

export class DrawHelper {
    private static buffer: CanvasBuffer = null;
    private static objects: ObjectList = null;

    /**
        * get the shared drawing buffer
        */
    static get sharedBuffer(): CanvasBuffer {
        if (DrawHelper.buffer == null) {
            DrawHelper.buffer = new CanvasBuffer("DrawingBuffer", 1600, 800);
            DrawHelper.objects = new ObjectList();
        }
        return DrawHelper.buffer;
    }

    /**
        * recreate and clear the buffer and increase the revision number
        */
    static reset() {
        if (DrawHelper.buffer != null) DrawHelper.buffer.reset();
    }

    /**
        * get information about a stored object.
        * returns: [revision number, rectangle on shared buffer, changed state].
        * if the changestate in returnvalue[2] equals false then the object should be redrawn.
        * @param id unique id of the object
        * @param width new width of the object
        * @param height new height of the object
        */
    static getStoredObject(id: number, width: number, height: number): [number, Rect, boolean] {
        const b = DrawHelper.sharedBuffer;
        let stored: [number, Rect, boolean] = DrawHelper.objects.getObject(id); // revision, rectangle, previously stored
        if (stored == undefined || (stored[0] !== b.revision || stored[1].width !== width || stored[1].height !== height)) {
            stored = [b.revision, b.getRect(width, height), false]; // false: it was not previously stored
            DrawHelper.objects.addObject(id, stored);
        } else
            stored[2] = true; // true: it was previously stored
        return stored;
    }

    /**
        * remove information about a stored object
        * @param id
        */
    static eraseStoredObject(id: number) {
        DrawHelper.objects.removeObject(id);
    }

    /**
        * draw a filled rectangle with the default colors defined in globals
        * @param ctx canvas context to draw on
        * @param left x position
        * @param top y position
        * @param width width of the rectangle
        * @param height height of the rectangle
        */
    static draw3DRect(ctx: CanvasRenderingContext2D, left: number, top: number, width: number, height: number) {
        ctx.fillStyle = Globals.face3DColor;
        ctx.fillRect(left, top, width, height);
        ctx.fillStyle = Globals.light3DColor;
        ctx.fillRect(left, top, width, 1);
        ctx.fillRect(left, top, 1, height);
        ctx.fillStyle = Globals.dark3DColor;
        ctx.fillRect(left, top + height - 1, width, 1);
        ctx.fillRect(left + width - 1, top, 1, height);
    }
}