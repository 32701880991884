import { StateParams } from '@uirouter/angularjs';
import { INumberService } from './../../shared/numberService';
import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import * as Globals from './../planboard/utils/globals';
import { TimeSpan } from './../planboard/utils/timespan';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import * as Constants from './../utils/constants';
import { Dictionary } from './../utils/dictionary';
import { IModalConfirmationWindowService } from './../utils/modalConfirmationWindowService';
import * as Timezone from './../utils/timezone';

export class ResourcePeriodBoundPropertiesController {
    resourceName = "";
    selectedPeriodBoundPropertyType = "orgUnits";
    organizationUnits: any = Object.create(null);
    resourceTypes: any = Object.create(null);
    skills = new Dictionary();
    emptyTree: any = Object.create(null);
    warningMessage = "";

    private commonSvc: any;
    private saveChangesTimer: angular.IPromise<any> = null;
    private saveChangesTimerRunning: boolean = false;
    private testItemValidTimer: angular.IPromise<any> = null;
    private testItemValidTimerRunning: boolean = false;
    private periodItems: Array<any> = [];
    private skillLevels: Dictionary = new Dictionary();
    private selectedItem: any = null;
    private changesMade: boolean = false;
    private changesInType: any = Object.create(null); // selectedPeriodBoundPropertyType a change was made in
    private organizationUnitsLoaded: boolean = false;
    private skillsLoaded: boolean = false;
    private skillLevelsLoaded: boolean = false;
    private periodboundInitialDates: any = { from: undefined, to: undefined };

    private readonly apiUrl: string = "api/Resources";
    private readonly apiRouteOrganizationUnits: string = this.apiUrl + "/OrganizationUnitMembershipPeriods";
    private readonly apiRoutePercentages: string = this.apiUrl + "/PercentagePeriods";
    private readonly apiRouteMaxOccupation: string = this.apiUrl + "/MaxOccupationPeriods";
    private readonly apiRouteResourceType: string = this.apiUrl + "/ResourceTypeMembershipPeriods";
    private readonly apiRouteSkills: string = this.apiUrl + "/SkillMembershipPeriods";
    private readonly apiRouteDaypartsNorm: string = this.apiUrl + "/DaypartsNorm";
    private readonly automaticSaveDelay: number = 5000;
    private readonly dialogToken: string = "periodBoundPropInfo";
    private readonly permission: string = "Resources";
    private readonly propertyTypes: Array<string> = ["orgUnits", "percentages", "maxOccupations", "resourceTypes", "skills", "daypartsNorm"];

    static $inject = [
        "$scope",
        "$stateParams",
        "$timeout",
        "modalConfirmationWindowService",
        "numberService",
        "pageStartService",
        "translationService",
        "userService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $stateParams: StateParams,
        private $timeout: ng.ITimeoutService,
        private modalConfirmationWindowService: IModalConfirmationWindowService,
        private numberService: INumberService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, this.permission, this.dialogToken);

        this.$scope.$on("$destroy", () => {
            if (userService.getUserVariable("resources.selectedItem") != undefined) // must have already been saved
                userService.setUserVariable("resources.selectedItem", this.selectedItem); // update with new values for resourceManagement
            if (this.testItemValidTimerRunning) this.$timeout.cancel(this.testItemValidTimer);
            this.stopSaveChangesTimer();
            this.saveChanges(true);
            this.restoreInvalidChanges();
        });

        this.commonSvc.start(() => { this.loadData(); });
    }

    private loadData() {
        // create empty tree
        this.emptyTree[-1] =
            {
                id: -1,
                displayName: this.$scope.textLabels.FILTER_NONE,
                level: Globals.maxInt,
                order: -Globals.maxInt
            };
        // load complete resource
        if (!this.tryGetSavedVariable("resources.selectedItem",
            (savedVar) => {
                this.selectedItem = savedVar;
                this.resourceName = this.selectedItem.displayName;
                this.handleEmptySkillLevels(this.selectedItem);
            }))
            this.commonSvc.loadData(this.apiUrl + "/" + this.$stateParams.resourceId, null,
                (success) => {
                    if (success.data) {
                        this.selectedItem = success.data;
                        this.resourceName = this.selectedItem.displayName;
                        this.handleEmptySkillLevels(this.selectedItem);
                    }
                },
                null, true);
        // load organization units
        if (!this.tryGetSavedVariable("resources.relatedOrganizationUnitDict",
            (savedVar) => {
                this.organizationUnits = savedVar.getStore();
                this.determineUnitWritability(this.organizationUnits);
                this.organizationUnitsLoaded = true;
            }))
            this.commonSvc.loadData("api/OrganizationUnits", this.organizationUnits,
                (success) => {
                    this.determineUnitWritability(this.organizationUnits);
                    this.organizationUnitsLoaded = true;
                },
                null, true, true);
        // load resource types
        if (!this.tryGetSavedVariable("resources.relatedResourceTypeDict",
            (savedVar) => {
                this.resourceTypes = savedVar.getStore();
                this.resourceTypes[-1] = this.emptyTree[-1];
            }))
            this.commonSvc.loadData("api/ResourceTypes", this.resourceTypes, (success) => {
                this.resourceTypes[-1] = this.emptyTree[-1];
            }, null, true, true);
        // load skills
        if (!this.tryGetSavedVariable("resources.relatedSkillDict",
            (savedVar) => {
                savedVar.forEach((id, item) => { this.addSkill(item) });
                this.skillsLoaded = true;
            }))
            this.commonSvc.loadData("api/Skills", null,
                (success) => {
                    if (success.data && success.data.length > 0) {
                        for (var i = 0; i < success.data.length; i++) this.addSkill(success.data[i]);
                    }
                    this.skillsLoaded = true;
                },
                null, true, true);
        // load skill levels
        if (!this.tryGetSavedVariable("resources.relatedSkillLevelDict",
            (savedVar) => {
                savedVar.forEach((id, item) => { this.addSkillLevel(item) });
                this.skillLevelsLoaded = true;
            }))
            this.commonSvc.loadData("api/SkillLevels", null,
                (success) => {
                    if (success.data && success.data.length > 0) {
                        for (var i = 0; i < success.data.length; i++) this.addSkillLevel(success.data[i]);
                    }
                    this.skillLevelsLoaded = true;
                },
                null, true);
    }

    onAddPeriodClick(): void {
        // determine unique identifier
        var id = 1;
        for (var i = 0; i < this.periodItems.length; i++)
            if (this.periodItems[i].newId != undefined && this.periodItems[i].newId + 1 > id)
                id = this.periodItems[i].newId + 1;

        var startDate = new Date(); // start date of the new period is today
        startDate.setHours(0, 0, 0, 0); // remove time from date

        this.periodItems.push({
            id: -1,
            newId: id,
            modified: true,
            pending: false,
            waitingForId: false,
            organizationUnitId: this.getInitialOrganizationUnitId(),
            resourceTypeId: this.getInitialResourceTypeId(),
            periodPercentage: 100,
            periodOccupation: 1,
            skillId: -1,
            skillLevelId: -1,
            daypartsNorm: 0,
            start: this.periodboundInitialDates.from != undefined ? this.periodboundInitialDates.from : startDate,
            end: this.periodboundInitialDates.to != undefined ? this.periodboundInitialDates.to : null,
            resourceId: this.selectedItem.id
        });

        this.changesMade = true;
        this.startSaveChangesTimer();
    }

    onRemovePeriodClick(period: any): void {
        var periodBoundPropertyType = this.selectedPeriodBoundPropertyType;
        this.modalConfirmationWindowService
            .showModalDialog(this.$scope.textLabels.PERIOD_DELETION_MODAL_TITLE,
                this.$scope.textLabels.PERIOD_DELETION_MODAL_TEXT,
                () => {
                    // remove the item from the $scope.periodItems array
                    var index = this.periodItems.length;
                    while (index > 0 && periodBoundPropertyType === this.selectedPeriodBoundPropertyType) {
                        index--;
                        if ((period.id > 0 && this.periodItems[index].id === period.id) ||
                            (period.newId != undefined && this.periodItems[index].newId === period.newId)) {
                            this.periodItems.splice(index, 1);
                        }
                    }
                    // send the webApi delete
                    this.sendDeletePeriod(period, periodBoundPropertyType);
                },
                null);
    }

    periodChanged(period: any): void {
        period.modified = true;
        this.changesMade = true;
        this.startSaveChangesTimer();

        // add the id of this resource to the recentlyModifiedEntityIds dictionary
        var recentlyModifiedEntityIds = this.userService.getUserVariable("resources.recentlyModifiedEntityIds");
        if (recentlyModifiedEntityIds) recentlyModifiedEntityIds.add(this.selectedItem.id, this.selectedItem.id);

        // test if the item is valid with a timer, because the changes are currently still busy in an $apply state
        if (this.testItemValidTimerRunning) this.$timeout.cancel(this.testItemValidTimer);
        this.testItemValidTimer = this.$timeout(() => {
            this.testItemValidTimerRunning = false;
            this.testSaveItems();
        }, 500);
        this.testItemValidTimerRunning = true;
    }

    periodStartDateChanged(date: Date, period: any): void {
        this.periodChanged(period);
        this.periodboundInitialDates.from = date;
    }

    periodEndDateChanged(date: Date, period: any): void {
        this.periodChanged(period);
        this.periodboundInitialDates.to = date;
    }

    onPeriodBoundPropertyChanged(): void {
        if (!this.selectedItem) return;

        var propertyTypeNr = this.propertyTypes.indexOf(this.selectedPeriodBoundPropertyType);

        if (propertyTypeNr === 1) { // Percentages
            for (let i = 0; i < this.selectedItem.percentages.length; i++)
                this.checkForConsistencyWarnings(this.selectedItem.percentages[i],
                    this.selectedItem.percentages[i].organizationUnitId
                );

        } else if (propertyTypeNr === 2) { // MaxOccupations
            for (let i = 0; i < this.selectedItem.maxOccupations.length; i++)
                this.checkForConsistencyWarnings(this.selectedItem.maxOccupations[i],
                    this.selectedItem.maxOccupations[i].organizationUnitId
                );

        } else if (propertyTypeNr === 3) { // ResourceTypes
            for (let i = 0; i < this.selectedItem.resourceTypes.length; i++)
                this.checkForConsistencyWarnings(this.selectedItem.resourceTypes[i],
                    this.selectedItem.resourceTypes[i].organizationUnitId
                );

        } else if (propertyTypeNr === 4) { // Skills
            for (let i = 0; i < this.selectedItem.skills.length; i++)
                this.checkForConsistencyWarnings(this.selectedItem.skills[i],
                    this.selectedItem.skills[i].organizationUnitId
                );
        } // DaypartsNorm (propertyTypeNr === 5) does not contain an organization unit nor a resource type, no need for a consistency warning check
    }

    periodOrganizationUnitChanged(itemId: number, period: any) {
        // Save the selected unit to use it as an initial value for unit dropdowns later.
        this.userService.setUserVariable(Constants.periodboundInitialOrgUnitUserVar, itemId);

        this.checkForConsistencyWarnings(period, itemId);

        // does the previously selected skillId exist in the skills tree for the new organization unit (wich has id: itemId)
        if (period.skillId != undefined && period.skillId !== -1 &&
            this.getSkillsForOrganizationUnit(itemId)[period.skillId] == undefined)
            period.skillId = -1;

        // does the previously selected skillLevelId exist in the skillLevels tree for the new organization unit (wich has id: itemId)
        if (period.skillLevelId != undefined && period.skillLevelId !== -1 &&
            this.getSkillLevelsForOrganizationUnit(itemId)[period.skillLevelId] == undefined)
            period.skillLevelId = -1;

        this.periodChanged(period);
    }

    periodResourceTypeChanged(itemId: number, period: any) {
        // Save the selected unit to use it as an initial value for unit dropdowns later.
        this.userService.setUserVariable(Constants.periodBoundInitialResourceTypeUserVar, itemId);

        const propertyTypeNr = this.propertyTypes.indexOf(this.selectedPeriodBoundPropertyType);
        if (propertyTypeNr === 1 || propertyTypeNr === 2) // Percentages or MaxOccupations
            this.checkForConsistencyWarnings(period, period.organizationUnitId, itemId);

        this.periodChanged(period);
    }

    getPeriodItems(): Array<any> {
        // set periodItems to the same reference as a property in $scope.selectedItem
        var propertyTypeNr = this.propertyTypes.indexOf(this.selectedPeriodBoundPropertyType);
        if (this.selectedItem == null || propertyTypeNr < 0)
            this.periodItems = []; // no valid data loaded
        else
            this.periodItems = this.getItemListForPropertyType(propertyTypeNr);

        // store a copy of each item as originalItem, also determine writability
        for (var i = 0; i < this.periodItems.length; i++) {
            var item = this.periodItems[i];
            var orgUnit;
            if (propertyTypeNr === 5) {
                // check resource's organization units for write permission on dayparts-norm
                var resourceOrgUnits = this.selectedItem.organizationUnits;
                item.readonly = true;
                var j = 0;
                while (item.readonly && j < resourceOrgUnits.length) {
                    orgUnit = this.organizationUnits[resourceOrgUnits[j].organizationUnitId];
                    if (orgUnit != undefined) {
                        item.readonly = !orgUnit.writable;
                    }
                    j++;
                }
            }
            else if (item.organizationUnitId > -1) {
                // check resource bound property's organization unit for write permission
                orgUnit = this.organizationUnits[item.organizationUnitId];
                item.readonly = orgUnit == undefined ? true : !orgUnit.writable;
            }
            if (item.originalItem != undefined) continue;
            this.storeOriginalItem(item);
        }
        return this.periodItems;
    }

    getSkillsForOrganizationUnit(organizationUnitId): any {
        return this.getTreeForOrganizationUnit(Number(organizationUnitId), this.skills);
    }

    getSkillLevelsForOrganizationUnit(organizationUnitId): any {
        return this.getTreeForOrganizationUnit(Number(organizationUnitId), this.skillLevels);
    }

    isColOrganizationUnitVisible(): boolean {
        return this.selectedPeriodBoundPropertyType !== "daypartsNorm";
    }

    isColResourceTypeVisible(): boolean {
        return this.selectedPeriodBoundPropertyType === "percentages" ||
            this.selectedPeriodBoundPropertyType === "resourceTypes" ||
            this.selectedPeriodBoundPropertyType === "maxOccupations";
    }

    isColPercentageVisible(): boolean {
        return this.selectedPeriodBoundPropertyType === "percentages";
    }

    isColMaxOccupationVisible(): boolean {
        return this.selectedPeriodBoundPropertyType === "maxOccupations";
    }

    isColSkillVisible(): boolean {
        return this.selectedPeriodBoundPropertyType === "skills";
    }

    isColDaypartsNormVisible(): boolean {
        return this.selectedPeriodBoundPropertyType === "daypartsNorm";
    }

    filterTextValue($event: any, oldValue: string, allowDecimal: boolean, period: any) {
        if (period.readonly) {
            $event.preventDefault();
            return;
        }

        this.periodChanged(period);
        this.numberService.filterTextValue($event, oldValue, allowDecimal);
    }

    isColWarningVisible(): boolean {
        var items = this.getPeriodItems();
        for (var i = 0; i < items.length; i++) {
            if (items[i].itemNotConsistent) return true;
        }
        return false;
    }

    private saveChanges(calledFromDestroy: boolean): void {
        if (!this.changesMade) return;
        this.changesMade = false;

        if (this.testItemValidTimerRunning) this.$timeout.cancel(this.testItemValidTimer);
        this.testItemValidTimerRunning = false;

        var itemsWithoutId = false;

        // make sure the planboard will reload data
        Planboard.clearData();

        for (var propertyTypeNr = 0; propertyTypeNr < this.propertyTypes.length; propertyTypeNr++) {
            var itemList = this.getItemListForPropertyType(propertyTypeNr);
            var url = this.getUrlForPropertyType(propertyTypeNr);

            for (var i = 0; i < itemList.length; i++) {
                var period = itemList[i];

                if (!period.modified) continue; // skip this item, it is not modified

                if (period.waitingForId) {
                    // change was made to new item, for wich we do not yet know an id, make sure to restart the timer at the end of this function
                    itemsWithoutId = true;
                    continue; // skip this item, we can not save it because we do not know the id
                }

                // determine if the item is valid (complete), only send valid items
                if (!this.testPeriodItem(propertyTypeNr, period)) continue;

                if (period.id < 0) period.waitingForId = true; // set a flag that we need to wait for the item to be created

                period.pending = true; // set a flag that there is a pending webapi post

                // Correct timezone info.
                // This is turning the dates into strings!
                if (period.start) period.start = Timezone.rollDateForWebApi(period.start);
                if (period.end) period.end = Timezone.rollDateForWebApi(period.end);


                // post the period item to insert or update
                ((period: any, url: string, propertyTypeNr: number) => {
                    this.commonSvc.post(url, period,
                        (success) => {
                            period.failed = false;
                            period.pending = false;
                            period.waitingForId = false;
                            if (period.id < 0) period.id = success.data.id;

                            // Overwrite skill level display name if skill level was set to "none".
                            if (period.skillLevelId === -1) period.skillLevelDisplayName = "";
                            this.storeOriginalItem(period); // save successful so this becomes the originalItem
                            this.removeUnnecesaryPendingFlags(propertyTypeNr);
                        },
                        (error) => {
                            this.restoreOriginalItem(period); // save failed, restore the originalItem
                            period.failed = true;
                            period.pending = false;
                            period.waitingForId = false;
                            period.itemNotComplete = true; // show attention to this item
                            this.commonSvc.httpErrorResponse(error, () => { });
                            this.removeUnnecesaryPendingFlags(propertyTypeNr);
                        },
                        false);
                })(period, url, propertyTypeNr); // create copies of parameters to remember in the post result

                // Change start and end back to dates if needed
                if (period.start && !period.start.getTime) period.start = new Date(period.start);
                if (period.end && !period.end.getTime) period.end = new Date(period.end);

                period.modified = false; // reset modified flag for future changes
            }

            if (!this.changesMade) this.removeUnnecesaryPendingFlags(propertyTypeNr);
        }

        // restart timer if there were changes to new items without an id
        if (itemsWithoutId && !calledFromDestroy) {
            this.changesMade = true;
            this.saveChangesTimer = this.$timeout(() => { this.saveChanges(false); }, this.automaticSaveDelay);
            this.saveChangesTimerRunning = true;
        }
    }

    private startSaveChangesTimer(): void {
        this.stopSaveChangesTimer();
        if (this.changesMade) {
            this.addPendingFlag(this.selectedPeriodBoundPropertyType);
            this.saveChangesTimer = this.$timeout(() => { this.saveChanges(false); }, this.automaticSaveDelay);
            this.saveChangesTimerRunning = true;
        }
    }

    private stopSaveChangesTimer(): void {
        if (this.saveChangesTimerRunning) this.$timeout.cancel(this.saveChangesTimer);
        this.saveChangesTimerRunning = false;
    }

    private sendDeletePeriod(period: any, periodBoundPropertyType: any): void {
        if (period == undefined || period.id == undefined || period.id <= 0) return;

        // make sure the planboard will reload data
        Planboard.clearData();

        // remember that we are waiting for this period to be deleted
        var flagName = "delete" + period.id + periodBoundPropertyType;
        this.addPendingFlag(flagName);

        // get the api url for the type of period we want to delete
        var url = "";
        if (periodBoundPropertyType === this.propertyTypes[0]) url = this.apiRouteOrganizationUnits;
        else if (periodBoundPropertyType === this.propertyTypes[1]) url = this.apiRoutePercentages;
        else if (periodBoundPropertyType === this.propertyTypes[2]) url = this.apiRouteMaxOccupation;
        else if (periodBoundPropertyType === this.propertyTypes[3]) url = this.apiRouteResourceType;
        else if (periodBoundPropertyType === this.propertyTypes[4]) url = this.apiRouteSkills;
        else if (periodBoundPropertyType === this.propertyTypes[5]) url = this.apiRouteDaypartsNorm;

        this.commonSvc.deleteData(url + "/" + period.id,
            (success) => {
                this.removePendingFlag(flagName);
            },
            (error) => {
                this.removePendingFlag(flagName);
                // add the removed item again if the selected propertyType is still the same
                if (periodBoundPropertyType === this.selectedPeriodBoundPropertyType)
                    this.periodItems.push(period);
                this.commonSvc.httpErrorResponse(error, () => { });
            },
            false);
    }

    private addPendingFlag(name: string): void {
        this.changesInType[name] = true;
        this.userService.setLogoffWaitTime(this.dialogToken, this.automaticSaveDelay);
    }

    private removePendingFlag(name: string): void {
        delete this.changesInType[name];
        if (!this.isChangePending()) this.userService.setLogoffWaitTime(this.dialogToken, 0);
    }

    private isChangePending(): boolean {
        for (var key in this.changesInType)
            if (Object.prototype.hasOwnProperty.call(this.changesInType, key))
                if (this.changesInType[key] === true)
                    return true;
        return false;
    }

    private removeUnnecesaryPendingFlags(propertyTypeNr: number): void {
        if (this.changesInType[this.propertyTypes[propertyTypeNr]] == undefined) return; // flag is not set anyway

        var itemList = this.getItemListForPropertyType(propertyTypeNr);

        // test if there are still modified or pending items
        for (var i = 0; i < itemList.length; i++)
            if (itemList[i].modified || itemList[i].pending || itemList[i].waitingForId) return;

        this.removePendingFlag(this.propertyTypes[propertyTypeNr]);
    }

    private testSaveItems(): void {
        var itemList = this.getPeriodItems();
        var propertyTypeNr = this.propertyTypes.indexOf(this.selectedPeriodBoundPropertyType);

        for (var i = 0; i < itemList.length; i++) {
            var period = itemList[i];
            if (!period.modified) continue; // skip this item, it is not modified
            this.testPeriodItem(propertyTypeNr, period);
        }
    }

    private testPeriodItem(propertyTypeNr: number, period: any): boolean {
        var itemValid = (propertyTypeNr === 5 || period.organizationUnitId > 0) && this.hasBeginningBeforeEnd(period);
        if (propertyTypeNr === 1 && (period.percentage == undefined || period.percentage === "")) itemValid = false;
        if (propertyTypeNr === 1 && (period.resourceTypeId == undefined || period.resourceTypeId <= 0)) itemValid = false;
        if (propertyTypeNr === 2 && (period.maxOccupation == undefined || period.maxOccupation === "")) itemValid = false;
        if (propertyTypeNr === 3 && (period.resourceTypeId == undefined || period.resourceTypeId <= 0)) itemValid = false;
        if (propertyTypeNr === 4 && (period.skillId == undefined || period.skillId <= 0)) itemValid = false;
        if (propertyTypeNr === 5 && (period.daypartsNorm == undefined || period.daypartsNorm === "")) itemValid = false;
        period.itemNotComplete = !itemValid; // indicates that the user still needs to select something for this period item
        return itemValid;
    }

    private restoreInvalidChanges(): void {
        for (var propertyTypeNr = 0; propertyTypeNr < this.propertyTypes.length; propertyTypeNr++) {
            var itemList = this.getItemListForPropertyType(propertyTypeNr);
            var itemsRestored = 0;

            for (var i = 0; i < itemList.length; i++) {
                var period = itemList[i];
                if (period.itemNotComplete) {
                    this.restoreOriginalItem(period);
                    itemsRestored++;
                    period.pending = false;
                    period.waitingForId = false;
                    period.modified = false;
                    period.itemNotComplete = false;
                }
            }

            if (itemsRestored > 0) this.removeUnnecesaryPendingFlags(propertyTypeNr);
        }
    }

    private addSkill(skill: any): void {
        for (var i = 0; i < skill.validOrganizationUnitIds.length; i++) {
            if (!this.skills.containsKey(skill.validOrganizationUnitIds[i])) {
                this.skills.add(skill.validOrganizationUnitIds[i], Object.create(null));
                this.skills.value(skill.validOrganizationUnitIds[i])[-1] = this.emptyTree[-1];
            }
            this.skills.value(skill.validOrganizationUnitIds[i])[skill.id] = skill;
        }
    }

    private addSkillLevel(skillLevel: any): void {
        if (!this.skillLevels.containsKey(skillLevel.organizationUnitId)) {
            this.skillLevels.add(skillLevel.organizationUnitId, Object.create(null));
            this.skillLevels.value(skillLevel.organizationUnitId)[-1] = this.emptyTree[-1];
        }
        skillLevel.order = -skillLevel.level; // make sure the dropdowntree maintains the order of the skill levels
        this.skillLevels.value(skillLevel.organizationUnitId)[skillLevel.id] = skillLevel;
    }

    private handleEmptySkillLevels(selectedItem: any): void {
        for (var i = 0; i < selectedItem.skills.length; i++)
            if (selectedItem.skills[i].skillLevelId == undefined)
                selectedItem.skills[i].skillLevelId = -1;
    }

    private determineUnitWritability(organizationUnits: any): void {
        for (let key in organizationUnits) {
            organizationUnits[key].writable = organizationUnits[key].maxPermissionForCurrentUser >= Constants.permWrite;
        }
    }

    private tryGetSavedVariable (name: string, callbackFunc: (savedVar: any) => void): boolean {
        var savedVar = this.userService.getUserVariable(name);
        if (savedVar != undefined) {
            callbackFunc(savedVar);
            return true;
        }
        return false;
    }

    private isBeginDateBeforeEndDate(beginDate: any, endDate: any): boolean {
        if (!beginDate || !endDate) return true;

        // Let's ensure that these dates are dates and not strings
        if (!beginDate.getTime) beginDate = new Date(beginDate);
        if (!endDate.getTime) endDate = new Date(endDate);

        return TimeSpan.getDayNr(endDate) - TimeSpan.getDayNr(beginDate) >= 1;
    }

    private hasBeginningBeforeEnd(period: any): boolean {
        return this.isBeginDateBeforeEndDate(period.start, period.end);
    }

    private getParentOrganizationUnits(organizationUnitId: number): Array<any> {
        var result = [];
        while (organizationUnitId >= 0) {
            var orgUnit = this.organizationUnits[organizationUnitId];
            var parentId = -1;
            if (orgUnit != undefined && orgUnit.parentId != undefined) parentId = parseInt(orgUnit.parentId);
            if (parentId > 0 && result.indexOf(parentId) < 0) {
                organizationUnitId = parentId;
                result.push(organizationUnitId);
            } else
                organizationUnitId = -1;
        }
        return result;
    }

    private mergeParentOrganizationUnits(organizationUnitId, treeDict, tree): void {
        if (treeDict.value("_merged" + organizationUnitId) === true) return; // already done
        if (this.organizationUnitsLoaded && this.skillsLoaded && this.skillLevelsLoaded)
            treeDict.add("_merged" + organizationUnitId, true); // mark as already done

        var parents = this.getParentOrganizationUnits(organizationUnitId);
        for (var i = 0; i < parents.length; i++) {
            var parentTree = treeDict.value(parents[i]);
            if (parentTree == undefined) continue;
            for (var key in parentTree) tree[key] = parentTree[key];
        }
    }

    private getTreeForOrganizationUnit(id, treeDict): Array<any> {
        var tree = treeDict.value(id); // get the tree for this organization unit
        if (tree == undefined) { // get the tree for a parent organization unit
            var parents = this.getParentOrganizationUnits(id);
            for (var i = 0; i < parents.length; i++) {
                id = parents[i]; // remember this id for the merge at the end of this function
                tree = treeDict.value(id);
                if (tree != undefined) break;
            }
        }
        if (tree == undefined) return this.emptyTree; // return the default tree with only the "none selected" item
        this.mergeParentOrganizationUnits(id, treeDict, tree); // merge all parent trees into this tree
        return tree;
    }

    private getItemListForPropertyType(propertyTypeNr: number): any {
        var itemList = [];
        if (propertyTypeNr === 0) itemList = this.selectedItem.organizationUnits;
        else if (propertyTypeNr === 1) itemList = this.selectedItem.percentages;
        else if (propertyTypeNr === 2) itemList = this.selectedItem.maxOccupations;
        else if (propertyTypeNr === 3) itemList = this.selectedItem.resourceTypes;
        else if (propertyTypeNr === 4) itemList = this.selectedItem.skills;
        else if (propertyTypeNr === 5) itemList = this.selectedItem.daypartsNorm;
        return itemList;
    }

    private getUrlForPropertyType(propertyTypeNr: number): string {
        if (propertyTypeNr === 0) return this.apiRouteOrganizationUnits;
        else if (propertyTypeNr === 1) return this.apiRoutePercentages;
        else if (propertyTypeNr === 2) return this.apiRouteMaxOccupation;
        else if (propertyTypeNr === 3) return this.apiRouteResourceType;
        else if (propertyTypeNr === 4) return this.apiRouteSkills;
        else if (propertyTypeNr === 5) return this.apiRouteDaypartsNorm;
        else return "";
    }

    private getInitialOrganizationUnitId(): number {
        return this.userService.getUserVariable(Constants.periodboundInitialOrgUnitUserVar) || -1;
    }

    private getInitialResourceTypeId(): number {
        return this.userService.getUserVariable(Constants.periodBoundInitialResourceTypeUserVar) || -1;
    }

    private storeOriginalItem(item: any): void {
        if (item.originalItem == undefined) item.originalItem = Object.create(null);
        for (var key in item)
            if (Object.prototype.hasOwnProperty.call(item, key) && key !== "originalItem")
                item.originalItem[key] = item[key];
    }

    private restoreOriginalItem(item: any): void {
        if (item.originalItem == undefined) return;
        for (var key in item.originalItem)
            if (Object.prototype.hasOwnProperty.call(item.originalItem, key))
                item[key] = item.originalItem[key];
    }

    private doesParentPeriodIncludeChild(parentPeriod: any, childPeriod: any) {
        var parentStart = parentPeriod.start ? new Date(parentPeriod.start) : null;
        var parentEnd = parentPeriod.end ? new Date(parentPeriod.end) : null;
        var childStart = childPeriod.start ? new Date(childPeriod.start) : null;
        var childEnd = childPeriod.end ? new Date(childPeriod.end) : null;

        return TimeSpan.periodContainsPeriod(parentStart, parentEnd, childStart, childEnd);
    }

    private isOrgUnitParentOfOrgUnitPeriod(periodOrgUnitId: number) {
        // foreach period in organizationUnit periods get parent ids of unit if the org unit period falls within the given period 
        var parentOrgUnitIds = [];
        for (let i = 0; i < this.selectedItem.organizationUnits.length; i++)
            parentOrgUnitIds =
                parentOrgUnitIds.concat(
                    this.getParentOrganizationUnits(this.selectedItem.organizationUnits[i].organizationUnitId));

        // check if periodOrgUnitId exists in list
        if (parentOrgUnitIds.indexOf(periodOrgUnitId) > -1)
            return true;

        return false;
    }

    private isOrgUnitSameAsOrgUnitPeriod(periodOrgUnitId: number) {
        for (let i = 0; i < this.selectedItem.organizationUnits.length; i++)
            if (this.selectedItem.organizationUnits[i].organizationUnitId === periodOrgUnitId)
                return true;
        return false;
    }

    private isOrgUnitChildOfOrgUnitPeriod(periodOrgUnitId: number) {
        // get parentIds of period organization units
        const parentOrgUnitIds = this.getParentOrganizationUnits(periodOrgUnitId);

        // foreach period in organizationUnit periods return true if the org unit period falls within the given period
        for (let i = 0; i < this.selectedItem.organizationUnits.length; i++)
            if (parentOrgUnitIds.indexOf(this.selectedItem.organizationUnits[i].organizationUnitId) > -1)
                return true;

        return false;
    }

    private doesPeriodHaveValidResourceType(periodResourceTypeId: number) {
        for (let i = 0; i < this.selectedItem.resourceTypes.length; i++)
            if (this.selectedItem.resourceTypes[i].resourceTypeId == periodResourceTypeId)
                return true;
        return false;
    }

    private checkForConsistencyWarnings(period: any, organizationUnitId: number, resourceTypeId: number = null) {
        const propertyTypeNr = this.propertyTypes.indexOf(this.selectedPeriodBoundPropertyType);
        resourceTypeId = resourceTypeId ? resourceTypeId : period.resourceTypeId;

        if (propertyTypeNr === 1) { // Percentages
            period.itemNotConsistent =
                !((this.isOrgUnitSameAsOrgUnitPeriod(organizationUnitId) ||
                        this.isOrgUnitChildOfOrgUnitPeriod(organizationUnitId) ||
                        this.isOrgUnitParentOfOrgUnitPeriod(organizationUnitId)) &&
                    this.doesPeriodHaveValidResourceType(resourceTypeId));
            if (period.itemNotConsistent)
                this.warningMessage = this.$scope.textLabels.RESOURCES_PERIOD_BOUND_WARNING_RESOURCE_TYPE;

        } else if (propertyTypeNr === 2) { // MaxOccupations
            period.itemNotConsistent =
                !((this.isOrgUnitSameAsOrgUnitPeriod(organizationUnitId) ||
                        this.isOrgUnitChildOfOrgUnitPeriod(organizationUnitId) ||
                        this.isOrgUnitParentOfOrgUnitPeriod(organizationUnitId)) &&
                    this.doesPeriodHaveValidResourceType(resourceTypeId));
            if (period.itemNotConsistent)
                this.warningMessage = this.$scope.textLabels.RESOURCES_PERIOD_BOUND_WARNING_RESOURCE_TYPE;

        } else if (propertyTypeNr === 3) { // ResourceTypes
            period.itemNotConsistent =
                !(this.isOrgUnitSameAsOrgUnitPeriod(organizationUnitId) ||
                    this.isOrgUnitChildOfOrgUnitPeriod(organizationUnitId) ||
                    this.isOrgUnitParentOfOrgUnitPeriod(organizationUnitId));
            if (period.itemNotConsistent)
                this.warningMessage = this.$scope.textLabels.RESOURCES_PERIOD_BOUND_WARNING_ORG_UNIT;

        } else if (propertyTypeNr === 4) { // Skills
            period.itemNotConsistent =
                !(this.isOrgUnitSameAsOrgUnitPeriod(organizationUnitId) ||
                    this.isOrgUnitChildOfOrgUnitPeriod(organizationUnitId) ||
                    this.isOrgUnitParentOfOrgUnitPeriod(organizationUnitId));
            if (period.itemNotConsistent)
                this.warningMessage = this.$scope.textLabels.RESOURCES_PERIOD_BOUND_WARNING_ORG_UNIT;
        } // DaypartsNorm (propertyTypeNr === 5) does not contain an organization unit nor a resource type
    }

}